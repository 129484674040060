import { Chip, Grid, ImageListItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import emotionSelectorStyles from "CSS/Components/EmotionsSelector.module.css";
import styles from "CSS/Components/JournalEntry.module.css";
import { USER_PROFILE_PICTURE_S3_URL } from "Constants/Constants";
import { setSelectedJournal } from "Reducers/JournalReducer";
import { convertScale } from "Utilities/adjust-scale";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const JournalEntry = memo(function temp({ journalEntry }) {
  const [day, setDay] = useState(moment());
  const [time, setTime] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setDay(
      moment(journalEntry.date).format("MMMM") +
      " " +
      moment(journalEntry.date).date()
    );
    setTime(
      ", " +
      moment(journalEntry.date)
        .local()
        .format("hh:mm A")
        .replace(/(^|-)0+/g, "")
    ); // remove leading 0 on date
  }, [journalEntry.date]);

  const handleImageSelection = (entry) => {
    dispatch(setSelectedJournal(entry));
  };

  return (
    <section className={styles.container}>
      <div
        className={`${styles.info}
        }`}
      >
        <Grid container>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={12}>
                <div className={styles.entryTitle} onClick={() => navigate(`/update-journal/${journalEntry._id}`)}>
                  {journalEntry.textReview.length > 15
                    ? <Typography variant="body1" noWrap={true} style={{ maxWidth: "80%", fontWeight: "bold" }}>
                      {journalEntry.textReview}
                    </Typography>
                    : journalEntry.textReview.length == 0
                      ? day + " " + time
                      : (
                        <Typography variant="body1" noWrap={true} style={{ maxWidth: "80%", fontWeight: "bold" }}>
                          {journalEntry.textReview}
                        </Typography>
                      )}
                </div>
              </Grid>
              {
                journalEntry.textReview.length !== 0 && (
                  <Grid item xs={12}>
                    <p className={styles.entrySubTitle} onClick={() => navigate(`/update-journal/${journalEntry._id}`)}>
                      {moment(journalEntry.date).isSame(moment(), "day")
                        ? "Today" + time
                        : day + time}
                    </p>
                  </Grid>
                )
              }
            </Grid>
          </Grid>
          <Grid item container xs={3} justifyContent="flex-end">
            <div className={styles.progress}>
              <CircularProgressbarWithChildren
                className={styles.progressBar}
                value={convertScale(journalEntry.emotionRating, 10)}
                strokeWidth={12}
                styles={buildStyles({
                  rotation: 0.25,
                  strokeLinecap: "round",
                  pathTransitionDuration: 0.3,
                  pathColor: `rgba(11, 126, 161, ${convertScale(
                    journalEntry.emotionRating,
                    10
                  )})`,
                  trailColor: "#d6d6d6",
                  backgroundColor: "#0b7ea1",
                })}
              >
                <p className={styles.progressText}>{journalEntry.emotionRating}</p>
              </CircularProgressbarWithChildren>
            </div>
          </Grid>
        </Grid>
        <div style={{ marginTop: "0.5em" }}>
          <Grid container>
            {journalEntry.images.map((image, index) => (
              <Grid item xs={journalEntry.images.length === 1 ? 12 : 5} key={image.fileName} className={styles.journalImageContainer}>
                <ImageListItem key={index} onClick={() => handleImageSelection(journalEntry)}>
                  <img
                    crossOrigin="anonymous"
                    className={styles.journalImage}
                    src={`${USER_PROFILE_PICTURE_S3_URL}journal/${image.fileName}`}
                    loading="lazy"
                    alt="journal entry image"
                  />
                </ImageListItem>
              </Grid>
            ))}
          </Grid>
        </div>
        <div style={{ marginTop: "0.5em" }}>
          {journalEntry.emotions
            .filter(emotion => emotion.selected === true)
            .slice(0, 2)
            .map((emotion, index) => (
              <Chip
                key={index}
                color="primary"
                label={emotion.label}
                className={`
                  ${emotionSelectorStyles.emotionsSelected} 
                  ${emotionSelectorStyles.emotionChip}`}
              />
            ))}
        </div>
      </div>
    </section>
  );
});

export default JournalEntry;
