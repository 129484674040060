import AddFab from "Components/Helpers/AddFab";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import UndoButton from "Components/Helpers/UndoButton";
import Wrapper from "Components/Helpers/Wrapper";
import ToDo from "Components/ToDo/ToDo";
import styles from "CSS/Screens/ToDosScreen.module.css";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCompletedToDos,
  getToDos,
  resetAddToDoSuccess,
  resetDeleteToDoSuccess,
  selectToDos,
} from "Reducers/ToDoReducer";
import undoToDo from "Utilities/undo-todo";

const ToDosScreen = () => {
  const dispatch = useDispatch();
  const [showUndo, setShowUndo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {
    daily,
    upcoming,
    completed,
    loading,
    error,
    successAdd,
    successDelete,
  } = useSelector(selectToDos);

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new to do!");
      timer = setTimeout(() => {
        dispatch(resetAddToDoSuccess());
      }, 2000);
    }
    return timer;
  };

  // displays undo bottom when habit is deleted
  const handleSuccessDelete = () => {
    if (successDelete) {
      // show undo popup after user deletes todo
      setShowUndo(true);
      setTimeout(() => {
        setShowUndo(false);
        dispatch(resetDeleteToDoSuccess());
      }, 5000);
    }
  };

  useEffect(() => {
    // fetch all todos
    dispatch(getToDos());
    dispatch(getCompletedToDos({ type: "cdaily" }));

    if (error) {
      setErrorMessage(error);
    }
  }, [dispatch, error]);

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();
    handleSuccessDelete();

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, successAdd, successDelete]);

  const displayList = (list) =>
    list?.map((todo) => (
      <ToDo key={todo._id} todo={todo} />
    ));

  return (
    <Screen>
      <Wrapper>
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        {/* display success message when user adds todo */}
        <AlertMessage
          message={successMessage}
          setMessage={() => {
            setSuccessMessage("");
            dispatch(resetAddToDoSuccess());
          }}
          type="success"
          toggle={successAdd}
        />
        <h3 className={`${styles.header} ${styles.headerFirst}`}>
          ⏰ Today&apos;s To-Do&apos;s
        </h3>
        {loading && daily?.length === 0 && upcoming?.length === 0 && (
          <LoadingSpinner />
        )}
        {daily?.length === 0 && (
          <Link to="/add-todo" className={styles.fillerButton}>
            <span className={styles.fillerPlus}>+</span> Add daily to-do
          </Link>
        )}
        {displayList(daily)}

        <h3 className={styles.header}>🗓 Upcoming To-Do&apos;s</h3>
        {upcoming?.length === 0 && (
          <p className={styles.filler}>No to-do&apos;s due in the next 7 days</p>
        )}
        {displayList(upcoming)}
        <div className={isMobile ? styles.mobileSection : styles.section}>
          <div className={`${styles.header} ${styles.completedHeader}`}>
            <h3>✔️ Completed Today</h3>
            <Link to="/completed-todos" className={styles.viewHistory}>
              View All
            </Link>
          </div>
          {completed?.length === 0 && (
            <p className={styles.filler}>Go get things done!</p>
          )}
          {displayList(completed)}
        </div>
      </Wrapper>
      <UndoButton
        callback={() => {
          setShowUndo(false);
          dispatch(resetDeleteToDoSuccess());
          undoToDo(dispatch);
        }}
        showUndo={showUndo}
      />
      <AddFab url="/add-todo" />
    </Screen>
  );
};

export default ToDosScreen;
