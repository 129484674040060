import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";


// send backend request to fetch all behavioral experiments
const getBehavioralExperiments = createAsyncThunk("get experiments", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/behavioral-entry"),
    thunkAPI
  )
);

// send backend request to add new behavioral experiment
const addBehavioralExperiments = createAsyncThunk("add experiments", (
  { date, thoughts, details, prediction, feeling }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/behavioral-entry",
      {
        date,
        thoughts,
        details,
        prediction,
        feeling,
        complete: false
      },
    ),
    thunkAPI
  )
);

//send backend request to edit experiemnt after completion
const editBehavioralExperiment = createAsyncThunk("edit behavioral experiment", (
  { id, feelingAfter, newThought, outcome }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/behavioral-entry/${id}`,
      {
        outcome,
        feelingAfter,
        newThought,
        complete: true
      },
    ),
    thunkAPI
  )
);

//send backend request to update experiment after editing
const updateExperiment = createAsyncThunk("update behavioral experiemnt", (
  { id, date, thoughts, details, prediction, feeling }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/behavioral-entry/update/${id}`,
      {
        date,
        thoughts,
        details,
        prediction,
        feeling,
        complete: false
      },
    ),
    thunkAPI
  )
);

//send backend request to add an attempt to experiment
const addExperimentAttempt = createAsyncThunk("edit behavioral experiment", (
  { id, obstacle, change }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/behavioral-entry/attempt/${id}`, { obstacle, change }),
    thunkAPI
  )
);


export {
  addBehavioralExperiments, addExperimentAttempt, editBehavioralExperiment,
  getBehavioralExperiments, updateExperiment
};

