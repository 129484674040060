import btn from "CSS/Button.module.css";
import styles from "CSS/Components/HabitHistoryItem.module.css";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";

const HabitHistoryItem = ({ date, progress, id, unit }) => {
  const { pathname } = useLocation();

  return (
    <div className={styles.body}>
      <div className={styles.info}>
        <div className={styles.item}>
          Date: <span>{moment(date).local().format("MMMM D, YYYY")}</span>
        </div>
        <div className={styles.item}>
          Progress:{" "}
          <span>
            {progress} {unit}
          </span>
        </div>
      </div>
      <Link
        to={`/habits/instance/${id}?redirect=${pathname}`}
        className={`${btn.faded_blue} ${styles.btn}`}
      >
        Edit
      </Link>
    </div>
  );
};
export default HabitHistoryItem;
