import { Grid } from "@mui/material";
import ToggleCard from "Components/Helpers/ToggleCard";
import { subscribeToJournalToDo } from "Reducers/Actions/SubscriptionActions.js";
import {
  getSubscription,
  selectSubscription,
  subscribeToApointment,
  subscribeToJournal,
  subscribeToToDos
} from "Reducers/SubscriptionReducer";
import { convertLocalTimeToUTC } from "Utilities/date-time.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTokenFromFirebase } from "../../firebase.js";

const NotificationScreen = () => {
  const {
    token,
    isJournalNotifEnabled,
    isApointmentNotifEnabled,
    isToDoNotifEnabled,
    journalTime,
    isJournalToDoEnabled,
    isJournalNotifEnabledLoading,
    isApointmentNotifEnabledLoading,
    isToDoNotifEnabledLoading,
    isJournalToDoEnabledLoading,
  } = useSelector(selectSubscription);
  const dispatch = useDispatch();

  // Getting user subscription details.
  useEffect(() => {
    dispatch(getSubscription());
  }, [dispatch]);

  const handleJournalToggleChange = async () => {
    const token = await checkToken();
    dispatch(
      subscribeToJournal({
        token,
        isEnabled: !isJournalNotifEnabled,
      })
    );
  };

  const handleApointmentToggleChange = async () => {
    const token = await checkToken();
    dispatch(
      subscribeToApointment({
        token,
        isEnabled: !isApointmentNotifEnabled,
      })
    );
  };

  const handleToDoToggleChange = async () => {
    const token = await checkToken();
    dispatch(
      subscribeToToDos({
        token,
        isEnabled: !isToDoNotifEnabled,
      })
    );
  };

  const handleJournalToDoToggleChange = async () => {
    const token = await checkToken();
    dispatch(
      subscribeToJournalToDo({
        token,
        isEnabled: !isJournalToDoEnabled,
      })
    );
  };

  const onTimeChange = async (localTime) => {
    const utcTimeString = convertLocalTimeToUTC(localTime);

    dispatch(
      subscribeToJournal({
        token,
        isEnabled: isJournalNotifEnabled,
        time: utcTimeString,
      })
    );
  };

  const checkToken = async () => {
    if (!token) {
      const newToken = await getTokenFromFirebase();
      return newToken;
    } else {
      return token;
    }
  };

  return (
    <Grid container item xs={12} justifyContent="left">
      <ToggleCard
        cardLabel="Journal Notification"
        subLabel="Get notifications to write daily journal"
        isChecked={isJournalNotifEnabled}
        onChange={handleJournalToggleChange}
        isJournal={true}
        time={journalTime}
        onTimeChange={onTimeChange}
        loading={isJournalNotifEnabledLoading}
      />
      <ToggleCard
        cardLabel="Journal To-Do"
        subLabel="Remind me if I have not journaled for the past three days"
        isChecked={isJournalToDoEnabled}
        onChange={handleJournalToDoToggleChange}
        loading={isJournalToDoEnabledLoading}
      />
      <ToggleCard
        cardLabel="Appointment Notification"
        subLabel="Give me notification after my apointment"
        isChecked={isApointmentNotifEnabled}
        onChange={handleApointmentToggleChange}
        loading={isApointmentNotifEnabledLoading}
      />
      <ToggleCard
        cardLabel="To-Do Notification"
        subLabel="Give me notifications for my to-do"
        isChecked={isToDoNotifEnabled}
        onChange={handleToDoToggleChange}
        loading={isToDoNotifEnabledLoading}
      />
    </Grid>
  );
};

export default NotificationScreen;
