
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/BehavioralExperimentEntry.module.css";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { resetBehavioralExperiment, selectBehavioralExperiment } from "Reducers/BehavioralExperimentReducer";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BehavioralCompleteViewScreen = () => {
    // const redirectUrl = useRedirect("/toolkit/complete-experiment-entry");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        selectedExperiment,
    } = useSelector(selectBehavioralExperiment);

    const handleBackClick = () => {
        dispatch(resetBehavioralExperiment());
        navigate("/toolkit/behavioral-experiments");
    };

    const handleEditClick = () => {
        navigate("/toolkit/complete-experiment-entry");
    };

    const [day, setDay] = useState(moment());
    const [time, setTime] = useState("");
    useEffect(() => {
        setDay(
            moment(selectedExperiment.date).format("MMMM") +
            " " +
            moment(selectedExperiment.date).date()
        );
        setTime(
            ", " +
            moment(selectedExperiment.date)
                .local()
                .format("hh:mm A")
                .replace(/(^|-)0+/g, "")
        ); // remove leading 0 on date
    }, [selectedExperiment.date]);

    return (
        <Screen>
            <Wrapper>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    style={{ marginTop: "2em" }}>
                    <Grid item xs={7}>
                        <button
                            className={`
                                ${btn.faded_orange} 
                                ${btn.smallActivityBtn} 
                                ${styles.loadMoreBtn}`
                            }
                            onClick={handleBackClick}>
                            Back to experiments
                        </button>
                    </Grid>
                    <Grid item xs={4} className={`${styles.rightAlign}`}>
                        <button
                            className={`
                                ${btn.faded_blue} 
                                ${btn.smallActivityBtn} 
                                ${styles.loadMoreBtn}`
                            }
                            onClick={handleEditClick}>
                            Edit
                        </button>
                    </Grid>
                </Grid>
                <Grid
                    className={`${styles.marginTop}`}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Grid xs={10} item>
                        <Grid item
                            sx={{
                                background: "#fff",
                                padding: "1.25rem",
                                borderRadius: "0.5rem",
                                border: "solid 0.15rem #0b7ea1",
                                margin: "1rem 0",
                            }}
                        >
                            <h2>🧪 Experiment</h2>
                            <div className={styles.selectedExperimentAnswers}>
                                <h3>Thought being tested</h3>
                                <p>{selectedExperiment.thoughts}</p>
                            </div>
                            <div className={styles.selectedExperimentAnswers}>
                                <h3>Experiment details</h3>
                                <p>{selectedExperiment.details}</p>
                            </div>
                            <div className={styles.selectedExperimentAnswers}>
                                <h3>Scheduled for:</h3>
                                <p>
                                    {moment(selectedExperiment.date).isSame(moment(), "day")
                                        ? "🗓️ " + "Today" + time
                                        : "🗓️ " + day + time}
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid xs={10} item>
                        <Grid item sx={{
                            background: "#fff",
                            padding: "1.25rem",
                            borderRadius: "0.5rem",
                            border: "solid 0.15rem #0b7ea1",
                            margin: "1rem 0",
                        }}>
                            <h2>🤔 Prediction</h2>
                            <div className={styles.selectedExperimentAnswers}>
                                <h3>Expected outcome of the experiment</h3>
                                <p>{selectedExperiment.prediction}</p>
                            </div>
                            <div className={styles.selectedExperimentAnswers}>
                                <h3>Expected feeling after experiment:</h3>
                                <p>{selectedExperiment.feeling} / 10</p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    selectedExperiment.attempts.length > 0 && (
                        selectedExperiment.attempts.map((entry, index) => (
                            <div className={`${styles.marginTop}`} key={index}>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12} item>
                                    <Card className={`${styles.card}`} sx={{ minWidth: 305 }}>
                                        <CardContent
                                            className={`${styles.cardContent}`}
                                        >
                                            <Grid
                                                container
                                                spacing={0}
                                                style={{ marginBottom: "10px" }} >
                                                {entry.obstacle}
                                            </Grid>
                                            <Grid
                                                container
                                                spacing={0}
                                                style={{ marginBottom: "10px" }} >
                                                {entry.change}
                                            </Grid>
                                            <Grid container spacing={0}>
                                                <Grid xs={2} item>
                                                    <Avatar
                                                        alt="Duration Indication"
                                                        sx={{ width: 24, height: 24 }}>
                                                    </Avatar>
                                                </Grid>
                                                <Grid xs={10} item>
                                                    <Avatar
                                                        alt="Difficulty Indication"
                                                        sx={{ width: 24, height: 24 }}>
                                                    </Avatar>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </div>
                        ))
                    )
                }
            </Wrapper>
        </Screen>
    );
};

export default BehavioralCompleteViewScreen;