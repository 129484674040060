import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../Axios";

const initialState = {
    loading: false,
    error: null,
    simsEntries: [], // Array to store answers for SIMS questions
    dimsEntries: [], // Array to store answers for DIMS questions
    selectedDangers: [], // Array to store user-selected dangerous DIMS
};
// functions for "useSelector"
const selectSimsDims = (state) => state.simsDims;

const getSimsDims = createAsyncThunk(
    "get sims dims/",
    async (_, { thunkAPI }) => {
        try {
            const user = thunkAPI.getState().user.user;

            const { data } = await axios.get("/sims-dims", {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            return data;
        } catch (e) {
            throw new Error(e.response.data.message);
        }
    }
);

const testSimsEntry = ["Test SIMS Entry"];
const testDimsEntry = ["Test DIMS Entry"];
// send backend request to add new sims dims entry
const addSimsDimsEntry = createAsyncThunk(
    "add sims dims/",
    async (simsDimsEntry, thunkAPI) => {
        try {
            const user = thunkAPI.getState().user.user;


            console.log("Hardcoded data being sent:", { testSimsEntry, testDimsEntry });
            const { data } = await axios.post("/sims-dims",
                {
                    simsEntries: testSimsEntry,
                    dimsEntries: testDimsEntry,
                    selectedDangers: simsDimsEntry.selectedDangers
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });

            return data;
        } catch (e) {
            throw new Error(e.response.data.message);
        }
    }
);


const simsDimsSlice = createSlice({
    name: "simsDims",
    initialState,
    reducers: {
        resetSimsDims: () => initialState,
        setSimsEntry: (state, { payload }) => {
            const { index, value } = payload;

            state.simsEntries[index] = value;
        },
        setDimsEntry: (state, { payload }) => {
            const { index, value } = payload;

            state.dimsEntries[index] = value;
        },
        selectDanger: (state, { payload }) => {
            const danger = payload;
            const index = state.selectedDangers.indexOf(danger);
            if (index === -1) {
                state.selectedDangers.push(danger);
            } else {
                state.selectedDangers.splice(index, 1);
            }
        },
    },
    extraReducers: {
        [getSimsDims.pending]: (state) => {
            state.loading = true;
        },

        [getSimsDims.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },

        [getSimsDims.fulfilled]: (state, action) => {
            state.loading = false;
            state.simsEntries = action.payload.simsEntries;
            state.dimsEntries = action.payload.dimsEntries;
        },

    },
});


export default simsDimsSlice.reducer;

export {
    addSimsDimsEntry, getSimsDims, selectSimsDims
};
export const {
    setSimsEntry,
    setDimsEntry,
    selectDanger,
    resetSimsDims
} = simsDimsSlice.actions;

