import { Avatar, Grid, Paper, Skeleton } from "@mui/material";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddCommunityFriendScreen.module.css";
import communityStyle from "CSS/Screens/CommunityScreen.module.css";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import { URLS, USER_PROFILE_PICTURE_S3_URL } from "Constants/Constants";
import { getUserFriends } from "Reducers/Actions/FriendActions";
import { addMembersToTeam } from "Reducers/Actions/TeamActions";
import { selectFriendRequest } from "Reducers/FriendRequestReducer";
import { selectTeam, setTeamToCreateMembers } from "Reducers/TeamReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const AddCommunityFriendScreen = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const backUrl = searchParams.get("backUrl");
  const { userFriends, loading } = useSelector(selectFriendRequest);
  const { selectedTeam } = useSelector(selectTeam);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const navigate = useNavigate();
  let teamMembers = [];
  const [listOfUserFriends, setListOfUserFriends] = useState([]);

  useEffect(() => {
    dispatch(getUserFriends()).then(() => {
      if (backUrl != null) {
        // Extracting the IDs of the members in the selected team.
        teamMembers = selectedTeam.members.map(member => member.user._id);

        // Filters the user's friends to find those who are not already in the team.
        const friendsNotInTeam = userFriends.filter(({ friendUser }) =>
          !teamMembers.includes(friendUser._id));

        // Updates the state with the list of user friends who are not in the team.
        setListOfUserFriends(friendsNotInTeam);
      } else {
        // Setting the list of user friends to be entire friend list 
        // when creating a new community.
        setListOfUserFriends(userFriends);
      }
    });
  }, [dispatch]);

  // Add members to team
  const handleMemberAdd = (id) => {
    setSelectedUsers([...selectedUsers, id]);
    dispatch(setTeamToCreateMembers(selectedUsers));
  };

  // Removed members from team
  const handleMemberRemove = (id) => {
    setSelectedUsers(selectedUsers.filter(userId => userId !== id));
    dispatch(setTeamToCreateMembers(selectedUsers));
  };

  const handleAddFriendToTeam = () => {
    dispatch(setTeamToCreateMembers(selectedUsers));
    if (backUrl != null) {
      dispatch(addMembersToTeam(
        { id: searchParams.get("teamId"), members: selectedUsers }
      ));
      navigate(backUrl);
    } else {
      navigate(URLS.COMMUNITY_ADD_COMMUNITY_GOAL);
    }
  };

  const renderUserFriends = () => {
    return (
      <>
        <Grid container justifyContent="center" alignItems="center">
          {listOfUserFriends.map(({ friendUser }) => (
            <Grid xs={5} key={friendUser._id} style={{ margin: "0.5em" }}>
              <Paper
                elevation={3}
                style={{ padding: "10px", borderRadius: "15px" }}
                onClick={selectedUsers.includes(friendUser._id) ?
                  () => handleMemberRemove(friendUser._id) :
                  () => handleMemberAdd(friendUser._id)}
                className={
                  selectedUsers.includes(friendUser._id) ?
                    styles.selectedBackground : ""}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}>
                  <Grid xs={4} item>
                    <Avatar
                      imgProps={{
                        crossOrigin: "anonymous"
                      }}
                      src={`${friendUser.profilePicture != null ? (USER_PROFILE_PICTURE_S3_URL + friendUser.profilePicture) : ""}`}
                      alt={friendUser.firstName}
                      sx={{ width: 45, height: 45 }}
                    />
                  </Grid>
                  <Grid xs={5} item>
                    <p>{friendUser.firstName} {friendUser.lastName ? friendUser.lastName : ""}</p>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <ScreenNoNav>
      <Grid
        style={{ marginTop: "2em", marginBottom: "10px" }}
        container
        xs={10}
        item={false}
        justifyContent="flex-start">
        <Grid item xs={4}>
          <Link
            to={
              searchParams.get("backUrl") != null ?
                searchParams.get("backUrl") :
                URLS.COMMUNITY_ADD_COMMUNITY}
            className={`${communityStyle.btn} ${communityStyle.btn_orange}`}
          >
            Back
          </Link>
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: "1em", marginBottom: "10px" }}
        container
        xs={10}
        item={false}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          item xs={12}>
          {
            listOfUserFriends && listOfUserFriends.length > 0
            && (<h3>Select friends to add to team</h3>)
          }
          {
            listOfUserFriends && listOfUserFriends.length === 0 && backUrl != null
            && (<h3>Add new friends to add them to your current team</h3>)
          }
          {
            listOfUserFriends && listOfUserFriends.length === 0
            && (
              <Paper style={{ padding: "15px", textAlign: "center" }}>
                <h4>Teams can be more fun with friends!</h4>
                <h4>Add new friends</h4>
              </Paper>
            )
          }
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "10px", marginBottom: "10px" }} container xs={10}>
        <Grid item xs={12}>
          {
            loading && (<Skeleton variant="rounded" height={60} />)
          }
          {
            listOfUserFriends && listOfUserFriends.length > 0 && (renderUserFriends())
          }
        </Grid>
      </Grid>
      {
        listOfUserFriends && listOfUserFriends.length > 0 && (
          <Grid style={{
            marginTop: "1em",
            marginBottom: "10px",
            textAlign: "center"
          }} container xs={10}>
            <Grid item xs={12}>
              <button
                onClick={handleAddFriendToTeam}
                className={`${btn.btn} ${btn.faded_blue}`}
                style={{ maxWidth: "100%" }}>
                Add friends to team
              </button>
            </Grid>
          </Grid>
        )
      }
      {
        listOfUserFriends && listOfUserFriends.length === 0 && backUrl != null && (
          <Grid style={{
            marginTop: "1em",
            marginBottom: "10px",
            textAlign: "center"
          }} container xs={10}>
            <Grid item xs={12}>
              <button
                onClick={() => navigate(URLS.COMMUNITY_ADD_FRIEND)}
                className={`${btn.btn} ${btn.faded_blue}`}
                style={{ maxWidth: "100%" }}>
                Search friends
              </button>
            </Grid>
          </Grid>
        )
      }

      {
        listOfUserFriends && listOfUserFriends.length === 0 && (
          <Grid style={{
            marginTop: "1em",
            marginBottom: "10px",
            textAlign: "center"
          }} container xs={10}>
            <Grid item xs={12}>
              <button
                onClick={handleAddFriendToTeam}
                className={`${btn.btn} ${btn.faded_blue}`}
                style={{ maxWidth: "100%" }}>
                Add friends to team
              </button>
            </Grid>
          </Grid>
        )
      }

    </ScreenNoNav>
  );
};

export default AddCommunityFriendScreen;
