import CircularSlider from "@fseehawer/react-circular-slider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { Grid, IconButton, ImageListItem, ImageListItemBar, Paper } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/Journal.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import Wrapper from "Components/Helpers/Wrapper";
import { EMOTION_EMOJI_MAP } from "Constants/EmotionSelector";
import useEmotionSelector from "Hooks/useEmotionSelector";
import { useRedirect } from "Hooks/useRedirect";
import { getActivities } from "Reducers/Actions/ActivityActions";
import { selectActivity } from "Reducers/ActivityReducer";
import { addJournalEntry } from "Reducers/JournalReducer";
import Compressor from "compressorjs";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const AddJournalEntryScreen = () => {
  const [dateTime, setDateTime] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [emotionRating, setEmotionRating] = useState(0);
  const [textReview, setTextReview] = useState("");
  const [activity, setActivity] = useState("");
  // const { badges } = useSelector(selectBadges);
  const { activities } = useSelector(selectActivity);
  const [emojiLabel, setEmojiLabel] = useState(EMOTION_EMOJI_MAP[0]);
  const inputFile = useRef(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectUrl = useRedirect("/journal");

  useEffect(() => {
    setEmojiLabel(EMOTION_EMOJI_MAP[emotionRating]);
  }, [emotionRating]);

  const { emotions, renderModal, renderSelector } = useEmotionSelector();

  useEffect(() => {
    dispatch(getActivities());
  }, [dispatch]);

  const handleJournalEntrySubmit = () => {
    dispatch(
      addJournalEntry({
        date: moment(dateTime).utc(),
        emotionRating,
        emotions,
        textReview,
        activity,
        images: selectedImages,
      })
    );

    // get journaling badge
    // const badge = badges.find((badge) => badge.name === "Journaling");

    // increment progress when submitting new journal entry
    // addToBadge(badge, dispatch, badge.progress + 1);

    navigate("/journal");
  };

  const handleImageUploadClick = () => {
    inputFile.current.click();
  };

  const handleFileUpload = (event) => {
    if (event.target.files.length > 3 || selectedImages.length === 3) {
      setAlertMessage("Cannot upload more than 3 images per entry");
      setIsAlertActive(true);
      return;
    }

    if (event.target.files.length > 0) {
      const filesArray = [...event.target.files];
      filesArray.map((image) => compressImage(image));
    }
  };

  const compressImage = (image) => {
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        setSelectedImages((prevState) => [...prevState, compressedResult]);
      },
    });
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prevState) => prevState.filter((_, i) => i !== index));
  };

  return (
    <ScreenNoNav>
      <Wrapper>
        {renderModal()}
        <div className={styles.journal_header}>
          <Link
            to={redirectUrl}
            className={`${btn.links} ${btn.secondaryLink}`}
          >
            Close
          </Link>
          <h3>New Journal Entry</h3>
          <button
            type="submit"
            className={`${btn.links} ${btn.primaryLink}`}
            onClick={handleJournalEntrySubmit}
          >
            Add
          </button>
        </div>

        <div
          className={`${styles.journal_body} ${isMobile ? styles.journal_bodyMobile : ""
            }`}
        >
          <TextField
            className={styles.datetextarea}
            type="datetime-local"
            name="day"
            required={true}
            value={dateTime}
            onChange={(e) => setDateTime(e.target.value)}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          <br />
          <CircularSlider
            label={emojiLabel}
            type="number"
            labelColor="#107ca4"
            knobColor="#009c9a"
            progressColorFrom="#FF9800"
            progressColorTo="#8BC34A"
            trackColor="#eeeeee"
            data={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]} //...
            min={0}
            max={10}
            dataIndex={0}
            labelBottom={true}
            labelFontSize="3rem"
            valueFontSize="1.5rem"
            onChange={(e) => setEmotionRating(e)}
            value={emotionRating}
            width={140} //og 280
            knobSize={32} //og 50
            trackSize={6} //og 24
            progressSize={12} //og 24
          />

          {renderSelector()}
          <FormControl variant="filled" sx={{ m: 1, minWidth: "85%" }}>
            <InputLabel id="activity-selector-label">
              Pick the trigger activity.
            </InputLabel>
            <Select
              labelId="activity-selector-label"
              id="activity-selector"
              variant="outlined"
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {activities.length > 0 && activities.map((activity, index) => (
                <MenuItem key={index} value={activity._id}>
                  {activity.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="filled" sx={{ m: 1, minWidth: "85%" }}>
            <TextField
              className={styles.textarea}
              label="Enter review"
              type="text"
              multiline
              fullWidth
              rows={3.5}
              placeholder="How are you feeling?"
              name="review"
              required={false}
              onChange={(e) => setTextReview(e.target.value)}
              value={textReview}
              variant="outlined"
              inputProps={{
                maxLength: 500,
              }}
            />
          </FormControl>
          <AlertMessage
            toggle={isAlertActive}
            type="warning"
            setMessage={() => setIsAlertActive(false)}
            message={alertMessage} />
          <FormControl variant="filled" sx={{ m: 1, minWidth: "85%", maxWidth: "85%" }}>
            <input
              onChange={handleFileUpload}
              type="file"
              id="images"
              name="images"
              accept="image/*"
              ref={inputFile}
              style={{ display: "none" }}
              multiple />
            <Paper
              variant="outlined"
              className={
                selectedImages.length > 0 ?
                  styles.imageUploadPaperActive :
                  styles.imageUploadPaper}
              onClick={selectedImages.length === 0 ? handleImageUploadClick : null}>
              {selectedImages.length > 0 ? (
                <Grid container>
                  {
                    selectedImages.map((image, index) => (
                      <Grid xs={4} key={index} className={styles.journalImageContainer}>
                        <ImageListItem key={index} className={styles.journalImage}>
                          <img
                            className={styles.journalImage}
                            src={URL.createObjectURL(image)}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            className={styles.imageList}
                            position="top"
                            actionIcon={
                              <IconButton
                                className={styles.imageIcon}
                                onClick={() => handleRemoveImage(index)}
                              >
                                <HighlightOffRoundedIcon />
                              </IconButton>
                            }
                            actionPosition="left"
                          />
                        </ImageListItem>
                      </Grid>
                    ))
                  }
                  <Grid xs={4}>
                    <Paper
                      onClick={handleImageUploadClick}
                      className={styles.moreImageUpload}>
                      <AddCircleOutlineIcon fontSize="large" />
                    </Paper>
                  </Grid>
                </Grid>
              ) : (<AddPhotoAlternateIcon fontSize="large" />)}
            </Paper>
          </FormControl>
          <button
            className={`${btn.btn} ${btn.primary}`}
            type="submit"
            onClick={handleJournalEntrySubmit}
          >
            Add
          </button>
        </div>
      </Wrapper>
    </ScreenNoNav>
  );
};

export default AddJournalEntryScreen;
