import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  addHabit,
  deleteHabit,
  getHabit,
  getHabitInstance,
  getHabitInstances,
  getHabits,
  updateHabit,
  updateHabitInstance,
} from "./Actions/HabitActions";

const initialState = {
  habits: { daily: [], weekly: [], monthly: [], archived: [] },
  selectedHabit: null,
  selectedInstances: [], // habit instances for selected habit
  selectedInstance: null,
  loading: false,
  error: null,
  successAdd: false,
  successDelete: false,
  page: "1", // 1 for "current", 2 for "all", 3 for "archived"
  instanceFilter: "7", // "7", "30", "90", "360" for the limit filter
};

// functions for "useSelector"
const selectHabits = (state) => state.habits;

const habitsSlice = createSlice({
  name: "habits",
  initialState,
  reducers: {
    resetHabits: () => initialState,
    // For updating when we only want to reset the error and loading
    resetHabit: ({ habits }) => {
      return {
        habits,
        selectedHabit: null,
        selectedInstances: [],
        selectedInstance: null,
        loading: false,
        error: null,
      };
    },
    resetInstances: (state) => {
      state.selectedInstance = null;
      state.selectedInstances = [];
    },
    resetAddHabitSuccess: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    resetDeleteHabitSuccess: (state) => {
      return {
        ...state,
        successDelete: false,
      };
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setInstanceFilter: (state, { payload }) => {
      state.instanceFilter = payload;
    },
  },
  extraReducers: {
    // Add habit Async Reducers
    [addHabit.pending]: (state) => {
      state.loading = true;
      state.successAdd = false;
    },
    [addHabit.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.habits[payload.frequency].push(payload);
      state.successAdd = true;
    },
    [addHabit.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },
    // Get habits Async Reducers
    [getHabits.pending]: (state) => {
      state.loading = true;
    },
    [getHabits.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;

      // get "archived" habits
      state.habits.archived = payload.habits.archived;
      if (payload.isCurrent) {
        // get "current" habits
        const daily = [];
        for (let habit of payload.habits.daily) {
          if (
            moment().utc(habit.currentDisplayTime).format("YYYY MM DD") ===
            moment().startOf("day").format("YYYY MM DD")
          ) {
            daily.push(habit);
          }
        }
        state.habits.daily = daily;
        state.habits.weekly = payload.habits.weekly;
        state.habits.monthly = payload.habits.monthly;
      } else {
        // get "all" habits
        state.habits.daily = payload.habits.daily;
        state.habits.weekly = payload.habits.weekly;
        state.habits.monthly = payload.habits.monthly;
      }
    },
    [getHabits.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Get habit Async Reducers
    [getHabit.pending]: (state) => {
      state.loading = true;
    },
    [getHabit.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedHabit = payload;
    },
    [getHabit.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Get habit Async Reducers
    [getHabitInstance.pending]: (state) => {
      state.loading = true;
    },
    [getHabitInstance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedInstance = payload;
    },
    [getHabitInstance.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Get habit Async Reducers
    [getHabitInstances.pending]: (state) => {
      state.loading = true;
    },
    [getHabitInstances.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedInstances = payload;
    },
    [getHabitInstances.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Update habit instance Async Reducers
    [updateHabitInstance.pending]: (state) => {
      state.loading = true;
    },
    [updateHabitInstance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.selectedInstance = payload;

      // find instance index and update it
      const idx = state.selectedInstances.findIndex(
        (instance) => instance._id === payload._id
      );
      state.selectedInstances[idx] = payload;
    },
    [updateHabitInstance.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Update habit Async Reducers
    [updateHabit.pending]: (state) => {
      state.error = null;
    },
    [updateHabit.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // update current selected habit
      state.selectedHabit = payload;

      // remove old habit and push updated version
      ["daily", "weekly", "monthly", "archived"].forEach((freq) => {
        const idx = state.habits[freq].findIndex(
          (habit) => habit._id === payload._id
        );

        if (idx !== -1) {
          if (payload.isArchived) {
            // remove from frequency list, add to archived list
            state.habits[freq].splice(idx, 1);
            state.habits.archived.push(payload);
          } else if (
            freq === payload.frequency ||
            (payload.frequency === "weekly" && freq === "daily") // for the weekly habits that appear in the "current daily" tab
          ) {
            state.habits[freq][idx] = payload;
          } else {
            state.habits[freq].splice(idx, 1); // remove element from old list
            state.habits[payload.frequency].push(payload); // add to updated list
          }
        }
      });
    },
    [updateHabit.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Delete Async Reducers
    [deleteHabit.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successDelete = true;

      // remove from proper list
      state.habits[payload.frequency] = state.habits[payload.frequency].filter(
        (habit) => habit._id !== payload._id
      );
    },
    [deleteHabit.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successDelete = false;
    },
  },
});

export default habitsSlice.reducer;
export {
  addHabit, deleteHabit, getHabit, getHabitInstance,
  getHabitInstances, getHabits, selectHabits, updateHabit, updateHabitInstance
};
export const {
  resetHabit,
  resetHabits,
  resetAddHabitSuccess,
  resetDeleteHabitSuccess,
  setInstanceFilter,
  resetInstances,
  setPage,
} = habitsSlice.actions;
