import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import { Avatar, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/UserProfileScreen.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import BoxItem from "Components/Helpers/BoxItem";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { URLS, USER_PROFILE_PICTURE_S3_URL } from "Constants/Constants";
import { resetAppointmentNotes } from "Reducers/AppointmentNoteReducer";
import { resetHabits } from "Reducers/HabitsReducer";
import { resetJournals } from "Reducers/JournalReducer";
import { logoutSubscription } from "Reducers/SubscriptionReducer";
import { resetToDos } from "Reducers/ToDoReducer";
import {
  deleteUser,
  getUser,
  logout,
  resetUser,
  selectUser,
  updateUser,
} from "Reducers/UserReducer";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotificationScreen from "./NotificationScreen";

const AccountSettingScreen = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [imageSource, setImageSource] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const { user, error, loading } = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Ensure proper credentials
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    let numAge = parseInt(age);

    if (age !== "" && Number.isNaN(numAge)) {
      setErrorMessage("Age must be a number");
      return;
    }
    // set as the number version to save a number in the db
    if (age !== "") {
      setAge(numAge);
    }

    let updatedProfilePic = profilePicture;
    if (imageSource != "") {
      updatedProfilePic = imageSource;
    }

    setErrorMessage("");
    dispatch(updateUser({
      firstName,
      lastName,
      age,
      email,
      password,
      profilePic: updatedProfilePic
    }));
  };

  // log user out
  const handleLogout = (event) => {
    event.preventDefault();
    dispatch(resetAppointmentNotes);
    dispatch(resetJournals);
    dispatch(resetToDos);
    dispatch(resetHabits);
    dispatch(logout());
    dispatch(logoutSubscription());
    navigate("/login");
  };

  // delete user
  const handleDelete = (event) => {
    event.preventDefault();
    dispatch(deleteUser());
    navigate("/login");
  };

  useEffect(() => {
    dispatch(getUser());
    // Set proper error message
    if (error) {
      setErrorMessage(error);
    }

    // Prefill user fields
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setAge(user.age);
      setEmail(user.email);
      setProfilePicture(user.profilePicture);
    }

    return () => {
      dispatch(resetUser());
    };
  }, [dispatch, error]);

  const handleImageChange = (event) => {
    if (event.target.files.length === 1) {
      setImageSource(event.target.files[0]);
    }
  };

  // Tooltip
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText("https://play.google.com/store/apps/details?id=ca.psyhealth.twa");
    setTooltipOpen(true);
  };

  return (
    <Screen>
      <Wrapper>
        <form
          className={`${styles.profile_form} ${isMobile ? styles.profile_formMobile : ""
            }`}
          onSubmit={handleSubmit}
        >
          <h2>Welcome, {user ? user.firstName : ""}</h2>
          <AlertMessage
            message={errorMessage.replace("User validation failed: ", "")}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <div className={styles.profile_field}>
            <Grid
              container
              alignItems="center">
              <Grid item xs={4}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  hidden
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  <Avatar
                    imgProps={{
                      crossOrigin: "anonymous"
                    }}
                    src={imageSource != "" ?
                      URL.createObjectURL(imageSource) :
                      profilePicture ? `${USER_PROFILE_PICTURE_S3_URL}${profilePicture}?${new Date()}` : ""}
                    style={{
                      margin: "10px",
                      width: "60px",
                      height: "60px",
                    }}
                  />
                </label>
              </Grid>
              <Grid item>
                <button className={`${btn.btn} ${btn.primary} ${btn.fullWidth}`}>
                  Change Picture
                  <input
                    type="file"
                    accept=".jpg, .png, .jpeg"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </button>
              </Grid>
            </Grid>
          </div>
          <h3 className={styles.h3}>👤 Profile</h3>
          <TextField
            className={styles.profile_field}
            label="First Name"
            type="text"
            placeholder="Update first name"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            variant="standard"
            inputProps={{
              maxLength: 256,
            }}
          />

          <TextField
            className={styles.profile_field}
            label="Last Name"
            type="text"
            placeholder="Update last name"
            name="lastName"
            value={lastName || ""}
            onChange={(e) => setLastName(e.target.value)}
            variant="standard"
            inputProps={{
              maxLength: 256,
            }}
          />

          <TextField
            className={styles.profile_field}
            type="text"
            placeholder="Update age"
            name="age"
            label="Age"
            value={age || ""}
            onChange={(e) => setAge(e.target.value)}
            variant="standard"
            inputProps={{
              maxLength: 3,
            }}
          />

          <TextField
            className={styles.profile_field}
            type="text"
            label="Email"
            placeholder="Update email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="standard"
            inputProps={{
              maxLength: 128,
            }}
          />

          <TextField
            className={styles.profile_field}
            label="Update Password"
            type="password"
            placeholder="Update password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            variant="standard"
            inputProps={{
              maxLength: 256,
            }}
          />

          <TextField
            className={styles.profile_field}
            label="Confirm Password"
            type="password"
            placeholder="Confirm password"
            name="confpassword"
            onChange={(e) => setConfirmPassword(e.target.value)}
            variant="standard"
            inputProps={{
              maxLength: 256,
            }}
          />
          <div className={styles.profile_btns}>
            <button className={`${btn.btn} ${btn.primary} ${btn.fullWidth}`} type="submit">
              {loading ? "Saving changes..." : "Save Profile Changes"}
            </button>
          </div>
          <h3 className={`${styles.h3} ${styles.h3_margin}`}>⚙️ Settings</h3>
          <h4>Notifications</h4>
          <NotificationScreen />
          <h4 className={styles.h4_margin}>Menu Customization</h4>
          <div className={styles.menucustom_box}>
            <BoxItem
              text="Bottom Menu Customization"
              icon={<DashboardCustomizeIcon
                className={styles.settingItem_icon}
                size={24} />}
              link={URLS.SETTING_BOTTOM_NAV}
            />
            <BoxItem
              text="Side Menu Customization"
              icon={<DashboardCustomizeIcon
                className={styles.settingItem_icon}
                size={24} />}
              link={URLS.SETTING_SIDE_NAV}
            />
          </div>
          <h4 className={styles.h4_margin}>Manage Account</h4>
          <button
            className={`${btn.btn} ${btn.disabled} ${btn.fullWidth}`}
            onClick={handleLogout}
          >
            Logout
          </button>
          <h4 className={styles.h4_margin}>Get in Touch</h4>
          <div className={`${btn.groupColumn} ${styles.companyLinkBox}`}>
            <div className={styles.companyLinks} onClick={() => navigate("/feedback")}>
              <span className={styles.sideNav_itemName}>{"Feedback"}</span>
            </div>
            <Tooltip
              open={isTooltipOpen}
              title={"Link copied to clipboard!"}
              leaveDelay={1500}
              onClose={handleTooltipClose}
              onClick={handleTooltipOpen}
            >
              <div className={styles.companyLinks}>
                <span className={styles.sideNav_itemName}>{"Share"}</span>
              </div>
            </Tooltip>
          </div>
          <h4 className={styles.h4_margin}>About</h4>
          <div className={`${btn.groupColumn} ${styles.companyLinkBox}`}>
            <a
              href="https://www.psystem.ca/meet-our-team"
              title="Link to About Psystem's Team"
              target="_blank"
              rel="noreferrer"
              className={styles.companyLinks}
            >
              Our Team
            </a>
            <a
              href="https://www.psystem.ca/terms-of-service"
              title="Link to Psystem's Terms and Services"
              target="_blank"
              rel="noreferrer"
              className={styles.companyLinks}
            >
              Terms &amp; Services
            </a>
            <a
              href="https://www.psystem.ca/privacy-policy"
              title="Link to Psystem's Privacy Policy"
              target="_blank"
              rel="noreferrer"
              className={styles.companyLinks}
            >
              Privacy Policy
            </a>
            <button
              className={`${btn.btn} ${btn.dangerLink} ${styles.requestDelete} ${styles.companyLinks}`}
              onClick={handleDelete}
            >
              Delete My Account
            </button>
          </div>
        </form>
      </Wrapper>
    </Screen>
  );
};
export default AccountSettingScreen;
