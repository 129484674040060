import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Avatar, Box, Drawer, Grid, IconButton, Toolbar } from "@mui/material";
import styles from "CSS/Components/Navbar.module.css";
import { USER_PROFILE_PICTURE_S3_URL } from "Constants/Constants";
import { selectUser } from "Reducers/UserReducer";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { MdOutlineAccountCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideNav from "./SideNav";

const drawerWidth = 220;

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <SideNav onClose={handleDrawerToggle} isShowSide={mobileOpen} />
    </Box>
  );

  const { user } = useSelector(selectUser);

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          width: "100%"
        }}
        component="nav">
        <Toolbar
          sx={{
            backgroundColor: "var(--blue-500)",
            py: "5px",
            height: "60px"
          }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={2}>
              <IconButton
                style={{ color: "white" }}
                color="inherit"
                aria-label="open drawer"
                edge="start"
                size="large"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid onClick={() => navigate("/setting/account")} item xs={1} sx={{ marginRight: "10px" }}>
              <Box sx={{ display: { xs: "block" } }}>
                {
                  user === null ? (
                    <MdOutlineAccountCircle
                      className={`${styles.navbar_icon} ${isMobile ? styles.navbar_iconMobile : ""
                        }`}
                      size={40}
                    />
                  ) : (
                    <Avatar
                      sx={{ width: 45, height: 45 }}
                      imgProps={{
                        crossOrigin: "anonymous"
                      }}
                      src={`${user.profilePicture != null ?
                        (USER_PROFILE_PICTURE_S3_URL + user.profilePicture) : ""}`}
                      aria-label={`profile picture of ${user.firstName}`} />
                  )
                }
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};

export default Navbar;
