import { Grid, Input, InputAdornment, InputLabel, NativeSelect } from "@mui/material";
import TextField from "@mui/material/TextField";
import btn from "CSS/Button.module.css";
import toggleCardStyles from "CSS/Components/ToggleCard.module.css";
import styles from "CSS/Screens/AddHabitScreen.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import DayOfWeekSelector from "Components/Selectors/DayOfWeekSelector";
import { useRedirect } from "Hooks/useRedirect";
import { useUnitSelector } from "Hooks/useUnitSelector";
import { getSubscription, subscribeToHabits } from "Reducers/Actions/SubscriptionActions.js";
import { addHabit, selectHabits } from "Reducers/HabitsReducer";
import {
  selectPathways,
  setAddPathwayInfo
} from "Reducers/PathwayReducer";
import { selectSubscription } from "Reducers/SubscriptionReducer";
import { convertLocalTimeToUTC } from "Utilities/date-time";
import { getNewFrequencyTitle } from "Utilities/habit-utility";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getTokenFromFirebase } from "../../firebase.js";

const AddHabitScreen = () => {
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [frequency, setFrequency] = useState("daily");
  const [frequencyTitle, setFrequencyTitle] = useState("day");
  const [interval, setInterval] = useState(1);
  const [unitAmount, setUnitAmount] = useState(1);
  const [searchParams] = useSearchParams();
  const [activityName] = useState(searchParams.get("name"));
  const [habitName, setHabitName] = useState(activityName ? activityName : "");
  const [notificationTime, setNotificationTime] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectUrl = useRedirect("/habits");
  const { loading, error } = useSelector(selectHabits);
  const {
    unitsToAdd,
    currentUnit,
    fillUnits,
    resetData,
    renderUnitModal,
    renderUnitSelector,
  } = useUnitSelector();
  const { token } = useSelector(selectSubscription);

  // Pathway habit state
  const { addPathwayInfo } = useSelector(selectPathways);
  const [plan] = useState(searchParams.get("plan"));
  const [index, setIndex] = useState(parseInt(searchParams.get("item"))); // index for current habit on Pathway

  useEffect(() => {
    dispatch(getSubscription());
  }, [dispatch]);

  const handleDaysOfWeek = (_, newDays) => {
    setDaysOfWeek(newDays);
  };

  const checkToken = async () => {
    if (!token) {
      const newToken = await getTokenFromFirebase();
      return newToken;
    } else {
      return token;
    }
  };

  const handleTimeChange = (e) => {
    setNotificationTime(e);
  };

  const addHabitSubmit = async (e) => {
    e.preventDefault();

    if (notificationTime !== "") {
      // Updating notification time to include default date.
      const utcTimeString = convertLocalTimeToUTC(notificationTime);

      // Making sure that the device has an updated token for notification.
      const token = await checkToken();

      // Subscribing to habit notification.
      dispatch(
        subscribeToHabits({
          token,
          isEnabled: true,
        })
      );

      dispatch(
        addHabit({
          habitName,
          unit: currentUnit,
          unitAmount: parseInt(unitAmount),
          extraUnits: unitsToAdd,
          interval,
          frequency,
          daysOfWeek,
          pathway: plan,
          isNotificationEnabled: true,
          notificationTime: utcTimeString,
        })
      );
    } else {
      dispatch(
        addHabit({
          habitName,
          unit: currentUnit,
          unitAmount: parseInt(unitAmount),
          extraUnits: unitsToAdd,
          interval,
          frequency,
          daysOfWeek,
          pathway: plan,
        })
      );
    }

    // Success to reset state and go back to habits
    if (!loading && !error) {
      navigate(redirectUrl);
    }
  };

  // submits a pathway habit to redux state
  // note: does not actually create the habit in the database
  const handleAddPathwayHabit = (e) => {
    e.preventDefault();

    const habitInfo = {
      name: habitName,
      unit: currentUnit,
      unitAmount: parseInt(unitAmount),
      extraUnits: unitsToAdd,
      interval,
      frequency,
      daysOfWeek,
    };

    // update state
    if (addPathwayInfo.habits.length >= index + 1) {
      // update habit
      let temp = [...addPathwayInfo.habits];
      temp[index] = habitInfo;
      dispatch(
        setAddPathwayInfo({
          ...addPathwayInfo, // preserve unchanged fields
          habits: temp,
        })
      );
    } else {
      // create habit
      dispatch(
        setAddPathwayInfo({
          ...addPathwayInfo, // preserve unchanged fields
          habits: [...addPathwayInfo.habits, habitInfo],
        })
      );
    }

    // go to review
    if (index == addPathwayInfo.list.length - 1) {
      if (addPathwayInfo.review) {
        navigate("/new-pathway?page=4");
        return;
      }
      navigate("/new-pathway?page=5");
    } else {
      setIndex(index + 1);
      // reset page
      setPathwayHabit(index + 1);
    }
  };

  // sets all the state for a pathway habit
  const setPathwayHabit = (idx) => {
    setErrorMessage("");

    // set info based on already edited habit
    if (addPathwayInfo.habits.length > idx) {
      setHabitName(addPathwayInfo.habits[idx].name);
      setDaysOfWeek(addPathwayInfo.habits[idx].daysOfWeek);
      setFrequency(addPathwayInfo.habits[idx].frequency);
      setFrequencyTitle(
        getNewFrequencyTitle(
          addPathwayInfo.habits[idx].frequency,
          addPathwayInfo.habits[idx].interval
        )
      );
      setInterval(addPathwayInfo.habits[idx].interval);
      setUnitAmount(addPathwayInfo.habits[idx].unitAmount);
      fillUnits(addPathwayInfo.habits[idx]);
      return;
    }

    // set new habit
    setHabitName(addPathwayInfo.list[idx]);
    setDaysOfWeek([]);
    setFrequency("daily");
    setFrequencyTitle("day");
    setInterval(1);
    setUnitAmount(1);
    resetData();
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }

    if (addPathwayInfo?.habits.length > index) {
      setPathwayHabit(index);
    } else if (addPathwayInfo?.list?.length > 0) {
      setHabitName(addPathwayInfo.list[index]);
    }
  }, [error]);

  const displayPage = () => (
    <WrapperForm
      onSubmit={
        pathname !== "/new-pathway"
          ? addHabitSubmit
          : handleAddPathwayHabit
      }
    >
      {renderUnitModal()}
      {pathname !== "/new-pathway" && (
        <div className={styles.header}>
          <Link
            to={redirectUrl}
            className={`${btn.links} ${btn.secondaryLink}`}
          >
            Close
          </Link>
          <h3>New Habit</h3>
          <button type="submit" className={`${btn.links} ${btn.primaryLink}`}>
            Add
          </button>
        </div>
      )}

      <BodyForm desktop={styles.body}>
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />

        {plan && (
          <p className={styles.addWellness}>
            ⭐️ You are adding a habit to a <span>Pathway</span>
          </p>
        )}
        <TextField
          className={styles.field}
          label="Habit Name"
          type="text"
          placeholder="Enter habit"
          name="title"
          value={habitName}
          required={true}
          onChange={(e) =>
            setHabitName(
              e.target.value.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
            )
          }
          variant="standard"
          inputProps={{
            maxLength: 50,
          }}
          autoFocus={!isMobile}
        />
        <div className={styles.field}>
          <InputLabel
            variant="standard"
            htmlFor="uncontrolled-native"
            className={styles.repeat}
          >
            <p>How often?</p>
          </InputLabel>

          <NativeSelect
            onChange={(e) => {
              setFrequency(e.target.value);
              setFrequencyTitle(getNewFrequencyTitle(e.target.value, interval));
            }}
            value={frequency}
            inputProps={{
              name: "Frequency",
              id: "uncontrolled-native",
            }}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </NativeSelect>
        </div>

        <div className={styles.field}>
          <InputLabel variant="standard" className={styles.repeat}>
            <p>Repeat Every</p>
          </InputLabel>
          <Input
            className={styles.goalAmount}
            label="Interval"
            type="number"
            variant="standard"
            value={interval}
            onChange={(e) => {
              setInterval(e.target.value);
              setFrequencyTitle(
                getNewFrequencyTitle(frequency, e.target.value)
              );
            }}
            endAdornment={
              <InputAdornment position="end">{frequencyTitle}</InputAdornment>
            }
          />
        </div>

        {frequency === "weekly" && (
          <div className={styles.field}>
            <InputLabel variant="standard" className={styles.frequency}>
              <p>Which Days?</p>
            </InputLabel>
            <DayOfWeekSelector
              className={`${isMobile ? styles.daysMobile : styles.days}`}
              daysOfWeek={daysOfWeek}
              handleDaysOfWeek={handleDaysOfWeek}
            />
          </div>
        )}
        <div className={styles.field}>
          <InputLabel variant="standard" className={styles.repeat}>
            <p>Goal Amount</p>
          </InputLabel>
          <Input
            className={styles.goalAmount}
            label="Goal Amount"
            type="number"
            variant="standard"
            value={unitAmount}
            onChange={(e) => setUnitAmount(e.target.value)}
            endAdornment={
              <InputAdornment position="end">{currentUnit}</InputAdornment>
            }
          />
        </div>

        <div className={styles.field}>
          <InputLabel variant="standard" className={styles.frequency}>
            <p>Units</p>
          </InputLabel>
          {renderUnitSelector()}
        </div>
        {
          pathname !== "/new-pathway" && (
            <Grid container>
              <Grid xs={12} item>
                <div className={`${toggleCardStyles.card} ${styles.notifToggleCard}`}>
                  <div>Set Notification Time</div>
                  <div>
                    <TextField
                      type="time"
                      name="day"
                      required={false}
                      value={notificationTime}
                      onChange={(e) => handleTimeChange(e.target.value)}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          )
        }
        {pathname === "/new-pathway" ? (
          <div className={styles.wellnessBtns}>
            <button
              type="button"
              onClick={() => {
                if (index == 0) {
                  navigate("/new-pathway?page=2");
                } else {
                  setIndex(index - 1);
                  setPathwayHabit(index - 1);
                }
              }}
              className={`${btn.btn} ${btn.secondary}`}
            >
              Back
            </button>
            <button
              type="submit"
              className={`${btn.btn} ${btn.primary}`}
            >
              Next
            </button>
          </div>
        ) : (
          <button
            type="submit"
            className={`${btn.btn} ${btn.primary}`}
          >
            {loading ? "Adding..." : "Add"}
          </button>
        )}
      </BodyForm>
    </WrapperForm>
  );

  // format properly for mobile
  return pathname === "/new-pathway" ? (
    displayPage()
  ) : (
    <ScreenNoNav>{displayPage()}</ScreenNoNav>
  );
};

export default AddHabitScreen;
