import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import styles from "CSS/Screens/CognitiveDistortionsScreen.module.css";
import { useRedirect } from "Hooks/useRedirect";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectCognitiveDistortion } from "Reducers/CognitiveDistortionReducer";

const CognitiveDistortionEntryDetailScreen = () => {
  const redirectUrl = useRedirect("/toolkit/cognitive-distortions");
  const { selectedDistortionEntry } = useSelector(selectCognitiveDistortion);

  return (
    <Screen>
      <Wrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid xs={10} item>
            <Link
              to={redirectUrl}
              className={`${styles.backBtn}`}>
              Back
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid xs={10}
            item
            justifyContent="center"
            alignItems="center"
            className={`${styles.flexDisplay}`} >
            <h3>Congitive Entry</h3>
          </Grid>
        </Grid>
        {
          selectedDistortionEntry != null
          && selectedDistortionEntry.situation?.length > 0 && (
            <Grid
              className={`${styles.marginTop}`}
              container
              direction="row"
              alignItems="center"
              justifyContent="center">
              <Grid xs={10} item>
                <Card
                  variant="outlined"
                  sx={{ minWidth: "100%" }}
                  className={styles.paper}>
                  <CardContent>
                    <Grid container direction="row">
                      <Grid
                        xs={3}
                        item
                        justifyContent="left"
                        alignItems="center"
                        className={`${styles.flexDisplay}`}>
                        <div className={`
                          ${styles.situationIcon} 
                          ${styles.icon}`}></div>
                      </Grid>
                      <Grid
                        xs={9}
                        item
                        style={{ display: "flex", fontSize: "0.9em" }}
                        alignItems="center">
                        <p>{selectedDistortionEntry.situation}</p>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
        }
        {
          selectedDistortionEntry != null &&
          selectedDistortionEntry.thoughts?.length > 0 && (
            <Grid
              className={`${styles.marginTop}`}
              container
              direction="row"
              alignItems="center"
              justifyContent="center">
              <Grid xs={10} item>
                <Card
                  variant="outlined"
                  sx={{ minWidth: "100%" }}
                  className={styles.paper}>
                  <CardContent>
                    <Grid container direction="row">
                      <Grid
                        xs={3}
                        item
                        justifyContent="left"
                        alignItems="center"
                        className={`${styles.flexDisplay}`}>
                        <div className={`
                          ${styles.thoughtIcon} 
                          ${styles.icon}`}>
                        </div>
                      </Grid>
                      <Grid
                        xs={9}
                        item
                        style={{ display: "flex", fontSize: "0.9em" }}
                        alignItems="center">
                        <p>{selectedDistortionEntry.thoughts}</p>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
        }

        {
          selectedDistortionEntry != null &&
          selectedDistortionEntry.questionEntries?.length > 0 && selectedDistortionEntry.questionEntries.map((distortion, key) => (
            // Questions For Each Distortions
            <Grid
              className={`${styles.marginTop}`}
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              key={key}>
              <Grid xs={10} item>
                <Card
                  variant="outlined"
                  sx={{ minWidth: "100%" }}>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      style={{ marginBottom: "1em" }}>
                      <Grid xs={12} item
                        justifyContent="center"
                        alignItems="center"
                        style={{ display: "flex", marginBottom: "0.5em" }}>
                        <div className={`
                          ${styles.thoughtIcon} 
                          ${styles.icon}`}></div>
                      </Grid>
                      <Grid xs={12} item
                        style={{ display: "flex", fontSize: "0.9em" }}
                        alignItems="center"
                        justifyContent="center">
                        <Typography
                          gutterBottom
                          variant="p"
                          component="div">
                          {distortion.distortionId.title}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* Questions for the distortion */}
                    {distortion.distortionQuestions.map((question, index) => (
                      <Grid container
                        direction="row"
                        key={index}
                        style={{ marginBottom: "1em" }}>
                        <Grid xs={12} item style={{
                          display: "flex",
                          fontSize: "0.9em",
                          fontWeight: "bold"
                        }}
                          alignItems="center">
                          <p>{question.id.question}</p>
                        </Grid>
                        <Grid xs={12} item style={{
                          display: "flex",
                          fontSize: "0.9em"
                        }}
                          alignItems="center">
                          <p>{question.answer}</p>
                        </Grid>
                      </Grid>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ))
        }
      </Wrapper>
    </Screen>
  );
};

export default CognitiveDistortionEntryDetailScreen;
