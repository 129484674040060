import { InputLabel, NativeSelect } from "@mui/material";
import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/Pathway/AddPathwayCheckin.module.css";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { FiRepeat } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  getPathway,
  selectPathways,
  setAddPathwayInfo,
} from "Reducers/PathwayReducer";
import {
  addToDo,
  resetToDo,
  selectToDos,
} from "Reducers/ToDoReducer";

const AddPathwayCheckinScreen = () => {
  const { addPathwayInfo } = useSelector(selectPathways);
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState("Review " + searchParams.get("title"));
  const [todoDate, setToDoDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [recurrenceType, setRecurrenceType] = useState("weekly");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectUrl = useRedirect("/todos");
  const { loading, error } = useSelector(selectToDos);
  const { pathname } = useLocation();

  const addCheckSubmit = (e) => {
    e.preventDefault();

    dispatch(
      addToDo({
        todoTitle: title,
        remindTime: moment(todoDate).utc(),
        isFullDay: true,
        recurrenceType,
        pathway: searchParams.get("plan"),
      })
    );
    dispatch(getPathway(searchParams.get("plan")));

    // Success to reset state and go back to todos
    if (!loading && !error) {
      dispatch(resetToDo);
      navigate(redirectUrl);
    }
  };

  const handleAddPathwayCheckin = () => {
    // add checkin/todo to redux state
    dispatch(
      setAddPathwayInfo({
        ...addPathwayInfo, // preserve unchanged fields
        checkin: {
          todoTitle: title,
          remindTime: moment(todoDate).utc().toString(),
          isFullDay: true,
          recurrenceType,
        },
      })
    );

    navigate("/new-pathway?page=5");
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }

    if (addPathwayInfo?.title) {
      setTitle("Review " + addPathwayInfo.title);
    }

    if (addPathwayInfo?.checkin) {
      setTitle(addPathwayInfo?.checkin.todoTitle);
      setToDoDate(addPathwayInfo?.checkin.todoTime);
      setRecurrenceType(addPathwayInfo?.checkin.recurrenceType);
    }
  }, [error, dispatch]);

  const displayPage = () => (
    <WrapperForm onSubmit={addCheckSubmit}>
      {pathname !== "/new-pathway" && (
        <div className={styles.header}>
          <Link
            to={redirectUrl}
            className={`${btn.links} ${btn.secondaryLink}`}
          >
            Close
          </Link>
          <h3>New Check-In</h3>
          <button
            type="submit"
            className={`${btn.links} ${btn.primaryLink}`}
          >
            Add
          </button>
        </div>
      )}

      <BodyForm desktop={styles.body}>
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        <p className={styles.information}>
          A check-in reminds you to come back to your pathways
          periodically and re-align your goals.
        </p>
        <TextField
          className={styles.field}
          label="Check-In Title"
          type="text"
          placeholder="Enter To-Do"
          name="title"
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          variant="standard"
          inputProps={{
            maxLength: 256,
          }}
          autoFocus={!isMobile}
        />
        <TextField
          className={styles.field}
          label="Pick a starting day"
          type="date"
          name="day"
          required
          value={todoDate}
          onChange={(e) => setToDoDate(e.target.value)}
          variant="standard"
          InputLabelProps={{ shrink: true }}
        />
        <div className={styles.field}>
          <InputLabel
            variant="standard"
            htmlFor="uncontrolled-native"
            className={styles.repeat}
          >
            <p>How Often?</p>
            <FiRepeat size={12} />
          </InputLabel>
          <NativeSelect
            onChange={(e) => setRecurrenceType(e.target.value)}
            value={recurrenceType}
            inputProps={{
              name: "Recurrence",
              id: "uncontrolled-native",
            }}
          >
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </NativeSelect>
        </div>
        {pathname === "/new-pathway" ? (
          <div className={styles.btns}>
            <button
              type="button"
              onClick={() => {
                if (addPathwayInfo.habits.length === 0) {
                  navigate("/new-pathway?page=2");
                  return;
                }
                navigate(
                  `/new-pathway?page=3&item=${addPathwayInfo.habits.length - 1
                  }`
                );
              }}
              className={`${btn.links} ${btn.secondaryLink}`}
            >
              Back
            </button>
            <button
              type="button"
              onClick={() => handleAddPathwayCheckin()}
              className={`${btn.links} ${btn.primaryLink}`}
            >
              Next
            </button>
          </div>
        ) : (
          <button
            type="submit"
            className={`${btn.btn} ${btn.primary} ${styles.submit}`}
          >
            {loading ? "Adding..." : "Add"}
          </button>
        )}
      </BodyForm>
    </WrapperForm>
  );

  return pathname === "/new-pathway" ? (
    displayPage()
  ) : (
    <ScreenNoNav>{displayPage()}</ScreenNoNav>
  );
};

export default AddPathwayCheckinScreen;
