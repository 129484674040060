import { TextField } from "@mui/material";
import BodyForm from "Components/Helpers/BodyForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Components/Pathway/NewPathwayIntro.module.css";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectPathways,
  setAddPathwayInfo,
} from "Reducers/PathwayReducer";

const NewPathwayIntro = () => {
  const navigate = useNavigate();
  const [desiredOutcome, setDesiredOutcome] = useState("");
  const [title, setTitle] = useState("");
  const [motivationalSubtitle, setMotivationalSubtitle] = useState("");
  const [yourHow, setYourHow] = useState("");

  const dispatch = useDispatch();
  const { addPathwayInfo } = useSelector(selectPathways);

  const handleSubmit = (e) => {
    e.preventDefault();

    // set redux state
    dispatch(
      setAddPathwayInfo({
        ...addPathwayInfo, // preserve unchanged fields
        desiredOutcome,
        title,
        motivationalSubtitle,
        yourHow,
      })
    );

    navigate("/new-pathway?page=2");
  };

  useEffect(() => {
    if (addPathwayInfo) {
      setDesiredOutcome(addPathwayInfo.desiredOutcome);
      setTitle(addPathwayInfo.title);
      setMotivationalSubtitle(addPathwayInfo?.motivationalSubtitle);
      setYourHow(addPathwayInfo?.yourHow);
    }
  }, [addPathwayInfo?.title]);

  return (
    <form className={styles.pathwayIntro} onSubmit={handleSubmit}>
      <BodyForm desktop={styles.pathwayIntro_body}>
        <label className={styles.pathwayIntro_label}>
          What is your desired outcome?
        </label>
        <TextField
          className={styles.pathwayIntro_textField}
          label="Desired Outcome"
          type="text"
          placeholder="e.g run a 10k, establish an exercise routine, heal my ankle injury"
          name="name"
          required={true}
          multiline={true}
          rows={3}
          value={desiredOutcome}
          onChange={(e) => setDesiredOutcome(e.target.value)}
          variant="outlined"
          inputProps={{
            maxLength: 256,
          }}
          autoFocus={!isMobile}
          size="small"
        />
        <label className={styles.pathwayIntro_label}>
          Based on your desired outcome, pick a title for your pathway:
        </label>
        <TextField
          className={styles.pathwayIntro_textField}
          label="Title"
          type="text"
          placeholder="e.g Heal Ankle"
          name="name"
          required
          value={title}
          onChange={(e) =>
            setTitle(
              e.target.value.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
            )
          }
          variant="outlined"
          inputProps={{
            maxLength: 50,
          }}
          size="small"
        />
        <label className={styles.pathwayIntro_label}>
          (Optional) Pick a motivational subtitle!
        </label>
        <TextField
          className={styles.pathwayIntro_textField}
          label="Subtitle"
          type="text"
          placeholder='"You can do it"'
          name="name"
          value={motivationalSubtitle}
          onChange={(e) => setMotivationalSubtitle(e.target.value)}
          variant="outlined"
          inputProps={{
            maxLength: 256,
          }}
          size="small"
        />
        <label className={styles.pathwayIntro_label}>
          (Optional) How will your life be better by achieving this goal?
        </label>
        <TextField
          className={styles.pathwayIntro_textField}
          label="Your why"
          type="text"
          placeholder="My ankle will be healed"
          name="name"
          required={false}
          value={yourHow}
          onChange={(e) => setYourHow(e.target.value)}
          variant="outlined"
          inputProps={{
            maxLength: 256,
          }}
          size="small"
        />
        <button
          type="submit"
          className={`${btn.btn} ${btn.primary}`}
        >
          Next
        </button>
      </BodyForm>
    </form>
  );
};
export default NewPathwayIntro;
