import { Tab, Tabs } from "@mui/material";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import Pathway from "Components/Pathway/Pathway";
import PathwaySpeedDial from "Components/Pathway/PathwaySpeedDial";
import styles from "CSS/Screens/Pathway/IndividualPathwayScreen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getPathways,
  selectPathways,
  setPage,
} from "Reducers/PathwayReducer";

const PathwayScreen = () => {
  const { pathways, page } = useSelector(selectPathways);

  const dispatch = useDispatch();
  const [showPlans, setShowPlans] = useState(page);

  const handleSetShowPlans = (_, newValue) => {
    setShowPlans(newValue);
    dispatch(setPage(newValue));
  };

  useEffect(() => {
    // only get pathways if they aren't in state already
    if (pathways.active.length === 0) {
      dispatch(getPathways());
    }
  }, [dispatch]);

  const displayPlans = (type) => {
    return pathways[type].map((pathway) => (
      <Pathway key={pathway._id} pathway={pathway} />
    ));
  };

  return (
    <Screen>
      <Wrapper>
        <Tabs
          value={showPlans}
          onChange={handleSetShowPlans}
          style={{ marginBottom: "10px" }}
        >
          <Tab label="Plans" value="1" />
          <Tab label="Archived" value="2" />
        </Tabs>
        {showPlans == "1" && (
          <>
            <h1>Your Pathways</h1>
            {displayPlans("active")}
          </>
        )}
        {showPlans == "2" && (
          <>
            <h1>Your Archived Pathways</h1>
            {displayPlans("archived")}
          </>
        )}
        {pathways?.active.length === 0 && (
          <div>
            <Link to={"/new-pathway?page=1"} className={styles.fillerBtn}>
              <span className={styles.fillerPlus}>+</span>
              Create a new pathway
            </Link>
          </div>
        )}
      </Wrapper>
      <PathwaySpeedDial />
    </Screen>
  );
};

export default PathwayScreen;
