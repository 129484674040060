import { Alert, FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import FrequencySelector from "Components/Selectors/FrequencySelector";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/UpdateMedicationScreen.module.css";
import { useProvider } from "Hooks/useProvider";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectHealthCareProviders } from "Reducers/HealthCareProvidersReducer";
import {
  deleteMedication,
  getMedication,
  resetMedication,
  selectMedications,
  updateMedication,
} from "Reducers/MedicationsReducer";

const UpdateMedicationScreen = () => {
  const { selectedMedication, loading, error } = useSelector(selectMedications);
  const { renderAutocomplete, setProviderName, providerName } = useProvider();
  const { newlyAddedId } = useSelector(selectHealthCareProviders);
  const redirectUrl = useRedirect("/my-health-record/medications");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [medicationName, setMedicationName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isInactive, setIsInactive] = useState(false);
  const [sideEffects, setSideEffects] = useState("");
  const [frequencies, setFrequencies] = useState([]);
  const [dosage, setDosage] = useState("");

  // fills all the fields for the given medication entry with the data from it
  const fillFields = () => {
    if (selectedMedication) {
      setMedicationName(selectedMedication.medicationName);
      setIsInactive(selectedMedication.isInactive);
      setDate(moment(selectedMedication.date).format("YYYY-MM-DDTHH:mm"));
      setProviderName(selectedMedication.prescriber);
      setDosage(selectedMedication.dosage);
      setFrequencies(selectedMedication.frequencies);
      setSideEffects(selectedMedication.sideEffects);
      setAdditionalNotes(selectedMedication.additionalNotes);
    }
  };

  const updateMedicationSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateMedication({
        id,
        medicationName,
        date: moment(date).utc(),
        isInactive,
        prescriber: providerName.healthCareProviderName,
        dosage,
        frequencies,
        sideEffects,
        additionalNotes,
        healthCareProvider: newlyAddedId,
      })
    );

    // Success so go back
    if (!loading && !error) {
      navigate("/my-health-record/medications");
    }
  };

  const deleteMedicationAction = (e) => {
    e.preventDefault();

    localStorage.setItem("tempMedication", JSON.stringify(selectedMedication));
    dispatch(deleteMedication(selectedMedication._id)); //may need to remove _ from id

    // Success so go back to history page
    if (!loading && !error) {
      navigate("/my-health-record/medications");
    }
  };

  useEffect(() => {
    dispatch(getMedication(id));

    fillFields();

    if (error) {
      setErrorMessage(error);
    }

    return () => {
      dispatch(resetMedication);
    };
  }, [selectedMedication?.medicationName]);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={updateMedicationSubmit}>
        <div className={styles.header}>
          <button
            className={`${btn.links} ${btn.secondaryLink}`}
            onClick={() => navigate(redirectUrl)}
            type="button"
          >
            Cancel
          </button>
          <h3>Update Medication</h3>
          <button type="submit" className={`${btn.links} ${btn.primaryLink}`}>
            Update
          </button>
        </div>
        <div className={`${styles.body} ${isMobile ? styles.bodyMobile : ""}`}>
          {errorMessage && (
            <Alert
              sx={{ width: "80%", marginBottom: "15px" }}
              severity="error"
              onClose={() => setErrorMessage("")}
            >
              {errorMessage}
            </Alert>
          )}

          <div
            className={`${styles.body} ${isMobile ? styles.bodyMobile : ""}`}
          >
            <div className={styles.textField}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!isInactive}
                    onChange={(e) => setIsInactive(!e.target.checked)}
                    label="Active Status"
                  />
                }
                label={`Active Status: ${isInactive ? "inactive" : "active"}`}
              />
            </div>
            <TextField
              className={styles.textField}
              label="Medication"
              type="text"
              placeholder="Enter medication name"
              name="name"
              required={true}
              value={medicationName}
              onChange={(e) => setMedicationName(e.target.value)}
              size="small"
            />
            <TextField
              className={styles.textField}
              label="Prescription start date"
              type="datetime-local"
              name="day"
              required={true}
              value={date}
              onChange={(e) => setDate(e.target.value)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              size="small"
            />

            {renderAutocomplete()}

            <TextField
              className={styles.textField}
              label="Dosage"
              type="text"
              placeholder="Enter dosage"
              name="dosage"
              required={false}
              value={dosage}
              onChange={(e) => setDosage(e.target.value)}
              size="small"
            />
            <TextField
              className={styles.textField}
              label="Side Effects"
              type="text"
              placeholder="Enter side effects"
              name="sideEffects"
              required={false}
              value={sideEffects}
              onChange={(e) => setSideEffects(e.target.value)}
              size="small"
              multiline={true}
              rows={3}
            />
            <TextField
              className={styles.textField}
              label="Additional Notes"
              type="text"
              placeholder="Enter additional notes (refills, insurance coverage, etc.)"
              name="additionalNotes"
              required={false}
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
              size="small"
              multiline={true}
              rows={3}
            />
            <FrequencySelector
              frequencies={frequencies}
              setFrequencies={(val) => setFrequencies(val)}
            />
            <div className={styles.btns}>
              <button
                type="button"
                className={`${btn.btn} ${btn.secondary}`}
                onClick={deleteMedicationAction} //none style
              >
                Delete
              </button>
              <button
                type="submit"
                className={`${btn.btn} ${btn.primary}`}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </div>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default UpdateMedicationScreen;
