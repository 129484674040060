import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send (backend) request to add new activities for a user
const addActivities = createAsyncThunk("add activities/", (activities, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/activity", { activities }),
    thunkAPI,
  )
);

// send (backend) request to add new activities for a user
const addActivityEntry = createAsyncThunk("add activity entry/", (activity, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/activity/entry", activity),
    thunkAPI,
  )
);

// send (backend) request to add new activities for a user
const addActivityToPathway = createAsyncThunk("add activity to pathway/", (pathway, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/activity/pathway", pathway),
    thunkAPI,
  )
);


const getActivities = createAsyncThunk("get activities/", (page, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/activity?page=${page}`),
    thunkAPI,
  )
);

const getActivityByName = createAsyncThunk("get Activity/", (name, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/activity?name=${name}`),
    thunkAPI,
  )
);

const getActivityHistory = createAsyncThunk("get activities history/", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/activity/history/${id}`),
    thunkAPI
  )
);

const deleteActivity = createAsyncThunk("delete activity/", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/activity/${id}`),
    thunkAPI
  )
);

const updateActivity = createAsyncThunk("update activity/", (activity, thunkAPI) =>
  actionHandler(
    axiosInstance.put("/activity", activity),
    thunkAPI
  )
);

export {
  addActivities, addActivityEntry, addActivityToPathway, deleteActivity, getActivities, getActivityByName, getActivityHistory, updateActivity
};

