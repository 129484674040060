import { FormControl, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../CSS/Screens/HealthCareProviderHistoryScreen.module.css";
import HealthCareProvider from "../../Components/HealthCareProvider/HealthCareProvider";
import AddFab from "../../Components/Helpers/AddFab";
import AlertMessage from "../../Components/Helpers/AlertMessage";
import LoadingSpinner from "../../Components/Helpers/LoadingSpinner";
import Screen from "../../Components/Helpers/Screen";
import UndoButton from "../../Components/Helpers/UndoButton";
import Wrapper from "../../Components/Helpers/Wrapper";
import BackBtn from "../../Components/Navigation/BackBtn";
import {
  getHealthCareProviders,
  resetAddHealthCareProviderSuccess,
  resetDeleteHealthCareProviderSuccess,
  selectHealthCareProviders,
  setStatus,
} from "../../Reducers/HealthCareProvidersReducer";
import undoHealthCareProvider from "../../Utilities/undo-healthCareProvider";

const HealthCareProviderHistoryScreen = () => {
  const dispatch = useDispatch();
  const {
    healthCareProviders,
    loading,
    error,
    successAdd,
    successDelete,
    status,
  } = useSelector(selectHealthCareProviders);

  const [showUndo, setShowUndo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [currentStatus, setCurrentStatus] = useState(status);

  // Hold Provider Search Filter
  const [searchInput, setSearchInput] = useState("");

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new care provider!");
      timer = setTimeout(() => {
        dispatch(resetAddHealthCareProviderSuccess());
      }, 2000);
    }
    return timer;
  };

  const handleSuccessDelete = () => {
    if (successDelete) {
      // show undo popup after user deletes entry
      setShowUndo(true);
      setTimeout(() => {
        setShowUndo(false);
        dispatch(resetDeleteHealthCareProviderSuccess());
      }, 5000);
    }
  };

  const handleChange = (event) => {
    // update local state and redux state
    setCurrentStatus(event.target.value);
    dispatch(setStatus(event.target.value));
  };

  useEffect(() => {
    dispatch(getHealthCareProviders());

    if (error) {
      setErrorMessage(error);
    }
  }, [dispatch, error]);

  useEffect(() => {
    const timer = handleSuccessAdd();
    handleSuccessDelete();

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, successAdd, successDelete]);

  const displayHealthCareProviders = (currentStatus) => {
    if (
      healthCareProviders[currentStatus] &&
      healthCareProviders[currentStatus].length > 0 &&
      searchInput === ""
    ) {
      return healthCareProviders[currentStatus].map((healthCareProvider) => (
        <HealthCareProvider
          key={healthCareProvider._id}
          provider={healthCareProvider}
        />
      ));
    } else if (
      healthCareProviders[currentStatus] &&
      healthCareProviders[currentStatus].length > 0 &&
      searchInput !== ""
    ) {

      return healthCareProviders[currentStatus]
        .filter(
          (cProvider) =>
            cProvider.healthCareProviderName
              .toLowerCase()
              .includes(searchInput) ||
            cProvider.field.toLowerCase().includes(searchInput) ||
            cProvider.phoneNumber.toLowerCase().includes(searchInput) ||
            cProvider.address.toLowerCase().includes(searchInput) ||
            cProvider.additionalNotes.toLowerCase().includes(searchInput)
        )
        .map((healthCareProvider) => (
          <HealthCareProvider
            key={healthCareProvider._id}
            provider={healthCareProvider}
          />
        ));
    }
  };

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <Screen>
      <Wrapper>
        <BackBtn title="Back" link="/my-health-record" />
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        <AlertMessage
          message={successMessage}
          setMessage={() => {
            setSuccessMessage("🎉 Added new health care provider!");
            dispatch(resetAddHealthCareProviderSuccess());
          }}
          type="success"
          toggle={successAdd}
        />

        {loading && healthCareProviders.current.length === 0 && (
          <LoadingSpinner />
        )}
        <div className={`${styles.header} ${styles.completedHeader}`}>
          <h3>👩‍⚕️ Care Providers</h3>
          <div>
            <FormControl fullWidth>
              <Select
                sx={{ borderRadius: "15px" }}
                className={`${styles.filterSelect}`}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentStatus}
                onChange={handleChange}
              >
                <MenuItem value={"current"}>Current</MenuItem>
                <MenuItem value={"archived"}>Archived</MenuItem>
                <MenuItem value={"all"}>All</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={styles.searchContainer}>
          <form>
            <TextField
              className={styles.searchBar}
              label="Search providers"
              type="search"
              placeholder="Search health care providers here"
              onChange={handleSearch}
              value={searchInput}
              size="small"
              inputProps={{
                maxLength: 15,
              }}
            />
          </form>
        </div>
        {healthCareProviders.current?.length === 0 &&
          healthCareProviders.archived?.length === 0 && (
            <p className={styles.filler}>No health care providers!</p>
          )}
        {(currentStatus === "current" || currentStatus === "all") &&
          displayHealthCareProviders("current")}
        {(currentStatus === "archived" || currentStatus === "all") &&
          displayHealthCareProviders("archived")}
      </Wrapper>
      <UndoButton
        callback={() => {
          setShowUndo(false);
          undoHealthCareProvider(dispatch);
        }}
        showUndo={showUndo}
      />
      <AddFab url="/my-health-record/add-health-care-provider" />
    </Screen>
  );
};

export default HealthCareProviderHistoryScreen;
