import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/CognitiveDistortionsScreen.module.css";
import { useRedirect } from "Hooks/useRedirect";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addSelectedDistortions, getCongitiveDistortions, removeSelectedDistortions, selectCognitiveDistortion, updateSelectedDistortion } from "Reducers/CognitiveDistortionReducer";

const CognitiveDistortionsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isToggleDefinition, setIsToggleDefinition] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const redirectUrl = useRedirect("/toolkit/cognitive-thoughts");
  const {
    cognitiveDistortions,
    selectedDistortionIds,
    thoughts,
    loading,
  } = useSelector(selectCognitiveDistortion);

  useEffect(() => {
    dispatch(getCongitiveDistortions());
  }, [dispatch]);

  const handleNextClick = () => {
    if (selectedDistortionIds.length === 0) {
      setIsToastOpen(true);
    } else {
      navigate("/toolkit/distortion-questions");
    }
  };

  const handleCancelClick = () => {
    navigate("/toolkit/cognitive-distortions");
  };

  const handleDefinitionToggle = (e) => {
    setIsToggleDefinition(e.target.checked);
  };

  const handleDistortionClick = (distortion) => {
    if (selectedDistortionIds.includes(distortion._id)) {
      dispatch(removeSelectedDistortions(distortion));
    } else {
      dispatch(addSelectedDistortions(distortion));
    }
  };

  const handleLearnMoreClick = (distortion) => {
    dispatch(updateSelectedDistortion(distortion));
    navigate("/toolkit/distortion-info");
  };

  return (
    <Screen>
      <Wrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid xs={10} item>
            <Link
              to={redirectUrl}
              className={`${styles.backBtn}`}>
              Back
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid
            xs={10}
            item
            className={`${styles.centerAlign}`}>
            <p>
              {thoughts}
            </p>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid xs={10} item>
            <Card className={`${styles.card}`}>
              <CardContent
                className={`${styles.cardContent}`}>
                <Grid item xs={12} className={`${styles.centerAlign}`}>
                  <h3>My thought an example of..</h3>
                </Grid>
                <Grid item xs={12} className={`${styles.centerAlign}`}>
                  (Tap all that apply)
                </Grid>
              </CardContent>
            </Card>
            <Grid xs={12} item style={{ textAlign: "end" }}>
              <FormControlLabel
                style={{ marginRight: "0px" }}
                control={<Switch
                  defaultChecked={isToggleDefinition}
                  onChange={handleDefinitionToggle} />}
                label={(isToggleDefinition ? "Hide definitions" : "Show definitions")} />
            </Grid>
          </Grid>
        </Grid>
        {
          loading && <LoadingSpinner />
        }
        {
          cognitiveDistortions && cognitiveDistortions.map((distortion, index) => (
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
              className={`${styles.marginTop}`}
              key={index}>
              <Grid xs={10} item>
                <Card
                  variant="outlined"
                  sx={{ minWidth: "100%" }}
                  className={(selectedDistortionIds.includes(distortion._id) ? `${styles.selected}` : "")}
                  onClick={() => handleDistortionClick(distortion)}>
                  <CardContent>
                    <Grid container direction="row">
                      <Grid
                        xs={3}
                        item
                        justifyContent="left"
                        alignItems="center"
                        className={`${styles.flexDisplay}`}>
                        <Avatar
                          alt="Remy Sharp"
                          src=""
                          sx={{ width: 40, height: 40 }}
                        />
                      </Grid>
                      <Grid
                        xs={9}
                        item
                        style={{ display: "flex", fontSize: "0.9em" }}
                        alignItems="center">
                        <p>
                          <span
                            className={(isToggleDefinition ? `${styles.bold}` : "")}>
                            {distortion.title}
                          </span>
                          {isToggleDefinition && (<span><br />{" " + distortion.info}</span>)}
                        </p>
                      </Grid>
                      {
                        isToggleDefinition && (
                          <Grid
                            xs={12}
                            item
                            style={{
                              display: "flex",
                              fontSize: "0.9em",
                              marginTop: "1em"
                            }}
                            alignItems="right"
                            justifyContent="right">
                            <a
                              onClick={() => handleLearnMoreClick(distortion)}
                              className={`${styles.backBtn} ${styles.link}`}>
                              Learn more
                            </a>
                          </Grid>
                        )
                      }
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ))
        }
        {
          isToastOpen && (
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                xs={10}
                justifyContent="center"
                alignItems="center"
                item
                className={`${styles.flexDisplay}`}>
                <AlertMessage
                  message="Select atleast one distortion to proceed."
                  setMessage={() => setIsToastOpen(false)}
                  type="warning"
                  toggle={isToastOpen}
                />
              </Grid>
            </Grid>
          )
        }

        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginTop: "0.5em" }}
          item={true}>
          <Grid item xs={4}>
            <button
              className={`
                ${btn.faded_orange} 
                ${btn.smallActivityBtn} 
                ${styles.loadMoreBtn}`}
              onClick={handleCancelClick}>
              Cancel
            </button>
          </Grid>
          <Grid item xs={4} className={`${styles.rightAlign}`}>
            <button
              className={`
                ${btn.faded_blue} 
                ${btn.smallActivityBtn} 
                ${styles.loadMoreBtn}`}
              onClick={handleNextClick}>
              Next
            </button>
          </Grid>
        </Grid>
      </Wrapper>
    </Screen >
  );
};

export default CognitiveDistortionsScreen;
