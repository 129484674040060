import CircularSlider from "@fseehawer/react-circular-slider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import AlertDialog from "Components/Helpers/AlertDialog";
import AlertMessage from "Components/Helpers/AlertMessage";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import { EMOTION_EMOJI_MAP } from "Constants/EmotionSelector";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/UpdateJournalEntryScreen.module.css";
import useEmotionSelector from "Hooks/useEmotionSelector";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getActivities } from "Reducers/Actions/ActivityActions";
import { selectActivity } from "Reducers/ActivityReducer";
import {
  deleteJournalEntry,
  getJournalEntry,
  resetJournal,
  selectJournalEntries,
  updateJournalEntry,
} from "Reducers/JournalReducer";

const UpdateJournalEntryScreen = () => {
  const { selectedJournalEntry, loading, error } =
    useSelector(selectJournalEntries);
  const { activities } = useSelector(selectActivity);
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [textReview, setTextReview] = useState("");
  const [emotionRating, setEmotionRating] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [activity, setActivity] = useState("");
  const [dateTime, setDateTime] = useState(
    moment().format("YYYY-MM-DDTHH:mm") // just set to present time
  );
  const [emojiLabel, setEmojiLabel] = useState(EMOTION_EMOJI_MAP[0]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { emotions, setEmotions, renderModal, renderSelector } =
    useEmotionSelector();
  // autofills all the fields for the given journal entry
  const fillFields = () => {
    if (selectedJournalEntry) {
      setTextReview(selectedJournalEntry.textReview);
      setEmotionRating(selectedJournalEntry.emotionRating);
      setEmojiLabel(EMOTION_EMOJI_MAP[selectedJournalEntry.emotionRating]);
      setDateTime(
        moment(selectedJournalEntry.date).local().format("YYYY-MM-DDTHH:mm")
      ); // format journal entry time
      setEmotions(selectedJournalEntry.emotions);
      setActivity(
        selectedJournalEntry.activity ? selectedJournalEntry.activity : ""
      );
    }
  };

  const updateJournalEntrySubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateJournalEntry({
        id,
        date: moment(dateTime).utc(),
        emotionRating,
        emotions,
        textReview: textReview,
        activity,
      })
    );

    // Success so go back to journals page
    if (!loading && !error) {
      navigate("/journal"); //remove once weird error bug resolved
    }
  };

  const handleDeleteJournalEntry = (e) => {
    e.preventDefault();
    setIsDeleteModalOpen(true);
  };

  const deleteJournalEntryAction = () => {
    localStorage.setItem(
      "tempJournalEntry",
      JSON.stringify(selectedJournalEntry)
    );
    dispatch(deleteJournalEntry(selectedJournalEntry._id));

    // Success so go back to history page
    if (!loading && !error) {
      navigate("/journal");
    }
  };

  useEffect(() => {
    dispatch(getJournalEntry(id));
    dispatch(getActivities());

    if (error) {
      setErrorMessage(error);
    }
    fillFields();

    return () => {
      dispatch(resetJournal);
    };
  }, [selectedJournalEntry?.textReview]);

  useEffect(() => {
    setEmojiLabel(EMOTION_EMOJI_MAP[emotionRating]);
  }, [emotionRating]);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={updateJournalEntrySubmit}>
        <AlertDialog
          handleConfirmation={deleteJournalEntryAction}
          handleClose={() => setIsDeleteModalOpen(false)}
          dialogTitle="Are you sure you want to delete?"
          dialogActionText="Delete"
          isOpen={isDeleteModalOpen}
        />
        <div className={styles.updateJournal_header}>
          <button
            className={`${btn.links} ${btn.secondaryLink}`}
            onClick={() => navigate("/journal")}
            type="button"
          >
            Cancel
          </button>
          <h3>Update Entry</h3>
          <button
            type="submit"
            className={`${btn.links} ${btn.primaryLink}`}
          >
            Update
          </button>
        </div>
        <div
          className={`${styles.updateJournal_body} ${isMobile ? styles.updateJournal_bodyMobile : ""
            }`}
        >
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />

          {renderModal()}

          <div
            className={`${styles.updateJournal_body} ${isMobile ? styles.updateJournal_bodyMobile : ""
              }`}
          >
            <TextField
              className={styles.datetextarea}
              autoFocus={!isMobile}
              type="datetime-local"
              name="day"
              required={true}
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
            />
            <br />
            <CircularSlider
              label={emojiLabel}
              value={emotionRating}
              type="number"
              labelColor="#107ca4"
              knobColor="#009c9a"
              progressColorFrom="#FF9800"
              progressColorTo="#8BC34A"
              trackColor="#eeeeee"
              data={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
              min={0}
              max={10}
              dataIndex={emotionRating}
              labelBottom={true}
              labelFontSize="3rem"
              valueFontSize="1.5rem"
              onChange={(e) => setEmotionRating(e)}
              width={140}
              knobSize={32}
              trackSize={6}
              progressSize={12}
            />

            {renderSelector()}
            <FormControl variant="filled" sx={{ m: 1, minWidth: "85%" }}>
              <InputLabel id="activity-selector">
                Pick the trigger activity.
              </InputLabel>
              <Select
                labelId="activity-selector"
                id="activity-selector"
                value={activity}
                variant="outlined"
                onChange={(e) => setActivity(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {activities.map((activity, index) => (
                  <MenuItem key={index} value={activity._id}>
                    {activity.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              className={styles.textarea}
              label="Enter review"
              type="text"
              multiline
              rows={3.5}
              placeholder="How are you feeling?"
              name="review"
              required={false}
              onChange={(e) => setTextReview(e.target.value)}
              value={textReview}
              variant="outlined"
              inputProps={{
                maxLength: 500,
              }}
            />
          </div>
          <div className={styles.updateJournal_btns}>
            <button
              type="button"
              className={`${btn.btn} ${btn.secondary}`}
              onClick={handleDeleteJournalEntry}
            >
              Delete
            </button>
            <button
              type="submit"
              className={`${btn.btn} ${btn.primary}`}
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default UpdateJournalEntryScreen;
