import { useState } from "react";
import { selectBadges } from "Reducers/BadgesReducer";
import { updateHabit, updateHabitInstance } from "Reducers/HabitsReducer";
import { addToBadge } from "Utilities/update-badge";

const { useDispatch, useSelector } = require("react-redux");

// custom hook to handle the progress of a habit
const useHabitProgress = (habit, instance) => {
  const [progress, setProgress] = useState(
    habit && habit.amountCompleted ? habit?.amountCompleted : 0 // needs to be 0 or else NaN will appear
  );

  const dispatch = useDispatch();
  const { badges } = useSelector(selectBadges);

  const increaseInstanceProgress = () => {
    const amount =
      instance.unit === "count"
        ? progress + 1 // add 1 if the unit is count
        : progress + instance.unitAmount * 0.25; // add 25% progress to bar on click

    setProgress(amount);

    let isCompleted = false;
    if (amount >= instance.unitAmount) {
      isCompleted = true;
    }

    // increase total completions on habit
    if (progress < instance.unitAmount && amount >= instance.unitAmount) {
      dispatch(
        updateHabit({
          id: instance.habit,
          incrementCompletion: true,
        })
      );
    }

    dispatch(
      updateHabitInstance({
        id: instance._id,
        amountCompleted: amount,
        isCompleted,
      })
    );
  };

  const decreaseInstanceProgress = () => {
    let amount =
      instance.unit === "count"
        ? progress - 1 // add 1 if the unit is count
        : progress - instance.unitAmount * 0.25; // subtract 25% progress to bar on click

    amount = amount <= 0 ? 0 : amount; // ensure you can't set negative values
    setProgress(amount);

    let isCompleted = true;
    if (amount < instance.unitAmount) {
      isCompleted = false;
    }

    // decrease total completions on habit
    if (progress >= instance.unitAmount && amount < instance.unitAmount) {
      dispatch(
        updateHabit({
          id: instance.habit,
          decrementCompletion: true,
        })
      );
    }

    dispatch(
      updateHabitInstance({
        id: instance._id,
        amountCompleted: amount,
        isCompleted,
      })
    );
  };

  const completeInstanceProgress = () => {
    if (progress < instance.unitAmount) {
      setProgress(instance.unitAmount);

      // increase total completions on habit
      dispatch(
        updateHabit({
          id: instance.habit,
          incrementCompletion: true,
        })
      );

      dispatch(
        updateHabitInstance({
          id: instance._id,
          amountCompleted: instance.unitAmount,
          isCompleted: true,
        })
      );
    }
  };

  const increaseProgress = () => {
    const amount =
      habit.unit === "count"
        ? progress + 1 // add 1 if the unit is count
        : progress + habit.unitAmount * 0.25; // add 25% progress to bar on click

    // the click that puts progress >= too 100% increments streak
    let currentStreak = habit.currentStreak;
    let bestStreak = habit.bestStreak;
    if (progress < habit.unitAmount && amount >= habit.unitAmount) {
      currentStreak++;
      if (currentStreak > bestStreak) {
        bestStreak++;
        // update badge if new best streak is higher than global highest
        const badge = badges.find((badge) => badge.name === "Streak");
        if (!badge.progress || bestStreak > badge.progress) {
          addToBadge(badge, dispatch, bestStreak); // add 0 since we diercly assign new value instead of increment
        }
      }
    }

    setProgress(amount);
    dispatch(
      updateHabit({
        id: habit._id,
        amountCompleted: amount,
        currentStreak,
        bestStreak,
      })
    );
  };

  const decreaseProgress = () => {
    let amount =
      habit.unit === "count"
        ? progress - 1 // add 1 if the unit is count
        : progress - habit.unitAmount * 0.25; // subtract 25% progress to bar on click

    amount = amount <= 0 ? 0 : amount; // ensure you can't set negative values
    let currentStreak = habit.currentStreak;
    let bestStreak = habit.bestStreak;
    if (progress >= habit.unitAmount && amount < habit.unitAmount) {
      if (currentStreak === bestStreak) {
        bestStreak--;
      }
      currentStreak--;
    }

    setProgress(amount);

    dispatch(
      updateHabit({
        id: habit._id,
        amountCompleted: amount,
        bestStreak,
        currentStreak,
      })
    );
  };

  const completeProgress = () => {
    if (progress < habit.unitAmount) {
      setProgress(habit.unitAmount);
      const currentStreak = habit.currentStreak + 1;
      let bestStreak = habit.bestStreak;

      if (currentStreak > bestStreak) {
        bestStreak++;
      }

      dispatch(
        updateHabit({
          id: habit._id,
          amountCompleted: habit.unitAmount,
          currentStreak,
          bestStreak,
        })
      );
    }
  };

  return {
    progress,
    setProgress,
    increaseProgress,
    decreaseProgress,
    completeProgress,
    increaseInstanceProgress,
    decreaseInstanceProgress,
    completeInstanceProgress,
  };
};

export { useHabitProgress };
