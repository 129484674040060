import { createSlice } from "@reduxjs/toolkit";
import {
  addAppointmentNote,
  deleteAppointmentNote,
  getAppointmentNote,
  getAppointmentNotes,
  updateAppointmentNote,
} from "./Actions/AppointmentNoteActions";

const initialState = {
  past: [], // past appointment notes only
  loading: false,
  error: null,
  successAdd: false,
  successDelete: false,
  selectedAppointmentNote: null,
};

// functions for "useSelector"
const selectAppointmentNotes = (state) => state.appointment; // idk if journalEntries or different name[maybe Journal]

const appointmentNotesSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    resetAppointmentNotes: () => initialState,
    // For updating when we only want to reset the error and loading
    resetAppointmentNote: ({ past }) => {
      return {
        past,
        loading: false,
        error: null,
        successAdd: false,
        successDelete: false,
        selectedAppointmentNote: null,
      };
    },
    resetAddAppointmentNoteSuccess: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    resetDeleteAppointmentNoteSuccess: (state) => {
      return {
        ...state,
        successDelete: false,
      };
    },
  },
  extraReducers: {
    // Get appointment notes Async Reducers
    [getAppointmentNote.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAppointmentNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedAppointmentNote = payload;
    },
    [getAppointmentNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Fetch entries Async Reducers
    [getAppointmentNotes.pending]: (state) => {
      state.loading = true;
    },
    [getAppointmentNotes.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.past = payload;
      state.error = null;
      sortList(state);
    },
    [getAppointmentNotes.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add appointment note Async Reducers
    [addAppointmentNote.pending]: (state) => {
      state.loading = true;
    },
    [addAppointmentNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.successAdd = true;
      state.past.push(payload); // Push entry to past [array]
      sortList(state);
    },
    [addAppointmentNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // Update appointment note Async Reducers
    [updateAppointmentNote.pending]: (state) => {
      state.loading = true;
    },
    [updateAppointmentNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.selectedAppointmentNote = payload;
      updateAppointmentNoteFulfilled(state, payload);
      sortList(state);
    },
    [updateAppointmentNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete appointment note Async Reducers
    [deleteAppointmentNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.successDelete = true;
      state.past = state.past.filter(
        (appointmentNotes) => appointmentNotes._id !== payload._id
      ); //remove entry from history visually immediately
      sortList(state);
    },
    [deleteAppointmentNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successDelete = false;
    },
  },
});

// helper functions
// logic for placing the updated appointment note into the proper order
const updateAppointmentNoteFulfilled = (state, payload) => {
  // find appointment note index and update it
  const idx = state.past.findIndex(
    (appointmentNote) => appointmentNote._id === payload._id
  );
  state.past[idx] = payload;
};

// sorts all past entries based on created time
const sortList = (state) => {
  state.past = state.past.sort((a, b) => new Date(b.date) - new Date(a.date));
};

export default appointmentNotesSlice.reducer;
export {
  addAppointmentNote, deleteAppointmentNote, getAppointmentNote, getAppointmentNotes, selectAppointmentNotes, updateAppointmentNote
};
export const {
  resetAppointmentNotes,
  resetAppointmentNote,
  resetAddAppointmentNoteSuccess,
  resetDeleteAppointmentNoteSuccess,
} = appointmentNotesSlice.actions;
