import moment from "moment";
import { addAppointmentNote } from "Reducers/AppointmentNoteReducer";

const undoAppointmentNote = (dispatch) => {
  const appointmentNote = JSON.parse(
    localStorage.getItem("tempAppointmentNote")
  );

  dispatch(
    addAppointmentNote({
      date: moment(appointmentNote.date).utc(),
      thingsToDiscuss: appointmentNote.thingsToDiscuss,
      appointmentNotes: appointmentNote.appointmentNotes,
      emotions: appointmentNote.emotions,
      followUp: appointmentNote.followUp,
    })
  );
};

export default undoAppointmentNote;
