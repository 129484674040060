import styles from "CSS/Components/Pathway/PathwayNote.module.css";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PathwayNote = memo(function temp({ pathwayNote }) {
  const [day, setDay] = useState(moment());
  const [time, setTime] = useState("");
  const navigate = useNavigate();

  const generateTitle = () => {
    if (!pathwayNote.textReview) {
      return "no title";
    }

    return pathwayNote.textReview.length > 28
      ? `${pathwayNote.textReview.substring(0, 28)}...`
      : pathwayNote.textReview.length == 0
        ? day + " " + time
        : pathwayNote.textReview;
  };

  useEffect(() => {
    setDay(
      moment(pathwayNote.date).format("MMMM") +
      " " +
      moment(pathwayNote.date).date()
    );
    setTime(
      ", " +
      moment(pathwayNote.date)
        .local()
        .format("hh:mm A")
        .replace(/(^|-)0+/g, "")
    ); // remove leading 0 on date
  }, [pathwayNote.date]);

  return (
    <section className={styles.pathwayNote_container}>
      <div
        className={`${styles.pathwayNote_info}
        }`}
      >
        <div className={styles.pathwayNote_header}>
          <h3
            onClick={() =>
              navigate(`/update-pathway-note/${pathwayNote._id}`)
            }
          >
            {generateTitle()}
          </h3>
          <div
            className={styles.pathwayNote_iconPathway}
            onClick={() =>
              navigate(
                `/pathway/${pathwayNote.pathway}?redirect=/journal`
              )
            }
          />
        </div>
        <p onClick={() => navigate("/journal")}>
          {moment(pathwayNote.date).isSame(moment(), "day")
            ? "🗓️ " + "Today" + time
            : "🗓️ " + day + time}
        </p>
      </div>
    </section>
  );
});

export default PathwayNote;
