import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import UndoButton from "Components/Helpers/UndoButton";
import Wrapper from "Components/Helpers/Wrapper";
import ToDo from "Components/ToDo/ToDo";
import globals from "CSS/Globals.module.css";
import styles from "CSS/Screens/CompletedToDosScreen.module.css";
import { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCompletedToDos,
  resetToDos,
  selectToDos,
} from "Reducers/ToDoReducer";
import undoToDo from "Utilities/undo-todo";

const CompletedToDosScreen = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [limitAmount] = useState(0);
  const [showUndo, setShowUndo] = useState(false);

  const { completed, loading, error } = useSelector(selectToDos);

  useEffect(() => {
    dispatch(getCompletedToDos({ type: "", limit: limitAmount }));

    if (error) {
      setErrorMessage(error);
    }

    return () => {
      dispatch(resetToDos());
    };
  }, [dispatch, error]);

  // const loadMore = () => {
  //   if (completed.length >= limitAmount) {
  //     setLimitAmount(limitAmount + 15);
  //   }
  //   dispatch(getCompletedToDos({ type: "", limit: limitAmount }));
  // };

  return (
    <Screen>
      <Wrapper>
        <div className={`${styles.header} ${styles.headerFirst}`}>
          <Link to="/todos" className={styles.back}>
            Back
          </Link>
          <h3>Completed To-Do&apos;s</h3>
        </div>
        {errorMessage && <p className={globals.errorMessage}>{errorMessage}</p>}
        {loading && <LoadingSpinner />}

        {completed.map((todo) => (
          <ToDo key={todo._id} todo={todo} />
        ))}
        {/* {completed.length > 14 && completed.length >= limitAmount && (
          <button
            type="submit"
            className={`${btn.btn} ${styles.loadMore}`}
            onClick={loadMore}
          >
            {loading ? "Loading..." : "Load more"}
          </button>
        )} */}

        <Link to="/todos" className={styles.viewHistory}>
          <BsArrowLeft className={styles.backArrow} size={16} />
          <p>go back</p>
        </Link>
      </Wrapper>
      {showUndo && (
        <UndoButton
          callback={() => {
            setShowUndo(false);
            undoToDo(dispatch);
          }}
          showUndo={showUndo}
        />
      )}
    </Screen>
  );
};

export default CompletedToDosScreen;
