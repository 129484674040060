import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import UndoButton from "Components/Helpers/UndoButton";
import Wrapper from "Components/Helpers/Wrapper";
import AddFabJournal from "Components/Journal/AddFabJournal";
import AppointmentNote from "Components/Journal/AppointmentNote";
import styles from "CSS/Screens/AppointmentNoteHistoryScreen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentNotes,
  resetAddAppointmentNoteSuccess,
  resetDeleteAppointmentNoteSuccess,
  selectAppointmentNotes,
} from "Reducers/AppointmentNoteReducer";
import undoAppointmentNote from "Utilities/undo-appointmentNote";

const AppointmentNoteHistoryScreen = () => {
  const dispatch = useDispatch();
  const [showUndo, setShowUndo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { past, loading, successAdd, successDelete, error } = useSelector(
    selectAppointmentNotes
  );

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new appointment note!");
      timer = setTimeout(() => {
        dispatch(resetAddAppointmentNoteSuccess());
      }, 2000);
    }
    return timer;
  };

  // displays undo button when appointment note is deleted
  const handleSuccessDelete = () => {
    if (successDelete) {
      // show undo popup after user deletes entry
      setShowUndo(true);
      setTimeout(() => {
        setShowUndo(false);
        dispatch(resetDeleteAppointmentNoteSuccess());
      }, 5000);
    }
  };

  useEffect(() => {
    // fetch all journal entries
    dispatch(getAppointmentNotes({ limit: 100000 })); //perhaps add limit parameter

    if (error) {
      setErrorMessage(error);
    }
  }, [dispatch, error]);

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();
    handleSuccessDelete();
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, successAdd, successDelete]);

  //function to create AppointmentNote object out of each entry in the past list
  const displayList = (list) =>
    list?.map((appointmentNote) => (
      <AppointmentNote
        key={appointmentNote._id}
        appointmentNote={appointmentNote}
      />
    ));

  return (
    <>
      <Wrapper>
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        <AlertMessage
          message={successMessage}
          setMessage={() => {
            setSuccessMessage("Added new appointment note!");
            dispatch(resetAddAppointmentNoteSuccess());
          }}
          type="success"
          toggle={successAdd}
        />

        {loading && past?.length === 0 && <LoadingSpinner />}
        <div
          className={`${styles.appointmentNote_header} ${styles.appointmentNote_completedHeader}`}
        >
          <h3>🧑‍⚕️ Recent Appointment Notes</h3>
        </div>

        {past?.length === 0 && (
          <p className={styles.appointmentNote_filler}>
            No past appointment notes!
          </p>
        )}
        {displayList(past)}
      </Wrapper>
      <UndoButton
        callback={() => {
          setShowUndo(false);
          dispatch(resetDeleteAppointmentNoteSuccess());
          undoAppointmentNote(dispatch);
        }}
        showUndo={showUndo}
      />
      <AddFabJournal page="2" />
    </>
  );
};

export default AppointmentNoteHistoryScreen;
