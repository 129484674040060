import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import styles from "CSS/Screens/BehavioralExperimentEntry.module.css";
import { useRedirect } from "Hooks/useRedirect";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectBehavioralExperiment } from "Reducers/BehavioralExperimentReducer";

const BehavioralCompleteRecord = () => {
    const redirectUrl = useRedirect("/toolkit/complete-experiment-view");

    // const dispatch = useDispatch();
    const {
        selectedExperiment,
        outcome,
        feelingAfter,
        newThought
    } = useSelector(selectBehavioralExperiment);



    return (
        <Screen>
            <Wrapper>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={`${styles.marginTop}`}>
                    <Grid xs={10} item>
                        <Link
                            to={redirectUrl}
                            className={`${styles.backBtn}`}>
                            Back
                        </Link>
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={`${styles.marginTop}`}>
                    <Grid
                        xs={10}
                        item
                        justifyContent="center"
                        alignItems="center"
                        className={`${styles.flexDisplay}`} >
                        <h3>Review Behavioral Experiment</h3>
                    </Grid>
                </Grid>
                <Grid
                    className={`${styles.marginTop}`}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Grid xs={10} item>
                        <Card
                            variant="outlined"
                            sx={{ minWidth: "100%" }}
                            className={styles.paper}>
                            <CardContent>
                                <h3>Thought being tested</h3>
                                <Grid container direction="row">
                                    <Grid
                                        xs={9}
                                        item
                                        style={{ display: "flex", fontSize: "0.9em" }}
                                        alignItems="center">
                                        <p>{selectedExperiment.thoughts}</p>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid
                    className={`${styles.marginTop}`}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Grid xs={10} item>
                        <Card
                            variant="outlined"
                            sx={{ minWidth: "100%" }}
                            className={styles.paper}>
                            <CardContent>
                                <h3>Experiment details</h3>
                                <Grid container direction="row">
                                    <Grid
                                        xs={9}
                                        item
                                        style={{ display: "flex", fontSize: "0.9em" }}
                                        alignItems="center">
                                        <p>{selectedExperiment.details}</p>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid
                    className={`${styles.marginTop}`}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Grid xs={10} item>
                        <h2>Prediction</h2>
                        <Card
                            variant="outlined"
                            sx={{ minWidth: "100%" }}
                            className={styles.paper}>
                            <CardContent>
                                <h3>Describe the outcome</h3>
                                <Grid container direction="row">
                                    <Grid
                                        xs={9}
                                        item
                                        style={{ display: "flex", fontSize: "0.9em" }}
                                        alignItems="center">
                                        <p>{outcome}</p>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid
                    className={`${styles.marginTop}`}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Grid xs={10} item>
                        <Card
                            variant="outlined"
                            sx={{ minWidth: "100%" }}
                            className={styles.paper}>
                            <h3>Feeling after experiment</h3>
                            <CardContent>
                                <Grid container direction="row">
                                    <Grid
                                        xs={9}
                                        item
                                        style={{ display: "flex", fontSize: "0.9em" }}
                                        alignItems="center">
                                        <p>{feelingAfter}</p>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid
                    className={`${styles.marginTop}`}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Grid xs={10} item>
                        <Card
                            variant="outlined"
                            sx={{ minWidth: "100%" }}
                            className={styles.paper}>
                            <h3>New thought based on your experience</h3>
                            <CardContent>
                                <Grid container direction="row">
                                    <Grid
                                        xs={9}
                                        item
                                        style={{ display: "flex", fontSize: "0.9em" }}
                                        alignItems="center">
                                        <p>{newThought}</p>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


            </Wrapper>
        </Screen>
    );
};

export default BehavioralCompleteRecord;

