import IndividualWidgetHabit from "Components/Habit/IndividualWidgetHabit";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import IndividualPathwayActivity from "Components/Pathway/IndividualPathwayActivity";
import IndividualPathwayNote from "Components/Pathway/IndividualPathwayNote";
import IndividualWidgetToDo from "Components/ToDo/IndividualWidgetToDo";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/Pathway/IndividualPathwayScreen.module.css";
import { useRedirect } from "Hooks/useRedirect";
import { useEffect, useState } from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setPage } from "Reducers/JournalReducer";
import {
  getPathway,
  getPathways,
  resetPathway,
  selectPathways,
  updatePathway,
} from "Reducers/PathwayReducer";

const IndividualPathwayScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const redirectUrl = useRedirect("/pathway");
  const { selectedPathway, pathways } =
    useSelector(selectPathways);

  const [title, setTitle] = useState("");
  const [yourHow, setYourHow] = useState("");
  const [desiredOutcome, setDesiredOutcome] = useState("");
  const [motivationalSubtitle, setMotivationalSubtitle] = useState("");
  const [notes, setNotes] = useState([]);
  const [habits, setHabits] = useState([]);
  const [activities, setActivities] = useState([]);
  const [todos, setToDos] = useState([]);

  useEffect(() => {
    dispatch(getPathway(id));
    // only get pathways if they aren't in state already
    if (pathways.active.length === 0) {
      dispatch(getPathways());
    }

    fillFields();

    return () => {
      dispatch(resetPathway);
    };
  }, [
    dispatch,
    selectedPathway?.title,
    selectedPathway?.habits?.length,
    selectedPathway?.todos?.length,
    selectedPathway?.notes?.length,
    selectedPathway?.activities?.length,
  ]);

  const fillFields = () => {
    if (selectedPathway) {
      // ensure users cannot access this page if the pathway is archived
      setTitle(selectedPathway.title);
      setDesiredOutcome(selectedPathway.desiredOutcome);
      setMotivationalSubtitle(selectedPathway?.motivationalSubtitle);
      setYourHow(selectedPathway?.yourHow);
      setNotes(selectedPathway?.notes);
      setHabits(selectedPathway?.habits);
      setToDos(selectedPathway?.todos);
      setActivities(selectedPathway?.activities);
    }
  };

  const renderCheckIn = () => (
    <div className={styles.body}>
      <h3>Next Check In</h3>
      <div className={styles.sectionBody}>
        {todos?.map((todo) => (
          <IndividualWidgetToDo
            key={todo._id}
            todo={todo}
            type="pathway"
          />
        ))}
        {todos?.length === 0 && (
          <Link
            to={`/add-pathway-checkin?redirect=${pathname}&title=${selectedPathway?.title}&plan=${selectedPathway?._id}`}
            className={styles.fillerBtn}
          >
            <span className={styles.fillerPlus}>+</span>
            Schedule a new check in
          </Link>
        )}
      </div>
    </div>
  );

  const renderHabits = () => (
    <div className={styles.body}>
      <h3>Habits</h3>
      <div className={styles.sectionBody}>
        {habits?.map((habit) => (
          <IndividualWidgetHabit key={habit._id} habit={habit} />
        ))}
        <Link
          to={`/add-habit?redirect=${pathname}&plan=${id}`}
          className={styles.fillerBtn}
        >
          <span className={styles.fillerPlus}>+</span>
          Add new habit
        </Link>
      </div>
    </div>
  );

  const renderNotes = () => (
    <div className={styles.body}>
      <h3>Recent Notes</h3>
      <div className={styles.sectionBody}>
        {notes?.map((note) => (
          <IndividualPathwayNote key={note._id} pathwayNote={note} />
        ))}
        <Link
          to={`/new-journal-entry?redirect=${pathname}&plan=${title}`}
          onClick={() => dispatch(setPage("3"))}
          className={styles.fillerBtn}
        >
          <span className={styles.fillerPlus}>+</span>
          New Note
        </Link>
      </div>
    </div>
  );

  const renderActivities = () => (
    <div className={styles.body}>
      <h3>Activities</h3>
      <div className={styles.sectionBody}>
        {activities?.map((activity) => (
          <IndividualPathwayActivity
            key={activity._id}
            activity={activity}
          />
        ))}
        <Link
          to={`/new-activity-entry?redirect=${pathname}&plan=${id}`}
          className={styles.fillerBtn}
        >
          <span className={styles.fillerPlus}>+</span>
          Add new activity
        </Link>
      </div>
    </div>
  );

  return (
    <Screen>
      <Wrapper>
        <Link
          to={redirectUrl}
          className={btn.backBtn}
        >
          <HiArrowNarrowLeft className={btn.toolkit_btnArrow} />
          <p>Back</p>
        </Link>
        <div className={styles.individualPathway}>
          <div className={styles.header}>
            <h1>{title}</h1>
            <h3>{motivationalSubtitle}</h3>
          </div>
          <div className={styles.body}>
            <p>
              Desired Outcome: <span>{desiredOutcome}</span>
            </p>
            <p>
              {yourHow && (
                <>
                  Your Why: <span>{yourHow}</span>
                </>
              )}
            </p>
          </div>
          {!selectedPathway?.isArchived && renderCheckIn()}
          {!selectedPathway?.isArchived && renderHabits()}
          {!selectedPathway?.isArchived && renderNotes()}
          {!selectedPathway?.isArchived && renderActivities()}

          <div className={btn.groupRow}>
            {!selectedPathway?.isArchived ? (
              <>
                <button
                  className={`${btn.btn} ${btn.secondary}`}
                  onClick={() => {
                    dispatch(
                      updatePathway({
                        ...selectedPathway,
                        isArchived: true,
                      })
                    );
                    navigate("/pathway");
                  }}
                >
                  Archive
                </button>
                <button
                  className={`${btn.btn} ${btn.primary}`}
                  onClick={() =>
                    navigate(
                      `/update-pathway/${selectedPathway._id}?redirect=${pathname}`
                    )
                  }
                >
                  Edit
                </button>
              </>
            ) : (
              <button
                className={`${btn.faded_blue} ${styles.btn}`}
                onClick={() => {
                  dispatch(
                    updatePathway({
                      ...selectedPathway,
                      isArchived: false,
                    })
                  );
                  navigate("/pathway");
                }}
              >
                Unarchive
              </button>
            )}
          </div>
        </div>
      </Wrapper>
    </Screen>
  );
};
export default IndividualPathwayScreen;
