import { useNavigate } from "react-router-dom";
import btn from "../../CSS/Button.module.css";
import styles from "../../CSS/Screens/HealthRecordTutorialScreen.module.css";
import Screen from "../../Components/Helpers/Screen";
import Wrapper from "../../Components/Helpers/Wrapper";

const HealthRecordTutorialScreen = () => {
  const navigate = useNavigate();

  return (
    <Screen>
      <Wrapper>
        <p className={`${styles.onboardingParagraph} ${styles.firstParagraph}`}>
          My Health Record is your <br /> go-to place to look up <br />
          information.
        </p>
        <p className={styles.onboardingParagraph}>
          Your current and past <br /> treatments, conditions, and <br />
          medications, health care <br /> providers, and more all in <br /> one
          convenient spot.
        </p>
        <div className={styles.btnContainer}>
          <button
            className={`${btn.btn} ${btn.blue_btn} ${styles.nextBtn}`}
            onClick={() => navigate("/health-record-provider")}
            type="button"
          >
            Set up My Health Record
          </button>
          <button
            className={`${btn.btn} ${btn.orange_btn} ${styles.skipBtn}`}
            onClick={() => navigate("/pathway-tutorial")}
            type="button"
          >
            I&apos;ll do that later
          </button>
        </div>
      </Wrapper>
    </Screen>
  );
};

export default HealthRecordTutorialScreen;
