import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import JournalInsightEntry from "Components/Journal/JournalInsightEntry";
import { EmotionWeight } from "Constants/MonthlyInsightScreen.constant";
import styles from "CSS/Screens/MonthlyInsightScreen.module.css";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getJournalEntriesForDate } from "Reducers/Actions/JournalActions";
import { selectJournalEntries } from "Reducers/JournalReducer";

const MonthlyInsightScreen = () => {
  const dispatch = useDispatch();

  // Use the useState hook to manage local state for error message
  const [errorMessage, setErrorMessage] = useState("");

  // Use the useState hook to manage local state for emotional weight map
  const [emotionalWeightMap, setEmotionalWeightMap] = useState(new Map());

  const { monthlyEntries, loading, error } = useSelector(selectJournalEntries);

  useEffect(() => {
    // fetch all journal entries
    const { year, month } = getTodayData();
    dispatch(getJournalEntriesForDate(`${year}-${month}-01`));

    if (error) {
      setErrorMessage(error);
    }
  }, []);

  useEffect(() => {
    if (monthlyEntries.length === 0) {
      setEmotionalWeightMap(new Map());
    } else {
      buildEmotionWeightMap();
    }
  }, [monthlyEntries]);

  const EMOTION_WEIGHT_STYLE_MAP = {
    6: `${styles.happy} ${styles.emotion_icon}`,
    5: `${styles.energized} ${styles.emotion_icon}`,
    4: `${styles.medium} ${styles.emotion_icon}`,
    3: `${styles.stressed} ${styles.emotion_icon}`,
    2: `${styles.sad} ${styles.emotion_icon}`,
    1: `${styles.pain} ${styles.emotion_icon}`,
    undefined: `${styles.emotion_icon}`,
  };

  /**
   * Builds a map of the emotional weight for each journal entry.
   * The map contains the date and time of the entry, as well as the average weight of the selected emotions.
   */
  const buildEmotionWeightMap = () => {
    const emotionWeightMap = new Map();

    // Check if monthlyEntries is an array and if it has at least one element
    if (Array.isArray(monthlyEntries) && monthlyEntries.length > 0) {
      monthlyEntries.forEach(entry => {
        let totalEmotionWeight = 0;
        let selectedEmotionCount = 0;

        // Loop through each emotion of the current entry
        entry.emotions.forEach(emotion => {
          if (emotion.selected) {
            selectedEmotionCount++;
            totalEmotionWeight += EmotionWeight[emotion.label.toUpperCase()];
          }
        });

        // Calculate average weight of selected emotions
        const averageEmotionWeight = Math.round(totalEmotionWeight / selectedEmotionCount);

        // Format the date and time of the entry
        const formattedDate = moment(entry.date).format("YYYY-M-D");
        const formattedTime = moment(entry.date).local().format("hh:mm A").replace(/(^|-)0+/g, "");

        // Add the entry data to the map
        emotionWeightMap.set(formattedDate, { time: formattedTime, weight: averageEmotionWeight });
      });
    }

    setEmotionalWeightMap(emotionWeightMap);
  };

  const getTodayData = () => {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const date = d.getDate();
    return { year, month, date };
  };

  /* eslint-disable no-unused-vars */
  const [today, setToday] = useState(getTodayData());
  const [year, setYear] = useState(today.year);
  const [month, setMonth] = useState(today.month);

  const todayStr = useMemo(() => {
    return Object.values(today).join("");
  }, [today]);

  const setYearMonth = (data) => {
    setYear(data.year);
    setMonth(data.month);
  };

  const goPrev = () => {
    const updatedDate = getPrevYearMonth({ year, month });
    dispatch(getJournalEntriesForDate(
      `${updatedDate.year}-${updatedDate.month}-01`));
    setYearMonth(updatedDate);
  };

  const goNext = () => {
    const updatedDate = getNextYearMonth({ year, month });
    dispatch(getJournalEntriesForDate(
      `${updatedDate.year}-${updatedDate.month}-01`));
    setYearMonth(updatedDate);
  };

  const goToday = () => {
    setYearMonth(today);
  };

  // UTILITIES
  // get all dates of that month
  const getMonthDetail = ({ year, month }) => {
    const lastDate = new Date(year, month, 0).getDate();
    return Array(lastDate).fill().map((_, key) => key + 1);
  };

  const getDateDay = (d) => {
    return d.getDay();
  };

  const getPrevYearMonth = ({ year, month }) => {
    const data = { year, month };
    if (month === 1) {
      data.year -= 1;
      data.month = 12;
    } else data.month -= 1;
    return data;
  };

  const getNextYearMonth = ({ year, month }) => {
    const data = { year, month };
    if (month === 12) {
      data.year += 1;
      data.month = 1;
    } else data.month += 1;
    return data;
  };

  const week = ["S", "M", "T", "W", "T", "F", "S"];

  // COMPONENTS
  const ActiveMonth = ({ year, month, todayStr }) => {
    const currMonth = getMonthDetail({ year, month });
    const prevMonth = getMonthDetail(getPrevYearMonth({ year, month }));
    const nextMonth = getMonthDetail(getNextYearMonth({ year, month }));

    const currMonthLastDate = currMonth[currMonth.length - 1];
    const startDay = getDateDay(new Date(year, month - 1, 1));
    const endDay = getDateDay(new Date(year, month - 1, currMonthLastDate));

    const prevMonthAddOn = prevMonth.slice(prevMonth.length - startDay);
    const nextMonthAddOn = nextMonth.slice(0, 6 - endDay);
    const visibleMonth =
      prevMonthAddOn.map(date => ({ date, disabled: true }))
        .concat(currMonth.map(date => ({ date, disabled: false })))
        .concat(nextMonthAddOn.map(date => ({ date, disabled: true })));

    return (
      <div className={styles.dates_wrapper} key={`${year}${month}`}>
        {week.map(el => <div key={el + Math.random()} className={styles.week}>{el}</div>)}
        {visibleMonth.map((el) => (
          <div className={[
            styles.date,
            el.disabled ? " " + styles.disabled : "",
            todayStr === `${year}${month}${el.date}` ? " " + styles.today : ""
          ].join(" ")} key={el.date + Math.random()}>
            {
              <div className={
                EMOTION_WEIGHT_STYLE_MAP[
                emotionalWeightMap.get(`${year}-${month}-${el.date}`)?.weight
                ]}></div>
            } {el.date}
          </div>
        ))}
      </div>
    );
  };

  const MonthWrapper = ({ goPrev, goNext, goToday, year, month, todayStr }) => {
    return (
      <div className={styles.month_wrapper}>
        <div className={styles.header}>
          <span className="btn arrow" onClick={goPrev}>&lt;</span>
          <span className={styles.current} onClick={goToday}>{year} . {month}</span>
          <span className="btn arrow" onClick={goNext}>&gt;</span>
        </div>
        <ActiveMonth year={year} month={month} todayStr={todayStr} />
      </div>
    );
  };

  return (
    <>
      <Screen>
        <Wrapper>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <h1>Monthly Insights</h1><br></br>
          {loading && <LoadingSpinner />}
          {emotionalWeightMap && (<MonthWrapper
            goPrev={goPrev}
            goNext={goNext}
            goToday={goToday}
            year={year}
            month={month}
            todayStr={todayStr}
          />)}
          <div>
            {loading && <LoadingSpinner />}
            {emotionalWeightMap && (
              Array.from(emotionalWeightMap.entries()).map((entry) => {
                const [key, value] = entry;
                if (value.weight)
                  return (<JournalInsightEntry key={key} date={key} value={value} />);
              })
            )}
            {
              emotionalWeightMap.size === 0 && (
                <Link to={"/new-journal-entry"}>
                  <Card sx={{
                    marginTop: "1em",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}>
                    <CardContent>
                      <Box sx={{ flexGrow: 0 }}>
                        No journal entries for this month.<br></br>
                        Try adding a new one.
                      </Box>
                    </CardContent>
                  </Card>
                </Link>
              )
            }
          </div>
        </Wrapper>
      </Screen>
    </>
  );
};

export default MonthlyInsightScreen;
