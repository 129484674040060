import { Box, IconButton, Modal, Typography } from "@mui/material";
import { IoClose } from "react-icons/io5";

const ModalBox = ({ open, onClose, children, title }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: 300,
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          borderRadius: 4,
          boxShadow: 24,
          padding: 2,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 12,
            right: 8,
            color: "gray",
          }}
        >
          <IoClose />
        </IconButton>
        {title && (<Typography variant="h6" sx={{ mr: "32px" }}>
          {title}
        </Typography>
        )}
        <Box sx={{ mt: 2 }}>{children}</Box>
      </Box>
    </Modal>
  );
};

export default ModalBox;
