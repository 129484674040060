// Defines the weight of each emotion
export const EmotionWeight = {
  HAPPY: 6,
  SAD: 5,
  STRESSED: 4,
  PAIN: 3,
  ENERGIZED: 2,
  TIRED: 1,
};

// Maps the weight of each emotion to its corresponding title
export const EMOTION_WEIGHT_TITLE_MAP = {
  6: "Happy",
  5: "Energized",
  4: "Medium",
  3: "Stressed",
  2: "Sad",
  1: "Pain",
};

// Maps the weight of each emotion to its corresponding color
export const EMOTION_WEIGHT_COLOR_MAP = {
  6: "#4caf50",
  5: "#4dd0e1",
  4: "#29b6f6",
  3: "#5c6bc0",
  2: "#ec407a",
  1: "#ef5350",
};