import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styles from "CSS/Screens/CognitiveDistortionsScreen.module.css";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { useRedirect } from "Hooks/useRedirect";
import { selectCognitiveDistortion } from "Reducers/CognitiveDistortionReducer";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const CognitiveDistortionsDetailScreen = () => {
  const redirectUrl = useRedirect("/toolkit/distortions");
  const navigate = useNavigate();
  const {
    selectedDistortion
  } = useSelector(selectCognitiveDistortion);

  // If there is currently no selected distortion -> redirect back.
  useEffect(() => {
    if (selectedDistortion === null)
      navigate("/toolkit/distortions");
  }, [selectedDistortion]);

  return (
    <Screen>
      <Wrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid xs={10}>
            <Link
              to={redirectUrl}
              className={`${styles.backBtn}`}>
              Back
            </Link>
          </Grid>
        </Grid>
        <Grid container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid xs={10}>
            <Card sx={{ maxWidth: 345 }} variant="outlined">
              {/* TODO: Need to update the image */}
              <CardMedia
                sx={{ height: 140 }}
                image="/static/images/cards/contemplative-reptile.jpg"
                title={selectedDistortion.title}
              />
              <CardContent style={{ textAlign: "center" }}>
                <Typography gutterBottom variant="h5" component="div">
                  {selectedDistortion.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {selectedDistortion.info}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Wrapper>
    </Screen>
  );
};

export default CognitiveDistortionsDetailScreen;
