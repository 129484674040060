import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import Wrapper from "Components/Helpers/Wrapper";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/Pathway/AddPathwayNoteScreen.module.css";
import { default as useEmotionSelector } from "Hooks/useEmotionSelector";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  addPathwayNote,
  resetPathwayNote,
} from "Reducers/PathwayNoteReducer";
import {
  getPathway,
  getPathways,
  selectPathways,
} from "Reducers/PathwayReducer";

const AddPathwayNoteScreen = () => {
  const { pathways, loading, error } = useSelector(selectPathways);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrl = useRedirect("/journal");
  const { emotions, renderModal, renderSelector } = useEmotionSelector();

  const [textReview, setTextReview] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [plan, setPlan] = useState({ title: "", _id: "", desiredOutcome: "" });
  const [dateTime, setDateTime] = useState(moment().format("YYYY-MM-DDTHH:mm"));

  // autofill pathway if directed from individual pathway
  const setPathway = () => {
    let title = searchParams.get("plan");

    if (title) {
      title = title.split("%20").join(" ");
      handleChange(title);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      addPathwayNote({
        date: moment(dateTime).utc(),
        emotions,
        textReview,
        pathway: plan._id,
      })
    );

    // updates individual pathway on screen
    dispatch(getPathway(plan._id));

    // Success to reset state and go back to journals
    if (!loading && !error) {
      dispatch(resetPathwayNote);
      navigate(redirectUrl);
    }
  };

  const handleChange = (title) => {
    const plan = {};
    const idx = pathways.active.findIndex(
      (pathway) => pathway.title === title
    );

    if (idx !== -1) {
      plan.title = pathways.active[idx].title;
      plan._id = pathways.active[idx]._id;
      plan.desiredOutcome = pathways.active[idx].desiredOutcome;
    }

    setPlan(plan);
  };

  useEffect(() => {
    dispatch(getPathways());

    if (pathways.active.length > 0) {
      setPathway();
    }
  }, [dispatch, pathways.active.length]);

  if (pathways.active.length === 0) {
    return (
      <ScreenNoNav>
        <Wrapper>
          <div className={styles.header}>
            <Link
              to={redirectUrl}
              onClick={() => dispatch(resetPathwayNote)}
              className={`${btn.links} ${btn.secondaryLink}`}
              type="button"
            >
              Close
            </Link>
            <h3>New Pathway Note</h3>
            <button
              type="submit"
              className={`${btn.links} ${btn.primaryLink}`}
            >
              Add
            </button>
          </div>
          <div className={styles.noPlan}>
            <h2>You have no pathways</h2>
            <Link to="/new-pathway?page=1" className={styles.noPlan_btn}>
              {" "}
              <span className={styles.noPlan_plus}>+</span> Create Pathway
            </Link>
          </div>
        </Wrapper>
      </ScreenNoNav>
    );
  }

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={handleSubmit}>
        {renderModal()}

        <div className={styles.header}>
          <Link
            to={redirectUrl}
            onClick={() => dispatch(resetPathwayNote)}
            className={`${btn.links} ${btn.secondaryLink}`}
            type="button"
          >
            Close
          </Link>
          <h3>New Pathway Note</h3>
          <button
            type="submit"
            className={`${btn.links} ${btn.primaryLink}`}
          >
            Add
          </button>
        </div>
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />

        <div className={`${styles.body} ${isMobile ? styles.bodyMobile : ""}`}>
          <TextField
            className={styles.datetextarea}
            type="datetime-local"
            name="day"
            required={true}
            value={dateTime}
            onChange={(e) => setDateTime(e.target.value)}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
          />

          <FormControl
            sx={{ minWidth: "70%", marginTop: "10px" }}
            size="small"
            required
          >
            <InputLabel>Select Pathway</InputLabel>
            <Select
              label="Select Pathway"
              value={plan.title}
              onChange={(e) => handleChange(e.target.value)}
            >
              {pathways?.active.map((pathway) => (
                <MenuItem key={pathway._id} value={pathway.title}>
                  {pathway.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <p className={styles.p}>
            Your desired outcome for this pathway is:
            <br />
            ⭐️{" "}
            <span>
              {plan.desiredOutcome
                ? plan.desiredOutcome
                : "please select a pathway"}
            </span>
          </p>

          <TextField
            className={styles.textarea}
            label="Record notes"
            multiline
            rows={5}
            placeholder="Enter notes"
            name="notes"
            value={textReview}
            onChange={(e) => setTextReview(e.target.value)}
            required={false}
            variant="outlined"
            inputProps={{
              maxLength: 700,
            }}
            size="small"
          />

          {renderSelector()}
        </div>

        <button
          className={`${btn.btn} ${btn.primary} ${styles.submit}`}
          type="submit"
        >
          {loading ? "Saving..." : "Save"}
        </button>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default AddPathwayNoteScreen;
