import { Grid, TextField } from "@mui/material";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AuthenticationScreen.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import Wrapper from "Components/Helpers/Wrapper";
import { URLS } from "Constants/Constants";
import { generateResetCode } from "Reducers/Actions/ResetPasswordActions";
import { selectResetPassword } from "Reducers/ResetPasswordReducer";
import { validateEmail } from "Utilities/validate";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../Components/Navigation/BackBtn";

const ForgetPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { loading, isResetPasswordFullfilled, error } = useSelector(selectResetPassword);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSendResetEmail = () => {
    if (email === "" || email === null || !validateEmail(email)) {
      // If entered email is not valid.
      setErrorMessage("Enter a valid email address");
    } else {
      setErrorMessage("");
      dispatch(generateResetCode(email));
    }
  };

  useEffect(() => {
    if (isResetPasswordFullfilled) {
      navigate(URLS.FORGET_PASSWORD_CODE_ENTRY);
    }
  }, [isResetPasswordFullfilled]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  return (
    <ScreenNoNav>
      <Wrapper>
        <Grid container justifyContent="left" item xs={12} sm={12} md={6} rowSpacing={6} marginTop={2}>
          <BackBtn className="forgot_password_back_btn" title="Back" link="/login" />
        </Grid>
        <Grid container justifyContent="center" item xs={12} sm={12} md={6} rowSpacing={6} marginTop={2}>
          <Grid container justifyContent="center" item xs={12} sm={12} md={12}>
            <img
              src="/images/forgot_password/forgot_password1.png"
              alt="3 fish swimming in a circle"
              height="200"
            />
          </Grid>

          <Grid item xs={10} sm={10} md={10} textAlign="center">
            Please enter the email associated with your account and we will send you an email with the code to reset your password.
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <AlertMessage
              message={errorMessage}
              setMessage={() => setErrorMessage("")}
              type="error"
              toggle={errorMessage}
              styles={{ marginBottom: "2em", width: "auto" }}
            />
            <TextField
              className={styles.auth_field}
              label="Enter your email"
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="standard"
              inputProps={{
                maxLength: 128,
              }}
            />
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <button
              className={`${styles.auth_btn} ${btn.btn} ${btn.orange_btn}`}
              type="submit"
              onClick={() => handleSendResetEmail()}
            >
              {loading ? "Loading..." : "Send Reset Email"}
            </button>
          </Grid>
        </Grid>
      </Wrapper>
    </ScreenNoNav>
  );
};

export default ForgetPasswordScreen;
