import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ImageListItem,
  TextField,
  Typography
} from "@mui/material";
import styles from "CSS/Components/CommunityPost.module.css";
import { COMMUNITY_POST_REACTION, USER_PROFILE_PICTURE_S3_URL } from "Constants/Constants";
import { addCommentToPost, reactToPost } from "Reducers/Actions/CommunityActions";
import { setSelectedPost } from "Reducers/CommunityReducer";
import { selectUser } from "Reducers/UserReducer";
import { convertScale } from "Utilities/adjust-scale";
import moment from "moment";
import { memo, useState } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CommunityPost = memo(function temp({ post }) {
  // State for managing the expanded state
  const [expanded, setExpanded] = useState(false);

  // Dispatch function from Redux
  const dispatch = useDispatch();

  // State for managing the comment input
  const [comment, setComment] = useState("");

  // Navigation function from React Router
  const navigate = useNavigate();

  // Getting current user data.
  const { user } = useSelector(selectUser);

  // Function to handle expanding/collapsing the component
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // Function to handle reacting to a post
  const handlePostReaction = (reaction) => {
    dispatch(reactToPost({ postId: post._id, reaction }));
  };

  // Function to handle adding a comment to a post
  const handleAddComment = () => {
    dispatch(addCommentToPost({ postId: post._id, comment: comment }));
    setComment("");
  };

  // Function to handle clicking on a team
  const handleTeamClick = (teamId) => {
    navigate(`/team/${teamId}`);
  };

  // Function to handle selecting an image
  const handleImageSelection = (entry) => {
    dispatch(setSelectedPost(entry));
  };

  return (
    <>
      <Card variant="outlined" style={{ borderRadius: "15px" }}>
        <Grid container>
          <Grid item xs={post.postType === "default" ? 9 : 12}>
            <CardHeader
              avatar={
                <Avatar
                  imgProps={{
                    crossOrigin: "anonymous"
                  }}
                  src={`${post.user.profilePicture != null ? (USER_PROFILE_PICTURE_S3_URL + post.user.profilePicture) : ""}`}
                  aria-label={`profile picture of ${post.user.firstName}`} />
              }
              title={`${post.user.firstName} ${post.user.lastName !== null && post.user.lastName !== undefined && post.user.lastName !== "undefined" && post.user.lastName !== "null" ? post.user.lastName : ""}`}
              subheader={
                post.team ? (
                  <span>
                    <span className={styles.teamLink} onClick={() => handleTeamClick(post.team._id)}>
                      {post.team.name}
                    </span>
                    {` ${moment(post.createdAt).format("MMM Do")}`}
                  </span>
                ) : (
                  moment(post.createdAt).calendar()
                )
              }
            />
          </Grid>
          {
            post.postType === "default" && (
              <Grid
                item
                container
                xs={3}
                justifyContent="center"
                alignItems="center">
                <div className={styles.progress}>
                  <CircularProgressbarWithChildren
                    className={styles.progressBar}
                    value={convertScale(post.emotionRating, 10)}
                    strokeWidth={12}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: "round",
                      pathTransitionDuration: 0.3,
                      pathColor: `rgba(11, 126, 161, ${convertScale(
                        post.emotionRating,
                        10
                      )})`,
                      trailColor: "#d6d6d6",
                      backgroundColor: "#0b7ea1",
                    })}>
                    <p className={styles.progressText}>{post.emotionRating}</p>
                  </CircularProgressbarWithChildren>
                </div>
              </Grid>)
          }
        </Grid>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {post.content}
          </Typography>
          <div style={{ marginTop: "0.5em" }}>
            <Grid container spacing={1}>
              {post.images?.map((image, index) => (
                <Grid item={true} xs={post.images.length === 1 ? 12 : 5} key={image.fileName} className={styles.journalImageContainer}>
                  <ImageListItem key={index} onClick={() => handleImageSelection(post)}>
                    <img
                      crossOrigin="anonymous"
                      className={styles.journalImage}
                      src={`${USER_PROFILE_PICTURE_S3_URL}posts/${image.fileName}`}
                      loading="lazy"
                      alt="journal entry image"
                    />
                  </ImageListItem>
                </Grid>
              ))}
            </Grid>
          </div>
          <div style={{ marginTop: "1em" }}>
            {
              post.emotions?.filter((emotion) => emotion.selected === true)
                .map((emotion) => (
                  <Chip style={{ marginRight: "10px" }} key={emotion.label} label={emotion.label} />
                ))
            }
          </div>
        </CardContent>
        {
          post.reachOut && post.user._id !== user._id && (
            <Grid
              className={`${styles.reachOutContainer}`}
              container
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="body2" color="text.secondary">
                I would appreciate a chat!
              </Typography>
              <IconButton onClick={() => navigate(`/chat/${post.user._id}`)}>
                <ChatBubbleIcon />
              </IconButton>
            </Grid>
          )
        }
        <CardActions sx={{ padding: "0px" }}>
          <ButtonGroup fullWidth color="secondary" aria-label="medium secondary button group">
            <Button
              className={`
                ${styles.reactionBtn} 
                ${post.reactions.HEART ? styles.activeReaction : styles.inActiveReaction}`}
              onClick={() => handlePostReaction(COMMUNITY_POST_REACTION.HEART)} key={COMMUNITY_POST_REACTION.HEART}>
              ❤️
              <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                {post.reactions.HEART ? post.reactions.HEART : 0}
              </span>
            </Button>
            <Button
              className={`
                ${styles.reactionBtn} 
                ${post.reactions.CELEBRATE ? styles.activeReaction : styles.inActiveReaction}`}
              onClick={() => handlePostReaction(COMMUNITY_POST_REACTION.CELEBRATE)}
              key={COMMUNITY_POST_REACTION.CELEBRATE}>
              🎉
              <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                {post.reactions.CELEBRATE ? post.reactions.CELEBRATE : 0}
              </span>
            </Button>
            <Button
              className={`
                ${styles.reactionBtn} 
                ${post.reactions.CLAP ? styles.activeReaction : styles.inActiveReaction}`}
              onClick={() => handlePostReaction(COMMUNITY_POST_REACTION.CLAP)}
              key={COMMUNITY_POST_REACTION.CLAP} >
              👏 <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                {post.reactions.CLAP ? post.reactions.CLAP : 0}
              </span>
            </Button>
            <Button
              className={`${styles.reactionBtn} ${styles.inActiveReaction}`}
              key="comment"
              onClick={handleExpandClick}>
              💬
              <span style={{ fontSize: "12px", marginLeft: "5px" }}>{post.comments.length}
              </span>
            </Button>
          </ButtonGroup>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="h6">Comments</Typography>
            <Grid container>
              <Grid xs={12} item={true}>
                <TextField
                  size="small"
                  id="outlined-multiline-flexible"
                  label="Write a comment"
                  multiline
                  fullWidth
                  maxRows={4}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Grid>
              <Divider variant="middle" />
              <Grid xs={12} item={true} style={{ textAlign: "end", marginTop: "0.5em" }}>
                <Button onClick={handleAddComment} variant="outlined" size="small">
                  Comment
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center">
              {
                post.comments.map(comment => (
                  <Grid item={true} key={comment._id}>
                    <Card
                      variant="outlined"
                      style={{ borderRadius: "15px", border: "none" }}>
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{ width: 34, height: 34 }}
                            aria-label={`profile picture of ${comment.user.firstName}`} />
                        }
                        title={`${comment.user.firstName} ${comment.user.lastName !== null && comment.user.lastName !== "null" ? comment.user.lastName : ""}`}
                        subheader={moment(comment.createdAt).calendar()}
                      />
                      <CardContent style={{
                        paddingLeft: "12px",
                        paddingRight: "0px",
                        paddingTop: "0px",
                        paddingBottom: "0px"
                      }}>
                        <Typography variant="body2" color="text.secondary">
                          {comment.comment}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              }
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
});

export default CommunityPost;
