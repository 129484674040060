const handleFrequencies = (index, frequencies) => {
  // copy over frequency because you cannot mutate redux state directly
  const tempFrequencies = [];
  frequencies.forEach((frequency) =>
    tempFrequencies.push({
      label: frequency.label,
      selected: frequency.selected,
    })
  );

  tempFrequencies[index].selected = !tempFrequencies[index].selected;
  const updatedFrequencies = [...tempFrequencies];
  return updatedFrequencies;
};

export { handleFrequencies };
