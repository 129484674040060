import { Divider, Grid, Typography } from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

const ExposureStep = memo(function ExposureStep({ exposureStep, index }) {

  const navigate = useNavigate();

  return (
    <Grid container
      sx={{
        // backgroundColor: exposureStep.fearLevel === 11 ? "#fff" : "#fff",
        backgroundColor: exposureStep.status === "completed" ? "var(--green-50)" : "#fff",
        borderRadius: 4,
        p: 0.5,
        alignItems: "center",
        border: exposureStep.fearLevel === 11 ? "3px solid var(--blue-500)" : "solid 2px var(--grey-500)"
      }}
      onClick={() => navigate(`/toolkit/fear-ladder/${exposureStep.fearLadder}/step/${exposureStep._id}`)}
    >
      <Grid item xs sx={{ ml: 1 }}>
        <Typography variant="body1" marginTop={"0.25rem"}>
          {index}. {exposureStep.title}
        </Typography>
        <Typography variant="body1" color="var(--blue-500)">
          {(exposureStep.status === "created") ?
            "Plan Exposure"
            : ((exposureStep.status === "planned") ?
              "Scheduled"
              :
              "Completed")}
        </Typography>
      </Grid>
      {(exposureStep.fearLevel !== 11) &&
        <>
          <Divider size={2} orientation="vertical" flexItem sx={{ m: 1, backgroundColor: "var(--grey-200)" }} />
          <Grid item sx={{
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "55px", height: "60px",
          }}>
            <div style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "var(--tangerine-100)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // border: "2px solid var(--blue-500)"
            }}>
              <Typography variant="body2" sx={{ fontSize: "14px", color: "var(--blue-900)" }}>
                {exposureStep.fearLevel}/10
              </Typography>
            </div>
            <Typography variant="body2" sx={{ fontSize: 10, color: "var(--blue-900)" }}>
              Fear Level
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
});

export default ExposureStep;
