import { Fab } from "@mui/material";
import styles from "CSS/Components/AddFab.module.css";
import { isMobile } from "react-device-detect";
import { MdAdd } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setPage } from "Reducers/JournalReducer";

const AddFabJournal = ({ page }) => {
  const dispatch = useDispatch();

  return (
    <div
      className={`${styles.addFab} ${isMobile ? styles.addFab_mobile : ""}`}
      onClick={() => {
        dispatch(setPage(page));
      }}
    >
      <Link className={styles.addFab_plus} to={"/new-journal-entry"}>
        <Fab color="primary" aria-label="add">
          <MdAdd size={32} color="white" />
        </Fab>
      </Link>
    </div>
  );
};

export default AddFabJournal;
