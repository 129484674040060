import GroupAddIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid, IconButton, InputBase, Paper, Typography } from "@mui/material";
import styles from "CSS/Screens/CommunityScreen.module.css";
import { COMMUNITY_TEAM_ICON_VALUE_TO_ICON_MAP, URLS } from "Constants/Constants";
import { addMembersToTeam, getTeams, searchTeams } from "Reducers/Actions/TeamActions";
import { selectTeam } from "Reducers/TeamReducer";
import { selectUser } from "Reducers/UserReducer";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CommunityTeams = memo(function temp({ teams }) {

  // Navigation function from React Router
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // Selecting the team search result and user from Redux state
  const { teamSearchResult } = useSelector(selectTeam);
  const { user } = useSelector(selectUser);

  // State for managing the community search text
  const [communitySearchText, setCommunitySearchText] = useState("");

  // Function to handle clicking on a team
  const handleTeamClick = (id) => {
    navigate(`${URLS.TEAM}/${id}`);
  };

  // Function to handle input change in the search field
  const handleSearchInputChange = (e) => {
    setCommunitySearchText(e.target.value);
    dispatch(searchTeams(e.target.value));
  };

  // Function to handle joining a team
  const handleTeamJoin = (teamId) => {
    dispatch(
      addMembersToTeam({ id: teamId, members: [user._id] })
    );
  };

  const handleReload = () => {
    dispatch(getTeams());
  };

  return (
    <>
      <Grid
        sx={{ mt: 3, mb: 2 }}
        justifyContent="center"
        alignItems="center"
        container
        xs={10}>
        <Grid item={true} md={8} lg={8} xs={12}>
          <Paper className={styles.searchBar}>
            <InputBase
              sx={{ ml: 2 }}
              style={{ width: "75%" }}
              placeholder="Search communities"
              inputProps={{ "aria-label": "search communities" }}
              onChange={handleSearchInputChange}
              value={communitySearchText}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        xs={10}
        justifyContent="center">
        {
          communitySearchText.length === 0 && teams.length === 0 && (
            <Grid item={true} xs={12}>
              <Paper
                elevation={2}
                className={`${styles.emptyCommunityCard}`}>
                <Grid>
                  <Grid container justifyContent="center">
                    <img
                      className={styles.emptyCommunityImage}
                      src="/images/community/not-found.png" />
                  </Grid>
                  <Grid container justifyContent="center">
                    No communities to show. Try adding one.
                  </Grid>
                  <Grid sx={{ mt: 2 }} container justifyContent="center">
                    <Button onClick={handleReload}>Reload </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )
        }
      </Grid>
      <Grid
        container
        justifyContent="center"
        spacing={3}>
        {
          communitySearchText.length === 0 && teams.map((team) => (
            <Grid key={team._id} item={true} xs={10}>
              <Paper
                onClick={() => handleTeamClick(team._id)}
                elevation={2}
                className={`${styles.communityCard}`}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <Grid item={true} xs={3}>
                    <span style={{ fontSize: "3em" }}>
                      {COMMUNITY_TEAM_ICON_VALUE_TO_ICON_MAP[team.icon]}
                    </span>
                  </Grid>
                  <Grid item={true} xs={8}>
                    <h3>{team.name}</h3>
                    <Typography
                      className={`${styles.memberTypo}`}
                      variant="body2">{team.members.length} Members</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
      <Grid
        container
        style={{ marginTop: "1em" }}
        justifyContent="center"
        spacing={1}>
        {
          communitySearchText.length > 0 && teamSearchResult.length > 0 &&
          (
            teamSearchResult.map((team) => (
              <Grid key={team._id} item={true} xs={10}>
                <Paper
                  onClick={() => handleTeamClick(team._id)}
                  elevation={2}
                  className={`${styles.communityCard}`}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Grid item={true} xs={3}>
                      <span style={{ fontSize: "3em" }}>
                        {COMMUNITY_TEAM_ICON_VALUE_TO_ICON_MAP[team.icon]}
                      </span>
                    </Grid>
                    <Grid item={true} xs={7}>
                      <h3>{team.name}</h3>
                      <Typography
                        className={`${styles.memberTypo}`}
                        variant="body2">{team.members.length} Members</Typography>
                    </Grid>
                    <Grid item={true} xs={2}>
                      <GroupAddIcon onClick={() => handleTeamJoin(team._id)} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
          )
        }

        {
          communitySearchText.length > 0 && teamSearchResult.length === 0 && (
            <Grid item={true} xs={10}>
              <Paper
                elevation={2}
                className={`${styles.emptyCommunityCard}`}>
                <Grid direction="row">
                  <Grid container justifyContent="center">
                    <img
                      className={styles.emptyCommunityImage}
                      src="/images/community/not-found.png" />
                  </Grid>
                  <Grid container justifyContent="center">
                    No communties found for
                    <span className={styles.emptyCommunitySearchText}> {communitySearchText}</span>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )
        }
      </Grid>
    </>
  );

});

export default CommunityTeams;
