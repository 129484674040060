
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, InputBase, Paper } from "@mui/material";
import communityStyle, { default as styles } from "CSS/Screens/CommunityScreen.module.css";
import FriendCard from "Components/Community/FriendCard";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import { URLS } from "Constants/Constants";
import { selectTeam } from "Reducers/TeamReducer";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const TeamMemberScreen = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { selectedTeam } = useSelector(selectTeam);

  const handleClose = (e) => {
    e.preventDefault();
    setSearchText("");
    navigate(`${URLS.TEAM}/${selectedTeam._id}`);
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <ScreenNoNav>
        <Grid
          style={{ marginTop: "2em", marginBottom: "10px" }}
          container
          xs={10}
          item={false}
          justifyContent="flex-start">
          <Grid item xs={4}>
            <Link
              to=""
              onClick={handleClose}
              className={`${communityStyle.btn} ${communityStyle.btn_orange}`}
            >
              Close
            </Link>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "10px", marginBottom: "10px" }} container xs={10}>
          <Grid item xs={12}>
            <Paper className={styles.searchBar}>
              <InputBase
                sx={{ ml: 2 }}
                style={{ width: "75%" }}
                placeholder="Search members"
                inputProps={{ "aria-label": "search members" }}
                onChange={handleSearchInputChange}
                value={searchText}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          container
          xs={10}
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          style={{ marginTop: ".5em" }}>
          {
            selectedTeam.members.map((member) => (
              <FriendCard user={member} key={member._id} />
            ))
          }
        </Grid>
      </ScreenNoNav>
    </>
  );
};

export default TeamMemberScreen;
