import { createSlice } from "@reduxjs/toolkit";
import {
  addBadge,
  addBadgeInstance,
  deleteBadge,
  getBadges,
  updateBadge,
  updateBadgeInstance,
} from "./Actions/BadgesActions";

const initialState = {
  badges: [],
  loading: false,
  error: null,
};

// functions for "useSelector"
const selectBadges = (state) => state.badges;

const badgesSlice = createSlice({
  name: "badges",
  initialState,
  reducers: {
    resetBadges: () => initialState,
    // For updating when we only want to reset the error and loading
    resetBadge: ({ badges }) => {
      return {
        badges,
        loading: false,
        error: null,
      };
    },
  },
  extraReducers: {
    // Add badge Async Reducers
    [addBadge.pending]: (state) => {
      state.loading = true;
    },
    [addBadge.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.badges.push(payload);
    },
    [addBadge.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Add badge Async Reducers
    [addBadgeInstance.pending]: (state) => {
      state.loading = true;
    },
    [addBadgeInstance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.badges.push(payload);
    },
    [addBadgeInstance.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Get badge Async Reducers
    [getBadges.pending]: (state) => {
      state.loading = true;
    },
    [getBadges.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.badges = payload;
    },
    [getBadges.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Update badge Async Reducers
    [updateBadge.pending]: (state) => {
      state.loading = true;
    },
    [updateBadge.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      const idx = state.badges.findIndex(
        (badge) => badge.name === payload.name
      );
      state.badges[idx] = payload;
    },
    [updateBadge.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Update badge instance Async Reducers
    [updateBadgeInstance.pending]: (state) => {
      state.loading = true;
    },
    [updateBadgeInstance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;

      const idx = state.badges.findIndex(
        (badge) => badge.name === payload.name
      );
      state.badges[idx] = payload;
    },
    [updateBadgeInstance.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Delete badge Async Reducers
    [deleteBadge.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;

      // remove from proper list
      state.badges = state.badges.filter((badge) => badge._id !== payload._id);
    },
    [deleteBadge.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default badgesSlice.reducer;
export {
  addBadge,
  addBadgeInstance, deleteBadge, getBadges, selectBadges, updateBadge, updateBadgeInstance
};
export const { resetBadge, resetBadges } = badgesSlice.actions;
