import { subscribe } from "Reducers/Actions/SubscriptionActions";
import { getBadges, selectBadges } from "Reducers/BadgesReducer";
import {
  selectSubscription,
  setSubscription
} from "Reducers/SubscriptionReducer";
import { logout, resetUser, selectUser, setUser } from "Reducers/UserReducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getTokenFromFirebase } from "../firebase";

/**
 * Custom hook to manage user authentication and related data fetching.
 * 
 * @param {Function} navigate - Navigation function to redirect users.
 * @returns {Object} - Currently authenticated user object.
 */
const useAuthentication = (navigate) => {
  // Hook to dispatch actions to the Redux store
  const dispatch = useDispatch();

  // Retrieve the currently authenticated user from the Redux store
  const { user } = useSelector(selectUser);

  // Retrieve the error (if any) related to badges from the Redux store
  const { error } = useSelector(selectBadges);

  // Retrieve the subscription token and its expiration status from the Redux store
  const { isExpired } = useSelector(selectSubscription);

  // Get route location.
  const location = useLocation();

  useEffect(() => {
    // Fetch and set the current user, badges, and subscription info on component mount
    dispatch(setUser());
    dispatch(getBadges());
    dispatch(setSubscription());

    // If there's an error with the badges or the user isn't authenticated, redirect to the signup page
    if (error === "Rejected") {
      dispatch(logout());
      navigate("/signup");
    }

    // Cleanup function: Reset the user data when the component is unmounted
    return () => {
      dispatch(resetUser());
    };
  }, [error]);

  // Effect to handle user's subscription token renewal when it's expired
  useEffect(() => {
    if (isExpired) {
      getTokenFromFirebase().then((token) => {
        dispatch(subscribe({ token }));
      });
    }
  }, [isExpired]);

  useEffect(() => {

    if (!user) {
      navigate("/signup");
    } else {
      // If the URL is another other than login, redirect to the URL.
      // Note: This is to ensure that when user's click on a notification they get redirected to the correct screen.
      if (location.pathname !== "/login") {
        navigate(location.pathname);
      }
    }

    // If the user is authenticated fetch a new token from Firebase and update the Redux store
    if (user) {
      getTokenFromFirebase().then((token) => {
        dispatch(subscribe({ token }));
      });
    }
  }, [user]);

  // Return the currently authenticated user
  return user;
};

export default useAuthentication;
