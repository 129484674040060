
import { Slider } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/BehavioralExperimentEntry.module.css";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { useRedirect } from "Hooks/useRedirect";
import { selectBehavioralExperiment, setDate, setDetails, setFeeling, setPrediction, setThoughts } from "Reducers/BehavioralExperimentReducer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const BehavioralEditEntryScreen = () => {
    const redirectUrl = useRedirect("/toolkit/behavioral-experiments");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { date, prediction, thoughts, details } = useSelector(selectBehavioralExperiment);
    const { selectedExperiment } = useSelector(selectBehavioralExperiment);
    // const id = selectedExperiment._id;
    //add success add hook toastify

    const updateOnSubmit = async (event) => {
        event.preventDefault();

        navigate("/toolkit/behavioral-save-edit");
    };

    // Slider
    const marks = [
        {
            value: 1,
            label: "1 (bad)"
        },
        {
            value: 2,
            label: "2"
        },
        {
            value: 3,
            label: "3"
        },
        {
            value: 4,
            label: "4"
        },
        {
            value: 5,
            label: "5"
        },
        {
            value: 6,
            label: "6"
        },
        {
            value: 7,
            label: "7"
        },
        {
            value: 8,
            label: "8"
        },
        {
            value: 9,
            label: "9"
        },
        {
            value: 10,
            label: "10 (good)"
        },
    ];

    function valuetext(value) {
        return `${value}`;
    }

    return (
        <Screen>
            <Wrapper>
                <Wrapper>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={`${styles.marginTop}`}>
                        <Grid xs={10} item>
                            <Link
                                to={redirectUrl}
                                className={`${styles.backBtn}`}>
                                Back
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={`${styles.marginTop}`}
                        item={true}>
                        <Grid xs={10} item>
                            <div>
                                <p>What thought are you testing in this experiment?</p>
                                <TextField
                                    className={styles.textarea}
                                    label=""
                                    type="text"
                                    multiline
                                    rows={3.5}
                                    placeholder={selectedExperiment.thoughts}
                                    name="thoughts"
                                    required={true}
                                    onChange={(e) => dispatch(setThoughts(e.target.value))}
                                    value={thoughts}
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "2em" }}
                        item={true}>
                        <Grid xs={10} item>
                            <div>
                                <p>Describe the details of this experiment</p>
                                <TextField
                                    className={styles.textarea}
                                    label=""
                                    type="text"
                                    multiline
                                    rows={3.5}
                                    placeholder={selectedExperiment.details}
                                    name="details"
                                    required={true}
                                    onChange={(e) => dispatch(setDetails(e.target.value))}
                                    value={details}
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "2em" }}
                        item={true}>
                        <Grid xs={10} item>
                            <div>
                                <p>When do you want to schedule this experiment?</p>
                                <input
                                    value={date}
                                    type="date"
                                    id="name"
                                    name="date"
                                    onChange={(e) => dispatch(setDate(e.target.value))}
                                />
                            </div>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{ marginTop: "2em" }}
                            item={true}>
                            <Grid xs={10} item>
                                <div>
                                    <p>What do you think might happen during the experiment?</p>
                                    <TextField
                                        className={styles.textarea}
                                        label=""
                                        type="text"
                                        multiline
                                        rows={3.5}
                                        placeholder={selectedExperiment.prediction}
                                        name="prediction"
                                        required={true}
                                        onChange={(e) => dispatch(setPrediction(e.target.value))}
                                        value={prediction}
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 500,
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{ marginTop: "2em" }}
                            item={true}>
                            <Grid xs={10} item>
                                <div>
                                    <p>How do you expect to feel after the experiment?</p>
                                    <Slider
                                        aria-label="Feeling Scale"
                                        defaultValue={selectedExperiment.feeling}
                                        getAriaValueText={valuetext}
                                        valueLabelDisplay="auto"
                                        step={1}
                                        marks={marks}
                                        min={1}
                                        max={10}
                                        required={true}
                                        onChange={(e) => dispatch(setFeeling(e.target.value))}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        style={{ marginTop: "2em", width: "80%" }}
                        item={true}>
                        <Grid item={true} xs={4}></Grid>
                        <Grid
                            item={true}
                            xs={4}
                            className={`${styles.rightAlign}`}>
                            <button
                                className={`
                                    ${btn.faded_blue} 
                                    ${btn.smallActivityBtn} 
                                    ${styles.loadMoreBtn}`}
                                onClick={updateOnSubmit}>
                                Save
                            </button>
                        </Grid>
                    </Grid>
                </Wrapper>
            </Wrapper>
        </Screen>
    );
};

export default BehavioralEditEntryScreen;
