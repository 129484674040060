import AlertMessage from "Components/Helpers/AlertMessage";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import ExposureStep from "Components/Toolkit/ExposureStep";
import { useEffect, useState } from "react";

import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import FearLadderQuestion from "Components/Toolkit/FearLadderQuestion";
import FearLadderSlider from "Components/Toolkit/FearLadderSlider";
import FearLadderSummary from "Components/Toolkit/FearLadderSummary";
import InfoButton from "Components/Toolkit/InfoButton";
import ModalBox from "Components/Toolkit/ModalBox";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { addExposureStep, getFearLadder, resetSuccess, selectFearLadders } from "Reducers/FearLadderReducer";

const FearLadderDashboardScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openAddStepModal, setOpenAddStepModal] = useState(false);
  const [stepTitle, setStepTitle] = useState("");
  const [fearLevel, setFearLevel] = useState(null);
  const { selectedFearLadder, error, loading, success, exposureSteps } = useSelector(selectFearLadders);


  useEffect(() => {
    dispatch(getFearLadder(id));
  }, [dispatch, id, successMessage]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }

    if (success) {
      setSuccessMessage("Step added successfully!");
      const timer = setTimeout(() => {
        dispatch(resetSuccess());
        setSuccessMessage("");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [dispatch, error, success]);

  const handleAddStepSubmit = () => {
    dispatch(addExposureStep({
      ladderId: id,
      title: stepTitle,
      fearLevel: fearLevel,
    }));
    setOpenAddStepModal(false);
    setStepTitle("");
    setFearLevel(null);
  };

  const addStepModal = () => (
    <ModalBox title="Add Exposure Step" open={openAddStepModal} onClose={() => setOpenAddStepModal(false)}>
      <Grid container display="flex" spacing={2} sx={{ justify: "center" }}>
        <Grid item xs={12}>
          <FearLadderQuestion
            question="Step title:"
            value={stepTitle}
            onChange={(e) => setStepTitle(e.target.value)}
            rows={1}
          />
        </Grid>

        <Grid item xs={12}>
          <FearLadderSlider
            value={fearLevel}
            onChange={(e, value) => setFearLevel(value)}
            question="Level of fear associated with this step:"
            label="stepFearLevel"
            marks={{ left: "1", right: "10" }}
          />
        </Grid>
        <Grid item container xs={12}>
          <Button
            variant="contained"
            onClick={handleAddStepSubmit}
            disabled={!stepTitle || fearLevel === null}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </ModalBox>
  );

  return (
    <Screen>
      <Wrapper>
        {/* Back button */}
        <Link style={{ marginTop: "-0.25rem", marginBottom: "0.3rem", width: "90%", justifyContent: "flex-start" }} to={"/toolkit/fear-ladder"}>
          <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
            <HiArrowNarrowLeft />
            <Typography variant="body1">
              Back to fear ladders
            </Typography>
          </Stack>
        </Link>

        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        <AlertMessage
          message={successMessage}
          setMessage={() => setSuccessMessage("")}
          type="success"
          toggle={successMessage}
        />


        {loading ? <LoadingSpinner />
          :
          <Container>
            {addStepModal()}
            <FearLadderSummary
              fearLadder={selectedFearLadder}
            />
            <br />
            <Grid container display="flex" spacing={2} sx={{ justify: "center", alignItems: "center" }}>
              <Grid item xs={10}>
                <Typography variant="h5">Exposure Steps</Typography>
              </Grid>
              <Grid item xs={2} justifyContent="flex-end" display="flex" alignItems="center" >
                {exposureSteps?.length === 1 ?
                  <InfoButton title="Add steps!" message="The next stage is planning the steps for your exposure. Create a series of steps of increasing levels of fear." />
                  :
                  <InfoButton title="Plan and complete your steps!" message="Add more steps, or press on a step to plan your exposure. Complete the steps to complete the fear ladder!" />
                }
              </Grid>
              <Grid
                display={"flex"}
                xs={12}
                item container justifyContent={"center"}>

                <Button variant="contained" fullWidth
                  sx={{
                    backgroundColor: "var(--blue-300)",
                    color: "#fff",
                    borderRadius: 4,
                    border: "2px solid #000",
                  }}
                  onClick={() => setOpenAddStepModal(true)}>
                  Add Exposure Step
                </Button>
              </Grid>
              {(exposureSteps?.length > 0) &&
                exposureSteps.map((step, index) => (
                  <Grid item key={step._id} xs={12} >
                    <ExposureStep key={step._id} index={index + 1} exposureStep={step} />
                  </Grid>
                ))
              }
            </Grid>
          </Container>
        }
      </Wrapper>
    </Screen>
  );
};

export default FearLadderDashboardScreen;
