import { Grid, Typography } from "@mui/material";
import { memo } from "react";

const FearLadderSummary = memo(function FearLadderSummary({ fearLadder }) {
  return (
    <Grid container sx={{
      backgroundColor: fearLadder?.isCompleted ? "var(--green-100)" : "#fff",
      border: "solid 1px",
      borderRadius: 4,
      paddingY: 1,
      paddingX: 2,
      justifyContent: "flex-start",
    }}
    >
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={600}>
          What do you want to do?
        </Typography>
        <Typography variant="body1" fontWeight={600}>
        </Typography>
        <Typography variant="body1">
          {fearLadder?.objective}
        </Typography>
        <Typography variant="body1" fontWeight={600} marginTop={"0.25rem"}>
          What is the anxious thought?
        </Typography>
        <Typography variant="body1">
          {fearLadder?.barrier}
        </Typography>
      </Grid>
    </Grid>
  );
});

export default FearLadderSummary;