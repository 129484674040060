import { Alert } from "@mui/material";
import TextField from "@mui/material/TextField";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/UpdateTreatmentScreen.module.css";
import { useProvider } from "Hooks/useProvider";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectHealthCareProviders } from "Reducers/HealthCareProvidersReducer";
import {
  deleteTreatment,
  getTreatment,
  resetTreatment,
  selectTreatments,
  updateTreatment,
} from "Reducers/TreatmentsReducer";

const UpdateTreatmentScreen = () => {
  const { selectedTreatment, loading, error } = useSelector(selectTreatments);
  const { id } = useParams();
  const redirectUrl = useRedirect("/my-health-record/treatments");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [treatmentName, setTreatmentName] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const { renderAutocomplete, setProviderName, providerName } = useProvider();
  const { newlyAddedId } = useSelector(selectHealthCareProviders);

  // Autofill fields for selected Treatment entry with the existing data
  const fillFields = () => {
    if (selectedTreatment) {
      setTreatmentName(selectedTreatment.treatmentName);
      setAdditionalNotes(selectedTreatment.additionalNotes);
      setDate(moment(selectedTreatment.date).format("YYYY-MM-DDTHH:mm"));
      setProviderName(selectedTreatment.prescriber);
    }
  };

  const updateTreatmentSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateTreatment({
        id,
        treatmentName,
        additionalNotes,
        date: moment(date).utc(),
        prescriber: providerName.healthCareProviderName,
        healthCareProvider: newlyAddedId,
      })
    );

    // Return to Treatments screen after success
    if (!loading && !error) {
      navigate("/my-health-record/treatments");
    }
  };

  const deleteTreatmentAction = (e) => {
    e.preventDefault();

    localStorage.setItem("tempTreatment", JSON.stringify(selectedTreatment));
    dispatch(deleteTreatment(selectedTreatment._id));

    // Return to Treatment screen after success
    if (!loading && !error) {
      navigate("/my-health-record/treatments");
    }
  };

  useEffect(() => {
    dispatch(getTreatment(id));

    fillFields();

    if (error) {
      setErrorMessage(error);
    }

    return () => {
      dispatch(resetTreatment);
    };
  }, [selectedTreatment?.treatmentName]);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={updateTreatmentSubmit}>
        <div className={styles.header}>
          <button
            className={`${btn.links} ${btn.secondaryLink}`}
            onClick={() => navigate(redirectUrl)}
            type="button"
          >
            Cancel
          </button>
          <h3>Update Treatment</h3>
          <button className={`${btn.links} ${btn.primaryLink}`} type="submit">
            Update
          </button>
        </div>
        <div className={`${styles.body} ${isMobile ? styles.bodyMobile : ""}`}>
          {errorMessage && (
            <Alert
              sx={{ width: "80%", marginBottom: "15px" }}
              severity="error"
              onClose={() => setErrorMessage("")}
            >
              {errorMessage}
            </Alert>
          )}
          <div
            className={`${styles.body} ${isMobile ? styles.bodyMobile : ""}`}
          >
            <TextField
              className={styles.textField}
              type="datetime-local"
              name="day"
              required={true}
              value={date}
              onChange={(e) => setDate(e.target.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className={styles.textField}
              label="Treatment"
              type="text"
              placeholder="Enter treatment name"
              name="name"
              require
              value={treatmentName}
              onChange={(e) => setTreatmentName(e.target.value)}
              size="small"
            />
            {renderAutocomplete()}
            <TextField
              className={styles.textField}
              label="Additional Notes"
              type="text"
              placeholder="Enter additional notes (Doctor's names, Location, Date of Next Treatment, etc.)"
              name="additionalNotes"
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
              size="small"
              multiline={true}
              rows={3}
            />
          </div>
          <div className={styles.btns}>
            <button
              type="button"
              className={`${btn.btn} ${btn.secondary}`}
              onClick={deleteTreatmentAction}
            >
              Delete
            </button>
            <button
              type="submit"
              className={`${btn.btn} ${btn.primary}`}
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default UpdateTreatmentScreen;
