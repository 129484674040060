import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Nunito Sans"].join(","),
  },
  palette: {
    primary: {
      main: "#f27254",
      secondary: "#0b7ea1",
    },
    grey: {
      main: "rgba(129, 129, 130, 1)",
      secondary: "rgba(183, 183, 183, 1)",
    },

    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
  },
});

export default theme;
