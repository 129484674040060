import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

// This component renders a chart using Chart.js library based 
// on the chart type passed as a prop and the data to be displayed
const Chart = ({ data, type }) => {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Habits Insight 📊",
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
  };

  // Define a function to select and render the chart 
  // based on the chart type prop
  const selectChart = () => {
    if (type === "bar") {
      return (<Bar
        options={options}
        data={data}
        height={"100%"}
        width={"100%"}
      />);
    } else if (type === "line") {
      return (
        <Line
          options={options}
          data={data}
          height={"100%"}
          width={"100%"} />
      );
    }
  };

  return (
    <>
      {selectChart()}
    </>
  );
};

export default Chart;