
import { Button, Grid, Paper, Typography } from "@mui/material";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import styles from "CSS/Screens/ToolKitScreen.module.css";

import { useNavigate } from "react-router-dom";

const SimsDimsHomeScreen = () => {

  const navigate = useNavigate();

  const handleStartLesson = () => {
    navigate("/toolkit/sims-dims-lesson");
  };

  const handleStartNewSIMsDIMs = () => {
    navigate("/toolkit/sims-dims-entry");
  };

  const handleHistorySIMsDims = () => {
    navigate("/toolkit/sims-dims-history");
  };

  return (
    <Screen className={styles.SimsDimsHomeScreen}>
      <Wrapper>
        <BackBtn title="Back to toolkit" link="/toolkit" />
        <h1>SIMs and DIMs</h1>

        <Grid
          container spacing={2}
          justifyContent="center"
          alignItems="flex-start"
          style={{ marginTop: "1em" }}>

          {/* Lesson Section */}
          <Grid item xs={10}>
            <Paper elevation={2} className={styles.paper}>
              <div className={styles.content} style={{ padding: "16px" }}>
                <Typography variant="h5" component="div" gutterBottom>
                  Lesson
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  What are SIMs? What are DIMs?
                </Typography>
              </div>
              <div style={{ textAlign: "center", padding: "16px" }}>
                <Button
                  className={styles.buttonFadedOrange}
                  onClick={handleStartLesson}>
                  View Lesson
                </Button>
              </div>
            </Paper>
          </Grid>

          {/* Activity Section */}
          <Grid item xs={10}>
            <Paper elevation={2} className={styles.paper}>
              <div className={styles.content} style={{ padding: "16px" }}>
                <Typography variant="h5" component="div" gutterBottom>
                  Activity
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Identifying your SIMs and DIMs
                </Typography>
              </div>
              <div style={{ textAlign: "center", padding: "16px" }}>
                <Button
                  className={styles.buttonFadedOrange}
                  onClick={handleStartNewSIMsDIMs}>
                  Start Activity
                </Button>
              </div>
            </Paper>
          </Grid>

          {/* Activity Log Section */}
          <Grid item xs={10}>
            <Paper elevation={2} className={styles.paper}>
              <div className={styles.content} style={{ padding: "16px" }}>
                <Typography variant="h5" component="div" gutterBottom>
                  Activity Log
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Your past SIMs and DIMs
                </Typography>
              </div>
              <div style={{ textAlign: "center", padding: "16px" }}>
                <Button
                  className={styles.buttonFadedOrange}
                  onClick={handleHistorySIMsDims}>
                  Go to History
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Wrapper>
    </Screen>
  );
};

export default SimsDimsHomeScreen;
