import Badge from "@mui/material/Badge";
import styles from "CSS/Components/FooterNav.module.css";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectToDos } from "Reducers/ToDoReducer";
import { selectUserSetting } from "Reducers/UserSettingReducer";

const FooterNav = () => {
  const { pathname } = useLocation();
  const { daily } = useSelector(selectToDos);
  const { bottomNavConfig } = useSelector(selectUserSetting);
  const [bottomNavMap, setBottomNavMap] = useState(null);

  useEffect(() => {
    setBottomNavMap(new Map(bottomNavConfig
      .map(navItem => [navItem.id,
      { content: navItem.content, isActive: navItem.isActive }])));
  }, [bottomNavConfig]);

  const navItems = [
    {
      link: "/",
      name: "home",
      icon: <div className={`${styles.iconHome} ${styles.icon}`} />,
      fillIcon: (
        <div
          className={`${styles.icon} ${styles.iconHome} ${styles.iconActive}`}
        />
      ),
    },
    {
      link: "/journal",
      name: "journal",
      icon: <div className={`${styles.iconJournal} ${styles.icon}`} />,
      fillIcon: (
        <div
          className={`${styles.icon} ${styles.iconJournal} ${styles.iconActive}`}
        />
      ),
    },
    {
      link: "/todos",
      name: "todos",
      icon: (
        <Badge
          badgeContent={daily?.length}
          color="primary"
          sx={{
            "& .MuiBadge-badge": {
              color: "white",
              fontWeight: "600",
            },
          }}
        >
          <div className={`${styles.iconTodos} ${styles.icon}`} />
        </Badge>
      ),
      fillIcon: (
        <Badge
          badgeContent={daily?.length}
          color="primary"
          sx={{
            "& .MuiBadge-badge": {
              color: "white",
              fontWeight: "600",
            },
          }}
        >
          <div
            className={`${styles.icon} ${styles.iconTodos} ${styles.iconActive}`}
          />
        </Badge>
      ),
    },
    {
      link: "/habits",
      name: "habits",
      icon: <div className={`${styles.iconHabits} ${styles.icon}`} />,
      fillIcon: (
        <div
          className={`${styles.icon} ${styles.iconHabits} ${styles.iconActive}`}
        />
      ),
    },
    {
      link: "/toolkit",
      name: "toolkit",
      icon: <div className={`${styles.iconToolkit} ${styles.icon}`} />,
      fillIcon: (
        <div
          className={`${styles.icon} ${styles.iconToolkit} ${styles.iconActive}`}
        />
      ),
    },
    {
      link: "/activity",
      name: "activity",
      icon: <div className={`${styles.iconActivity} ${styles.icon}`} />,
      fillIcon: (
        <div
          className={`${styles.icon} ${styles.iconActivity} ${styles.iconActive}`}
        />
      ),
    },
    {
      link: "/community",
      name: "community",
      icon: <div className={`${styles.iconCommunity} ${styles.icon}`} />,
      fillIcon: (
        <div
          className={`${styles.icon} ${styles.iconCommunity} ${styles.iconActive}`}
        />
      ),
    },
  ];

  const NavItem = ({ link, icon, fillIcon }) => {
    return (
      <Link className={styles.iconLink} to={link}>
        {pathname === link ? fillIcon : icon}
      </Link>
    );
  };

  return (
    <>
      {isMobile && <div className={styles.fillerMoile} />}
      <footer className={`${styles.nav} ${isMobile ? styles.navMobile : ""}`}>
        <div className={styles.container}>
          {bottomNavMap != null && navItems.map((navItem) => {
            if (bottomNavMap.has(navItem.name)) {
              const userConfigNavItem = bottomNavMap.get(navItem.name);
              if (userConfigNavItem.isActive) {
                return (<NavItem
                  key={navItem.link}
                  link={navItem.link}
                  icon={navItem.icon}
                  fillIcon={navItem.fillIcon}
                />);
              }
            }
          })}
        </div>
      </footer>
    </>
  );
};

export default FooterNav;
