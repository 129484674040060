import { createSlice } from "@reduxjs/toolkit";
import {
  addAdditionalNote,
  deleteAdditionalNote,
  getAdditionalNote,
  getAdditionalNotes,
  updateAdditionalNote,
} from "./Actions/AdditionalNoteActions";

const initialState = {
  additionalNote: [],
  error: null,
  loading: false,
  successAdd: false,
  successDelete: false,
  selectedAdditionalNote: null,
};

// Functions for "useSelector"
const selectAdditionalNotes = (state) => state.additionalNote;

const additionalNotesSlice = createSlice({
  name: "additionalNote",
  initialState,
  reducers: {
    resetAdditionalNotes: () => initialState,
    resetAdditionalNote: ({ additionalNote }) => {
      return {
        additionalNote,
        loading: false,
        error: null,
        successAdd: false,
        successDelete: false,
        selectedAdditionalNote: null,
      };
    },
    resetAddAdditionalNoteSuccess: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    resetDeleteAdditionalNoteSuccess: (state) => {
      return {
        ...state,
        successDelete: false,
      };
    },
  },
  extraReducers: {
    // Fetch Additional Notes Async Reducers
    [getAdditionalNotes.pending]: (state) => {
      state.loading = true;
    },
    [getAdditionalNotes.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.additionalNote = payload;
      sortList(state);
    },
    [getAdditionalNotes.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Fetch a specific/singular Additional Notes Async Reducers
    [getAdditionalNote.pending]: (state) => {
      state.loading = true;
    },
    [getAdditionalNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedAdditionalNote = payload;
    },
    [getAdditionalNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add Additional Note Async Reducers
    [addAdditionalNote.pending]: (state) => {
      state.loading = true;
    },
    [addAdditionalNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
      state.additionalNote.push(payload); // Push entry to additional note [array]
      sortList(state);
    },
    [addAdditionalNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // Update Additional Note Async Reducers
    [updateAdditionalNote.pending]: (state) => {
      state.loading = true;
    },
    [updateAdditionalNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      updateAdditionalNoteFulfilled(state, payload);
      state.selectedAdditionalNote = payload;
      sortList(state);
    },
    [updateAdditionalNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete Additional Note Async Reducers
    [deleteAdditionalNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successDelete = true;
      state.additionalNote = state.additionalNote.filter(
        (additionalNote) => additionalNote._id !== payload._id
      ); //remove entry from history
      sortList(state);
    },
    [deleteAdditionalNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successDelete = false;
    },
  },
});

// helper functions
// sorts all entries based on date (most recent first)
const sortList = (state) => {
  state.additionalNote = state.additionalNote.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
};

// logic for placing the updated entry into the proper order
const updateAdditionalNoteFulfilled = (state, payload) => {
  // find additional note index and update it
  const idx = state.additionalNote.findIndex(
    (additionalNote) => additionalNote._id === payload._id
  );
  state.additionalNote[idx] = payload;
};

export default additionalNotesSlice.reducer;
export {
  addAdditionalNote, deleteAdditionalNote, getAdditionalNote, getAdditionalNotes, selectAdditionalNotes, updateAdditionalNote
};
export const {
  resetAdditionalNote,
  resetAdditionalNotes,
  resetAddAdditionalNoteSuccess,
  resetDeleteAdditionalNoteSuccess,
} = additionalNotesSlice.actions;
