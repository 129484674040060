import { FormControlLabel, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddHealthCareProviderScreen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addHealthCareProvider,
  selectHealthCareProviders,
} from "Reducers/HealthCareProvidersReducer";

const AddHealthCareProviderScreen = () => {
  const [healthCareProviderName, setHealthCareProviderName] = useState("");
  const [field, setField] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [isArchived, setIsArchived] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const { loading, error } = useSelector(selectHealthCareProviders);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleHealthCareProviderSubmit = (event) => {
    event.preventDefault();

    dispatch(
      addHealthCareProvider({
        healthCareProviderName,
        field,
        phoneNumber,
        address,
        isArchived,
        additionalNotes,
      })
    );
    navigate("/my-health-record/health-care-providers");
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={handleHealthCareProviderSubmit}>
        <div className={styles.header}>
          <Link
            to="/my-health-record/health-care-providers"
            className={`${btn.links} ${btn.secondaryLink}`}
          >
            Close
          </Link>
          <h3>New Provider</h3>
          <button type="submit" className={`${btn.links} ${btn.primaryLink}`}>
            Add
          </button>
        </div>

        <BodyForm desktop={styles.body}>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <div className={styles.switchField}>
            <FormControlLabel
              control={
                <Switch
                  checked={!isArchived}
                  onChange={(e) => setIsArchived(!e.target.checked)}
                  label="Active Status"
                />
              }
              label="Active Status"
            />
          </div>
          <TextField
            className={styles.textField}
            label="Health Care Provider Name"
            type="text"
            placeholder="Enter health care provider name"
            name="name"
            required
            value={healthCareProviderName}
            onChange={(e) => setHealthCareProviderName(e.target.value)}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Field"
            type="text"
            placeholder="Enter field"
            name="field"
            value={field}
            onChange={(e) => setField(e.target.value)}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Phone Number"
            type="text"
            placeholder="Enter phone number"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            size="small"
            inputProps={{
              maxLength: 15,
            }}
          />
          <TextField
            className={styles.textField}
            label="Address"
            type="text"
            placeholder="Enter address"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Additional Notes"
            type="text"
            placeholder="Enter additional notes"
            name="additionalNotes"
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            size="small"
            multiline={true}
            rows={3}
          />

          <button
            type="submit"
            className={`${btn.btn} ${btn.primary} ${styles.submit}`}
          >
            {loading ? "Adding..." : "Add"}
          </button>
        </BodyForm>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default AddHealthCareProviderScreen;
