export const URLS = {
  COMMUNITY: "/community",
  TEAM: "/team",
  COMMUNITY_ADD_POST: "/add-post",
  COMMUNITY_ADD_COMMUNITY: "/add-community",
  COMMUNITY_ADD_COMMUNITY_FRIENDS: "/add-community-friends",
  COMMUNITY_ADD_COMMUNITY_GOAL: "/add-community/goal",
  COMMUNITY_ADD_FRIEND: "/add-community/friends",
  COMMUNITY_CHAT: "/chat",
  SETTING_BOTTOM_NAV: "/setting/bottom-nav",
  SETTING_SIDE_NAV: "/setting/side-nav",
  SETTING_ACCOUNT: "/setting/account",
  FORGET_PASSWORD: "/forget-password",
  FORGET_PASSWORD_CODE_ENTRY: "/forget-password/code-entry",
  RESET_PASSWORD_SCREEN: "/forget-password/new-password",
};

export const COMMUNITY_TEAM_ICONS = ["🏆", "🏃‍♂️", "🏋️‍♂️", "🏊‍♀️", "🏃‍♂️"];

export const COMMUNITY_TEAM_ICON_TO_VALUE_MAP = {
  "🏆": "TROPHY",
  "🏃‍♂️": "RUN",
  "🏋️‍♂️": "WORKOUT",
  "🏊‍♀️": "SWIM",
  "🎯": "GOAL"
};

export const COMMUNITY_TEAM_ICON_VALUE_TO_ICON_MAP = {
  "TROPHY": "🏆",
  "RUN": "🏃‍♂️",
  "WORKOUT": "🏋️‍♂️",
  "SWIM": "🏊‍♀️",
  "GOAL": "🎯",
  "T": "🌟",
};

export const COMMUNITY_POST_REACTION = {
  HEART: "HEART",
  CELEBRATE: "CELEBRATE",
  CLAP: "CLAP",
};

export const DEFAULT_BOTTOM_NAV_CONFIG = [
  { id: "home", content: "Home", isActive: true },
  { id: "journal", content: "Journal", isActive: true },
  { id: "todos", content: "To-Do's", isActive: true },
  { id: "habits", content: "Habits", isActive: true },
  { id: "toolkit", content: "Toolkit", isActive: true },
  { id: "community", content: "Community", isActive: false },
  { id: "activity", content: "Activity", isActive: false },
];

export const DEFAULT_SIDE_NAV_CONFIG = [
  { content: "Home", isActive: true, isModifiable: false },
  { content: "Journal", isActive: true, isModifiable: true },
  { content: "To-Do's", isActive: true, isModifiable: true },
  { content: "Habits", isActive: true, isModifiable: true },
  { content: "Pathways", isActive: true, isModifiable: true },
  { content: "My Health Record", isActive: true, isModifiable: true },
  { content: "Toolkit", isActive: true, isModifiable: true },
  { content: "Insights", isActive: true, isModifiable: true },
  { content: "Community", isActive: true, isModifiable: true },
  { content: "Book a Session", isActive: true, isModifiable: true },
  { content: "Settings", isActive: true, isModifiable: false },
  { content: "Admin", isActive: true, isModifiable: false },
];

export const USER_PROFILE_PICTURE_S3_URL = "https://psyhealth-dev.s3.us-east-2.amazonaws.com/";
