import { TextField } from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import {
  addHealthCareProvider,
  resetAddHealthCareProviderSuccess,
  selectHealthCareProviders,
} from "Reducers/HealthCareProvidersReducer";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import btn from "../../CSS/Button.module.css";
import styles from "../../CSS/Screens/HealthRecordSetupProviderScreen.module.css";
import Screen from "../../Components/Helpers/Screen";
import WrapperForm from "../../Components/Helpers/WrapperForm";

const HealthRecordSetupProviderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { loading, error, successAdd } = useSelector(selectHealthCareProviders);

  const [healthCareProviderName, setHealthCareProviderName] = useState("");
  const [field, setField] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new provider!");
      timer = setTimeout(() => {
        dispatch(resetAddHealthCareProviderSuccess());
      }, 2000);
    }
    return timer;
  };

  const handleHealthCareProviderSubmit = (event) => {
    event.preventDefault();

    dispatch(
      addHealthCareProvider({
        healthCareProviderName,
        field,
        phoneNumber,
        address,
      })
    );

    // Reset input fields after submitting new entry
    setHealthCareProviderName("");
    setField("");
    setPhoneNumber("");
    setAddress("");
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();

    return () => {
      clearTimeout(timer);
    };
  }, [successAdd]);

  return (
    <Screen>
      <WrapperForm onSubmit={handleHealthCareProviderSubmit}>
        <div className={styles.stepDescription}>
          <p className={styles.paragraph}>
            PsyHealth can remember things so <br /> you don&apos;t have to.
          </p>
          <p className={styles.paragraph}>
            Let&apos;s start with your health care <br /> providers. Remember
            your GP, <br /> surgeon, physiotherapist, therapist, <br /> and
            other specialists.
          </p>
        </div>
        <div
          className={`${styles.healthProviderContainer_body} ${
            isMobile ? styles.healthProviderContainer_bodyMobile : ""
          }`}
        >
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <AlertMessage
            message={successMessage}
            setMessage={() => {
              setSuccessMessage("Added new provider!");
              dispatch(resetAddHealthCareProviderSuccess());
            }}
            type="success"
            toggle={successAdd}
          />
          <TextField
            className={styles.textField}
            label="Provider Name"
            type="text"
            placeholder="Enter name"
            name="name"
            required={true}
            value={healthCareProviderName}
            onChange={(e) => setHealthCareProviderName(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Field/Profession"
            type="text"
            placeholder="Enter field/profession"
            name="field"
            value={field}
            onChange={(e) => setField(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Phone #"
            type="text"
            placeholder="Enter phone number"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 10,
            }}
            autoFocus={!isMobile}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Address"
            type="text"
            placeholder="Enter address"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
            size="small"
          />
          <div className={styles.btnGroup}>
            <button
              className={`${btn.btn} ${btn.faded_blue} ${styles.submitBtn}`}
              type="submit"
            >
              {loading ? "Saving..." : "Save and Add New"}
            </button>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={`${btn.btn} ${btn.blue_btn} ${styles.nextBtn}`}
            onClick={() => navigate("/health-record-condition")}
            type="button"
          >
            Continue
          </button>
          <button
            className={styles.skipBtn}
            onClick={() => navigate("/health-record-condition")}
            type="button"
          >
            Skip
          </button>
        </div>
      </WrapperForm>
    </Screen>
  );
};

export default HealthRecordSetupProviderScreen;
