import { Box, TextField, Typography } from "@mui/material";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectFearLadders } from "Reducers/FearLadderReducer";

const FearLadderQuestion = memo(function FearLadderQuestion({ question, value, placeholder, onChange, rows }) {

  const { isPreview } = useSelector(selectFearLadders);

  return (
    <Box>
      <Typography variant="body1">
        {question}
      </Typography>
      {isPreview ? (
        <Typography variant="body1" color={"var(--tangerine-500)"}>{value}</Typography>
      ) : (

        <TextField
          style={{ width: "100%" }}
          label=""
          multiline
          rows={rows || 3.5}
          placeholder={placeholder || ""}
          type="text"
          required={true}
          onChange={onChange}
          value={value}
          variant="outlined"
          inputProps={{
            maxLength: 100,
          }}
          size="small"
        />
      )
      }
    </Box >
  );
});

export default FearLadderQuestion;
