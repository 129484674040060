import { createSlice } from "@reduxjs/toolkit";
import {
  addCondition,
  deleteCondition,
  getCondition,
  getConditions,
  updateCondition,
} from "./Actions/ConditionsActions";

const initialState = {
  past: [], // past conditions only
  error: null,
  loading: false,
  successAdd: false,
  successDelete: false,
  selectedCondition: null,
};

// functions for "useSelector"
const selectConditions = (state) => state.conditions; //Must match spelling 3 lines down

const conditionsSlice = createSlice({
  name: "conditions",
  initialState,
  reducers: {
    resetConditions: () => initialState,
    // For updating when we only want to reset the error and loading
    resetCondition: ({ past }) => {
      return {
        past,
        error: null,
        loading: false,
        successAdd: false,
        successDelete: false,
        selectedCondition: null,
      };
    },
    resetAddConditionSuccess: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    resetDeleteConditionSuccess: (state) => {
      return {
        ...state,
        successDelete: false,
      };
    },
  },
  extraReducers: {
    // Fetch conditions Async Reducers
    [getConditions.pending]: (state) => {
      state.loading = true;
    },
    [getConditions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.past = payload;
      state.error = null;
      sortList(state);
    },
    [getConditions.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Fetch medication Async Reducers
    [getCondition.pending]: (state) => {
      state.loading = true;
    },
    [getCondition.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedCondition = payload;
    },
    [getCondition.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add conditions Async Reducers
    [addCondition.pending]: (state) => {
      state.loading = true;
    },
    [addCondition.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.successAdd = true;
      state.past.push(payload);
      sortList(state);
    },
    [addCondition.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // Update conditions Async Reducers
    [updateCondition.pending]: (state) => {
      state.loading = true;
    },
    [updateCondition.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.selectedCondition = payload;
      updateConditionFulfilled(state, payload);
    },
    [updateCondition.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete condition Async Reducers
    [deleteCondition.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.past = state.past.filter(
        (conditions) => conditions._id !== payload._id
      );
      state.successDelete = true;
    },
    [deleteCondition.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successDelete = false;
    },
  },
});

// helper functions
// logic for placing the updated condition into the proper order
const updateConditionFulfilled = (state, payload) => {
  // find condition index and update it
  const idx = state.past.findIndex(
    (condition) => condition._id === payload._id
  );
  state.past[idx] = payload;
};

// sorts all past entries based on date (most recent first)
const sortList = (state) => {
  state.past = state.past.sort((a, b) => new Date(b.date) - new Date(a.date));
};

export default conditionsSlice.reducer;
export {
  addCondition, deleteCondition, getCondition, getConditions, selectConditions, updateCondition
};
export const {
  resetConditions,
  resetCondition,
  resetAddConditionSuccess,
  resetDeleteConditionSuccess,
} = conditionsSlice.actions;
