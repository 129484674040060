import { TextField } from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import {
  addTreatment,
  resetAddTreatmentSuccess,
  selectTreatments,
} from "Reducers/TreatmentsReducer";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import btn from "../../CSS/Button.module.css";
import styles from "../../CSS/Screens/HealthRecordSetupTreatmentScreen.module.css";
import Screen from "../../Components/Helpers/Screen";
import WrapperForm from "../../Components/Helpers/WrapperForm";

const HealthRecordSetupProviderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { loading, error, successAdd } = useSelector(selectTreatments);

  const [treatmentName, setTreatmentName] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [additionalNotes, setAdditionalNotes] = useState("");

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new treatment!");
      timer = setTimeout(() => {
        dispatch(resetAddTreatmentSuccess());
      }, 2000);
    }
    return timer;
  };

  const handleTreatmentSubmit = (e) => {
    e.preventDefault();

    dispatch(
      addTreatment({
        treatmentName,
        date: moment(date).utc(),
        additionalNotes,
      })
    );

    // Reset input fields after submitting new entry
    setTreatmentName("");
    setDate("");
    setAdditionalNotes([]);
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();

    return () => {
      clearTimeout(timer);
    };
  }, [successAdd]);

  return (
    <Screen>
      <WrapperForm onSubmit={handleTreatmentSubmit}>
        <div className={styles.stepDescription}>
          <p className={styles.paragraph}>
            Now let&apos;s record any <br /> treatments and procedures <br />{" "}
            you have had.
          </p>
        </div>
        <div
          className={`${styles.treatmentContainer_body} ${
            isMobile ? styles.treatmentContainer_bodyMobile : ""
          }`}
        >
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <AlertMessage
            message={successMessage}
            setMessage={() => {
              setSuccessMessage("Added new treatment!");
              dispatch(resetAddTreatmentSuccess());
            }}
            type="success"
            toggle={successAdd}
          />
          <TextField
            className={styles.textField}
            label="Treatment/Procedure"
            type="text"
            placeholder="Enter treatment"
            name="name"
            required={true}
            value={treatmentName}
            onChange={(e) => setTreatmentName(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
            size="small"
          />
          <TextField
            className={`${styles.textField} ${styles.dateInput}`}
            label="Date of diagonsis"
            type="datetime-local"
            name="day"
            required
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            className={styles.textField}
            label="Additional notes"
            type="text"
            placeholder="Any other notes to keep in mind?"
            name="additional notes"
            multiline
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            variant="outlined"
            autoFocus={!isMobile}
            size="small"
          />
          <div className={styles.btnGroup}>
            <button
              className={`${btn.btn} ${btn.faded_blue} ${styles.submitBtn}`}
              type="submit"
            >
              {loading ? "Saving..." : "Save and Add New"}
            </button>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={`${btn.btn} ${btn.blue_btn} ${styles.nextBtn}`}
            onClick={() => navigate("/health-record-medication")}
            type="button"
          >
            Continue
          </button>
          <button
            className={styles.skipBtn}
            onClick={() => navigate("/health-record-medication")}
            type="button"
          >
            Skip
          </button>
        </div>
      </WrapperForm>
    </Screen>
  );
};

export default HealthRecordSetupProviderScreen;
