// NewFearLadderScreen.js
import { CircularProgress, Grid } from "@mui/material";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import BackBtn from "Components/Navigation/BackBtn";
import FearLadderQuestion from "Components/Toolkit/FearLadderQuestion"; // Adjust path as necessary
import btn from "CSS/Button.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addFearLadder, selectFearLadders } from "Reducers/FearLadderReducer";

const NewFearLadderScreen = () => {

  const [objective, setObjective] = useState("");
  const [barrier, setBarrier] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, selectedFearLadder } = useSelector(selectFearLadders);

  const handleFearLadderSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addFearLadder({
        objective: objective,
        barrier: barrier,
      })
    );
  };

  useEffect(() => {
    if (selectedFearLadder) {
      navigate(`/toolkit/fear-ladder/${selectedFearLadder._id}`);
    }
  }, [selectedFearLadder, navigate]);


  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={handleFearLadderSubmit}>
        <BackBtn title="Back" link="/toolkit/fear-ladder" />
        <h2>NewFearLadderScreen</h2><br />

        <Grid spacing={2} container justifyContent="center">

          <Grid xs={10} item>
            <FearLadderQuestion
              question="What do you want to do?"
              value={objective}
              onChange={(e) => setObjective(e.target.value)}
            />
          </Grid>

          <Grid xs={10} item>
            <FearLadderQuestion
              question="What is the anxious thought around starting?"
              value={barrier}
              onChange={(e) => setBarrier(e.target.value)}
            />
          </Grid>

          <Grid xs={10} item sx={{ textAlign: "end" }}>
            <button
              type="submit"
              className={`${btn.btn} ${btn.orange_btn}`}
            >
              {loading ? <CircularProgress size={24} /> : "Next"}
            </button>
          </Grid>

        </Grid>

      </WrapperForm>
    </ScreenNoNav>
  );
};

export default NewFearLadderScreen;
