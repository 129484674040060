import styles from "CSS/Screens/404Screen.module.css";
import { Link } from "react-router-dom";

const NotFoundScreen = () => {
  return (
    <main className={styles.notFound_container}>
      <h1>404 Page Not Found</h1>
      <h2>Sorry, this page does not exists</h2>
      <h3>
        Click{" "}
        <Link className={styles.notFound_link} to="/">
          here
        </Link>{" "}
        to return to the homepage
      </h3>
    </main>
  );
};

export default NotFoundScreen;
