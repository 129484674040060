import styles from "CSS/Components/AppointmentNote.module.css";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AppointmentNote = memo(function temp({ appointmentNote }) {
  const [day, setDay] = useState(moment());
  const [time, setTime] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setDay(
      moment(appointmentNote.date).format("MMMM") +
      " " +
      moment(appointmentNote.date).date()
    );
    setTime(
      ", " +
      moment(appointmentNote.date)
        .local()
        .format("hh:mm A")
        .replace(/(^|-)0+/g, "")
    ); // remove leading 0 on date
  }, [appointmentNote.date]);

  return (
    <section className={styles.container}>
      <div
        className={`${styles.info}
        }`}
      >
        <h3
          onClick={() =>
            navigate(`/update-appointment-note/${appointmentNote._id}`)
          }
        >
          {appointmentNote.appointmentNotes.length > 15 // if appointment notes is > 15
            ? `${appointmentNote.appointmentNotes.substring(0, 15)}...` // then show just first 15 chars as title
            : appointmentNote.appointmentNotes.length == 0 // if zero notes then fill in provider name
              ? appointmentNote.providerName.length > 15 // if provider name > 15
                ? `${appointmentNote.providerName.substring(0, 15)}...` // title is just first 15 chars of provider name
                : appointmentNote.providerName // otherwise title is full provider name
              : // if notes are between 0 - 14 then just put title as full notes
              appointmentNote.appointmentNotes}
        </h3>
        <p
          onClick={() =>
            navigate(`/update-appointment-note/${appointmentNote._id}`)
          }
        >
          {moment(appointmentNote.date).isSame(moment(), "day")
            ? "🗓️ " + "Today" + time
            : "🗓️ " + day + time}
        </p>
        <p
          onClick={() =>
            navigate(`/update-appointment-note/${appointmentNote._id}`)
          }
        >
          {appointmentNote.providerName.length > 15
            ? "🩺 " + `${appointmentNote.providerName.substring(0, 15)}...`
            : "🩺 " + appointmentNote.providerName}
        </p>
      </div>
    </section>
  );
});

export default AppointmentNote;
