import { Grid, Typography } from "@mui/material";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/SimsDimsEntryScreen.module.css";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addSimsDimsEntry, selectSimsDims } from "Reducers/SimsDimsReducer";

const SimsDimsReviewScreen = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { simsEntries = [], dimsEntries = [], selectedDangers } = useSelector(selectSimsDims) || {};

    const handleBackClick = () => {
        navigate("/toolkit/sims-dims-saying");
    };
    const handleSaveClick = () => {

        dispatch(addSimsDimsEntry({
            simsEntries,
            dimsEntries,
            selectedDangers,
            date: moment().utc(),
        }));

        navigate("/toolkit/sims-dims");
    };

    return (
        <Screen>
            <Wrapper>

                {/* Subtitle */}
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.marginTop}
                >
                    <Grid xs={10} item>
                        <Typography variant="h6" gutterBottom>
                            Review
                        </Typography>
                    </Grid>
                </Grid>

                {/* First Question */}
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.marginTop}
                >
                    <Grid xs={10} item>
                        <div>
                            <p>SIMS</p>
                        </div>
                    </Grid>
                </Grid>

                {/* Second Question */}
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: "2em" }}
                >
                    <Grid xs={10} item>
                        <div>
                            <p>DIMS</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: "2em", width: "80%" }}
                >
                    <Grid item={true} xs={4} style={{ textAlign: "left" }}>
                        <button
                            className={`${btn.faded_orange} 
                            ${btn.smallActivityBtn} 
                            ${styles.navBtn}`}
                            onClick={handleBackClick}>
                            Back
                        </button>
                    </Grid>

                    <Grid item={true} xs={4} style={{ textAlign: "right" }}>
                        <button
                            className={`${btn.faded_blue} 
                            ${btn.smallActivityBtn} 
                            ${styles.navBtn}`}
                            onClick={handleSaveClick}>
                            Save
                        </button>
                    </Grid>
                </Grid>


            </Wrapper>
        </Screen>
    );
};
export default SimsDimsReviewScreen;
