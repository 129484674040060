import { Alert, Button, Grid } from "@mui/material";
import { getFeedPosts } from "Reducers/Actions/CommunityActions";
import { memo } from "react";
import { useDispatch } from "react-redux";
import CommunityPost from "./CommunityPost";

const CommunityFeed = memo(function temp({ communityPosts }) {

  const dispatch = useDispatch();

  const handleLoadMore = () => {
    const lastPost = communityPosts.slice(-1)[0];
    dispatch(getFeedPosts(lastPost.page + 1));
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        style={{ marginTop: "1em" }}
        spacing={1}>
        {
          communityPosts.length === 0 ? (
            <Alert style={{ marginTop: "1em" }} icon={false} severity="success">
              No posts to show. Try adding one.
            </Alert>
          ) : (
            <>
              {communityPosts.map((post) => (
                <Grid key={post._id} item xs={11} md={8}>
                  <CommunityPost post={post} />
                </Grid>
              ))}
              <Grid item xs={11} md={8}>
                <Button
                  sx={{ textTransform: "none", color: "white", borderRadius: "10px" }}
                  fullWidth
                  variant="contained"
                  onClick={handleLoadMore}
                  disableElevation>
                  Tap for More!
                </Button>
              </Grid>
            </>
          )
        }
      </Grid>
    </>
  );

});

export default CommunityFeed;
