import CloseIcon from "@mui/icons-material/Close";
import { Modal, TextField } from "@mui/material";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/Pathway/UpdatePathwayScreen.module.css";
import { useRedirect } from "Hooks/useRedirect";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deletePathway,
  getPathway,
  selectPathways,
  updatePathway,
} from "Reducers/PathwayReducer";

const UpdatePathwayScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectUrl = useRedirect("/pathway");
  const { selectedPathway, loading, error } =
    useSelector(selectPathways);

  const [title, setTitle] = useState("");
  const [yourHow, setYourHow] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [desiredOutcome, setDesiredOutcome] = useState("");
  const [motivationalSubtitle, setMotivationalSubtitle] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updatePathway({
        ...selectedPathway,
        title,
        yourHow,
        desiredOutcome,
        motivationalSubtitle,
      })
    );

    if (!error && !loading) {
      navigate(redirectUrl);
    }
  };

  const fillFields = () => {
    if (selectedPathway) {
      setTitle(selectedPathway.title);
      setYourHow(selectedPathway?.yourHow);
      setDesiredOutcome(selectedPathway.desiredOutcome);
      setMotivationalSubtitle(selectedPathway?.motivationalSubtitle);
    }
  };

  useEffect(() => {
    dispatch(getPathway(id));
    fillFields();
  }, [selectedPathway.title]);

  const renderDeleteModal = () => (
    <Modal className={styles.modal} open={isOpen}>
      <div className={styles.modalForm}>
        <div className={styles.modalHeader}>
          <h2>Are you sure?</h2>
          <CloseIcon
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div className={styles.cautionMssg}>
          <p>
            <span className={styles.cautionBold}>All</span> associated <span className={styles.cautionBold}>pathway notes</span>{" "}
            and <span className={styles.cautionBold}>habits</span> will be deleted. This <span className={styles.cautionBold}>cannot</span> be undone.
          </p>
          <p>
            We recommend <span className={styles.cautionBold}>archiving</span> instead.
          </p>
        </div>
        <div className={btn.groupRow}>
          <button
            className={`${btn.btn} ${btn.secondary}`}
            onClick={() => {
              setIsOpen(false);
              dispatch(deletePathway(id));
              navigate("/pathways");
            }}
          >
            Delete
          </button>
          <button
            className={`${btn.btn} ${btn.primary}`}
            onClick={() => {
              setIsOpen(false);
              dispatch(
                updatePathway({
                  ...selectedPathway,
                  isArchived: true,
                })
              );
              navigate("/pathways");
            }}
          >
            Archive
          </button>
        </div>
      </div>
    </Modal >
  );

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={onSubmit}>
        <div className={styles.header}>
          <Link
            to={redirectUrl}
            className={`${btn.links} ${btn.secondaryLink}`}
          >
            Cancel
          </Link>
          <h3>Update Pathway</h3>
          <button
            type="submit"
            className={`${btn.links} ${btn.primaryLink}`}
          >
            Update
          </button>
        </div>
        <BodyForm desktop={styles.body}>
          {renderDeleteModal()}
          <label className={styles.label}>What is your desired outcome?</label>
          <TextField
            className={styles.textField}
            label="Desired Outcome"
            type="text"
            placeholder="e.g run a 10k, establish an exercise routine, heal my ankle injury"
            name="name"
            required={true}
            multiline={true}
            rows={3}
            value={desiredOutcome}
            onChange={(e) => setDesiredOutcome(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
            size="small"
          />
          <label className={styles.label}>
            Based on your desired outcome, pick a title for your pathway:
          </label>
          <TextField
            className={styles.textField}
            label="Title"
            type="text"
            placeholder="e.g Heal Ankle"
            name="name"
            required
            value={title}
            onChange={(e) =>
              setTitle(
                e.target.value.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
              )
            }
            variant="outlined"
            inputProps={{
              maxLength: 50,
            }}
            size="small"
          />
          <label className={styles.label}>
            (Optional) Pick a motivational quote!
          </label>
          <TextField
            className={styles.textField}
            label="Subtitle"
            type="text"
            placeholder='"You can do it"'
            name="name"
            value={motivationalSubtitle}
            onChange={(e) => setMotivationalSubtitle(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            size="small"
          />
          <label className={styles.label}>
            (Optional) How will your life be better by achieving this goal?
          </label>
          <TextField
            className={styles.textField}
            label="Your why"
            type="text"
            placeholder="My ankle will be healed"
            name="name"
            required={false}
            value={yourHow}
            onChange={(e) => setYourHow(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            size="small"
          />
          <div className={btn.groupRow}>
            <button
              className={`${btn.btn} ${btn.secondary}`}
              onClick={() => {
                setIsOpen(true);
              }}
              type="button"
            >
              Delete
            </button>
            <button
              className={`${btn.btn} ${btn.primary}`}
              type="submit"
            >
              Update
            </button>
          </div>
        </BodyForm>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default UpdatePathwayScreen;
