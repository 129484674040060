import { createSlice } from "@reduxjs/toolkit";
import {
  addMedication,
  deleteMedication,
  getMedication,
  getMedications,
  updateMedication,
} from "./Actions/MedicationsActions";

const initialState = {
  medications: { active: [], inactive: [] },
  loading: false,
  error: null,
  successAdd: false,
  successDelete: false,
  selectedMedication: null,
  timeOfDayState: ["Morning", "Noon", "Evening", "All"],
  activeState: "active",
};

// functions for "useSelector"
const selectMedications = (state) => state.medications;

const medicationsSlice = createSlice({
  name: "medications",
  initialState,
  reducers: {
    resetMedications: () => initialState,
    // For updating when we only want to reset the error and loading
    resetMedication: ({ medications }) => {
      return {
        medications,
        loading: false,
        error: null,
        successAdd: false,
        successDelete: false,
      };
    },
    resetAddMedicationSuccess: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    resetDeleteMedicationSuccess: (state) => {
      return {
        ...state,
        successDelete: false,
      };
    },
    setTimeOfDayState: (state, { payload }) => {
      state.timeOfDayState = payload;
    },
    setActiveState: (state, { payload }) => {
      state.activeState = payload;
    },
  },
  extraReducers: {
    // Fetch medication Async Reducers
    [getMedication.pending]: (state) => {
      state.loading = true;
    },
    [getMedication.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedMedication = payload; //simple since just 1 medication object
    },
    [getMedication.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Fetch medications Async Reducers
    [getMedications.pending]: (state) => {
      state.loading = true;
    },
    [getMedications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;

      state.medications.inactive = payload.inactive;
      state.medications.active = payload.active;
    },
    [getMedications.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add medications Async Reducers
    [addMedication.pending]: (state) => {
      state.loading = true;
    },
    [addMedication.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.successAdd = true;
      state.medications[payload.isInactive ? "inactive" : "active"].push(
        payload
      ); // push payload to inactive if isInactive is true
    },
    [addMedication.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // Update medications Async Reducers
    [updateMedication.pending]: (state) => {
      state.loading = true;
    },
    [updateMedication.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedMedication = payload;
      //remove the old version of the current medication and instead push the updated version to DB
      // remove old habit and push updated version
      ["inactive", "active"].forEach((activeStatus) => {
        const idx = state.medications[activeStatus].findIndex(
          (medication) => medication._id === payload._id
        ); //go through both lists, find the index of medication with id of payload._id

        if (idx !== -1) {
          if (payload.isInactive) {
            //if the updated medication has inActive set to true, and the old version of it was also in the inactive list
            //means that an inactive medication still remained inactive with other changes in text fields
            if (activeStatus === "inactive") {
              state.medications["inactive"][idx] = payload; //replace the index found earlier of the inactive list with the updated element from payload
            } else {
              // an inactive medication has been changed to active, so we need to push it to the active list
              state.medications["active"].push(payload);
            }

            // If the updated medication is ACTIVE
          } else if (!payload.isInactive) {
            //if the updated medication has inActive set to false, and the old version of it was also in the ACTIVE list
            //means that an active medication still remained active despite other changes in text fields
            if (activeStatus === "active") {
              state.medications["active"][idx] = payload; //replace the index found earlier of the active list with the updated element from payload
            } else {
              // an active medication has been changed to inactive, so we need to push it to the inactive list
              state.medications["inactive"].push(payload);
            }

            state.medications["active"][idx] = payload; ///replace the index found earlier of the active list with the updated element from payload
          }
        }
      });
    },
    [updateMedication.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete medication Async Reducers
    [deleteMedication.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successDelete = true;

      //remove from proper list
      state.medications[payload.isInactive ? "inactive" : "active"] =
        state.medications[payload.isInactive ? "inactive" : "active"].filter(
          (medication) => medication._id !== payload._id
        ); // set the inActive list to a new copy of same list without the medication that was selected to be trashed
    },
    [deleteMedication.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successDelete = false;
    },
  },
});

export default medicationsSlice.reducer;
export {
  addMedication, deleteMedication, getMedication, getMedications, selectMedications, updateMedication
};
export const {
  resetMedications,
  resetMedication,
  resetAddMedicationSuccess,
  resetDeleteMedicationSuccess,
  setTimeOfDayState,
  setActiveState,
} = medicationsSlice.actions;
