import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  addToDo,
  deleteToDo,
  getCompletedToDos,
  getToDo,
  getToDos,
  toggleCompleteToDo,
  updateToDo,
} from "./Actions/ToDoActions";

const initialState = {
  daily: [],
  upcoming: [],
  completed: [],
  selectedToDo: null,
  loading: false,
  successAdd: false,
  successDelete: false,
  error: null,
};

// functions for "useSelector"
const selectToDos = (state) => state.todos;

const todosSlice = createSlice({
  name: "todos",
  initialState,
  reducers: {
    resetToDos: () => initialState,
    // For updating when we only want to reset the error and loading
    resetToDo: ({ todos }) => {
      return {
        todos,
        loading: false,
        error: null,
      };
    },
    resetAddToDoSuccess: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    resetDeleteToDoSuccess: (state) => {
      return {
        ...state,
        successDelete: false,
      };
    },
  },
  extraReducers: {
    // Fetch upcoming todos Async Reducers
    [getToDos.pending]: (state) => {
      state.loading = true;
    },
    [getToDos.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.upcoming = payload;
      let daily = [];
      let upcoming = [];
      const today = moment();
      for (let todo of payload) {
        const date = moment(todo.remindTime).local();
        if (date.date() === today.date() || date < today) {
          daily.push(todo);
        } else {
          upcoming.push(todo);
        }
      }

      state.daily = daily;
      state.upcoming = upcoming;
    },
    [getToDos.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get todo Async Reducers
    [getToDo.pending]: (state) => {
      state.loading = true;
    },
    [getToDo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedToDo = payload;
    },
    [getToDo.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Fetch completed todos Async Reducers
    [getCompletedToDos.pending]: (state) => {
      state.loading = true;
    },
    [getCompletedToDos.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.completed = payload;
    },
    [getCompletedToDos.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add todo Async Reducers
    [addToDo.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.successAdd = true;

      const today = moment();
      const date = moment(payload.remindTime).local();

      // add new todo to proper list
      if (payload.completed) {
        state.completed.push(payload);
      } else if (date.date() === today.date() || date < today) {
        state.daily.push(payload);
      } else {
        state.upcoming.push(payload);
      }
    },
    [addToDo.rejected]: (state, { error }) => {
      state.error = error.message;
      state.successAdd = false;
    },

    // Update todo Async Reducers
    [updateToDo.pending]: (state) => {
      state.loading = true;
    },
    [updateToDo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedToDo = payload;

      if (payload.completed) {
        toggleCompleteFulfilled(state, payload);
      } else {
        updateToDoFulfilled(state, payload);
      }
    },
    [updateToDo.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Complete todo Async Reducers
    [toggleCompleteToDo.pending]: (state) => {
      state.loading = true;
    },
    [toggleCompleteToDo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      toggleCompleteFulfilled(state, payload);
    },
    [toggleCompleteToDo.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete todo Async Reducers
    [deleteToDo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successDelete = true;
      removeFromAll(state, payload);
    },
    [deleteToDo.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

// helper functions
// logic for placing the updated todo into the proper list
const updateToDoFulfilled = (state, payload) => {
  const today = moment();
  const date = moment(payload.remindTime).local();

  removeFromAll(state, payload);

  if (date.date() === today.date() || date < today) {
    // the updated todo goes into the daily list
    state.daily.push(payload);

    // sort back into descending order
    sortList(state, "daily");
  } else {
    // the updated todo goes into the upcoming
    state.upcoming.push(payload);

    // sort back into descending order
    sortList(state, "upcoming");
  }
};

// logic for putting toggled todo into the proper list
const toggleCompleteFulfilled = (state, payload) => {
  if (payload.completed) {
    removeFromAll(state, payload);
    // Add to completed
    state.completed.push(payload);
    sortList(state, "completed");
  } else {
    // Move back to daily or upcoming
    updateToDoFulfilled(state, payload);
  }
};

// removes specific todo from all lists in state
const removeFromAll = (state, payload) => {
  state.daily = state.daily.filter((todo) => todo._id !== payload._id);

  state.upcoming = state.upcoming.filter(
    (todo) => todo._id !== payload._id
  );

  state.completed = state.completed.filter(
    (todo) => todo._id !== payload._id
  );
};

// sorts specific list based remindTime
const sortList = (state, name) => {
  state[name] = state[name].sort(
    (a, b) => new Date(a.remindTime) - new Date(b.remindTime)
  );
};

export default todosSlice.reducer;
export {
  addToDo, deleteToDo, getCompletedToDos, getToDo, getToDos, selectToDos, toggleCompleteToDo, updateToDo
};
export const {
  resetToDos,
  resetToDo,
  resetAddToDoSuccess,
  resetDeleteToDoSuccess,
} = todosSlice.actions;
