import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to get a singular journal entry
const getJournalEntry = createAsyncThunk("get journal entry", (id, thunkAPI) =>

  actionHandler(
    //retrieve singular entry with given ID
    axiosInstance.get(`/journal/${id}`),
    thunkAPI
  )
);

// send backend request to fetch logged in users journal entries for a month
const getJournalEntriesForDate = createAsyncThunk("journal for date/", (date, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/journal?date=${date}`),
    thunkAPI
  )
);

// send backend request to fetch logged in users past 10 journal entries
const getJournalEntries = createAsyncThunk("journal/", ({ limit = 10 }, thunkAPI) =>
  actionHandler(
    // get user parameter number of entries and skip user parameter first xxx entries
    axiosInstance.get(`/journal?limit=${limit}`),
    thunkAPI
  )
);

const deleteJournalEntry = createAsyncThunk("delete/", (_id, thunkAPI) =>
  actionHandler(
    //delete journal entry with specificed ID
    axiosInstance.delete(`/journal/${_id}`),
    thunkAPI
  )
);

// send backend request to add new journal entry
const addJournalEntry = createAsyncThunk("add journal entry/", async (
  { date, emotionRating, emotions, textReview, activity, images }, thunkAPI) => {

  // Create a form and append image with additional fields
  const form = new FormData();
  images.forEach(image => {
    form.append("images", image);
  });

  form.append("date", date);
  form.append("emotionRating", emotionRating);
  form.append("emotions", JSON.stringify(emotions));
  form.append("textReview", textReview);
  form.append("activity", activity);

  actionHandler(
    axiosInstance.post("/journal", form, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }),
    thunkAPI
  );
});

// send backend request to update current journal entry
const updateJournalEntry = createAsyncThunk("update journal entry/", (
  { id, date, emotionRating, emotions, textReview, activity }, thunkAPI) =>
  actionHandler(
    //update entry with new data
    axiosInstance.put(`/journal/${id}`,
      {
        date,
        emotionRating,
        emotions,
        textReview,
        activity
      },
    ),
    thunkAPI
  )
);


export {
  addJournalEntry, deleteJournalEntry, getJournalEntries, getJournalEntriesForDate, getJournalEntry, updateJournalEntry
};

