import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to search for users.
const sendFriendRequest = createAsyncThunk("send friend request/", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/friend-request/", { toUser: id }),
    thunkAPI
  )
);

// send backend request to get friend-requests for users.
const getFriendRequests = createAsyncThunk("get friend requests/", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/friend-request/?status=pending"),
    thunkAPI
  )
);

// send backend request to accept friend request for users.
const acceptFriendRequest = createAsyncThunk("accept friend request/", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/friend-request/${id}`),
    thunkAPI
  )
);

// send backend request to delete a friend request.
const deleteFriendRequest = createAsyncThunk("delete friend request/", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/friend-request/${id}`),
    thunkAPI
  )
);

export {
  acceptFriendRequest, deleteFriendRequest,
  getFriendRequests, sendFriendRequest
};

