import {
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import BodyForm from "Components/Helpers/BodyForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Components/Pathway/OutcomeList.module.css";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectPathways,
  setAddPathwayInfo,
} from "Reducers/PathwayReducer";

const suggestions = [
  "healthy diet",
  "proper sleep schedule",
  "daily movement",
  "meditation",
  "read",
];

const OutcomeList = () => {
  const [currentText, setCurrentText] = useState("");
  const [list, setList] = useState([]);
  const [exists, setExists] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [review, setReview] = useState("no");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addPathwayInfo } = useSelector(selectPathways);

  const renderCancelModal = () => (
    <Modal className={styles.outcomeList_modal} open={isOpen}>
      <div className={styles.outcomeList_modalForm}>
        <h3>Did you miss one?</h3>
        <p>Would you like to add &quot;{currentText}&quot;?</p>
        <button
          className={`${btn.faded_orange} ${btn.btn}`}
          onClick={handleAddModal}
        >
          Yes
        </button>
        <button
          className={`${btn.faded_red} ${btn.btn}`}
          onClick={() => {
            setIsOpen(false);
            goToNextPage();
          }}
        >
          No
        </button>
      </div>
    </Modal>
  );

  const handleAddModal = () => {
    setIsOpen(false);
    const valid = validateItem(currentText);
    if (valid) {
      dispatch(
        setAddPathwayInfo({
          ...addPathwayInfo,
          list: [...list, currentText],
          review: review === "yes",
        })
      );
    }
    if (list.length === 0) {
      if (review === "yes") {
        navigate("/new-pathway?page=4");
        return;
      }

      navigate("/new-pathway?page=5");
      return;
    }

    navigate("/new-pathway?page=3&item=0");
  };

  const goToNextPage = (e) => {
    if (list.length === 0) {
      dispatch(
        setAddPathwayInfo({ ...addPathwayInfo, list, review: review === "yes" })
      );
      if (review === "yes") {
        navigate("/new-pathway?page=4");
        return;
      }
      navigate("/new-pathway?page=5");
      return;
    }
    handleAction(e, "/new-pathway?page=3&item=0");
  };

  const handleSubmitItem = (e) => {
    e?.preventDefault();

    const valid = validateItem(currentText);

    // valid so update list
    if (valid) {
      setCurrentText("");
      setList([...list, currentText]);
    }
  };

  const validateItem = (txt) => {
    // user must enter something
    if (txt === "") {
      setExists(true);
      return;
    }

    setExists(false);
    let isValid = true;

    // ensure each item is unique
    list.forEach((item) => {
      if (item === txt) {
        isValid = false;
        setExists(true);
      }
    });

    return isValid;
  };

  const handleAction = (e, url) => {
    e?.preventDefault();
    dispatch(
      setAddPathwayInfo({ ...addPathwayInfo, list, review: review === "yes" })
    );
    navigate(url);
  };

  const removeFromLists = (idx) => {
    setList(list.filter((_, index) => index !== idx));

    // remove from habit list if it exists
    if (addPathwayInfo.habits[idx] !== undefined) {
      let updatedHabits = [...addPathwayInfo.habits].filter(
        (_, index) => index !== idx
      );
      dispatch(setAddPathwayInfo({ ...addPathwayInfo, habits: updatedHabits }));
    }
  };

  const OutcomeListItem = ({ title, idx }) => {
    return (
      <div className={styles.outcomeListItem}>
        <h4>{title}</h4>
        <span onClick={() => removeFromLists(idx)}>x</span>
      </div>
    );
  };

  useEffect(() => {
    if (addPathwayInfo?.list) {
      setList(addPathwayInfo.list);
      setReview(addPathwayInfo.review ? "yes" : "no");
    }
  }, [addPathwayInfo?.list]);

  return (
    <div className={styles.outcomeList_intro}>
      <BodyForm desktop={styles.outcomeList_body}>
        {renderCancelModal()}
        <p className={styles.outcomeList_info}>
          What <span>habits</span> do you need to achieve your desired outcome?
        </p>
        {list.map((item, idx) => (
          <OutcomeListItem key={idx} title={item} idx={idx} />
        ))}
        <form className={styles.outcomeList_list} onSubmit={handleSubmitItem}>
          <TextField
            className={styles.outcomeList_textField}
            label={`Item ${list.length + 1}`}
            type="text"
            placeholder={`e.g ${suggestions[list.length % suggestions.length]}`}
            name="name"
            value={currentText}
            onChange={(e) =>
              setCurrentText(
                e.target.value.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
              )
            }
            variant="outlined"
            inputProps={{
              maxLength: 35,
            }}
            autoFocus={!isMobile}
            size="small"
            error={exists}
            helperText={exists ? "You already entered this" : ""}
          />
          <button
            type="submit"
            className={`${btn.btn} ${btn.primary} ${btn.FullWidth} ${styles.outcomeList_submit}`}
          >
            Add
          </button>
        </form>
        <p className={styles.outcomeList_info}>
          Would you like to set up <span>periodic reviews</span> of your
          pathway?
        </p>
        <RadioGroup
          row
          sx={{ alignSelf: "flex-start" }}
          value={review}
          onChange={(e) => {
            setReview(e.target.value);
          }}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        <div className={styles.outcomeList_btns}>
          <button
            onClick={(e) => handleAction(e, "/new-pathway?page=1")}
            className={`${btn.btn} ${btn.secondary}`}
          >
            Back
          </button>
          <button
            onClick={(e) => {
              if (currentText.length !== 0) {
                setIsOpen(true);
                return;
              }
              goToNextPage(e);
            }}
            className={`${btn.btn} ${btn.primary}`}
          >
            Next
          </button>
        </div>
      </BodyForm>
    </div>
  );
};
export default OutcomeList;
