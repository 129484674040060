import {
  Checkbox,
  FormControl,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import AddFab from "Components/Helpers/AddFab";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import UndoButton from "Components/Helpers/UndoButton";
import Wrapper from "Components/Helpers/Wrapper";
import Medication from "Components/Medication";
import BackBtn from "Components/Navigation/BackBtn";
import styles from "CSS/Screens/MedicationHistoryScreen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMedications,
  resetAddMedicationSuccess,
  resetDeleteMedicationSuccess,
  selectMedications,
  setActiveState,
  setTimeOfDayState,
} from "Reducers/MedicationsReducer";
import undoMedication from "Utilities/undo-medication";

const MedicationHistoryScreen = () => {
  const {
    medications,
    loading,
    error,
    successAdd,
    successDelete,
    timeOfDayState,
    activeState,
  } = useSelector(selectMedications);
  const dispatch = useDispatch();

  const [showUndo, setShowUndo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [timeOfDay, setTimeOfDay] = useState(timeOfDayState);
  const [activeStatus, setActiveStatus] = useState(activeState);

  const times = ["Morning", "Noon", "Evening"];

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new medication!");
      timer = setTimeout(() => {
        dispatch(resetAddMedicationSuccess());
      }, 2000);
    }
    return timer;
  };

  const handleSuccessDelete = () => {
    if (successDelete) {
      // show undo popup after user deletes entry
      setShowUndo(true);
      setTimeout(() => {
        setShowUndo(false);
        dispatch(resetDeleteMedicationSuccess());
      }, 5000);
    }
  };

  const handleChange = (event) => {
    setActiveStatus(event.target.value);
    dispatch(setActiveState(event.target.value));
    dispatch(getMedications({ type: event.target.value, time: timeOfDay }));
  };

  const handleTimeOfDay = (e) => {
    const val = e.target.value;
    let time;
    // if statements handle the logic of pressing the "All" button on the filter
    if (timeOfDay.includes("All") && !val.includes("All")) {
      time = [];
    } else if (timeOfDay.includes("All") && val.length <= 3) {
      val.pop(); // remove "All"
      time = val;
    } else if (val.includes("All")) {
      time = ["Morning", "Noon", "Evening", "All"];
    } else {
      if (val.sort().toString().trim() == times.sort().toString().trim()) {
        val.push("All");
      }
      time = val;
    }

    setTimeOfDay(time);
    dispatch(setTimeOfDayState(time));
    dispatch(getMedications({ type: activeStatus, time }));
  };

  useEffect(() => {
    // fetch medications
    dispatch(getMedications({ type: activeStatus, time: timeOfDay }));

    if (error) {
      setErrorMessage(error);
    }
  }, [dispatch, error]);

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();
    handleSuccessDelete();

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, successAdd, successDelete]);

  const displayList = (type) => {
    if (medications[type] && medications[type].length > 0) {
      return medications[type].map((medication) => (
        <Medication key={medication._id} medication={medication} />
      ));
    }
  };

  return (
    <Screen>
      <Wrapper>
        <BackBtn title="Back" link="/my-health-record" />
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        <AlertMessage
          message={successMessage}
          setMessage={() => {
            setSuccessMessage("Added new medication!");
            dispatch(resetAddMedicationSuccess());
          }}
          type="success"
          toggle={successAdd}
        />

        {loading && medications.active.length === 0 && <LoadingSpinner />}
        <div className={`${styles.header} ${styles.completedHeader}`}>
          <h3>🩹 Medications</h3>
        </div>
        <div className={`${styles.filterButtonGroup}`}>
          <FormControl>
            <Select
              sx={{ borderRadius: "15px" }}
              className={`${styles.filterSelect}`}
              value={activeStatus}
              onChange={handleChange}
            >
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"inactive"}>Inactive</MenuItem>
              <MenuItem value={"all"}>All</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ marginLeft: "5px" }}>
            <Select
              sx={{ borderRadius: "15px" }}
              className={`${styles.filterSelect}`}
              multiple
              value={timeOfDay}
              onChange={handleTimeOfDay}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>None</em>;
                }

                if (selected.length === 4) {
                  return "All";
                }
                return selected.join(", ");
              }}
            >
              <MenuItem value="All">
                <Checkbox
                  checked={timeOfDay.indexOf("All") > -1}
                  sx={{ marginLeft: "-10px" }}
                />
                <ListItemText primary="All" />
              </MenuItem>
              <ListSubheader>Times</ListSubheader>
              {times.map((time) => (
                <MenuItem key={time} value={time}>
                  <Checkbox
                    checked={timeOfDay.indexOf(time) > -1}
                    sx={{ marginLeft: "-10px" }}
                  />
                  <ListItemText primary={time} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {medications.inactive?.length === 0 &&
          medications.active?.length === 0 && (
            <p className={styles.filler}>No medications!</p>
          )}
        {displayList("active")}
        {displayList("inactive")}
      </Wrapper>
      <UndoButton
        callback={() => {
          setShowUndo(false);
          undoMedication(dispatch);
        }}
        showUndo={showUndo}
      />
      <AddFab url="/my-health-record/add-medication" />
    </Screen>
  );
};

export default MedicationHistoryScreen;
