import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddConditionScreen.module.css";
import { useProvider } from "Hooks/useProvider";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { addCondition, selectConditions } from "Reducers/ConditionReducer";
import { selectHealthCareProviders } from "Reducers/HealthCareProvidersReducer";

const AddConditionScreen = () => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [conditionName, setConditionName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector(selectConditions);
  const { renderAutocomplete, providerName } = useProvider(
    true,
    searchParams.get("healthCareProvider")
  );
  const { newlyAddedId } = useSelector(selectHealthCareProviders);

  const handleConditionSubmit = () => {
    dispatch(
      addCondition({
        conditionName,
        date: moment(date).utc(),
        healthCareProfessional: providerName.healthCareProviderName,
        additionalNotes,
        healthCareProvider: newlyAddedId,
      })
    );

    navigate("/my-health-record/condition-history");
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={handleConditionSubmit}>
        <div className={styles.header}>
          <Link
            to="/my-health-record/condition-history"
            className={`${btn.links} ${btn.secondaryLink}`}
          >
            Close
          </Link>
          <h3>New Condition</h3>
          <button type="submit" className={`${btn.links} ${btn.primaryLink}`}>
            Add
          </button>
        </div>

        <BodyForm desktop={styles.body}>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <TextField
            className={styles.textField}
            label="Condition"
            type="text"
            placeholder="Enter condition name"
            name="name"
            required
            value={conditionName}
            onChange={(e) => setConditionName(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Date of diagnosis"
            type="datetime-local"
            name="day"
            required
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />

          {renderAutocomplete()}

          <TextField
            className={styles.textField}
            label="Additional Notes"
            type="text"
            placeholder="Enter notes"
            name="name"
            multiline={true}
            rows={3}
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            size="small"
          />
          <button
            type="submit"
            className={`${btn.btn} ${btn.primary}`}
          >
            {loading ? "Adding..." : "Add"}
          </button>
        </BodyForm>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default AddConditionScreen;
