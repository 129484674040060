import globals from "CSS/Globals.module.css";
import { isMobile } from "react-device-detect";

const Wrapper = ({ children }) => {
  return (
    <section
      className={`${isMobile ? globals.generic_wrapperMobile : globals.generic_wrapper
        }`}
    >
      {children}
    </section>
  );
};
export default Wrapper;
