import { configureStore } from "@reduxjs/toolkit";
import AdditionalNoteReducer from "Reducers/AdditionalNoteReducer";
import BehavioralExperimentReducer from "Reducers/BehavioralExperimentReducer";
import CognitiveDistortionReducer from "Reducers/CognitiveDistortionReducer";
import FearLadderReducer from "Reducers/FearLadderReducer";
import activityReducer from "./Reducers/ActivityReducer";
import adminReducer from "./Reducers/AdminReducer";
import appointmentNoteReducer from "./Reducers/AppointmentNoteReducer";
import badgesReducer from "./Reducers/BadgesReducer";
import chatReducer from "./Reducers/ChatReducer";
import checkinReducer from "./Reducers/CheckinReducer";
import communityReducer from "./Reducers/CommunityReducer";
import conditionsReducer from "./Reducers/ConditionReducer";
import friendRequestReducer from "./Reducers/FriendRequestReducer";
import habitsReducer from "./Reducers/HabitsReducer";
import healthCareProvidersReducer from "./Reducers/HealthCareProvidersReducer";
import healthInfoReducer from "./Reducers/HealthInfoReducer";
import journalEntriesReducer from "./Reducers/JournalReducer";
import medicationsReducer from "./Reducers/MedicationsReducer";
import pathwayNoteReducer from "./Reducers/PathwayNoteReducer";
import pathwayReducer from "./Reducers/PathwayReducer";
import resetPasswordReducer from "./Reducers/ResetPasswordReducer";
import subscribeReducer from "./Reducers/SubscriptionReducer";
import teamReducer from "./Reducers/TeamReducer";
import todosReducer from "./Reducers/ToDoReducer";
import treatmentsReducer from "./Reducers/TreatmentsReducer";
import userReducer from "./Reducers/UserReducer";
import userSettingReducer from "./Reducers/UserSettingReducer";

const reducer = {
  user: userReducer,
  todos: todosReducer,
  journal: journalEntriesReducer,
  appointment: appointmentNoteReducer,
  medications: medicationsReducer,
  conditions: conditionsReducer,
  habits: habitsReducer,
  badges: badgesReducer,
  admin: adminReducer,
  pathways: pathwayReducer,
  pathwayNotes: pathwayNoteReducer,
  healthCareProviders: healthCareProvidersReducer,
  healthInfo: healthInfoReducer,
  subscription: subscribeReducer,
  treatments: treatmentsReducer,
  checkins: checkinReducer,
  activity: activityReducer,
  additionalNote: AdditionalNoteReducer,
  cognitiveDistortion: CognitiveDistortionReducer,
  behavioralExperiment: BehavioralExperimentReducer,
  community: communityReducer,
  friendRequest: friendRequestReducer,
  team: teamReducer,
  chat: chatReducer,
  userSetting: userSettingReducer,
  resetPassword: resetPasswordReducer,
  fearLadder: FearLadderReducer,
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
