import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// backend request to get all user's fear ladders
const getAllFearLadders = createAsyncThunk("get fear ladders/", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/fear-ladder/"),
    thunkAPI
  )
);

// send backend request to add new fear ladder
const addFearLadder = createAsyncThunk("add fear ladder/", (fearLadderEntry, thunkAPI) =>
  actionHandler(
    // objective, barrier, fearLevel
    axiosInstance.post("/fear-ladder/", fearLadderEntry),
    thunkAPI
  )
);

// send backend request for fear-ladder info by id.
const getFearLadder = createAsyncThunk("get fear ladder/", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/fear-ladder/${id}`),
    thunkAPI
  )
);

// send backend request to update fear ladder
const updateFearLadder = createAsyncThunk("update fear ladder/", (updatedFearLadder, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/fear-ladder/${updatedFearLadder.id}`, updatedFearLadder),
    thunkAPI
  )
);

// send backend request to create and add exposure step to fear ladder
const addExposureStep = createAsyncThunk("add exposure step to fear ladder/", ({ ladderId, title, fearLevel }, thunkAPI) =>
  actionHandler(
    axiosInstance.post(`/fear-ladder/${ladderId}/steps`,
      {
        title: title,
        fearLevel: fearLevel,
      }
    ),
    thunkAPI
  )
);

// send backend request to update exposure step by id.
const updateExposureStep = createAsyncThunk("get exposure step/", (updatedStep, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/fear-ladder/${updatedStep.ladderId}/steps/${updatedStep.stepId}`,
      updatedStep,
    ),
    thunkAPI
  )
);

// send backend request to delete exposure step by id.
const deleteExposureStep = createAsyncThunk("get exposure step/", ({ ladderId, stepId }, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/fear-ladder/${ladderId}/steps/${stepId}`),
    thunkAPI
  )
);


export {
  addExposureStep, addFearLadder, deleteExposureStep, getAllFearLadders,
  getFearLadder, updateExposureStep, updateFearLadder
};

