import TextField from "@mui/material/TextField";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/FeedbackScreen.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { selectUser } from "Reducers/UserReducer";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import axios from "../Axios";

const FeedbackScreen = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [feedback, setFeedback] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(selectUser);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (feedback === "") {
      setErrorMessage("Please enter feedback!");
      return;
    }

    try {
      setLoading(true);

      await axios.post(
        "/feedback",
        {
          feedback,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setLoading(false);
      setSuccessMessage("🎉 Thank you for sending feedback!");

      // reset after 3 seconds
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
    }
    setFeedback("");
  };

  return (
    <Screen>
      <Wrapper>
        <h1>📝 Send Feedback</h1>
        <p className={styles.feedback}>
          Submit any feature request, bug report, or general feedback
        </p>
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        <AlertMessage
          message={successMessage}
          setMessage={() => {
            setSuccessMessage("");
          }}
          type="success"
          toggle={successMessage}
        />
        <TextField
          className={styles.textField}
          label="Enter feedback"
          multiline
          rows={3.5}
          placeholder="feedback"
          autoFocus={!isMobile}
          name="review"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          inputProps={{
            maxLength: 1000,
          }}
        />
        <br />
        <button
          className={`${btn.btn} ${btn.primary}`}
          onClick={handleSubmit}
        >
          {loading ? "Sending..." : "Send"}
        </button>
      </Wrapper>
    </Screen>
  );
};
export default FeedbackScreen;
