import styles from "CSS/Screens/MyHealthRecordScreen.module.css";
import Screen from "Components/Helpers/Screen";
import ToggleCard from "Components/Helpers/ToggleCard";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import { selectUserSetting, updateBottomNavConfig } from "Reducers/UserSettingReducer";
import { useDispatch, useSelector } from "react-redux";

const BottomNavSettingScreen = () => {
  const dispatch = useDispatch();
  const { bottomNavConfig } = useSelector(selectUserSetting);

  const handleToggle = (id) => {
    dispatch(updateBottomNavConfig({ id }));
  };

  return (
    <Screen>
      <Wrapper>
        <BackBtn title="Back to Account Settings" link="/setting/account" />
        <div className={styles.myHealthRecordScreen}>
          <h3>Select order for bottom navigation</h3>
          {
            bottomNavConfig != null && bottomNavConfig != undefined && (
              bottomNavConfig.map((item, index) => (
                <ToggleCard
                  key={index}
                  cardLabel={item.content}
                  isChecked={item.isActive}
                  onChange={() => handleToggle(item.id)}
                />
              ))
            )
          }
        </div>
      </Wrapper>
    </Screen>
  );
};

export default BottomNavSettingScreen;
