import { Button, Grid } from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import FearLadder from "Components/Toolkit/FearLadder";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllFearLadders, resetSelectedFearLadder, selectFearLadders } from "Reducers/FearLadderReducer";

const FearLaddersHomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    error,
    loading,
    fearLadders
  } = useSelector(selectFearLadders);

  const [errorMessage, setErrorMessage] = useState("");

  const handleStartNewFearLadder = () => {
    navigate("/toolkit/fear-ladder/add");
  };

  useEffect(() => {
    dispatch(getAllFearLadders()).then(() => {
      dispatch(resetSelectedFearLadder());
    });
    if (error) {
      setErrorMessage(error);
    }
  }, [dispatch, error]);

  return (
    <Screen>
      <Wrapper>
        <BackBtn title="Back to toolkit" link="/toolkit" />
        <h1>Fear Ladders</h1>
        <Grid container
          spacing={0}
          direction="row"
          justify="center"
          marginTop={"1em"}>
          <Grid
            item
            xs={10}
            justifyContent="center"
            alignItems="center"
            display={"flex"}>

            <AlertMessage
              message={errorMessage}
              setMessage={() => setErrorMessage("")}
              type="error"
              toggle={errorMessage}
            />

          </Grid>
        </Grid>
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" xs={11} item>

          <Grid item xs={12}>
            <Button sx={{
              backgroundColor: "var(--blue-500)", borderRadius: 5
            }} fullWidth variant="contained" onClick={handleStartNewFearLadder}>Start New</Button>
          </Grid>

          {/* Display all fear ladders */}

          {(fearLadders && fearLadders?.length > 0) && fearLadders.map((ladder) => {
            return (
              <Grid key={ladder._id} item xs={12}>
                <FearLadder key={ladder._id} ladder={ladder} />
              </Grid>
            );
          })}

        </Grid>
        {loading && <LoadingSpinner />}
      </Wrapper>
    </Screen>
  );
};

export default FearLaddersHomeScreen;
