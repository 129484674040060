import globals from "CSS/Globals.module.css";
import styles from "CSS/Widgets/ToDosWidget.module.css";
import moment from "moment";
import { FiRepeat } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getPathway } from "Reducers/PathwayReducer";
import { toggleCompleteToDo } from "Reducers/ToDoReducer";

const IndividualWidgetToDo = ({ todo, type }) => {
  // const { badges } = useSelector(selectBadges);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const toggleToDo = (todo) => {
    // get todos badge
    // const badge = badges.find((badge) => badge.name === "ToDo");

    // // increment progress if completing
    // if (!todo.completed) {
    //   addToBadge(badge, dispatch, badge.progress + 1);
    // } else {
    //   // remove one from progress when uncompleting
    //   addToBadge(badge, dispatch, badge.progress - 1);
    // }
    dispatch(
      toggleCompleteToDo({
        id: todo._id,
        completedDate: todo.completed ? null : new Date(),
        completed: !todo.completed,
      })
    );

    if (todo.pathway) {
      dispatch(getPathway(todo.pathway));
    }
  };
  return (
    <div key={todo._id} className={styles.toDosWidget_toDo}>
      <button
        onClick={() => {
          toggleToDo(todo);
        }}
      />
      <div className={styles.toDosWidget_toDoWrapper}>
        <Link
          className={styles.toDosWidget_toDoTitle}
          to={`/todos/${todo._id}?redirect=${pathname}`}
        >
          {todo.title.length > 22
            ? `${todo.title.substring(0, 22)}...`
            : todo.title}
        </Link>
        <span
          className={`${styles.toDosWidget_date}
            ${
            // turn date text red if todo is past due
            todo.isFullDay
              ? moment(todo.remindTime).local().isBefore(moment(), "day")
                ? globals.errorMessage
                : ""
              : moment(todo.remindTime).local().isBefore(moment())
                ? globals.errorMessage
                : ""
            }
            `}
        >
          {/* render on todos widget */}
          {type === "widget" &&
            (todo.isFullDay
              ? "All Day"
              : moment(todo.remindTime)
                .local()
                .format("hh:mm A")
                .replace(/(^|-)0+/g, ""))}

          {/* renders on individual pathway */}
          {type === "pathway" &&
            moment(todo.remindTime).local().format("MMM DD")}

          {todo.recurrenceType !== "once" && (
            <FiRepeat className={styles.toDosWidget_repeat} size={12} />
          )}
        </span>
      </div>
    </div>
  );
};
export default IndividualWidgetToDo;
