import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import styles from "CSS/Components/MuiMenu.module.css";
import * as React from "react";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "2px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 14,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1),
            },
            "&:active": {
                backgroundColor: "rgba(242, 114, 84, 0.2)",
            },
        },
    },
}));

export default function CompleteIncompleteFilter({ handleFilterMenuChange }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedFilter, setSelectedFilter] = React.useState("View");
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilterSelection = (type, value) => {
        setSelectedFilter(type);
        handleFilterMenuChange({ type, value });
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                style={{
                    height: "48px",
                    backgroundColor: "rgba(242, 114, 84, 0.2)",
                    borderRadius: "15px",
                    textTransform: "none",
                    color: "#f27254",
                    fontWeight: 600,
                }}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {selectedFilter}
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleFilterSelection("Complete")} disableRipple>
                    <div className={`${styles.indicationIcon} ${styles.levelEasy}`} />
                    Complete
                </MenuItem>
                <MenuItem onClick={() => handleFilterSelection("Incomplete")} disableRipple>
                    <div className={`${styles.indicationIcon} ${styles.levelMedium}`} />
                    Incomplete
                </MenuItem>
                <MenuItem onClick={() => handleFilterSelection("View")} disableRipple>
                    <div className={`${styles.indicationIcon} ${styles.levelMedium}`} />
                    Clear Filters
                </MenuItem>
            </StyledMenu>
        </div>
    );
}


