import { addTreatment } from "Reducers/TreatmentsReducer";

const undoTreatment = (dispatch) => {
  const treatment = JSON.parse(localStorage.getItem("tempTreatment"));

  dispatch(
    addTreatment({
      treatmentName: treatment.treatmentName,
      additionalNotes: treatment.additionalNotes,
    })
  );
};

export default undoTreatment;
