import { createSlice } from "@reduxjs/toolkit";
import {
  addHabitToTeam,
  addMembersToTeam,
  createTeam,
  createTeamPost,
  getTeamById,
  getTeamPosts,
  getTeams,
  searchTeams
} from "./Actions/TeamActions";

const initialState = {
  loading: false,
  error: null,
  successAdd: false,
  successDelete: false,
  teams: [],
  selectedTeam: null,
  teamToCreate: {
    name: "",
    description: "",
    isPublic: true,
    icon: "T",
    members: [],
    habits: [],
  },
  teamSearchResult: [],
  teamPosts: [],
};

// functions for "useSelector"
const selectTeam = (state) => state.team;

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    resetTeam: () => initialState,
    setTeamToCreateInfo: (state, { payload }) => {
      state.teamToCreate = { ...state.teamToCreate, ...payload };
    },
    setTeamToCreateMembers: (state, { payload }) => {
      const updatedMemberList = payload.map((id) => ({ user: id }));
      state.teamToCreate = { ...state.teamToCreate, members: updatedMemberList };
    },
    setTeamToCreateHabit: (state, { payload }) => {
      state.loading = true;
      state.teamToCreate = { ...state.teamToCreate, habits: payload };
      state.loading = false;
    },
    resetTeamToCreate: (state) => {
      state.teamToCreate = {
        name: "",
        description: "",
        isPublic: true,
        icon: "T",
        habits: [],
        members: [],
      };
    },
  },
  extraReducers: {
    // Add Community Async Reducers
    [createTeam.pending]: (state) => {
      state.loading = true;
    },
    [createTeam.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
    },
    [createTeam.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Teams for a user Async Reducers
    [getTeams.pending]: (state) => {
      state.loading = true;
    },
    [getTeams.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.teams = payload;
      state.error = null;
    },
    [getTeams.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Search teams by name.
    [searchTeams.pending]: (state) => {
      state.loading = true;
    },
    [searchTeams.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.teamSearchResult = payload;
      state.error = null;
    },
    [searchTeams.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Teams for a user Async Reducers
    [getTeamById.pending]: (state) => {
      state.loading = true;
    },
    [getTeamById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.selectedTeam = payload;
      state.error = null;
    },
    [getTeamById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add members to team Async Reducers.
    [addMembersToTeam.pending]: (state) => {
      state.loading = true;
    },
    [addMembersToTeam.fulfilled]: (state, { payload }) => {
      state.loading = false;
      console.log(payload);
      state.error = null;
    },
    [addMembersToTeam.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add habit to team Async Reducers.
    [addHabitToTeam.pending]: (state) => {
      state.loading = true;
    },
    [addHabitToTeam.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.selectedTeam.habits.push(payload.newHabit);
      state.error = null;
    },
    [addHabitToTeam.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Create team post Async Reducers.
    [createTeamPost.pending]: (state) => {
      state.loading = true;
    },
    [createTeamPost.fulfilled]: (state, { payload }) => {
      payload.user = {};
      let user = localStorage.getItem("userData");
      if (user) {
        let parsedUser = JSON.parse(user);
        payload.user.profilePicture = parsedUser.profilePicture;
        payload.user.firstName = parsedUser.firstName;
      } else {
        payload.user.profilePicture = "";
      }

      state.teamPosts = [payload, ...state.teamPosts];
      state.error = null;
      state.loading = false;
    },
    [createTeamPost.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get all team post Async Reducers.
    [getTeamPosts.pending]: (state) => {
      state.loading = true;
    },
    [getTeamPosts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.teamPosts = payload;
      state.error = null;
    },
    [getTeamPosts.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default teamSlice.reducer;

export {
  createTeam, getTeamById, getTeams, selectTeam
};

export const {
  resetTeam,
  setTeamToCreateInfo,
  setTeamToCreateMembers,
  setTeamToCreateHabit,
  resetTeamToCreate,
} = teamSlice.actions;
