import { Alert } from "@mui/material";

const AlertMessage = ({ message, setMessage, type, toggle, styles }) =>
  toggle && (
    <Alert
      sx={{
        width: "80%",
        marginBottom: "10px",
        marginTop: "10px",
        ...styles,
      }}
      severity={type}
      onClose={() => setMessage()}
    >
      {message}
    </Alert>
  );
export default AlertMessage;
