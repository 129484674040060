import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import btn from "CSS/Button.module.css";
import pathwayStyle from "CSS/Screens/PathwayTutorialScreen.module.css";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { useRedirect } from "Hooks/useRedirect";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const QuickCheckTutorialScreen = () => {
  const navigate = useNavigate();
  const redirectUrl = useRedirect("/toolkit");
  const [currentStep, setCurrentStep] = useState(0);

  const tutorials = [
    {
      image: "/images/tutorial/checkin/checkin_step1.gif",
      title: "Perform Pre-Checkin",
      info: "Enter how you feel before you start a toolkit function",
      isFinal: false,
    },
    {
      image: "/images/tutorial/checkin/checkin_step2.png",
      title: "Perform Function",
      info: "Enjoy performing your function such as body scan, breath work or butterfly hug",
      isFinal: false,
    },
    {
      image: "/images/tutorial/checkin/checkin_step1.gif",
      title: "Perform Post-Checkin",
      info: "Enter how you feel after you finish a toolkit function",
      isFinal: true,
    },
  ];

  const handleNext = () => {
    setCurrentStep((prevStep) => {
      if (prevStep + 1 > (tutorials.length - 1))
        return prevStep;

      return (prevStep + 1);
    });
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => {
      if (prevStep - 1 < 0)
        return prevStep;

      return (prevStep - 1);
    });
  };

  const handleCompletedClick = () => {
    navigate("/toolkit");
  };

  return (
    <Screen>
      <Wrapper>
        <Grid
          container
          direction="row"
          spacing={0}
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "2em" }}>
          <Grid xs={10} item>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={currentStep} alternativeLabel>
                {tutorials.map((tutorial, index) => (
                  <Step key={index}>
                    <StepLabel>{tutorial.title}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
          <Grid xs={10} item style={{ marginTop: "2em" }}>
            <Grid
              container
              direction="row"
              spacing={0}
              justifyContent="center"
              alignItems="center">
              <Grid xs={1} item onClick={handlePrev}>
                <NavigateBeforeIcon />
              </Grid>
              <Grid xs={10} item>
                {
                  tutorials.map((tutorial, index) => (
                    currentStep === index &&
                    (
                      <div key={index}>
                        <Grid
                          container
                          direction="row"
                          spacing={0}>
                          <Grid
                            xs={12}
                            item
                            justifyContent="center"
                            alignItems="center"
                            style={{ display: "flex" }}>
                            <img src={tutorial.image}
                              style={{ borderRadius: "10px" }}
                              className={pathwayStyle.tutorialVideo}
                              loading="lazy"></img>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          spacing={0}
                          style={{ marginTop: "2em" }}>
                          <Grid
                            xs={12}
                            item
                            style={{ textAlign: "center" }}>
                            <Typography variant="h6" gutterBottom>
                              {tutorial.title}
                            </Typography>
                          </Grid>
                          <Grid
                            xs={12}
                            item
                            style={{ textAlign: "center" }}>
                            <Typography variant="body1" gutterBottom>
                              {tutorial.info}
                            </Typography>
                          </Grid>
                        </Grid>
                        {
                          tutorial.isFinal && (
                            <Grid
                              container
                              direction="row"
                              spacing={0}>
                              <Grid
                                xs={12}
                                item
                                justifyContent="center"
                                alignItems="center"
                                style={{ display: "flex", marginTop: "1em" }}>
                                <button
                                  className={`${btn.btn} ${btn.faded_orange}`}
                                  onClick={handleCompletedClick}
                                >
                                  Go back
                                </button>
                              </Grid>
                            </Grid>
                          )
                        }
                      </div>
                    )
                  ))
                }
              </Grid>
              <Grid xs={1} item onClick={handleNext}>
                <NavigateNextIcon />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={10}
            item
            style={{ textAlign: "center", marginTop: "1em" }}>
            <Link
              to={redirectUrl}
              className={`${btn.backBtn}`}>
              Cancel
            </Link>
          </Grid>
        </Grid>
      </Wrapper>
    </Screen >
  );
};

export default QuickCheckTutorialScreen;
