import styles from "CSS/Widgets/HabitsWidget.module.css";
import { useHabitProgress } from "Hooks/useHabitProgress";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { Link, useLocation } from "react-router-dom";
import { convertScale } from "Utilities/adjust-scale";

const IndividualWidgetHabit = ({ habit }) => {
  const { pathname } = useLocation();
  const { progress, increaseProgress } = useHabitProgress(habit);

  return (
    <div className={styles.habitsWidget_habit}>
      <Link
        to={`/habits/${habit._id}?redirect=${pathname}`}
        className={styles.habitsWidget_habitText}
      >
        <h3>{habit.name}</h3>
        <span>
          Goal: {progress}/{habit.unitAmount}{" "}
          {habit.unit === "count" ? "" : habit.unit}
        </span>
      </Link>
      <div className={styles.habitsWidget_progress} onClick={increaseProgress}>
        <CircularProgressbarWithChildren
          className={styles.habitsWidget_progressBar}
          value={convertScale(progress, habit.unitAmount)}
          styles={buildStyles({
            rotation: 0.25,
            strokeLinecap: "round",
            pathTransitionDuration: 0.3,
            pathColor: `rgba(11, 126, 161, ${convertScale(progress, habit.unitAmount) / 100
              })`,
            trailColor: "#d6d6d6",
            backgroundColor: "#0b7ea1",
          })}
        >
          <p className={styles.habitsWidget_progressText}>
            {convertScale(progress, habit.unitAmount)}%
          </p>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
};

export default IndividualWidgetHabit;
