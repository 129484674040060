import { Modal, TextField } from "@mui/material";
import EmotionsSelector from "Components/Selectors/EmotionsSelector";
import btn from "CSS/Button.module.css";
import styles from "CSS/Components/AddEmotionModal.module.css";
import { useState } from "react";
import defaultEmotions from "Utilities/emotions";
import { handleAddEmotionSubmit } from "Utilities/emotions-selector";

const useEmotionSelector = (customEmotions) => {
  const [newEmotion, setNewEmotion] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [emotions, setEmotions] = useState(customEmotions !== undefined ? customEmotions : defaultEmotions);

  const renderModal = () => (
    <Modal className={styles.emotion_modal} open={isOpen}>
      <div className={styles.emotion_modalForm}>
        <h3>Add Tag</h3>
        <TextField
          label="Tag"
          type="text"
          placeholder="energetic"
          name="title"
          value={newEmotion}
          onChange={(e) => setNewEmotion(e.target.value)}
          variant="standard"
          required
          inputProps={{
            maxLength: 50,
          }}
        />
        <div className={btn.groupRow}>
          <button
            className={`${btn.btn} ${btn.secondary}`}
            onClick={() => {
              setIsOpen(false);
              setNewEmotion("");
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className={`${btn.btn} ${btn.primary}`}
            onClick={() => {
              setEmotions(handleAddEmotionSubmit(newEmotion, emotions));
              setIsOpen(false);
              setNewEmotion("");
            }}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );

  const renderSelector = () => (
    <EmotionsSelector
      emotions={emotions}
      setEmotions={(val) => setEmotions(val)}
      handleAddEmotion={() => setIsOpen(true)}
    />
  );

  return { emotions, setEmotions, renderModal, renderSelector };
};

export default useEmotionSelector;
