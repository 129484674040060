import CircularSlider from "@fseehawer/react-circular-slider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import useEmotionSelector from "Hooks/useEmotionSelector";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addActivityEntry } from "Reducers/Actions/ActivityActions";

const ActivityCompletionScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dateTime, setDateTime] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [emotionRating, setEmotionRating] = useState(0);
  const { emotions, renderSelector } = useEmotionSelector([
    { label: "Happy", selected: false },
    { label: "Sad", selected: false },
  ]);
  const [textReview, setTextReview] = useState("");
  const [isCompleted, setIsCompleted] = useState(true);

  const handleManageQuestionSelection = (answer) => {
    if (answer === "No") {
      setIsCompleted(false);
    } else {
      setIsCompleted(true);
    }
  };

  const handleSave = () => {
    dispatch(
      addActivityEntry({
        isCompleted,
        emotionRating,
        emotions,
        textReview,
        activity: id,
        date: moment(dateTime).utc(),
      })
    );
    navigate("/activities");
  };

  return (
    <>
      <Screen>
        <Wrapper>
          <div>
            <h1>Post Activity Checkin</h1>
          </div>
          <div style={{ marginTop: "1em" }}>
            <TextField
              type="datetime-local"
              name="day"
              required={true}
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div style={{ marginTop: "2em" }}>
            <Card sx={{ minWidth: 275, borderRadius: "15px" }}>
              <CardContent style={{ paddingBottom: "1rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
                <p style={{ textAlign: "center" }}>
                  Did you manage your activity?
                </p>
                <div div style={{ display: "flex", gap: "1rem" }}>
                  <button
                    style={{
                      backgroundColor:
                        isCompleted === true
                          ? "rgba(242, 114, 84, 1)"
                          : "rgba(242, 114, 84, 0)",
                    }}
                    className={`${btn.btn} ${btn.primary}`}
                    onClick={() => handleManageQuestionSelection("Yes")}
                  >
                    Yes
                  </button>
                  <button
                    style={{
                      backgroundColor:
                        isCompleted === false
                          ? "rgba(11, 126, 161, 1)"
                          : "rgba(11, 126, 161, 0)",
                      color:
                        isCompleted === false
                          ? "rgba(256, 256, 256, 1)"
                          : "rgba(0, 0, 0, 1)",
                    }}
                    className={`${btn.btn} ${btn.secondary}`}
                    onClick={() => handleManageQuestionSelection("No")}
                  >
                    No
                  </button>
                </div>
              </CardContent>
            </Card>
          </div>
          <div style={{ marginTop: "2em", textAlign: "center" }}>
            <p>How are you feeling after this activity?</p>
            <div style={{ marginTop: "1em" }}>
              <CircularSlider
                label="How are you feeling?"
                type="number"
                labelColor="#107ca4"
                knobColor="#009c9a"
                progressColorFrom="rgba(11, 126, 161, 0.4)"
                progressColorTo="rgba(11, 126, 161, 1)"
                trackColor="#eeeeee"
                data={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]} //...
                min={0}
                max={10}
                dataIndex={0}
                labelBottom={true}
                labelFontSize="0.7rem"
                valueFontSize="1rem"
                onChange={(e) => setEmotionRating(e)}
                value={emotionRating}
                width={140} //og 280
                knobSize={32} //og 50
                trackSize={6} //og 24
                progressSize={12} //og 24
              />
            </div>
            {renderSelector()}

            <TextField
              style={{ width: "80%", marginTop: "1em" }}
              label="Enter additional notes"
              type="text"
              multiline
              rows={3.5}
              placeholder="How are you feeling?"
              name="review"
              required={false}
              onChange={(e) => setTextReview(e.target.value)}
              value={textReview}
              variant="outlined"
              inputProps={{
                maxLength: 500,
              }}
            />
          </div>
          <div style={{ width: "80%" }}>
            <Grid container spacing={0}>
              <Grid item={true} xs={6} style={{ textAlign: "center" }}>
                <button
                  style={{ width: "80%", margin: "0px", marginTop: "1em" }}
                  className={`${btn.btn} ${btn.secondary}`}
                >
                  Cancel
                </button>
              </Grid>
              <Grid item={true} xs={6} style={{ textAlign: "center" }}>
                <button
                  style={{ width: "80%", margin: "0px", marginTop: "1em" }}
                  className={`${btn.btn} ${btn.primary}`}
                  onClick={handleSave}
                >
                  Add
                </button>
              </Grid>
            </Grid>
          </div>
        </Wrapper>
      </Screen>
    </>
  );
};

export default ActivityCompletionScreen;
