import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Avatar, Box, Grid, IconButton, Paper, Stack, Tab, Tabs } from "@mui/material";
import btn from "CSS/Button.module.css";
import communityStyle from "CSS/Screens/CommunityScreen.module.css";
import styles from "CSS/Screens/TeamScreen.module.css";
import CommunityPost from "Components/Community/CommunityPost";
import HabitSelectionDialog from "Components/Community/HabitSelectionDialog";
import AddFab from "Components/Helpers/AddFab";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import { COMMUNITY_TEAM_ICON_VALUE_TO_ICON_MAP, URLS } from "Constants/Constants";
import { addMembersToTeam, getTeamById, getTeamPosts } from "Reducers/Actions/TeamActions";
import { selectTeam } from "Reducers/TeamReducer";
import { selectUser } from "Reducers/UserReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

const TeamScreen = () => {
  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [isHabitDialogOpen, setIsHabitDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector(selectUser);
  const navigate = useNavigate();
  const { selectedTeam, teamPosts, loading } = useSelector(selectTeam);

  // Fetch team details and team posts when component mounts
  useEffect(() => {
    dispatch(getTeamById(id));
    dispatch(getTeamPosts(id));
  }, [dispatch]);

  // Close habit dialog when selected team changes
  useEffect(() => {
    setIsHabitDialogOpen(false);
  }, [selectedTeam]);

  // Navigate to add community friends page with teamId as a parameter
  const handleAddFriend = () => {
    navigate(`${URLS.COMMUNITY_ADD_COMMUNITY_FRIENDS}?backUrl=${URLS.TEAM}/${id}&teamId=${id}`);
  };

  // Add user to the team and fetch updated team details
  const handleTeamJoin = () => {
    dispatch(addMembersToTeam({ id, members: [user._id] }))
      .then(() => dispatch(getTeamById(id)));
  };

  // Open the habit dialog
  const handleAddHabitScreen = () => {
    setIsHabitDialogOpen(true);
  };

  // Handle tab change
  const handleTabChange = (_, tabIndex) => {
    setTabIndex(tabIndex);
  };

  const displayFeed = () => {
    return (
      <>
        <Stack style={{ marginTop: "1em", width: "100%" }} spacing={1}>
          {
            loading && (<LoadingSpinner />)
          }
          {
            teamPosts.map(post => (
              <div key={post._id}>
                <CommunityPost post={post} />
              </div>
            ))
          }
        </Stack>
      </>
    );
  };

  const displayGoals = () => {
    return (
      <>
        <Grid
          container
          spacing={2}
          style={{ marginTop: "1em" }}>
          {
            selectedTeam?.habits.map((habit) => (
              <Grid item xs={6} key={habit._id}>
                <Paper elevation={2} className={`${styles.goalCard}`}>
                  <h4>{habit.name}</h4>
                </Paper>
              </Grid>
            ))
          }
          <Grid item xs={6}>
            <Paper elevation={2} className={`${styles.goalCard}`}>
              <IconButton onClick={handleAddHabitScreen}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleToggleDisplay = () => {
    if (tabIndex === 0) {
      return displayFeed();
    }
    if (tabIndex === 1) {
      return displayGoals();
    }
  };

  const handleMembersClick = () => {
    if (selectedTeam.isMember) {
      navigate(`${URLS.TEAM}/members`);
    }
  };

  return (
    <>
      <ScreenNoNav>
        {(<HabitSelectionDialog
          open={isHabitDialogOpen}
          handleClose={() => setIsHabitDialogOpen(false)}
          teamId={id} />)}
        <Grid
          style={{ marginTop: "2em", marginBottom: "10px" }}
          container
          xs={10}
          item={false}
          justifyContent="flex-start">
          <Grid item xs={4}>
            <Link
              to={URLS.COMMUNITY}
              className={`${communityStyle.btn} ${communityStyle.btn_orange}`}
            >
              Back
            </Link>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "1em", marginBottom: "10px" }}
          container
          xs={10}
          item={false}>
          <Grid
            container
            xs={12}
            item
            style={{ marginTop: "1em" }}>
            <Grid container item justifyContent="flex-start"
              alignContent="center" xs={4}>
              <Avatar sx={{ width: 70, height: 70, bgcolor: "rgba(25, 117, 146, 0.2)" }}>
                <span style={{ fontSize: "50px" }}>
                  {selectedTeam?.icon ?
                    COMMUNITY_TEAM_ICON_VALUE_TO_ICON_MAP[selectedTeam.icon] :
                    COMMUNITY_TEAM_ICON_VALUE_TO_ICON_MAP["T"]}
                </span>
              </Avatar>
            </Grid>
            <Grid container justifyContent="flex-start"
              alignContent="center" xs={8}>
              <Stack spacing={1}>
                <h3>{selectedTeam?.name}</h3>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handleMembersClick}>
                  <span style={{ fontWeight: "bold" }}>
                    {selectedTeam?.members.length}
                  </span> Here
                </div>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            style={{ marginTop: "1em" }}>
            <p>
              {
                selectedTeam?.description
                  && selectedTeam?.description.length > 70 ?
                  `${selectedTeam?.description.slice(0, 70)}...`
                  : selectedTeam?.description
              }
            </p>
          </Grid>
          <Grid
            container
            xs={12}
            style={{ marginTop: "1em" }}>
            <Grid container item justifyContent="flex-start"
              alignContent="center" xs={6}>
              {
                selectedTeam?.isMember && (
                  <button
                    className={`${btn.btn} ${btn.faded_blue}`}
                    onClick={handleAddFriend}
                    style={{ padding: "8px", fontSize: "15px" }}>
                    Add friends
                  </button>
                )
              }
              {
                !selectedTeam?.isMember && (
                  <button
                    className={`${btn.btn} ${btn.faded_blue}`}
                    onClick={handleTeamJoin}
                    style={{ padding: "8px", fontSize: "15px" }}>
                    Join
                  </button>
                )
              }

            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "1em", marginBottom: "10px" }}
          container
          xs={12}
          item={false}>
          <Box sx={{ width: "100%", borderBottom: 1, mt: 1, borderColor: "divider" }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="fullWidth"
              centered>
              <Tab sx={{ width: "50%" }} label="Posts" />
              <Tab sx={{ width: "50%" }} label="Goals" />
            </Tabs>
          </Box>
        </Grid>
        <Grid
          style={{ marginTop: "1em", marginBottom: "10px" }}
          container
          xs={11}
          item={false}>
          {handleToggleDisplay()}
          <AddFab url={`${URLS.TEAM}/${id}/add-post`} />
        </Grid>
      </ScreenNoNav>
    </>
  );
};

export default TeamScreen;
