import IndividualWidgetToDo from "Components/ToDo/IndividualWidgetToDo";
import styles from "CSS/Widgets/ToDosWidget.module.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getToDos, selectToDos } from "Reducers/ToDoReducer";

const ToDosWidget = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { daily } = useSelector(selectToDos);

  useEffect(() => {
    if (daily?.length === 0) {
      dispatch(getToDos());
    }
  }, [dispatch]);

  return (
    <section
      data-testid="todosWidget"
      className={styles.toDosWidget_container}
    >
      <h3 data-testid="todosWidget-title">⏰ Today&apos;s To-Do&apos;s</h3>
      <div className={styles.toDosWidget_wrapper}>
        {daily?.map((todo) => (
          <IndividualWidgetToDo
            key={todo._id}
            todo={todo}
            type="widget"
          />
        ))}
        <Link
          to={`/add-todo?redirect=${pathname}`}
          className={styles.toDosWidget_fillerButton}
          data-testid="todosWidget-addToDo"
        >
          <span className={styles.toDosWidget_fillerPlus}>+</span>Add daily
          to-do
        </Link>
      </div>
    </section>
  );
};

export default ToDosWidget;
