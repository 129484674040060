import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/CognitiveDistortionsScreen.module.css";
import { useRedirect } from "Hooks/useRedirect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectCognitiveDistortion, setSituation, setThoughts } from "Reducers/CognitiveDistortionReducer";

const CognitiveDistortionsThoughtScreen = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectUrl = useRedirect("/toolkit/cognitive-distortions");
  const { thoughts, situation } = useSelector(selectCognitiveDistortion);

  const handleNextClick = () => {
    navigate("/toolkit/distortions");
  };

  return (
    <Screen>
      <Wrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid xs={10} item>
            <Link
              to={redirectUrl}
              className={`${styles.backBtn}`}>
              Back
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}
          item={true}>
          <Grid xs={10} item>
            <div>
              <p>What is the situation?</p>
              <TextField
                className={styles.textarea}
                label=""
                type="text"
                multiline
                rows={3.5}
                placeholder=""
                name="review"
                required={false}
                onChange={(e) => dispatch(setSituation(e.target.value))}
                value={situation}
                variant="outlined"
                inputProps={{
                  maxLength: 500,
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "2em" }}
          item={true}>
          <Grid xs={10} item>
            <div>
              <p>What are my thoughts?</p>
              <TextField
                className={styles.textarea}
                label=""
                type="text"
                multiline
                rows={3.5}
                placeholder=""
                name="review"
                required={false}
                onChange={(e) => dispatch(setThoughts(e.target.value))}
                value={thoughts}
                variant="outlined"
                inputProps={{
                  maxLength: 500,
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ marginTop: "2em", width: "80%" }}
          item={true}>
          <Grid item={true} xs={4}></Grid>
          <Grid
            item={true}
            xs={4}
            className={`${styles.rightAlign}`}>
            <button
              className={`
              ${btn.faded_blue} 
              ${btn.smallActivityBtn} 
              ${styles.loadMoreBtn}`}
              onClick={handleNextClick}>
              Next
            </button>
          </Grid>
        </Grid>
      </Wrapper>
    </Screen>
  );
};

export default CognitiveDistortionsThoughtScreen;
