import moment from "moment";
import { addToDo } from "Reducers/ToDoReducer";

const undoToDo = (dispatch) => {
  const todo = JSON.parse(localStorage.getItem("tempToDo"));

  dispatch(
    addToDo({
      todoTitle: todo.title,
      remindTime: moment(todo.remindTime).utc(),
      completed: todo.completed,
      completedDate: moment(todo.completedDate).utc(),
      isFullDay: todo.isFullDay,
      recurrenceType: todo.recurrenceType,
    })
  );
};

export default undoToDo;
