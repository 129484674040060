import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/BehavioralExperimentEntry.module.css";
import { useRedirect } from "Hooks/useRedirect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { updateExperiment, resetBehavioralExperiment, selectBehavioralExperiment } from "Reducers/BehavioralExperimentReducer";

const BehavioralSaveEditScreen = () => {
  const redirectUrl = useRedirect("/toolkit/behavioral-entries");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedExperiment } = useSelector(selectBehavioralExperiment);
  const id = selectedExperiment._id;
  const {
    thoughts,
    details,
    prediction,
    date,
    feeling,
  } = useSelector(selectBehavioralExperiment);

  const handleSaveClick = () => {
    dispatch(updateExperiment({
      id,
      date,
      feeling,
      prediction,
      thoughts,
      details,
      complete: false
    }));
    dispatch(resetBehavioralExperiment());
    navigate("/toolkit/behavioral-experiments");
  };

  const handleEditClick = () => {
    navigate("/toolkit/behavioral-entries");
  };

  return (
    <Screen>
      <Wrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid xs={10} item>
            <Link
              to={redirectUrl}
              className={`${styles.backBtn}`}>
              Back
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid
            xs={10}
            item
            justifyContent="center"
            alignItems="center"
            className={`${styles.flexDisplay}`} >
            <h3>Review Behavioral Experiment</h3>
          </Grid>
        </Grid>
        <Grid
          className={`${styles.marginTop}`}
          container
          direction="row"
          alignItems="center"
          justifyContent="center">
          <Grid xs={10} item>
            <Card
              variant="outlined"
              sx={{ minWidth: "100%" }}
              className={styles.paper}>
              <CardContent>
                <Grid container direction="row">
                  <Grid
                    xs={9}
                    item
                    style={{ display: "flex", fontSize: "0.9em" }}
                    alignItems="center">
                    <p>{thoughts}</p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          className={`${styles.marginTop}`}
          container
          direction="row"
          alignItems="center"
          justifyContent="center">
          <Grid xs={10} item>
            <Card
              variant="outlined"
              sx={{ minWidth: "100%" }}
              className={styles.paper}>
              <CardContent>
                <Grid container direction="row">
                  <Grid
                    xs={9}
                    item
                    style={{ display: "flex", fontSize: "0.9em" }}
                    alignItems="center">
                    <p>{details}</p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          className={`${styles.marginTop}`}
          container
          direction="row"
          alignItems="center"
          justifyContent="center">
          <Grid xs={10} item>
            <Card
              variant="outlined"
              sx={{ minWidth: "100%" }}
              className={styles.paper}>
              <CardContent>
                <Grid container direction="row">
                  <Grid
                    xs={9}
                    item
                    style={{ display: "flex", fontSize: "0.9em" }}
                    alignItems="center">
                    <p>{date}</p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          className={`${styles.marginTop}`}
          container
          direction="row"
          alignItems="center"
          justifyContent="center">
          <Grid xs={10} item>
            <Card
              variant="outlined"
              sx={{ minWidth: "100%" }}
              className={styles.paper}>
              <CardContent>
                <Grid container direction="row">
                  <Grid
                    xs={9}
                    item
                    style={{ display: "flex", fontSize: "0.9em" }}
                    alignItems="center">
                    <p>{prediction}</p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          className={`${styles.marginTop}`}
          container
          direction="row"
          alignItems="center"
          justifyContent="center">
          <Grid xs={10} item>
            <Card
              variant="outlined"
              sx={{ minWidth: "100%" }}
              className={styles.paper}>
              <CardContent>
                <Grid container direction="row">
                  <Grid
                    xs={9}
                    item
                    style={{ display: "flex", fontSize: "0.9em" }}
                    alignItems="center">
                    <p>{feeling}</p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginTop: "2em" }}>
          <Grid item xs={4}>
            <button
              className={`
                ${btn.faded_orange} 
                ${btn.smallActivityBtn} 
                ${styles.loadMoreBtn}`}
              onClick={handleEditClick}>
              Edit
            </button>
          </Grid>
          <Grid item xs={4} className={`${styles.rightAlign}`}>
            <button
              className={`
                ${btn.faded_blue} 
                ${btn.smallActivityBtn} 
                ${styles.loadMoreBtn}`}
              onClick={handleSaveClick}>
              Save
            </button>
          </Grid>
        </Grid>
      </Wrapper>
    </Screen>
  );
};

export default BehavioralSaveEditScreen;
