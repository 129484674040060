import styles from "CSS/Components/ToDo.module.css";
import globals from "CSS/Globals.module.css";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { FiRepeat } from "react-icons/fi";
import { PiLadder } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleCompleteToDo } from "Reducers/ToDoReducer";

const ToDo = memo(function temp({ todo }) {
  const [isPast, setIsPast] = useState(false);
  const [day, setDay] = useState(moment());
  const [time, setTime] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { badges } = useSelector(selectBadges);

  useEffect(() => {
    if (todo.isFullDay) {
      setIsPast(moment(todo.remindTime).isBefore(moment(), "day"));
    } else {
      setIsPast(moment(todo.remindTime).isBefore(moment()));
    }
    setDay(
      moment(todo.remindTime).format("MMMM") +
      " " +
      moment(todo.remindTime).date()
    );

    if (!todo.isFullDay) {
      setTime(
        ", " +
        moment(todo.remindTime)
          .local()
          .format("hh:mm A")
          .replace(/(^|-)0+/g, "") // remove leading 0 on date
      );
    } else {
      setTime("");
    }
  }, [todo.remindTime, todo.isFullDay, dispatch]);

  const toggleToDo = () => {
    // get todos badge
    // const badge = badges.find((badge) => badge.name === "To Do");

    // // increment progress if completing
    // if (!todo.completed) {
    //   addToBadge(badge, dispatch, badge.progress + 1);
    // } else {
    //   // remove one from progress when uncompleting
    //   addToBadge(badge, dispatch, badge.progress - 1);
    // }

    dispatch(
      toggleCompleteToDo({
        id: todo._id,
        completedDate: todo.completed ? null : new Date(),
        completed: !todo.completed,
        isFullDay: todo.isFullDay,
      })
    );
  };

  return (
    <section className={styles.container}>
      <button
        className={todo.completed ? styles.completedButton : ""}
        onClick={toggleToDo}
      />
      <div
        className={`${styles.info} ${todo.completed ? styles.completedInfo : ""
          }`}
      >
        <div className={styles.header}>
          <h3
            onClick={() => navigate(`/todos/${todo._id}`)}
            className={todo.completed ? styles.headerCompleted : ""}
          >
            {todo.title.length > 23
              ? `${todo.title.substring(0, 23)}...`
              : todo.title}
          </h3>
          {todo.pathway && (
            <div
              className={styles.iconPathways}
              onClick={() =>
                navigate(
                  `/pathways/${todo.pathway}?redirect=/todos`
                )
              }
            />
          )}
          {todo.__t === "ExposureToDo" && (
            <PiLadder size={20}
              onClick={() =>
                navigate(
                  `/toolkit/fear-ladder/${todo.fearLadder}/step/${todo.step}?redirect=/todos`
                )}
            />
          )}
        </div>
        <p
          className={
            isPast & !todo.completed ? globals.simpleErrorMessage : "" //turns date red if uncompleted expired todo
          }
        >
          {moment(todo.remindTime).isSame(moment(), "day")
            ? "Today" + time
            : day + time}
        </p>
      </div>
      {todo.recurrenceType !== "once" && <FiRepeat size={12} />}
    </section>
  );
});

export default ToDo;
