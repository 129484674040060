import { Modal } from "@mui/material";
import Badge from "Components/Badge";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/BadgesScreen.module.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectBadges } from "Reducers/BadgesReducer";

const BadgesScreen = () => {
  const { badges } = useSelector(selectBadges);
  const [openBadgeModal, setOpenBadgeModal] = useState(false);
  const [currentBadge, setCurrentBage] = useState({});

  const handleOnClick = (badge) => {
    setOpenBadgeModal(true);
    setCurrentBage(badge);
  };

  const viewBadgeInfoModal = () => (
    <Modal className={styles.badges_modal} open={openBadgeModal}>
      <div className={styles.badges_modalForm}>
        <h3>Badge Information</h3>
        <div className={styles.badges_modalInfo}>
          {currentBadge?.completed?.blueCompleted ? (
            <img src={"/images/badges/blue.png"} alt={"blue badge"} />
          ) : (
            <img src={"/images/badges/unachieved.png"} alt={"no badge"} />
          )}
          <p>{currentBadge?.descriptions?.blueDescription}</p>
        </div>
        <div className={styles.badges_modalInfo}>
          {currentBadge?.completed?.bronzeCompleted ? (
            <img src={"/images/badges/bronze.png"} alt={"bronze badge"} />
          ) : (
            <img src={"/images/badges/unachieved.png"} alt={"no badge"} />
          )}
          <p>{currentBadge?.descriptions?.bronzeDescription}</p>
        </div>
        <div className={styles.badges_modalInfo}>
          {currentBadge?.completed?.silverCompleted ? (
            <img src={"/images/badges/silver.png"} alt={"silver badge"} />
          ) : (
            <img src={"/images/badges/unachieved.png"} alt={"no badge"} />
          )}
          <p>{currentBadge?.descriptions?.silverDescription}</p>
        </div>
        <div className={styles.badges_modalInfo}>
          {currentBadge?.completed?.goldCompleted ? (
            <img src={"/images/badges/gold.png"} alt={"gold badge"} />
          ) : (
            <img src={"/images/badges/unachieved.png"} alt={"no badge"} />
          )}
          <p>{currentBadge?.descriptions?.goldDescription}</p>
        </div>
        <button
          className={`${btn.faded_black} ${btn.btn}`}
          onClick={() => setOpenBadgeModal(false)}
        >
          Close
        </button>
      </div>
    </Modal>
  );

  return (
    <Screen>
      <Wrapper>
        {viewBadgeInfoModal()}
        <h1>🏅 Badges</h1>
        <div className={styles.badges}>
          {badges?.map((badge) => (
            <Badge
              onClick={() => handleOnClick(badge)}
              badge={badge}
              key={badge._id}
            />
          ))}
        </div>
      </Wrapper>
    </Screen>
  );
};

export default BadgesScreen;
