import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Firebase configurations.
var firebaseConfig = {
  apiKey: "AIzaSyBYqlifaBSFH-l-lnxeiFHJ6-DziMdHc5c",
  authDomain: "psystem-dev.firebaseapp.com",
  projectId: "psystem-dev",
  storageBucket: "psystem-dev.appspot.com",
  messagingSenderId: "9456915965",
  appId: "1:9456915965:web:5b27cd1235b79376a8b594",
};

/**
 * This function requests permission for notifications from Firebase messaging API.
 * It returns a Promise that resolves with the Firebase notification
 * token if permission is granted,
 * or rejects with an error if the request fails or is denied.
 */
export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

/**
 * This function returns a subscription token from Firebase which
 * is used to identify a unique system.
 *
 * If the token is successfully retrieved, it will be logged and returned.
 * If not, the function will request notification permission
 * and log the result of the request.
 */
export const getTokenFromFirebase = async () => {
  return getToken(messaging, {
    vapidKey:
      "BGqd2fLVq8H-OeqcdCHTWIixH0E8lcSdC9INVPH8eH4-iVXW54vbf8HPti1vvooPlHrpHa7TSZBJ543E0XCOUJ0",
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        Notification.requestPermission().then((permission) => {
          console.log("Access for notification is ", permission);
        });
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
