import CircularSlider from "@fseehawer/react-circular-slider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { FormControl, FormControlLabel, Grid, IconButton, ImageListItem, ImageListItemBar, Paper, Switch, TextField } from "@mui/material";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddPostScreen.module.css";
import communityStyle from "CSS/Screens/CommunityScreen.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import { URLS } from "Constants/Constants";
import { EMOTION_EMOJI_MAP, SLIDER } from "Constants/EmotionSelector";
import useEmotionSelector from "Hooks/useEmotionSelector";
import { useRedirect } from "Hooks/useRedirect";
import { createPost } from "Reducers/Actions/CommunityActions";
import Compressor from "compressorjs";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const AddPostScreen = () => {
  const redirectUrl = useRedirect(URLS.COMMUNITY);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emotionRating, setEmotionRating] = useState(0);
  const [emojiLabel, setEmojiLabel] = useState(EMOTION_EMOJI_MAP[0]);
  const [content, setContent] = useState("");
  const [reachOut, setReachOut] = useState(false);

  const inputFile = useRef(null);

  // States required for image upload.
  const [selectedImages, setSelectedImages] = useState([]);
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    setEmojiLabel(EMOTION_EMOJI_MAP[emotionRating]);
  }, [emotionRating]);
  const { emotions, renderModal, renderSelector } = useEmotionSelector();

  const addPostSubmit = (e) => {
    e.preventDefault();
    dispatch(createPost({
      emotions,
      emotionRating,
      content,
      reachOut,
      postType: "default",
      images: selectedImages,
    }));
    navigate(URLS.COMMUNITY);
  };

  const handleImageUploadClick = () => {
    inputFile.current.click();
  };

  const handleFileUpload = (event) => {
    if (event.target.files.length > 3 || selectedImages.length === 3) {
      setAlertMessage("Cannot upload more than 3 images per entry");
      setIsAlertActive(true);
      return;
    }

    if (event.target.files.length > 0) {
      const filesArray = [...event.target.files];
      filesArray.map((image) => compressImage(image));
    }
  };

  const compressImage = (image) => {
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        setSelectedImages((prevState) => [...prevState, compressedResult]);
      },
    });
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prevState) => prevState.filter((_, i) => i !== index));
  };

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={addPostSubmit}>
        {renderModal()}
        <div className={communityStyle.header}>
          <Link
            to={redirectUrl}
            className={`${communityStyle.btn} ${communityStyle.btn_orange}`}>
            Close
          </Link>
          <h3>New Post</h3>
          <button
            type="submit"
            className={`${communityStyle.btn} ${communityStyle.btn_blue}`}>
            Add
          </button>
        </div>
        <div
          className={`
            ${styles.add_post_body} 
            ${isMobile ? styles.add_post_bodyMobile : ""
            }`}>
          <CircularSlider
            label={emojiLabel}
            type="number"
            labelColor={SLIDER.LABEL_COLOR_CODE}
            knobColor={SLIDER.KNOB_COLOR_CODE}
            progressColorFrom={SLIDER.COLOR_FROM_CODE}
            progressColorTo={SLIDER.COLOR_TO_CODE}
            trackColor={SLIDER.TRACK_COLOR_CODE}
            data={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]} //...
            min={0}
            max={10}
            dataIndex={0}
            labelBottom={true}
            labelFontSize="3rem"
            valueFontSize="1.5rem"
            onChange={(e) => setEmotionRating(e)}
            value={emotionRating}
            width={140}
            knobSize={32}
            trackSize={6}
            progressSize={12}
          />
          {renderSelector()}
          <Grid container justifyContent="center">
            <Grid item xs={10}>
              <TextField
                className={styles.textarea}
                label="Enter post"
                type="text"
                fullWidth
                multiline
                rows={3.5}
                placeholder="How are you feeling?"
                name="review"
                required={false}
                onChange={(e) => setContent(e.target.value)}
                value={content}
                variant="outlined"
                inputProps={{
                  maxLength: 500,
                }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid xs={10}>
              <AlertMessage
                toggle={isAlertActive}
                type="warning"
                setMessage={() => setIsAlertActive(false)}
                message={alertMessage} />
              <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
                <input
                  onChange={handleFileUpload}
                  type="file"
                  id="images"
                  name="images"
                  accept="image/*"
                  ref={inputFile}
                  style={{ display: "none" }}
                  multiple />
                <Paper
                  variant="outlined"
                  className={
                    selectedImages.length > 0 ?
                      styles.imageUploadPaperActive :
                      styles.imageUploadPaper}
                  onClick={selectedImages.length === 0 ? handleImageUploadClick : null}>
                  {selectedImages.length > 0 ? (
                    <Grid container>
                      {
                        selectedImages.map((image, index) => (
                          <Grid xs={4} key={index} className={styles.journalImageContainer}>
                            <ImageListItem key={index} className={styles.journalImage}>
                              <img
                                className={styles.journalImage}
                                src={URL.createObjectURL(image)}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                className={styles.imageList}
                                position="top"
                                actionIcon={
                                  <IconButton
                                    className={styles.imageIcon}
                                    onClick={() => handleRemoveImage(index)}
                                  >
                                    <HighlightOffRoundedIcon />
                                  </IconButton>
                                }
                                actionPosition="left"
                              />
                            </ImageListItem>
                          </Grid>
                        ))
                      }
                      <Grid xs={4}>
                        <Paper
                          onClick={handleImageUploadClick}
                          className={styles.moreImageUpload}>
                          <AddCircleOutlineIcon fontSize="large" />
                        </Paper>
                      </Grid>
                    </Grid>
                  ) : (<AddPhotoAlternateIcon fontSize="large" />)}
                </Paper>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  checked={reachOut}
                  onChange={(e) => setReachOut(e.target.checked)} />}
              label="Allow friends to reach out from post" />
          </Grid>
          <button
            className={`${btn.btn} ${btn.faded_blue} ${styles.post_submit}`}
            type="submit">
            Share Post
          </button>
        </div>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default AddPostScreen;
