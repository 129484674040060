import { addHealthCareProvider } from "Reducers/HealthCareProvidersReducer";

const undoHealthCareProvider = (dispatch) => {
  const healthCareProvider = JSON.parse(
    localStorage.getItem("tempHealthCareProvider")
  );

  dispatch(
    addHealthCareProvider({
      healthCareProviderName: healthCareProvider.healthCareProviderName,
      field: healthCareProvider.field,
      phoneNumber: healthCareProvider.phoneNumber,
      address: healthCareProvider.address,
      additionalNotes: healthCareProvider.additionalNotes,
      isArchived: healthCareProvider.isArchived,
      user: healthCareProvider.user,
    })
  );
};

export default undoHealthCareProvider;
