import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/CognitiveDistortionsScreen.module.css";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCognitiveDistortionEntries, resetAddDistortionEntrySuccess, selectCognitiveDistortion, setSelectedDistortionEntry } from "Reducers/CognitiveDistortionReducer";

const CognitiveDistortionsHomeScreen = () => {
  const navigate = useNavigate();
  const redirectUrl = useRedirect("/toolkit");
  const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();
  const {
    successAdd,
    distortionEntries,
    loading,
  } = useSelector(selectCognitiveDistortion);

  const handleStartNewCognitiveDistortions = () => {
    navigate("/toolkit/cognitive-thoughts");
  };

  const handleCognitiveHistoryClick = (distortionEntry) => {
    dispatch(setSelectedDistortionEntry(distortionEntry));
    // Navigate to review screen.
    navigate("/toolkit/distortion-entry-info");
  };

  useEffect(() => {
    dispatch(getCognitiveDistortionEntries());
  }, [dispatch, successAdd]);

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("Added new distortion entry!");
    }
    return timer;
  };

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, successAdd]);

  return (
    <Screen>
      <Wrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "1em", marginBottom: "1em" }}>
          <Grid xs={10} item>
            <Link
              to={redirectUrl}
              className={`${styles.backBtn}`}>
              Back to toolkit
            </Link>
          </Grid>
        </Grid>
        <h1>Cognitive Distortions</h1>
        <Grid container
          spacing={0}
          direction="row"
          className={`${styles.marginTop}`}>
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            className={`${styles.flexDisplay}`}>
            <AlertMessage
              message={successMessage}
              setMessage={() => {
                setSuccessMessage("🎉 Added new distortion entry!");
                dispatch(resetAddDistortionEntrySuccess());
              }}
              type="success"
              toggle={successAdd}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={11} item>
          <button
            className={`
                ${btn.faded_blue} 
                ${btn.cognitiveDistortionsBtn}`}
            onClick={handleStartNewCognitiveDistortions}>
            Start New
          </button>
        </Grid>
        {
          loading && <LoadingSpinner />
        }
        {
          distortionEntries.length > 0 && (
            distortionEntries.map((entry, index) => (
              <div className={`${styles.marginTop}`} key={index}>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  xs={12} item>
                  <Card className={`${styles.card}`} sx={{ minWidth: 305 }}>
                    <CardContent
                      className={`${styles.cardContent}`}
                      onClick={() => handleCognitiveHistoryClick(entry)}>
                      <Grid
                        container
                        spacing={0}
                        style={{ marginBottom: "10px", fontSize: "12px" }}>
                        <Grid xs={12} item>
                          {moment(entry.date).format("MMMM Do YYYY, h:mm a")}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        style={{ marginBottom: "10px" }} >
                        {entry.thoughts}
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid xs={2} item>
                          <Avatar
                            alt="Duration Indication"
                            sx={{ width: 24, height: 24 }}>
                          </Avatar>
                        </Grid>
                        <Grid xs={10} item>
                          <Avatar
                            alt="Difficulty Indication"
                            sx={{ width: 24, height: 24 }}>
                          </Avatar>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </div>
            ))
          )
        }
      </Wrapper>
    </Screen>
  );
};

export default CognitiveDistortionsHomeScreen;
