import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";


// send backend request to add new journal entry
const getUserCheckin = createAsyncThunk("get user checkin/", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/checkin/"),
    thunkAPI
  )
);

// send backend request to add new journal entry
const updateUserCheckin = createAsyncThunk("update user checkin/", (isCheckinEnabled, thunkAPI) =>
  actionHandler(
    axiosInstance.put("/checkin/", { isCheckinEnabled }),
    thunkAPI
  )
);

// send backend request to add new journal entry
const addPreCheckin = createAsyncThunk("add pre checkin/", (preCheckinData, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/checkin/pre", preCheckinData),
    thunkAPI
  )
);

// send backend request to add new journal entry
const addPostCheckin = createAsyncThunk("add post checkin/", (postCheckinData, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/checkin/post", postCheckinData),
    thunkAPI
  )
);


export { addPostCheckin, addPreCheckin, getUserCheckin, updateUserCheckin };

