import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/ActivityScreen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addActivities, selectActivity } from "Reducers/ActivityReducer";

const DetailedEntryActivitiesScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activitiesToCreate } = useSelector(selectActivity);
  const [activityEntries, setActivityEntries] = useState();

  useEffect(() => {
    let activityInitialState = new Map();
    activitiesToCreate.map((value) => {
      activityInitialState.set(value, {
        duration: "short",
        difficulty: "easy",
      });
    });

    setActivityEntries(activityInitialState);
  }, [activitiesToCreate]);

  const handleSaveClick = () => {
    let listOfActivitiesToSave = [];
    Array.from(activityEntries.entries()).map(([key, value]) => {
      listOfActivitiesToSave.push({ name: key, ...value });
    });

    dispatch(addActivities(listOfActivitiesToSave));
    navigate("/activities");
  };

  const handleSelectChange = (activityName, activityValue) => {
    let exitingActivityInfo = activityEntries.get(activityName);
    setActivityEntries(
      (exitingState) =>
        new Map(
          exitingState.set(activityName, {
            ...exitingActivityInfo,
            ...activityValue,
          })
        )
    );
  };

  const handleCancelClick = () => {
    navigate("/new-activity-entry");
  };

  return (
    <>
      <Screen>
        <Wrapper>
          <div className={`${styles.center} ${styles.containerWidth}`}>
            <h1>Provide Time and Difficulty Details</h1>
          </div>
          <div className={`${styles.containerWidth}`}>
            {activityEntries &&
              Array.from(activityEntries.entries()).map(([key, value]) => (
                <div key={key}>
                  <Grid container spacing={0}>
                    <Grid item={true} xs={12} style={{ marginTop: "1em" }}>
                      <Chip label={key} />
                    </Grid>
                  </Grid>
                  <div className={`${styles.fullWidth}`}>
                    <Grid container spacing={0}>
                      <Grid item={true} xs={12} style={{ marginTop: "1em" }}>
                        <FormControl
                          sx={{ minWidth: "100%", margin: "0px" }}
                          size="small"
                        >
                          <InputLabel id="activity-duration-selection">
                            How much time will this activity take?
                          </InputLabel>
                          <Select
                            labelId="activity-duration-selection"
                            id="activity-duration-selection"
                            value={value.duration}
                            label="How much time will this activity take?"
                            onChange={(e) =>
                              handleSelectChange(key, {
                                duration: e.target.value,
                              })
                            }
                          >
                            <MenuItem value={"short"}>Short</MenuItem>
                            <MenuItem value={"medium"}>Medium</MenuItem>
                            <MenuItem value={"long"}>Long</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item={true} xs={12} style={{ marginTop: "1em" }}>
                        <FormControl
                          sx={{ minWidth: "100%", margin: "0px" }}
                          size="small"
                        >
                          <InputLabel id="challenge-selection">
                            How challenging will this activity be?
                          </InputLabel>
                          <Select
                            labelId="challenge-selection"
                            id="challenge-selection"
                            value={value.difficulty}
                            label="How challenging will this activity be?"
                            onChange={(e) =>
                              handleSelectChange(key, {
                                difficulty: e.target.value,
                              })
                            }
                          >
                            <MenuItem value={"easy"}>Easy</MenuItem>
                            <MenuItem value={"moderate"}>Moderate</MenuItem>
                            <MenuItem value={"difficult"}>Difficult</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ))}
          </div>
          <div className={`${styles.containerWidth}`}>
            <Grid container spacing={1} style={{ marginTop: "3em" }}>
              <Grid item xs={6} className={`${styles.center}`}>
                <button
                  className={`${btn.btn} ${btn.secondary}`}
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </Grid>
              <Grid item={true} xs={6} className={`${styles.center}`}>
                <button
                  className={`${btn.btn} ${btn.primary}`}
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              </Grid>
            </Grid>
          </div>
        </Wrapper>
      </Screen>
    </>
  );
};

export default DetailedEntryActivitiesScreen;
