import { TextField } from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import { useProvider } from "Hooks/useProvider";
import {
  addCondition,
  resetAddConditionSuccess,
  selectConditions,
} from "Reducers/ConditionReducer";
import { selectHealthCareProviders } from "Reducers/HealthCareProvidersReducer";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import btn from "../../CSS/Button.module.css";
import styles from "../../CSS/Screens/HealthRecordSetupConditionScreen.module.css";
import Screen from "../../Components/Helpers/Screen";
import WrapperForm from "../../Components/Helpers/WrapperForm";

const HealthRecordSetupProviderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { loading, error } = useSelector(selectHealthCareProviders);
  const { successAdd } = useSelector(selectConditions);

  const [conditionName, setConditionName] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const { renderAutocomplete, setProviderName, providerName } = useProvider();
  const [additionalNotes, setAdditionalNotes] = useState([]);
  const { newlyAddedId } = useSelector(selectHealthCareProviders);

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new condition!");
      timer = setTimeout(() => {
        dispatch(resetAddConditionSuccess());
      }, 2000);
    }
    return timer;
  };

  const handleConditionSubmit = (event) => {
    event.preventDefault();

    dispatch(
      addCondition({
        conditionName,
        date: moment(date).utc(),
        healthCareProfessional: providerName.healthCareProviderName,
        additionalNotes,
        healthCareProvider: newlyAddedId,
      })
    );

    // Reset input fields after submitting new entry
    setConditionName("");
    setDate("");
    setProviderName("");
    setAdditionalNotes([]);
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();

    return () => {
      clearTimeout(timer);
    };
  }, [successAdd]);

  return (
    <Screen>
      <WrapperForm onSubmit={handleConditionSubmit}>
        <div className={styles.stepDescription}>
          <p className={styles.paragraph}>
            Enter any current conditions, <br /> diagnoses, or allergies.
          </p>
        </div>
        <div
          className={`${styles.conditionContainer_body} ${
            isMobile ? styles.conditionContainer_bodyMobile : ""
          }`}
        >
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <AlertMessage
            message={successMessage}
            setMessage={() => {
              setSuccessMessage("Added new condition!");
              dispatch(resetAddConditionSuccess());
            }}
            type="success"
            toggle={successAdd}
          />
          <TextField
            className={styles.textField}
            label="Conditions"
            type="text"
            placeholder="Enter condition"
            name="name"
            required={true}
            value={conditionName}
            onChange={(e) => setConditionName(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
            size="small"
          />
          <TextField
            className={`${styles.textField} ${styles.dateInput}`}
            label="Date of diagonsis"
            type="datetime-local"
            name="day"
            required
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          {renderAutocomplete()}
          <TextField
            className={styles.textField}
            label="Additional notes"
            type="text"
            placeholder="Any other notes to keep in mind?"
            name="additional notes"
            multiline
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 500,
            }}
            autoFocus={!isMobile}
            size="small"
          />
          <div className={styles.btnGroup}>
            <button
              className={`${btn.btn} ${btn.faded_blue} ${styles.submitBtn}`}
              type="submit"
            >
              {loading ? "Saving..." : "Save and Add New"}
            </button>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={`${btn.btn} ${btn.blue_btn} ${styles.nextBtn}`}
            onClick={() => navigate("/health-record-treatment")}
            type="button"
          >
            Continue
          </button>
          <button
            className={styles.skipBtn}
            onClick={() => navigate("/health-record-treatment")}
            type="button"
          >
            Skip
          </button>
        </div>
      </WrapperForm>
    </Screen>
  );
};

export default HealthRecordSetupProviderScreen;
