import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_BOTTOM_NAV_CONFIG, DEFAULT_SIDE_NAV_CONFIG } from "Constants/Constants";

// Initialize state with values from localStorage or default values
const initialState = {
  bottomNavConfig: JSON.parse(localStorage.getItem("bottomNavConfig")) || DEFAULT_BOTTOM_NAV_CONFIG,
  sideNavConfig: JSON.parse(localStorage.getItem("sideNavConfig")) || DEFAULT_SIDE_NAV_CONFIG,
};

// functions for "useSelector"
const selectUserSetting = (state) => state.userSetting;

const userSettingSlice = createSlice({
  name: "userSetting",
  initialState,
  reducers: {
    // Update the bottomNavConfig state and save it to localStorage
    updateBottomNavConfig: (state, { payload }) => {
      state.bottomNavConfig = state.bottomNavConfig.map(navItem => {
        if (navItem.id === payload.id) {
          return { ...navItem, isActive: !navItem.isActive };
        }
        return navItem;
      });
      localStorage.setItem("bottomNavConfig",
        JSON.stringify(state.bottomNavConfig));
    },
    // Update the sideNavConfig state and save it to localStorage
    updateSideNavConfig: (state, { payload }) => {
      state.sideNavConfig = state.sideNavConfig.map(navItem => {
        if (navItem.content === payload.content) {
          return { ...navItem, isActive: !navItem.isActive };
        }
        return navItem;
      });
      localStorage.setItem("sideNavConfig",
        JSON.stringify(state.sideNavConfig));
    },
  },
  extraReducers: {
  },
});

export default userSettingSlice.reducer;
export { selectUserSetting };
export const { updateBottomNavConfig, updateSideNavConfig } = userSettingSlice.actions;
