import { TextField } from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import {
  addAdditionalNote,
  resetAddAdditionalNoteSuccess,
  selectAdditionalNotes,
} from "Reducers/AdditionalNoteReducer";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import btn from "../../CSS/Button.module.css";
import styles from "../../CSS/Screens/HealthRecordSetupAdditionalNoteScreen.module.css";
import Screen from "../../Components/Helpers/Screen";
import WrapperForm from "../../Components/Helpers/WrapperForm";

const HealthRecordSetupAdditionalNoteScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { loading, error, successAdd } = useSelector(selectAdditionalNotes);

  const [additionalNotes, setAdditionalNotes] = useState("");

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new additional note!");
      timer = setTimeout(() => {
        dispatch(resetAddAdditionalNoteSuccess());
      }, 2000);
    }
    return timer;
  };

  const handleAdditionalNoteSubmit = (e) => {
    e.preventDefault();

    dispatch(addAdditionalNote({ additionalNotes }));

    // Reset input fields after submitting new entry
    setAdditionalNotes([]);
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  // separate useEffect to avoid making 3 requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();

    return () => {
      clearTimeout(timer);
    };
  }, [successAdd]);

  return (
    <Screen>
      <WrapperForm onSubmit={handleAdditionalNoteSubmit}>
        <div className={styles.stepDescription}>
          <p className={styles.paragraph}>Any additional notes to add?</p>
        </div>
        <div
          className={`${styles.additionalNoteContainer_body} ${isMobile ? styles.additionalNoteContainer_bodyMobile : ""
            }`}
        >
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <AlertMessage
            message={successMessage}
            setMessage={() => {
              setSuccessMessage("Added new additional note!");
              dispatch(resetAddAdditionalNoteSuccess());
            }}
            type="success"
            toggle={successAdd}
          />
          <TextField
            className={styles.textField}
            label="Additional notes"
            type="text"
            placeholder="Any other notes to keep in mind?"
            name="additional notes"
            required={true}
            multiline
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            variant="outlined"
            autoFocus={!isMobile}
            size="small"
          />
          <div className={styles.btnGroup}>
            <button
              className={`${btn.btn} ${btn.faded_blue} ${styles.submitBtn}`}
              type="submit"
            >
              {loading ? "Saving..." : "Save and Add New"}
            </button>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={`${btn.btn} ${btn.blue_btn} ${styles.nextBtn}`}
            onClick={() => navigate("/pathway-tutorial")}
            type="button"
          >
            Next
          </button>
          <button
            className={styles.skipBtn}
            onClick={() => navigate("/pathway-tutorial")}
            type="button"
          >
            Skip
          </button>
        </div>
      </WrapperForm>
    </Screen>
  );
};

export default HealthRecordSetupAdditionalNoteScreen;
