import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/Pathway/UpdatePathwayNoteScreen.module.css";
import { default as useEmotionSelector } from "Hooks/useEmotionSelector";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deletePathwayNote,
  getPathwayNote,
  resetPathwayNote,
  selectPathwayNotes,
  updatePathwayNote,
} from "Reducers/PathwayNoteReducer";
import {
  getPathways,
  selectPathways,
} from "Reducers/PathwayReducer";

const UpdatePathwayNoteScreen = () => {
  const { selectedPathwayNote, loading, error } = useSelector(
    selectPathwayNotes
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectUrl = useRedirect("/journal");
  const { pathways } = useSelector(selectPathways);
  const { emotions, setEmotions, renderModal, renderSelector } =
    useEmotionSelector();

  const [textReview, setTextReview] = useState("");
  const [isArchived, setIsArchived] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pathway, setPathway] = useState({ title: "", _id: "" });
  const [dateTime, setDateTime] = useState(moment().format("YYYY-MM-DDTHH:mm"));

  // autofills all the fields for the given note
  const fillFields = () => {
    if (selectedPathwayNote && pathways.active.length > 0) {
      setTextReview(selectedPathwayNote.textReview);
      setDateTime(
        moment(selectedPathwayNote.date).local().format("YYYY-MM-DDTHH:mm")
      );
      setEmotions(selectedPathwayNote.emotions);
      handlePathwayChange(null, selectedPathwayNote.pathway);
    }
  };

  const handlePathwayChange = (title, id) => {
    const pathway = {};
    let idx;

    if (title) {
      idx = pathways.active.findIndex(
        (pathway) => pathway.title === title
      );
    } else {
      idx = pathways.active.findIndex(
        (pathway) => pathway._id === id
      );
    }
    let list = "active";

    // set archived pathway title
    if (idx === -1) {
      setIsArchived(true);
      idx = pathways.archived.findIndex(
        (pathway) => pathway._id === id
      );
      if (idx !== -1) {
        list = "archived";
      }
    } else {
      setIsArchived(false);
    }

    pathway.title = pathways[list][idx].title;
    pathway._id = pathways[list][idx]._id;

    setPathway(pathway);
  };

  const updatePathwayNoteSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updatePathwayNote({
        id,
        date: moment(dateTime).utc(),
        emotions,
        textReview,
        pathway: pathway._id,
      })
    );

    // Success so go back to history page
    if (!loading && !error) {
      navigate("/journal");
    }
  };

  const deleteJournalEntryAction = (e) => {
    e.preventDefault();

    localStorage.setItem(
      "tempPathwayNote",
      JSON.stringify(selectedPathwayNote)
    );
    dispatch(deletePathwayNote(selectedPathwayNote._id));

    // Success so go back to history page
    if (!loading && !error) {
      navigate("/journal");
    }
  };

  useEffect(() => {
    dispatch(getPathwayNote(id));

    // only get pathways if they aren't in state already
    if (pathways.active.length === 0) {
      dispatch(getPathways());
    }

    if (error) {
      setErrorMessage(error);
    }

    fillFields();

    return () => {
      dispatch(resetPathwayNote);
    };
  }, [
    dispatch,
    selectedPathwayNote?.textReview,
    pathways.active.length,
  ]);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={updatePathwayNoteSubmit}>
        <div className={styles.header}>
          <button
            className={`${btn.links} ${btn.secondaryLink}`}
            onClick={() => {
              navigate(redirectUrl);
              dispatch(resetPathwayNote);
            }}
            type="button"
          >
            Cancel
          </button>
          <h3>Update Pathway Note</h3>
          <button
            type="submit"
            className={`${btn.links} ${btn.primaryLink}`}
          >
            Update
          </button>
        </div>

        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />

        {renderModal()}

        <div className={`${styles.body} ${isMobile ? styles.bodyMobile : ""}`}>
          <TextField
            className={styles.datetextarea}
            autoFocus={!isMobile}
            type="datetime-local"
            name="day"
            required={true}
            value={dateTime}
            onChange={(e) => setDateTime(e.target.value)}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
          />

          <FormControl
            sx={{ minWidth: "70%", marginTop: "10px" }}
            size="small"
            required
          >
            <InputLabel>Update Pathway</InputLabel>
            <Select
              label="Update Pathway"
              value={pathway.title}
              onChange={(e) => handlePathwayChange(e.target.value, null)}
              className={styles.pathwayselect}
            >
              {!isArchived ? (
                pathways?.active.map((pathway) => (
                  <MenuItem key={pathway._id} value={pathway.title}>
                    {pathway.title}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={pathway._id} value={pathway.title}>
                  {pathway.title}
                </MenuItem>
              )}
            </Select>
          </FormControl>

          <TextField
            className={styles.textarea}
            label="Record notes"
            type="text"
            multiline
            rows={5}
            placeholder="Enter notes"
            name="notes"
            onChange={(e) => setTextReview(e.target.value)}
            value={textReview}
            variant="outlined"
            inputProps={{
              maxLength: 500,
            }}
          />

          {renderSelector()}
        </div>

        <div className={btn.groupRow}>
          <button
            type="button"
            className={`${btn.btn} ${btn.secondary}`}
            onClick={deleteJournalEntryAction}
          >
            Delete
          </button>
          <button type="submit" className={`${btn.btn} ${btn.primary}`}>
            {loading ? "Updating..." : "Update"}
          </button>
        </div>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default UpdatePathwayNoteScreen;
