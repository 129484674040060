import { Grid, Typography } from "@mui/material";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/SimsDimsEntryScreen.module.css";

import { useNavigate } from "react-router-dom";

const SimsDimsHistoryScreen = () => {

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate("/toolkit/sims-dims");
    };

    return (
        <Screen>
            <Wrapper>

                {/* Subtitle */}
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.marginTop}
                >
                    <Grid xs={10} item>
                        <Typography variant="h6" gutterBottom>
                            Past SIMS DIMS
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: "2em", width: "80%" }}
                >
                    <Grid item={true} xs={4} style={{ textAlign: "left" }}>
                        <button
                            className={`${btn.faded_orange} 
                            ${btn.smallActivityBtn} 
                            ${styles.navBtn}`}
                            onClick={handleBackClick}>
                            Back
                        </button>
                    </Grid>

                </Grid>


            </Wrapper>
        </Screen>
    );
};
export default SimsDimsHistoryScreen;
