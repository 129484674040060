import BackBtn from "Components/Navigation/BackBtn";
import { BiCircle, BiSquareRounded } from "react-icons/bi";
import { GiNoseSide } from "react-icons/gi";
import { TbWaveSine } from "react-icons/tb";

import BoxItem from "Components/Helpers/BoxItem";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import styles from "CSS/Screens/BreathworkScreen.module.css";

const BreathworkScreen = () => {
  return (
    <Screen>
      <Wrapper>
        <BackBtn title="Back to toolkit" link="/toolkit" />
        <h1>Breathwork</h1>
        <div className={styles.toolKitScreen}>
          <BoxItem
            text="Circle"
            link="circle-breath"
            icon={<BiCircle className={styles.toolKitItem_icon} size={24} />}
          />
          <BoxItem
            text="Square"
            link="square-breath"
            icon={
              <BiSquareRounded className={styles.toolKitItem_icon} size={24} />
            }
          />
          <BoxItem
            text="Wave"
            link="wave-breath"
            icon={<TbWaveSine className={styles.toolKitItem_icon} size={24} />}
          />
          <BoxItem
            text="Nostril"
            icon={<GiNoseSide className={styles.toolKitItem_icon} size={24} />}
            link="nostril-breath"
          />
        </div>
      </Wrapper>
    </Screen>
  );
};
export default BreathworkScreen;
