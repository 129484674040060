import { Avatar, Button, Grid, Paper } from "@mui/material";
import { URLS, USER_PROFILE_PICTURE_S3_URL } from "Constants/Constants";
import { acceptFriendRequest, deleteFriendRequest } from "Reducers/FriendRequestReducer";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const CommunityFriends = memo(function temp({ friendRequests, userFriends }) {

  // Navigation function from React Router
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // Function to handle accepting a friend request
  const handleFriendRequestAccept = (friendRequestId) => {
    dispatch(acceptFriendRequest(friendRequestId));
  };

  // Function to handle declining a friend request
  const handleFriendRequestDecline = (friendRequestId) => {
    dispatch(deleteFriendRequest(friendRequestId));
  };

  // Function to handle messaging a user
  const handleMessageUser = (friendUser) => {
    navigate(`${URLS.COMMUNITY_CHAT}/${friendUser._id}`);
  };

  return (
    <>
      {
        friendRequests.length > 0
        && (<h4>You have {friendRequests.length} friend request</h4>)
      }
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs={10}
      >
        {
          friendRequests.map((request) => (
            <Grid sx={{ mt: 2 }} item={true} xs={12} sm={8} key={request._id}>
              <Paper
                elevation={2}
                style={{ padding: "10px", borderRadius: "15px" }}>
                <Grid container
                  direction="row"
                  alignItems="center">
                  <Grid item xs={3}>
                    <Avatar
                      imgProps={{
                        crossOrigin: "anonymous"
                      }}
                      src={`${request.fromUser.profilePicture != null ? (USER_PROFILE_PICTURE_S3_URL + request.fromUser.profilePicture) : ""}`}
                      alt={request.fromUser.firstName}
                      sx={{ width: 55, height: 55 }}
                    />
                  </Grid>
                  <Grid item={true} xs={9}>
                    <Grid container alignItems="center">
                      <Grid xs={12} item>
                        <p>{`
                    ${request.fromUser.firstName} 
                    ${request.fromUser.lastName !== null && request.fromUser.lastName !== "null" ?
                            request.fromUser.lastName : ""}`}
                        </p>
                      </Grid>
                      <Grid sx={{ mt: 2 }} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Button
                              size="small"
                              variant="outlined"
                              style={{ fontSize: "10px" }}
                              onClick={() => handleFriendRequestAccept(request._id)}>
                              Add Friend
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button

                              size="small"
                              variant="outlined"
                              style={{ fontSize: "10px" }}
                              onClick={() => handleFriendRequestDecline(request._id)}>
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
      <Grid item sx={{ mt: 3 }}>
        <h4>Friends ({userFriends.length})</h4>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs={10}
        style={{ marginTop: ".5em" }}>
        {
          userFriends.map(({ friendUser }) => (
            <Grid item={true} xs={12} sm={8} key={friendUser._id} style={{ marginBottom: "12px" }}>
              <Paper
                elevation={2}
                style={{ padding: "10px", borderRadius: "15px", }}>
                <Grid container
                  direction="row"
                  alignItems="center">
                  <Grid item={true} xs={3}>
                    <Avatar
                      imgProps={{
                        crossOrigin: "anonymous"
                      }}
                      src={`${friendUser.profilePicture != null ? (USER_PROFILE_PICTURE_S3_URL + friendUser.profilePicture) : ""}`}
                      alt={friendUser.firstName}
                      sx={{ width: 45, height: 45 }}
                    />
                  </Grid>
                  <Grid item={true} xs={5}>
                    <p>{`${friendUser.firstName} ${(friendUser.lastName !== null
                      && friendUser.lastName !== "undefined"
                      && friendUser.lastName !== "null"
                      && friendUser.lastName !== undefined)
                      ? friendUser.lastName : ""}`}</p>
                  </Grid>
                  <Grid item={true} xs={4} container justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      style={{ textTransform: "none", borderRadius: "10px" }}
                      onClick={() => handleMessageUser(friendUser)}>
                      Say Hi 👋
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </>
  );

});

export default CommunityFriends;
