import styles from "CSS/Components/HealthCareProvider.module.css";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

const HealthCareProvider = memo(function temp({ provider }) {
  const navigate = useNavigate();

  return (
    <section
      className={
        provider.additionalNotes?.length === 0
          ? styles.container
          : styles.bigContainer
      }
    >
      <div
        className={`${styles.info}
        }`}
      >
        <h3
          onClick={() =>
            navigate(
              `/my-health-record/update-health-care-provider/${provider._id}`
            )
          }
          className={provider.isArchived ? styles.headerArchived : ""}
        >
          {provider.healthCareProviderName?.length > 15
            ? `${provider.healthCareProviderName.substring(0, 15)}...`
            : provider.healthCareProviderName}
        </h3>
        <p
          onClick={() =>
            navigate(
              `/my-health-record/update-health-care-provider/${provider._id}`
            )
          }
        >
          {provider.field?.length > 15
            ? "👩‍🔬 " + `${provider?.field.substring(0, 15)}...`
            : "👩‍🔬 " + provider?.field}
        </p>
        <p
          onClick={() =>
            navigate(
              `/my-health-record/update-health-care-provider/${provider._id}`
            )
          }
        >
          {provider.additionalNotes?.length > 25
            ? "✏️ " + `${provider.additionalNotes?.substring(0, 25)}...`
            : provider.additionalNotes?.length === 0
              ? ""
              : "✏️ " + provider?.additionalNotes}
        </p>
      </div>
    </section>
  );
});

export default HealthCareProvider;
