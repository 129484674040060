import globals from "CSS/Globals.module.css";
import { isMobile } from "react-device-detect";

const WrapperForm = ({ children, onSubmit }) => {
  return (
    <form
      onSubmit={onSubmit}
      className={`${isMobile ? globals.generic_wrapperMobile : globals.generic_wrapper
        }`}
    >
      {children}
    </form>
  );
};
export default WrapperForm;
