import btn from "CSS/Button.module.css";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";

const BackBtn = ({ link, title }) => {
  return (
    <Link to={link} className={`${btn.backBtn}`}>
      <HiArrowNarrowLeft />
      <p> {title}</p>
    </Link>
  );
};

export default BackBtn;
