import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const DayOfWeekSelector = ({ daysOfWeek, handleDaysOfWeek, className }) => {
  return (
    <ToggleButtonGroup
      value={daysOfWeek}
      onChange={handleDaysOfWeek}
      aria-label="days of week"
      size="small"
      color="primary"
      fullWidth
      className={className}
    >
      <ToggleButton value="sunday" aria-label="sunday">
        Sun
      </ToggleButton>
      <ToggleButton value="monday" aria-label="monday">
        Mon
      </ToggleButton>
      <ToggleButton value="tuesday" aria-label="tuesday">
        Tue
      </ToggleButton>
      <ToggleButton value="wednesday" aria-label="wednesday">
        Wed
      </ToggleButton>
      <ToggleButton value="thursday" aria-label="thursday">
        Thu
      </ToggleButton>
      <ToggleButton value="friday" aria-label="friday">
        Fri
      </ToggleButton>
      <ToggleButton value="saturday" aria-label="saturday">
        Sat
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default DayOfWeekSelector;
