import { IconButton, Menu, MenuItem } from "@mui/material";
import styles from "CSS/Components/Habit.module.css";
import { useHabitProgress } from "Hooks/useHabitProgress";
import { memo, useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { BiArchiveIn, BiArchiveOut, BiTrash } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlineFire, HiOutlinePencil } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteHabit, updateHabit } from "Reducers/HabitsReducer";
import { convertScale } from "Utilities/adjust-scale";

const Habit = memo(function temp({ habit }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { progress, increaseProgress } = useHabitProgress(habit);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e, option) => {
    setAnchorEl(null);
    const id = habit._id;
    const optionName = option[0];

    if (optionName == "Delete") {
      dispatch(deleteHabit(id));
      navigate("/habits");
    } else if (optionName === "Unarchive") {
      dispatch(updateHabit({ id, isArchived: "false" }));
    } else if (optionName === "Archive") {
      dispatch(updateHabit({ id, ...habit, isArchived: true }));
    } else if (optionName === "Edit") {
      navigate(`/update-habit/${habit._id}`);
    }
  };

  const getDotsList = () =>
    habit.isArchived
      ? [
        ["Unarchive", <BiArchiveOut key={0} className={styles.editOption} />],
        ["Delete", <BiTrash key={1} className={styles.editOption} />],
      ]
      : [
        ["Edit", <HiOutlinePencil key={0} className={styles.editOption} />],
        ["Archive", <BiArchiveIn key={1} className={styles.editOption} />],
        ["Delete", <BiTrash key={2} className={styles.editOption} />],
      ];

  return (
    <section className={styles.container}>
      <div className={styles.info}>
        <div className={styles.header}>
          <h3
            onClick={() =>
              !habit.isArchived && navigate(`/habits/${habit._id}`)
            }
          >
            {habit.name.length > 20
              ? `${habit.name.substring(0, 20)}...`
              : habit.name}{" "}
          </h3>
          {habit.pathway && (
            <div
              className={styles.iconPathways}
              onClick={() =>
                navigate(
                  `/pathway/${habit.pathway}?redirect=/habits`
                )
              }
            />
          )}
        </div>
        <div
          className={styles.extraInfo}
          onClick={() => !habit.isArchived && navigate(`/habits/${habit._id}`)}
        >
          <p>
            Goal: {!habit.isArchived && progress + "/"}
            {habit.unitAmount} {habit.unit === "count" ? "" : habit.unit}
          </p>
          {habit.currentStreak > 2 && (
            <div>
              <HiOutlineFire />
              {habit.currentStreak}
            </div>
          )}
        </div>
      </div>
      {!habit.isArchived && (
        <div className={styles.progress} onClick={increaseProgress}>
          <CircularProgressbarWithChildren
            className={styles.progressBar}
            value={convertScale(progress, habit.unitAmount)}
            styles={buildStyles({
              rotation: 0.25,
              strokeLinecap: "round",
              pathTransitionDuration: 0.3,
              pathColor: `rgba(11, 126, 161, ${convertScale(progress, habit.unitAmount) / 100
                })`,
              trailColor: "#d6d6d6",
              backgroundColor: "#0b7ea1",
            })}
          >
            <p className={styles.progressText}>
              {convertScale(progress, habit.unitAmount)}%
            </p>
          </CircularProgressbarWithChildren>
        </div>
      )}
      <div className={styles.edit}>
        <IconButton
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <BsThreeDotsVertical size={20} />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "14ch",
            },
          }}
        >
          {getDotsList().map((option) => (
            <MenuItem key={option} onClick={(e) => handleClose(e, option)}>
              <div className={styles.editOptions}>
                {option[1]}
                <p className={styles.editOptionText}>{option[0]}</p>
              </div>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </section>
  );
});
export default Habit;
