import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddToDoScreen.module.css";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { FiRepeat } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addToDo,
  resetToDo,
  selectToDos,
} from "Reducers/ToDoReducer";

const AddToDoScreen = () => {
  const [todoTitle, setToDoTitle] = useState("");
  const [todoDate, setToDoDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [isFullDay, setIsFullDay] = useState(false);
  const [todoTime, setToDoTime] = useState("");
  const [recurrenceType, setRecurrenceType] = useState("once");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectUrl = useRedirect("/todos");
  const { loading, error } = useSelector(selectToDos);

  const addToDoSubmit = (e) => {
    e.preventDefault();

    let remindTime = moment(todoDate + " " + todoTime).utc();
    // no time was added
    if (todoTime === "") {
      remindTime = moment(todoDate).utc();
    }

    dispatch(
      addToDo({ todoTitle, remindTime, isFullDay, recurrenceType })
    );

    // Success to reset state and go back to todos
    if (!loading && !error) {
      dispatch(resetToDo);
      navigate(redirectUrl);
    }
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error, dispatch]);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={addToDoSubmit}>
        <div className={styles.header}>
          <Link
            to={redirectUrl}
            className={`${btn.links} ${btn.secondaryLink}`}
          >
            Close
          </Link>
          <h3>New To-Do</h3>
          <button
            type="submit"
            className={`${btn.links} ${btn.primaryLink}`}
          >
            Add
          </button>
        </div>

        <BodyForm desktop={styles.body}>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <TextField
            className={styles.field}
            label="To-Do Title"
            type="text"
            placeholder="Enter to do"
            name="title"
            required={true}
            value={todoTitle}
            onChange={(e) => setToDoTitle(e.target.value)}
            variant="standard"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
          />
          <TextField
            className={styles.field}
            label="Pick a day"
            type="date"
            name="day"
            required={true}
            value={todoDate}
            onChange={(e) => setToDoDate(e.target.value)}
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel
            className={styles.field}
            control={
              <Checkbox
                checked={isFullDay}
                onChange={(e) => setIsFullDay(e.target.checked)}
              />
            }
            label="All Day"
          />
          {!isFullDay && (
            <TextField
              className={styles.field}
              label="Pick a time"
              type="time"
              name="time"
              onChange={(e) => setToDoTime(e.target.value)}
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          )}
          <div className={styles.field}>
            <InputLabel
              variant="standard"
              htmlFor="uncontrolled-native"
              className={styles.repeat}
            >
              <p>Repeat</p>
              <FiRepeat size={12} />
            </InputLabel>
            <NativeSelect
              onChange={(e) => setRecurrenceType(e.target.value)}
              value={recurrenceType}
              inputProps={{
                name: "Recurrence",
                id: "uncontrolled-native",
              }}
            >
              <option value="once">Once</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </NativeSelect>
          </div>
          <button
            type="submit"
            className={`${btn.btn} ${btn.primary} ${styles.submit}`}
          >
            {loading ? "Adding..." : "Add"}
          </button>
        </BodyForm>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default AddToDoScreen;
