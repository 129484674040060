import globals from "CSS/Globals.module.css";
import { isMobile } from "react-device-detect";
import FooterNav from "../Navigation/FooterNav";
import Navbar from "../Navigation/Navbar";

const Screen = ({ children }) => {
  return (
    <main
      className={`${isMobile ? globals.generic_containerMobile : globals.generic_container
        }`}
    >
      <Navbar />
      {children}
      <FooterNav />
    </main>
  );
};
export default Screen;
