import { Input, InputAdornment, InputLabel } from "@mui/material";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AdjustHabitInstanceScreen.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import { useHabitProgress } from "Hooks/useHabitProgress";
import { useRedirect } from "Hooks/useRedirect";
import {
  getHabitInstance,
  resetHabit,
  selectHabits,
  updateHabitInstance,
} from "Reducers/HabitsReducer";
import { convertScale } from "Utilities/adjust-scale";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import Confetti from "react-confetti";
import { isMobile } from "react-device-detect";
import { HiCheck, HiMinus, HiPlus } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const AdjustHabitInstanceScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const redirectUrl = useRedirect("/habits");
  const { selectedInstance, error } = useSelector(selectHabits);
  const {
    progress,
    setProgress,
    increaseInstanceProgress,
    decreaseInstanceProgress,
    completeInstanceProgress,
  } = useHabitProgress(null, selectedInstance);

  const [manualAdd, setManualAdd] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isConfettiActive, setIsConfettiActive] = useState(false);

  const handleManualAdd = () => {
    if (manualAdd === "") return;
    let isCompleted = false;
    const amount = progress + parseInt(manualAdd);
    setProgress(amount);
    if (amount / selectedInstance?.unitAmount >= 1) {
      isCompleted = true;
    }
    setManualAdd("");
    dispatch(
      updateHabitInstance({
        id: selectedInstance._id,
        amountCompleted: amount,
        isCompleted,
      })
    );
  };

  useEffect(() => {
    dispatch(getHabitInstance(id));

    if (selectedInstance) {
      setProgress(selectedInstance.amountCompleted);
    }

    if (error) {
      setErrorMessage(error);
    }

    return () => {
      dispatch(resetHabit);
    };
  }, [selectedInstance?.unitAmount]);

  useEffect(() => {
    if (progress === selectedInstance?.unitAmount) {
      setIsConfettiActive(true);
    } else {
      setIsConfettiActive(false);
    }
  }, [progress]);

  return (
    <ScreenNoNav>
      {isConfettiActive && <Confetti recycle={false} />}
      <WrapperForm onSubmit={(e) => e.preventDefault()}>
        <div className={styles.header}>
          <Link to={redirectUrl} className={styles.btn}>
            Back
          </Link>
        </div>
        <div className={styles.title}>
          <h1>{selectedInstance?.name}</h1>
          <h3>
            {moment(selectedInstance?.date).local().format("MMMM D, YYYY")}
          </h3>
        </div>
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        <div className={`${styles.body} ${isMobile ? styles.bodyMobile : ""}`}>
          <div className={`${isMobile ? styles.infoMobile : styles.info}`}>
            <CircularProgressbarWithChildren
              className={styles.progressBar}
              value={convertScale(progress, selectedInstance?.unitAmount)}
              styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: "round",
                textSize: "18px",
                pathTransitionDuration: 0.5,
                pathColor: `rgba(242, 114, 84, ${convertScale(progress + 10, selectedInstance?.unitAmount) /
                  100
                  })`,
                trailColor: "rgba(242, 114, 84, 0.2)",
                backgroundColor: "#0b7ea1",
              })}
            >
              <p
                className={`${styles.progressText} ${selectedInstance?.unitAmount.toString().length > 6
                    ? styles.progressTextSmaller
                    : styles.progressTextLarger
                  }`}
              >
                {progress} / {selectedInstance?.unitAmount}{" "}
                {selectedInstance?.unit !== "count" && selectedInstance?.unit}
              </p>
            </CircularProgressbarWithChildren>
          </div>
          <div className={styles.adjust}>
            <button
              className={btn.faded_orange}
              onClick={decreaseInstanceProgress}
              type="button"
            >
              <HiMinus size={36} />
            </button>
            <button
              className={btn.faded_green}
              onClick={completeInstanceProgress}
              type="button"
            >
              <HiCheck size={36} />
            </button>
            <button
              className={btn.faded_blue}
              onClick={increaseInstanceProgress}
              type="button"
            >
              <HiPlus size={36} />
            </button>
          </div>
          <div className={styles.field}>
            <div>
              <InputLabel variant="standard">
                <p>Manual add</p>
              </InputLabel>

              <Input
                label="Goal Amount"
                type="number"
                variant="standard"
                value={manualAdd}
                placeholder="0"
                onChange={(e) =>
                  setManualAdd(e.target.value < 0 ? 0 : e.target.value)
                }
                endAdornment={
                  <InputAdornment position="end">
                    {selectedInstance?.unit}
                  </InputAdornment>
                }
              />
            </div>
            <button
              className={`${styles.manualBtn} ${btn.faded_blue}`}
              onClick={handleManualAdd}
            >
              Add
            </button>
          </div>
        </div>
      </WrapperForm>
    </ScreenNoNav>
  );
};
export default AdjustHabitInstanceScreen;
