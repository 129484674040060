import styles from "CSS/Components/Pathway/IndvidualPathwayNote.module.css";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const IndividualPathwayActivity = memo(function temp({ activity }) {
  const [day, setDay] = useState(moment());
  const [time, setTime] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const generateTitle = () => {
    if (!activity.name) {
      return "no title";
    }

    return activity.name.length > 28
      ? `${activity.name.substring(0, 28)}...`
      : activity.name.length == 0
        ? day + " " + time
        : activity.name;
  };

  useEffect(() => {
    setDay(
      moment(activity.date).format("MMMM") +
      " " +
      moment(activity.date).date()
    );
    setTime(
      ", " +
      moment(activity.date)
        .local()
        .format("hh:mm A")
        .replace(/(^|-)0+/g, "")
    ); // remove leading 0 on date
  }, [activity.date]);

  return (
    <section className={styles.individualPathwayNote_container} style={{ width: "100%" }} >
      <div
        className={`${styles.individualPathwayNote_info}
        }`}
        onClick={() =>
          navigate(`/activity-history/${activity._id}?redirect=${pathname}`)
        }
      >
        <div className={styles.individualPathwayNote_header}>
          <h3>{generateTitle()}</h3>
        </div>
        <p>
          {"🕐 " + "Last done " + day + time}
        </p>
      </div>
    </section>
  );
});

export default IndividualPathwayActivity;
