import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to fetch logged in users past pathway notes
const getPathwayNotes = createAsyncThunk("get pathway reviews", ({ limit }, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/pathway-note?limit=${limit}`),
    thunkAPI
  )
);

// send backend request to fetch logged in users past pathways
const getPathwayNote = createAsyncThunk("get pathway review", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/pathway-note/${id}`),
    thunkAPI
  )
);

// send backend request to delete specific pathway review
const deletePathwayNote = createAsyncThunk("delete pathway review", (_id, thunkAPI) =>
  actionHandler(
    //delete pathway review with specified ID
    axiosInstance.delete(`/pathway-note/${_id}`),
    thunkAPI
  )
);

// send backend request to add new entry
const addPathwayNote = createAsyncThunk("add pathway review", (
  { date, textReview, emotions, pathway }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/pathway-note",
      {
        date,
        textReview,
        emotions,
        pathway,
      }
    ),
    thunkAPI
  )
);

// send backend request to update entry
const updatePathwayNote = createAsyncThunk("update pathway review", ({ id, date, emotions, textReview, pathway }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/pathway-note/${id}`,
      {
        date,
        textReview,
        pathway,
        emotions,
      },
    ),
    thunkAPI
  )
);


export {
  addPathwayNote, deletePathwayNote, getPathwayNote,
  getPathwayNotes,
  updatePathwayNote
};

