import Fab from "@mui/material/Fab";
import styles from "CSS/Components/AddFab.module.css";
import { isMobile } from "react-device-detect";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom";

const AddFab = ({ url }) => {
  return (
    <div className={`${styles.addFab} ${isMobile ? styles.addFab_mobile : ""}`}>
      <Link className={styles.addFab_plus} to={url}>
        <Fab color="primary" aria-label="add">
          <MdAdd size={32} color="white" />
        </Fab>
      </Link>
    </div>
  );
};

export default AddFab;
