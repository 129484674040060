import { Link } from "@mui/material";
import styles from "CSS/Components/BoxItem.module.css";

const BoxItemExt = ({ text, icon, link }) => {
    return (
        <Link
            href={link}
            target="_blank"
            underline="none"
            color="black"
            marginBottom={"1rem"}
            className={styles.boxItem}
        >
            <p>{text}</p>
            {icon}
        </Link>
    );
};

export default BoxItemExt;
