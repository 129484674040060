import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to search for users.
const searchUsers = createAsyncThunk("search users/", (searchText, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/users/search/"),
    thunkAPI
  )
);

// send backend request to get all feed posts for user
const getFeedPosts = createAsyncThunk("get feed posts/", (page, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/posts/users/posts?page=${page}`),
    thunkAPI
  )
);

// send backend request to create post for team.
const createPost = createAsyncThunk("create general posts/", async (post, thunkAPI) => {

  // Create a form and append image with additional fields
  const form = new FormData();
  post.images.forEach(image => {
    form.append("images", image);
  });

  form.append("emotions", JSON.stringify(post.emotions));
  form.append("emotionRating", post.emotionRating);
  form.append("content", post.content);
  form.append("reachOut", post.reachOut);
  form.append("postType", post.postType);

  actionHandler(
    axiosInstance.post("/posts", form, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }),
    thunkAPI,
  );
});

// react to a post
const reactToPost = createAsyncThunk("react to post/", (reactionInfo, thunkAPI) =>
  actionHandler(
    axiosInstance.post(`/posts/${reactionInfo.postId}/react`,
      { reaction: reactionInfo.reaction }),
    thunkAPI
  )
);


// add comment to a post.
const addCommentToPost = createAsyncThunk("add comment to post/", (commentInfo, thunkAPI) =>
  actionHandler(
    axiosInstance.post(`/posts/${commentInfo.postId}/comments`,
      { comment: commentInfo.comment }),
    thunkAPI
  )
);


export { addCommentToPost, createPost, getFeedPosts, reactToPost, searchUsers };

