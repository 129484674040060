import { createSlice } from "@reduxjs/toolkit";
import { convertUTCToLocalTime } from "Utilities/date-time";
import {
  getSubscription,
  subscribe,
  subscribeToApointment,
  subscribeToHabits,
  subscribeToJournal,
  subscribeToJournalToDo,
  subscribeToToDos,
} from "./Actions/SubscriptionActions";

const initialState = {
  token: "",
  isJournalNotifEnabled: false,
  journalTime: "",
  isApointmentNotifEnabled: false,
  isToDoNotifEnabled: false,
  isJournalToDoEnabled: false,
  isHabitNotifEnabled: false,
  isJournalNotifEnabledLoading: false,
  isApointmentNotifEnabledLoading: false,
  isToDoNotifEnabledLoading: false,
  isJournalToDoEnabledLoading: false,
  isHabitNotifEnabledLoading: false,
  error: null,
  isExpired: false,
};

const selectSubscription = (state) => state.subscription;

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscription: (state) => {
      let subscriptionToken = localStorage.getItem("subscription");
      if (subscriptionToken) {
        state.token = JSON.parse(subscriptionToken).token;
        return;
      }
      state.token = null;
    },
    toggleJournalNotif: (state) => {
      state.isJournalNotifEnabled = !state.isJournalNotifEnabled;
    },
    toggleApointmentNotif: (state) => {
      state.isApointmentNotifEnabled = !state.isApointmentNotifEnabled;
    },
    toggleToDoNotif: (state) => {
      state.isToDoNotifEnabled = !state.isToDoNotifEnabled;
    },
    logoutSubscription: (state) => {
      localStorage.removeItem("subscription");
      state.isJournalNotifEnabled = null;
      state.isApointmentNotifEnabled = false;
      state.isToDoNotifEnabled = null;
      state.token = null;
    },
  },
  extraReducers: {
    // get subscription Async Reducers
    [getSubscription.pending]: (state) => {
      state.isApointmentNotifEnabledLoading = true;
      state.isJournalNotifEnabledLoading = true;
      state.isJournalToDoEnabledLoading = true;
      state.isToDoNotifEnabledLoading = true;
      state.isHabitNotifEnabledLoading = true;
    },
    [getSubscription.fulfilled]: (state, { payload }) => {
      if (payload) {

        state.isJournalToDoEnabled = payload.journalNotification?.isToDoEnabled
          ? payload.journalNotification.isToDoEnabled : false;

        state.isJournalNotifEnabled = payload.journalNotification?.isEnabled
          ? payload.journalNotification.isEnabled : false;

        state.isApointmentNotifEnabled = payload.appointmentNotification?.isEnabled
          ? payload.appointmentNotification.isEnabled : false;

        state.isToDoNotifEnabled = payload.todoNotification?.isEnabled
          ? payload.todoNotification.isEnabled : false;

        if (payload.habitNotification) {
          state.isHabitNotifEnabled = payload.habitNotification.isEnabled;
        }
        state.token = payload.token;
        state.isExpired = payload.isExpired;
      }

      if (payload && payload.journalNotification.time) {
        state.journalTime = convertUTCToLocalTime(payload.journalNotification.time);
      }

      state.isApointmentNotifEnabledLoading = false;
      state.isJournalNotifEnabledLoading = false;
      state.isJournalToDoEnabledLoading = false;
      state.isToDoNotifEnabledLoading = false;
      state.isHabitNotifEnabledLoading = false;
      state.error = null;
    },
    [getSubscription.rejected]: (state, { error }) => {
      state.isApointmentNotifEnabledLoading = false;
      state.isJournalNotifEnabledLoading = false;
      state.isJournalToDoEnabledLoading = false;
      state.isToDoNotifEnabledLoading = false;
      state.isHabitNotifEnabledLoading = false;
      state.error = error.message;
    },

    // Initial notification subscription.
    [subscribe.pending]: (state) => {
      state.isApointmentNotifEnabledLoading = true;
      state.isJournalNotifEnabledLoading = true;
      state.isJournalToDoEnabledLoading = true;
      state.isToDoNotifEnabledLoading = true;
    },
    [subscribe.fulfilled]: (state, { payload }) => {
      state.isApointmentNotifEnabledLoading = false;
      state.isJournalNotifEnabledLoading = false;
      state.isJournalToDoEnabledLoading = false;
      state.isToDoNotifEnabledLoading = false;
      state.error = null;
      if (payload.token) {
        state.token = payload.token;
      }
    },
    [subscribe.rejected]: (state, { error }) => {
      state.isApointmentNotifEnabledLoading = false;
      state.isJournalNotifEnabledLoading = false;
      state.isJournalToDoEnabledLoading = false;
      state.isToDoNotifEnabledLoading = false;
      state.error = error.message;
    },
    // Subscribe to journal Async Reducers
    [subscribeToJournal.pending]: (state) => {
      state.isJournalNotifEnabledLoading = true;
    },
    [subscribeToJournal.fulfilled]: (state, { payload }) => {
      state.isJournalNotifEnabledLoading = false;
      state.error = null;
      state.isJournalNotifEnabled = payload.isEnabled;
      if (payload.token) {
        state.token = payload.token;
      }

      if (payload && payload.time) {
        state.journalTime = convertUTCToLocalTime(payload.time);
      }
    },
    [subscribeToJournal.rejected]: (state, { error }) => {
      state.isJournalNotifEnabledLoading = false;
      state.error = error.message;
    },

    // Subscribe to apointment Async Reducers
    [subscribeToApointment.pending]: (state) => {
      state.isApointmentNotifEnabledLoading = true;
    },
    [subscribeToApointment.fulfilled]: (state, { payload }) => {
      state.isApointmentNotifEnabledLoading = false;
      state.error = null;
      state.isApointmentNotifEnabled = !state.isApointmentNotifEnabled;
      if (payload.token) {
        state.token = payload.token;
      }
    },
    [subscribeToApointment.rejected]: (state, { error }) => {
      state.isApointmentNotifEnabledLoading = false;
      state.error = error.message;
    },

    // Subscribe to todos Async Reducers
    [subscribeToToDos.pending]: (state) => {
      state.isToDoNotifEnabledLoading = true;
    },
    [subscribeToToDos.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isToDoNotifEnabled = !state.isToDoNotifEnabled;
      if (payload.token) {
        state.token = payload.token;
      }
      state.isToDoNotifEnabledLoading = false;
    },
    [subscribeToToDos.rejected]: (state, { error }) => {
      state.isToDoNotifEnabledLoading = false;
      state.error = error.message;
    },

    // Subscribe to journal todos Async Reducers
    [subscribeToJournalToDo.pending]: (state) => {
      state.isJournalToDoEnabledLoading = true;
    },
    [subscribeToJournalToDo.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isJournalToDoEnabled = !state.isJournalToDoEnabled;
      if (payload.token) {
        state.token = payload.token;
      }
      state.isJournalToDoEnabledLoading = false;
    },
    [subscribeToJournalToDo.rejected]: (state, { error }) => {
      state.isJournalToDoEnabledLoading = false;
      state.error = error.message;
    },

    // Subscribe to habits Async Reducers
    [subscribeToHabits.pending]: (state) => {
      state.isHabitNotifEnabledLoading = true;
    },
    [subscribeToHabits.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isHabitNotifEnabled = !state.isHabitNotifEnabled;
      if (payload.token) {
        state.token = payload.token;
      }
      state.isHabitNotifEnabledLoading = false;
    },
    [subscribeToHabits.rejected]: (state, { error }) => {
      state.isHabitNotifEnabledLoading = false;
      state.error = error.message;
    },
  },
});

export default subscriptionSlice.reducer;

export {
  getSubscription,
  selectSubscription,
  subscribeToApointment,
  subscribeToJournal,
  subscribeToToDos
};

export const {
  toggleJournalNotif,
  toggleApointmentNotif,
  toggleToDoNotif,
  setSubscription,
  logoutSubscription,
} = subscriptionSlice.actions;
