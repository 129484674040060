const emotions = [
  { label: "Happy", selected: false },
  { label: "Sad", selected: false },
  { label: "Stressed", selected: false },
  { label: "Pain", selected: false },
  { label: "Energized", selected: false },
  { label: "Tired", selected: false },
];

export default emotions;
