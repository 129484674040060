import {useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import btn from "../../CSS/Button.module.css";
import styles from "../../CSS/Screens/OnboardingScreen.module.css";
import Wrapper from "../../Components/Helpers/Wrapper";
import {
  selectUser
} from "../../Reducers/UserReducer";

const OnboardingScreen = () => {
  const { user } = useSelector(selectUser);
  const navigate = useNavigate();

  return (
    <body className={styles.background}>
      <Wrapper>
        <h1 className={styles.heading}>
          Welcome, <br /> {user ? user.firstName : "user"}
        </h1>
        <img className={styles.logo}
              src="/images/icons/Logo.png"
              alt="Psyhealth logo"
            />
        <p className={styles.onboardingParagraph}>
          <b>PsyHealth</b> is your ultimate <br /> health headquarters.
        </p>
        <p className={styles.onboardingParagraph}>
          An <b>agenda</b>, <br /> A <b>journal</b>, <br /> A <b>habit tracker</b>, <br /> a <b>learning</b> tool,
          <br />and a <b>database</b> for your health information.
        </p>
        <p className={styles.loweronboardingParagraph}>
        To start, let’s review Psyhealth’s different features and get you set up. 
        </p>
        <div className={styles.btnContainer}>
          <button
            className={`${btn.btn} ${btn.orange_btn} ${styles.nextBtn}`}
            onClick={() => navigate("/journal-tutorial")}
            type="button"
          >
            Let&apos;s Go!
          </button>
        </div>
      </Wrapper>
      </body>
  );
};

export default OnboardingScreen;
