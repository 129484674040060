import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import FrequencySelector from "Components/Selectors/FrequencySelector";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddMedicationScreen.module.css";
import { useProvider } from "Hooks/useProvider";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { selectHealthCareProviders } from "Reducers/HealthCareProvidersReducer";
import { addMedication, selectMedications } from "Reducers/MedicationsReducer";

const AddMedicationScreen = () => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [medicationName, setMedicationName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isInactive, setIsInactive] = useState(false);
  const [sideEffects, setSideEffects] = useState("");
  const [dosage, setDosage] = useState("");
  const [frequencies, setFrequencies] = useState([
    { label: "Morning", selected: false },
    { label: "Noon", selected: false },
    { label: "Evening", selected: false },
  ]);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector(selectMedications);
  const { renderAutocomplete, providerName } = useProvider(
    true,
    searchParams.get("healthCareProvider")
  );
  const { newlyAddedId } = useSelector(selectHealthCareProviders);

  const handleMedicationSubmit = (e) => {
    e.preventDefault();

    dispatch(
      addMedication({
        medicationName,
        date: moment(date).utc(),
        isInactive,
        prescriber: providerName.healthCareProviderName,
        dosage,
        frequencies,
        additionalNotes,
        healthCareProvider: newlyAddedId,
      })
    );
    navigate("/my-health-record/medications");
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={handleMedicationSubmit}>
        <div className={styles.header}>
          <Link
            to="/my-health-record/medications"
            className={`${btn.links} ${btn.secondaryLink}`}
          >
            Close
          </Link>
          <h3>New Medication</h3>
          <button type="submit" className={`${btn.links} ${btn.primaryLink}`}>
            Add
          </button>
        </div>

        <BodyForm desktop={styles.body}>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <div className={styles.switchField}>
            <FormControlLabel
              control={
                <Switch
                  checked={!isInactive}
                  onChange={(e) => setIsInactive(!e.target.checked)}
                  label="Active Status"
                />
              }
              label={`Active Status: ${isInactive ? "inactive" : "active"}`}
            />
          </div>
          <TextField
            className={styles.textField}
            label="Medication"
            type="text"
            placeholder="Enter medication name"
            name="name"
            required
            value={medicationName}
            onChange={(e) => setMedicationName(e.target.value)}
            size="small"
          />

          <TextField
            className={styles.textField}
            label="Prescription start date"
            type="datetime-local"
            name="day"
            required
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            size="small"
          />
          {renderAutocomplete()}
          <TextField
            className={styles.textField}
            label="Dosage"
            type="text"
            placeholder="Enter dosage"
            name="dosage"
            value={dosage}
            onChange={(e) => setDosage(e.target.value)}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Side Effects"
            type="text"
            placeholder="Enter side effects"
            name="sideEffects"
            value={sideEffects}
            onChange={(e) => setSideEffects(e.target.value)}
            size="small"
            multiline={true}
            rows={3}
          />
          <TextField
            className={styles.textField}
            label="Additional Notes"
            type="text"
            placeholder="Enter additional notes (refills, insurance coverage, etc.)"
            name="additionalNotes"
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            size="small"
            multiline={true}
            rows={3}
          />

          <FrequencySelector
            frequencies={frequencies}
            setFrequencies={(val) => setFrequencies(val)}
          />
          <button
            type="submit"
            className={`${btn.btn} ${btn.primary}`}
          >
            {loading ? "Adding..." : "Add"}
          </button>
        </BodyForm>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default AddMedicationScreen;
