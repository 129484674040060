import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import PropTypes from "prop-types";

/**
 * Toast Component
 *
 * Displays a notification message in a toast.
 *
 * Props:
 * message: string or ReactNode
 *   The message to display in the Toast.
 *
 * onClickHandler: function
 *   A callback function to be executed when the toast is clicked.
 *
 * onCloseHandler: function
 *   A callback function to be executed when the close button is clicked.
 *
 * isOpen: boolean
 *   A flag to indicate if the Toast is open or not.
 *
 * Usage:
 * <Toast
 *   message={<toastMessage>}
 *   onClickHandler={onClickMethod}
 *   onCloseHandler={onCloseMethod}
 *   isOpen={<boolean>}
 * />
 */
const Toast = ({
  message,
  onClickHandler,
  onCloseHandler,
  isOpen,
  className,
}) => {
  return (
    <>
      <Snackbar
        className={className}
        open={isOpen}
        autoHideDuration={8000}
        onClick={onClickHandler}
        message={message}
        onClose={onCloseHandler}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={
          <Button color="inherit" size="small" onClick={onCloseHandler}>
            Close
          </Button>
        }
      />
    </>
  );
};

Toast.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Toast;
