import moment from "moment";
import { addPathwayNote } from "Reducers/PathwayNoteReducer";

const undoPathwayNote = (dispatch) => {
  const appointmentNote = JSON.parse(localStorage.getItem("tempPathwayNote"));

  dispatch(
    addPathwayNote({
      textReview: appointmentNote.textReview,
      date: moment(appointmentNote.date).utc(),
      emotions: appointmentNote.emotions,
      pathway: appointmentNote.pathway,
    })
  );
};

export default undoPathwayNote;
