import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/UpdateCondition.module.css";
import { useProvider } from "Hooks/useProvider";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteCondition,
  getCondition,
  resetCondition,
  selectConditions,
  updateCondition,
} from "Reducers/ConditionReducer";
import { selectHealthCareProviders } from "Reducers/HealthCareProvidersReducer";

const UpdateConditionScreen = () => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [additionalNotes, setAdditionalNotes] = useState([]);
  const [conditionName, setConditionName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  const { newlyAddedId } = useSelector(selectHealthCareProviders);
  const redirectUrl = useRedirect("/my-health-record/condition-history");
  const { loading, error, selectedCondition } = useSelector(selectConditions);
  const { renderAutocomplete, setProviderName, providerName } = useProvider();

  const dispatch = useDispatch();

  const updateConditionSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateCondition({
        id,
        date: moment(date).utc(),
        conditionName,
        healthCareProfessional: providerName.healthCareProviderName,
        additionalNotes,
        healthCareProvider: newlyAddedId,
      })
    );

    if (!loading && !error) {
      navigate("/my-health-record/condition-history");
    }
  };
  const deleteConditionAction = (e) => {
    e.preventDefault();

    localStorage.setItem("tempCondition", JSON.stringify(selectedCondition));
    dispatch(deleteCondition(selectedCondition._id));

    if (!loading && !error) {
      navigate("/my-health-record/condition-history");
    }
  };

  const fillFields = () => {
    if (selectedCondition) {
      setDate(moment(selectedCondition.date).format("YYYY-MM-DDTHH:mm"));
      setConditionName(selectedCondition.conditionName);
      setAdditionalNotes(selectedCondition.additionalNotes);
      setProviderName(selectedCondition.healthCareProfessional);
    }
  };

  useEffect(() => {
    dispatch(getCondition(id));

    if (error) {
      setErrorMessage(error);
    }
    fillFields();
  }, [error, dispatch, selectedCondition?.conditionName]);

  useEffect(() => {
    return () => {
      dispatch(resetCondition());
    };
  }, []);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={updateConditionSubmit}>
        <div className={styles.header}>
          <button
            className={`${btn.links} ${btn.secondaryLink}`}
            onClick={() => navigate(redirectUrl)}
            type="button"
          >
            Cancel
          </button>
          <h3>Update Condition</h3>
          <button type="submit" className={`${btn.links} ${btn.primaryLink}`}>
            Update
          </button>
        </div>

        <BodyForm desktop={styles.body}>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <TextField
            className={styles.textField}
            label="Condition"
            type="text"
            placeholder="Enter condition name"
            name="name"
            required
            value={conditionName}
            onChange={(e) => setConditionName(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Date of diagonsis"
            type="datetime-local"
            name="day"
            required
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />

          {renderAutocomplete()}

          <TextField
            className={styles.textField}
            label="Additional Notes"
            type="text"
            placeholder="Enter notes"
            name="name"
            multiline
            rows={10}
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            size="small"
          />
          <div className={styles.btns}>
            <button
              type="button"
              className={`${btn.btn} ${btn.secondary}`}
              onClick={deleteConditionAction}
            >
              Delete
            </button>
            <button
              type="submit"
              className={`${btn.btn} ${btn.primary}`}
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        </BodyForm>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default UpdateConditionScreen;
