import styles from "CSS/Screens/AmbientSounds.module.css";
import BoxItem from "Components/Helpers/BoxItem";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import { BiCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const ambientSounds = [
    { name: "White Noise", link: "/toolkit/ambient-sounds-screen", audioSrc: "/audio/white-noise.mp3" },
    { name: "Grey Noise", link: "/toolkit/ambient-sounds-screen", audioSrc: "/audio/grey-noise.mp3" },
    { name: "Brown Noise", link: "/toolkit/ambient-sounds-screen", audioSrc: "/audio/brown-noise.mp3" },
    { name: "Rain Sound", link: "/toolkit/ambient-sounds-screen", audioSrc: "/audio/rain-sound.mp3" },
    { name: "Others", link: "/toolkit" }
];

const AmbientSoundList = () => {
    const navigate = useNavigate();

    return (
        <Screen>
            <Wrapper>
                <BackBtn title="Back to toolkit" link="/toolkit" />
                <h1>Choose an Ambient Sound</h1>
                <div className={styles.toolKitScreen}>
                    {ambientSounds.map((sound, index) => (
                        <BoxItem
                            key={index}
                            text={sound.name}
                            icon={<BiCircle className={styles.toolKitItem_icon} size={24} />}
                            onClick={() => navigate(sound.link, { state: { audioSrc: sound.audioSrc } })}
                        />
                    ))}
                </div>
            </Wrapper>
        </Screen>
    );
};

export default AmbientSoundList;
