import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";

const generateResetCode = createAsyncThunk("users/generate-reset-code", async (email) => {
  try {
    const { data } = await axiosInstance.post("/reset-password/", { email });

    return data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
});

const verifyResetCode = createAsyncThunk("users/verify-reset-code", async (resetCodeData) => {
  try {
    console.log(resetCodeData);
    const { data } = await axiosInstance.post("/reset-password/verify-reset-code", resetCodeData);

    return data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
});

const resetPassword = createAsyncThunk("users/reset-code", async (resetCodeData) => {
  try {
    const { data } = await axiosInstance.put("/reset-password", resetCodeData);

    return data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
});

export { generateResetCode, resetPassword, verifyResetCode };

