import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/ActivityScreen.module.css";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import {
  addActivityToPathway,
  getActivityHistory,
} from "Reducers/Actions/ActivityActions";
import { selectActivity } from "Reducers/ActivityReducer";
import {
  getPathways,
  selectPathways,
} from "Reducers/PathwayReducer";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const ActivityHistoryScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activityHistory } = useSelector(selectActivity);
  const [selectedPathway, setSelectedPathway] = useState("");
  const { pathways } = useSelector(selectPathways);

  useEffect(() => {
    dispatch(getActivityHistory(id));
    dispatch(getPathways());
  }, [id]);

  const handleCreateHabitClick = () => {
    navigate(`/add-habit?name=${activityHistory.name}`);
  };

  const handleActivityCompletedClick = () => {
    navigate(`/post-activities-entry/${activityHistory._id}`);
  };

  const handleAddToPathwayChange = (e) => {
    setSelectedPathway(e.target.value);
    dispatch(
      addActivityToPathway({
        pathwayId: e.target.value,
        activityId: activityHistory._id,
      })
    );
  };

  const ActivityHistory = activityHistory.history.map((activity, index) => (
    <div className={`${styles.historyContainer}`} key={index}>
      <Grid item={true} xs={12}>
        <Grid container justifyContent="center">
          <Card
            className={`${styles.activityHistoryCard}`}
            sx={{ minWidth: 275 }}
          >
            <CardContent className={`${styles.historyCardContent}`}>
              <p className={`${styles.cardText}`}>
                <span className={`${styles.cardTextGrey}`}>Date </span>
                {moment(activity.updatedAt).format("MMMM Do YYYY, h:mm a")}
              </p>
              <p className={`${styles.cardText}`}>
                <span className={`${styles.cardTextGrey}`}>
                  Managed the activity?{" "}
                </span>
                {activity.isCompleted ? "Yes" : "No"}
              </p>
              <p className={`${styles.cardText}`}>
                <span className={`${styles.cardTextGrey}`}>Mood keywords </span>
                {activity.emotions.map((emotion, key) =>
                  emotion.selected ? (
                    <span key={key}>{emotion.label} </span>
                  ) : (
                    <span key={key}></span>
                  )
                )}
              </p>
              <p>{activity.textReview}</p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  ));

  return (
    <Screen>
      <Wrapper>
        <BackBtn title="Back to Activities" link="/activities" />
        {activityHistory && activityHistory.history.length > 0 && (
          <div className={`${styles.container} ${styles.activityCheckin}`}>
            <p className={`${styles.center}`}>{activityHistory.name}</p>
            <Grid container spacing={0}>
              <Grid item xs={12} className={btn.groupColumn}>
                <button
                  className={`${btn.btn} ${btn.complete} ${btn.FullWidth}`}
                  onClick={handleActivityCompletedClick}
                >
                  I did this today
                </button>
                <button
                  className={`${btn.btn} ${btn.primary} ${btn.FullWidth}`}
                  onClick={handleCreateHabitClick}
                >
                  Create habit based on this activity
                </button>
              </Grid>
              {pathways.active && pathways.active.length > 0 && (
                <Grid
                  item={true}
                  xs={16}
                  style={{ marginTop: "1em" }}
                  className={`${styles.center}`}
                >
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Add to Pathway
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={selectedPathway}
                      onChange={handleAddToPathwayChange}
                      label="Age"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {pathways.active.map((plan, index) => (
                        <MenuItem value={plan._id} key={index}>
                          {plan.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </div>
        )}
        {activityHistory && activityHistory.history.length === 0 ? (
          <div className={`${styles.container}`}>
            <Alert severity="info">You have never done this activity</Alert>
          </div>
        ) : (
          <div className={`${styles.historyContainer}`}>
            <h3 className={`${styles.center}`}>History</h3>
            {activityHistory && ActivityHistory}
          </div>
        )}
      </Wrapper>
    </Screen>
  );
};

export default ActivityHistoryScreen;
