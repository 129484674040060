import { Chip } from "@mui/material";
import styles from "CSS/Components/EmotionsSelector.module.css";
import {
  handleEmotions
} from "../../Utilities/emotions-selector";

const EmotionsSelector = ({ emotions, handleAddEmotion, setEmotions }) => {
  return (
    <div className={styles.emotions}>
      {emotions.map((emotion, index) => (
        <Chip
          variant={emotion.selected ? "filled" : "outlined"}
          key={index}
          color="primary"
          label={emotion.label}
          onClick={() => setEmotions(handleEmotions(index, emotions))}
          className={`${emotion.selected ? styles.emotionsSelected : ""} ${styles.emotionChip
            }`}
        />
      ))}
      <Chip
        variant="filled"
        color="primary"
        label="+"
        className={styles.emotionAdd}
        onClick={handleAddEmotion}
      />
    </div>
  );
};

export default EmotionsSelector;
