import styles from "CSS/Components/BodyForm.module.css";
import { isMobile } from "react-device-detect";

// renders a mobile-responsive form body
const BodyForm = ({ children, desktop }) => {
  return (
    <div
      className={`${styles.bodyForm} ${isMobile ? styles.bodyMobile : desktop}`}
    >
      {children}
    </div>
  );
};
export default BodyForm;
