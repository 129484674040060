import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to get a pathway
const getPathway = createAsyncThunk("get pathway", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/pathway/${id}`),
    thunkAPI
  )
);

// send backend request to get pathways
const getPathways = createAsyncThunk("get pathways", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/pathway"),
    thunkAPI
  )
);

// send backend request to add new pathway
const addPathway = createAsyncThunk("add pathway", (
  { title, desiredOutcome, motivationalSubtitle, yourHow, habits, checkin }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/pathway",
      {
        title,
        desiredOutcome,
        motivationalSubtitle,
        yourHow,
        habits,
        checkin,
      }
    ),
    thunkAPI
  )
);

// send backend request to update a pathway
const updatePathway = createAsyncThunk("update pathway", (
  { _id, title, desiredOutcome, motivationalSubtitle, yourHow, isArchived }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/pathways/${_id}`,
      {
        title,
        desiredOutcome,
        motivationalSubtitle,
        yourHow,
        isArchived,
      }
    ),
    thunkAPI
  )
);

// send backend request to delete specific pathway
const deletePathway = createAsyncThunk("delete pathway", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/pathway/${id}`),
    thunkAPI
  )
);


export {
  addPathway, deletePathway, getPathway, getPathways, updatePathway
};

