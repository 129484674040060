import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import styles from "CSS/Components/ToggleCard.module.css";
import PropTypes from "prop-types";
import LoadingSpinner from "./LoadingSpinner";

/**
 * ToggleSwitch is a React component that displays a toggle switch.
 *
 * The component requires the following props to function correctly:
 * - id: a unique identifier for the toggle switch
 * - checked: a boolean value that represents the current state of the switch
 * - onChange: a callback function that is triggered when the switch state changes
 *
 * The following props are optional:
 * - name: a string that sets the name attribute for the toggle switch (used for accessibility)
 * - small: a boolean value that makes the toggle switch smaller if set to true
 * - disabled: a boolean value that disables the toggle switch if set to true
 * - optionLabels: an array of two strings that sets the label for each toggle option
 * - isJournal: a boolean value that check if the toogle is for journal.
 *    This is used because, journal will have time picker in them (default = false)
 *
 * Example usage:
 * <ToggleSwitch
 *   id="notification-toggle"
 *   checked={notificationsEnabled}
 *   isJournal={true/false}
 *   onChange={checked => setNotificationsEnabled(checked)}
 * />
 */
const ToggleCard = ({
  cardLabel,
  subLabel,
  isChecked,
  onChange,
  isJournal = false,
  time,
  onTimeChange,
  loading,
  style,
  required = true,
}) => {
  return (
    <div className={styles.card} style={style}>
      <div style={{ marginLeft: "1em" }}>
        <b>{cardLabel}</b>
        <br></br>
        {isJournal ? (
          <TextField
            type="time"
            name="day"
            required={required}
            value={time}
            onChange={(e) => onTimeChange(e.target.value)}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        ) : (
          <p>
            <span style={{ fontWeight: 100 }}>{subLabel}</span>
          </p>
        )}
      </div>
      {
        loading ? (<div style={{ marginRight: "10px" }}><LoadingSpinner /></div>)
          : <Switch checked={isChecked} onChange={onChange} />
      }
    </div>
  );
};

ToggleCard.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isJournal: PropTypes.bool,
  time: PropTypes.string,
  onTimeChange: PropTypes.func,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  cardLabel: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  loading: PropTypes.bool,
  required: PropTypes.bool,
};

export default ToggleCard;
