import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/UpdateAppointmentNoteScreen.module.css";
import useEmotionSelector from "Hooks/useEmotionSelector";
import { useProvider } from "Hooks/useProvider";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteAppointmentNote,
  getAppointmentNote,
  resetAppointmentNote,
  selectAppointmentNotes,
  updateAppointmentNote,
} from "Reducers/AppointmentNoteReducer";
import { selectHealthCareProviders } from "Reducers/HealthCareProvidersReducer";

const UpdateAppointmentNoteScreen = () => {
  const { selectedAppointmentNote, loading, error } = useSelector(
    selectAppointmentNotes
  );
  const { newlyAddedId } = useSelector(selectHealthCareProviders);
  const { id } = useParams();

  const [dateTime, setDateTime] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [thingsToDiscuss, setThingsToDiscuss] = useState("");
  const [appointmentNotes, setAppointmentNotes] = useState("");
  const [followUp, setFollowUp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { emotions, setEmotions, renderModal, renderSelector } =
    useEmotionSelector();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectUrl = useRedirect("/journal");
  const { renderAutocomplete } = useProvider(true);

  // autofills all the fields for the given journal entry
  const fillFields = () => {
    if (selectedAppointmentNote) {
      setDateTime(
        moment(selectedAppointmentNote.date).local().format("YYYY-MM-DDTHH:mm")
      );
      setThingsToDiscuss(selectedAppointmentNote.thingsToDiscuss);
      setAppointmentNotes(selectedAppointmentNote.appointmentNotes);
      setFollowUp(selectedAppointmentNote.followUp);
      setEmotions(selectedAppointmentNote.emotions);
    }
  };

  const updateAppointmentNoteSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateAppointmentNote({
        id,
        date: moment(dateTime).utc(),
        thingsToDiscuss,
        appointmentNotes,
        emotions,
        followUp,
        healthCareProvider: newlyAddedId,
      })
    );

    // Success so go back to history page
    if (!loading && !error) {
      navigate("/journal");
    }
  };

  const deleteAppointmentNoteAction = (e) => {
    e.preventDefault();

    localStorage.setItem(
      "tempAppointmentNote",
      JSON.stringify(selectedAppointmentNote)
    );
    dispatch(deleteAppointmentNote(selectedAppointmentNote._id));

    // Success so go back to history page
    if (!loading && !error) {
      navigate("/journal");
    }
  };

  useEffect(() => {
    if (selectedAppointmentNote?._id !== id) {
      dispatch(getAppointmentNote(id));
    }

    setErrorMessage(error);

    fillFields();
  }, [error, selectedAppointmentNote?.providerName]);

  useEffect(() => {
    return () => {
      dispatch(resetAppointmentNote());
    };
  }, []);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={updateAppointmentNoteSubmit}>
        <div className={styles.header}>
          <button
            className={`${styles.appointmentBtn} ${styles.appointmentBtn_orange}`}
            onClick={() => navigate(redirectUrl)}
            type="button"
          >
            Cancel
          </button>
          <h3 style={{ textAlign: "center" }}>Update <br /> Appointment Note</h3>
          <button
            type="submit"
            className={`${styles.appointmentBtn} ${styles.appointmentBtn_blue}`}
          >
            Update
          </button>
        </div>
        <div className={`${styles.body} ${isMobile ? styles.bodyMobile : ""}`}>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />

          {renderModal()}

          <Grid
            container
            justifyContent="center"
            spacing={2}>
            <Grid item xs={10}>
              <TextField
                style={{ width: "100%" }}
                className={styles.datetextarea}
                autoFocus={!isMobile}
                type="datetime-local"
                name="day"
                required={true}
                value={dateTime}
                onChange={(e) => setDateTime(e.target.value)}
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={10}>
              {renderAutocomplete()}
            </Grid>

            <Grid item xs={10}>
              <TextField
                label="Things to Discuss"
                multiline
                style={{ width: "100%" }}
                rows={3}
                placeholder="Enter discussion points"
                name="discussionPoints"
                required={false}
                value={thingsToDiscuss}
                onChange={(e) => setThingsToDiscuss(e.target.value)}
                variant="outlined"
                inputProps={{
                  maxLength: 500,
                }}
                size="small"
              />
            </Grid>

            <Grid item xs={10}>
              <TextField
                label="Appointment Notes"
                multiline
                rows={3}
                style={{ width: "100%" }}
                placeholder="Enter appointment notes"
                name="appointmentNotes"
                required={false}
                value={appointmentNotes}
                onChange={(e) => setAppointmentNotes(e.target.value)}
                variant="outlined"
                inputProps={{
                  maxLength: 500,
                }}
                size="small"
              />
            </Grid>


            <Grid item xs={10}>
              <TextField
                style={{ width: "100%" }}
                label="Follow Up"
                multiline
                rows={2}
                placeholder="What are some follow ups?"
                name="followUp"
                required={false}
                value={followUp}
                onChange={(e) => setFollowUp(e.target.value)}
                variant="outlined"
                inputProps={{
                  maxLength: 500,
                }}
                size="small"
              />
            </Grid>

            <Grid item xs={10}>
              {renderSelector()}
            </Grid>

          </Grid>
          <div className={styles.btns}>
            <button
              type="button"
              className={`${btn.btn} ${btn.faded_orange} ${styles.delete}`}
              onClick={deleteAppointmentNoteAction}
            >
              Delete
            </button>
            <button
              type="submit"
              className={`${btn.btn} ${btn.faded_blue} ${styles.submit}`}
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default UpdateAppointmentNoteScreen;
