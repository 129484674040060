import moment from "moment";
import { addMedication } from "Reducers/MedicationsReducer";

const undoMedication = (dispatch) => {
  const medication = JSON.parse(localStorage.getItem("tempMedication"));

  dispatch(
    addMedication({
      medicationName: medication.medicationName,
      dosage: medication.dosage,
      sideEffects: medication.sideEffects,
      additionalNotes: medication.additionalNotes,
      isInactive: medication.isInactive,
      prescriber: medication.prescriber,
      date: moment(medication.date),
    })
  );
};

export default undoMedication;
