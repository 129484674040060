import { Chip } from "@mui/material";
import styles from "CSS/Components/FrequencySelector.module.css";
import { handleFrequencies } from "Utilities/frequency-selector";

const FrequenciesSelector = ({ frequencies, setFrequencies }) => {
  return (
    <div className={styles.frequency}>
      {frequencies.map((frequency, index) => (
        <Chip
          variant={frequency.selected ? "filled" : "outlined"}
          key={index}
          color="primary"
          label={frequency.label}
          onClick={() => setFrequencies(handleFrequencies(index, frequencies))}
          className={`${frequency.selected ? styles.frequencySelected : ""} ${styles.frequencyChip
            }`}
        />
      ))}
    </div>
  );
};

export default FrequenciesSelector;
