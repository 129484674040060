import { createSlice } from "@reduxjs/toolkit";
import { addCognitiveDistortionEntry, getCognitiveDistortionEntries, getCongitiveDistortions } from "./Actions/CognitiveDistortionActions";

const initialState = {
  loading: false,
  error: null,
  situation: "",
  thoughts: "",
  successAdd: false,
  cognitiveDistortions: null,
  selectedDistortions: [], // List of disortion objects selected.
  selectedDistortionIds: [], // List of distortion id selected.
  distortionEntries: [],
  selectedDistortion: null, // Distortion selected for learn more.
  selectedDistortionEntry: null,
};

// functions for "useSelector"
const selectCognitiveDistortion = (state) => state.cognitiveDistortion;

const cognitiveDistortionSlice = createSlice({
  name: "cognitiveDistortion",
  initialState,
  reducers: {
    resetCognitiveDistortion: () => initialState,
    setSituation: (state, { payload }) => {
      return {
        ...state,
        situation: payload
      };
    },
    setThoughts: (state, { payload }) => {
      return {
        ...state,
        thoughts: payload,
      };
    },
    resetAddDistortionEntrySuccess: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    addSelectedDistortions: (state, { payload }) => {
      // Check if the selected distortions array already has an 
      // item with the same ID as the payload.
      const hasSelectedDistortion = state.selectedDistortions
        .some(distortion => distortion._id === payload._id);

      if (!hasSelectedDistortion) {

        // Create a new array with the selected distortions 
        // plus the new payload item.
        const listOfSelectedIds = [...state.selectedDistortionIds, payload._id];
        const newSelectedDistortions = [...state.selectedDistortions, payload];

        return {
          ...state,
          selectedDistortions: newSelectedDistortions,
          selectedDistortionIds: listOfSelectedIds,
        };
      }

      // If the selected distortions array already has an 
      // item with the same ID as the payload,
      // return the original state object without any changes
      return { ...state };
    },
    removeSelectedDistortions: (state, { payload }) => {
      // Only remove the distortion if its selected.
      const updated = state.selectedDistortions
        .filter(distortion => distortion._id !== payload._id);
      const updatedIdList = state.selectedDistortionIds
        .filter(id => id !== payload._id);
      return {
        ...state,
        selectedDistortions: updated,
        selectedDistortionIds: updatedIdList,
      };
    },
    addAnswer: (state, { payload }) => {
      const { distortionId, questionId, answer } = payload;

      // Map over the selectedDistortions array to find the distortion with the matching distortionId
      const updatedDistortions = state.selectedDistortions.map(distortion => {
        if (distortion._id === distortionId) {

          // If the distortionId matches, map over the distortionQuestions array 
          // to find the question with the matching questionId
          const updatedDistortionQuestions = distortion.distortionQuestions.map(question => {
            if (question._id === questionId) {

              // If the questionId matches, update the answer property with the 
              // newAnswer parameter using the spread operator
              return { ...question, answer: answer };
            } else {

              // If the questionId doesn't match, return the original question object
              return question;
            }
          });

          // After all the questions have been mapped over, return a new distortion object 
          // with the updated distortionQuestions array using the spread operator
          return { ...distortion, distortionQuestions: updatedDistortionQuestions };
        } else {
          return distortion;
        }
      });

      return { ...state, selectedDistortions: updatedDistortions };
    },
    updateSelectedDistortion: (state, { payload }) => {
      // Update the selected distortion.
      return {
        ...state,
        selectedDistortion: payload,
      };
    },
    setSelectedDistortionEntry: (state, { payload }) => {
      // Update the selected distortion entry.
      return {
        ...state,
        selectedDistortionEntry: payload,
      };
    },
    resetCurrentDistortionEntry: (state) => {
      return {
        ...state,
        thoughts: "",
        situation: "",
        selectedDistortions: [],
        selectedDistortionIds: [],
        selectedDistortion: null,
      };
    },
  },
  extraReducers: {
    // Get all current cognitive distortions.
    [getCongitiveDistortions.pending]: (state) => {
      state.loading = true;
    },
    [getCongitiveDistortions.fulfilled]: (state, { payload }) => {
      // Assigning empty answer property for all questions.
      payload.map((distortion) =>
        distortion.distortionQuestions.map((question) => question["answer"] = ""));
      state.cognitiveDistortions = payload;
      state.loading = false;
    },
    [getCongitiveDistortions.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add cognitive distortion entry
    [addCognitiveDistortionEntry.pending]: (state) => {
      state.loading = true;
    },
    [addCognitiveDistortionEntry.fulfilled]: (state) => {
      state.loading = false;
      state.successAdd = true;
    },
    [addCognitiveDistortionEntry.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get cognitive distortion entries
    [getCognitiveDistortionEntries.pending]: (state) => {
      state.loading = true;
    },
    [getCognitiveDistortionEntries.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.distortionEntries = payload;
    },
    [getCognitiveDistortionEntries.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default cognitiveDistortionSlice.reducer;

export {
  addCognitiveDistortionEntry, getCognitiveDistortionEntries, getCongitiveDistortions, selectCognitiveDistortion
};

export const {
  resetCognitiveDistortion,
  setSituation,
  setThoughts,
  addSelectedDistortions,
  removeSelectedDistortions,
  addAnswer,
  resetAddDistortionEntrySuccess,
  updateSelectedDistortion,
  setSelectedDistortionEntry,
  resetCurrentDistortionEntry,
} = cognitiveDistortionSlice.actions;
