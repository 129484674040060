import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

const getSubscription = createAsyncThunk("get subscription", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/subscription"),
    thunkAPI
  )
);

const subscribe = createAsyncThunk("subscribe to notification", async (subscription, thunkAPI) => {
  try {

    const { data } = await axiosInstance.post("/subscription", subscription);
    localStorage.setItem("subscription", JSON.stringify(data));

    return data;
  } catch (e) {
    const { rejectWithValue } = thunkAPI;
    return rejectWithValue(e.response.data.message);
  }
});

const subscribeToJournal = createAsyncThunk("subscribe to journal notification", (subscription, thunkAPI) =>
  actionHandler(
    axiosInstance.put("/subscription/journal", subscription),
    thunkAPI
  )
);

const subscribeToApointment = createAsyncThunk("subscribe to apointment notification", (subscription, thunkAPI) =>
  actionHandler(
    axiosInstance.put("/subscription/apointment", subscription),
    thunkAPI
  )
);

const subscribeToToDos = createAsyncThunk("subscribe to todos notification", (subscription, thunkAPI) =>
  actionHandler(
    axiosInstance.put("/subscription/todo", subscription),
    thunkAPI
  )
);

const subscribeToJournalToDo = createAsyncThunk("subscribe to journal todos notification", (subscription, thunkAPI) =>
  actionHandler(
    axiosInstance.put("/subscription/journal-todo", subscription),
    thunkAPI
  )
);

const subscribeToHabits = createAsyncThunk("subscribe to habit notification", (subscription, thunkAPI) =>
  actionHandler(
    axiosInstance.put("/subscription/habit", subscription),
    thunkAPI
  )
);

export {
  getSubscription,
  subscribe,
  subscribeToApointment,
  subscribeToHabits,
  subscribeToJournal,
  subscribeToJournalToDo,
  subscribeToToDos
};