// sets the new frequency
const getNewFrequencyTitle = (frequency, interval) => {
  const s = interval > 1 ? "s" : "";
  if (frequency === "daily") {
    return "day" + s;
  } else if (frequency === "weekly") {
    return "week" + s;
  } else {
    return "month" + s;
  }
};

export { getNewFrequencyTitle };
