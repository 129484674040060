import { MenuItem, Select } from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import UndoButton from "Components/Helpers/UndoButton";
import Wrapper from "Components/Helpers/Wrapper";
import AddFabJournal from "Components/Journal/AddFabJournal";
import PathwayNote from "Components/Pathway/PathwayNote";
import styles from "CSS/Screens/JournalScreen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPathwayNotes,
  resetPathwayNoteAdd,
  resetPathwayNoteDelete,
  selectPathwayNotes,
  setActiveStatus,
} from "Reducers/PathwayNoteReducer";
import undoPathwayNote from "Utilities/undo-pathwayNote";

const PathwayNoteHistoryScreen = () => {
  const dispatch = useDispatch();
  const [showUndo, setShowUndo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { past, loading, error, successAdd, successDelete, activeStatus } =
    useSelector(selectPathwayNotes);

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new pathway review!");
      timer = setTimeout(() => {
        dispatch(resetPathwayNoteAdd());
      }, 2000);
    }
    return timer;
  };

  // displays undo button when entry is deleted
  const handleSuccessDelete = () => {
    if (successDelete) {
      // show undo popup after user deletes entry
      setShowUndo(true);
      setTimeout(() => {
        setShowUndo(false);
        dispatch(resetPathwayNoteDelete());
      }, 5000);
    }
  };

  useEffect(() => {
    // fetch all Pathway notes
    dispatch(getPathwayNotes({ limit: 100000 })); //possible limit

    if (error) {
      //removed error
      setErrorMessage(error);
    }
  }, [dispatch, error]); //remove errror

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();
    handleSuccessDelete();

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, successAdd, successDelete]);

  const displayList = (list, isArchived) =>
    list?.map(
      (pathwayNote) =>
        pathwayNote.isArchived === isArchived && (
          <PathwayNote
            key={pathwayNote._id}
            pathwayNote={pathwayNote}
          />
        )
    );

  return (
    <>
      <Wrapper>
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        {/* display success message when user adds entry */}
        <AlertMessage
          message={successMessage}
          setMessage={() => {
            setSuccessMessage("Added new pathway note!");
            dispatch(resetPathwayNoteAdd());
          }}
          type="success"
          toggle={successAdd}
        />

        {loading && past?.length === 0 && <LoadingSpinner />}
        <div
          className={`${styles.journalEntry_header} ${styles.journalEntry_completedHeader}`}
        >
          <h3>📝 Pathway Notes</h3>
          <Select
            sx={{ borderRadius: "15px", height: "30px" }}
            className={`${styles.medication_filterSelect}`}
            value={activeStatus}
            onChange={(e) => dispatch(setActiveStatus(e.target.value))}
          >
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"inactive"}>Archived</MenuItem>
          </Select>
        </div>
        {past?.length === 0 && (
          <p className={styles.journalEntry_filler}>No past entries!</p>
        )}
        {displayList(past, activeStatus === "inactive")}
      </Wrapper>
      <UndoButton
        callback={() => {
          setShowUndo(false);
          dispatch(resetPathwayNoteDelete());
          undoPathwayNote(dispatch);
        }}
        showUndo={showUndo}
      />
      <AddFabJournal page="3" />
    </>
  );
};

export default PathwayNoteHistoryScreen;
