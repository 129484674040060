import { TextField } from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import Screen from "Components/Helpers/Screen";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/EditBloodTypeScreen.module.css";
import moment from "moment";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectHealthInfo, updateBloodType } from "Reducers/HealthInfoReducer";

const EditBloodTypeScreen = () => {
  const { loading, error } = useSelector(selectHealthInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const [dateTime, setDateTime] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [bloodType, setBloodType] = useState("");

  const updateBloodTypeSubmit = (e) => {
    e.preventDefault();

    dispatch(updateBloodType(bloodType));

    // If successful, return to previous screen
    if (!loading && !error) {
      navigate("/health-info");
    }
  };

  return (
    <Screen>
      <WrapperForm onSubmit={updateBloodTypeSubmit}>
        <div className={`${styles.body} ${isMobile ? styles.bodyMobile : ""}`}>
          <TextField
            className={styles.datetextarea}
            type="datetime-local"
            name="day"
            required={true}
            value={dateTime}
            onChange={(e) => setDateTime(e.target.value)}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          {errorMessage && (
            <AlertMessage
              sx={{ width: "80%", marginBottom: "15px" }}
              severity="error"
              onClose={() => setErrorMessage("")}
            >
              {errorMessage}
            </AlertMessage>
          )}
          <h1 style={{ margin: "10px" }}>Blood Type</h1>
          <div className={`${btn.center}`} style={{ marginBottom: "20px" }}>
            <button
              className={`${btn.journalentrybtn} ${btn.white_btn}`}
              style={{ padding: "5px", minWidth: "50px", minHeight: "50px" }}
              type="button"
              onClick={() => setBloodType("A+")}
            >
              A+
            </button>
            <button
              className={`${btn.journalentrybtn} ${btn.white_btn}`}
              style={{ padding: "5px", minWidth: "50px", minHeight: "50px" }}
              type="button"
              onClick={() => setBloodType("B+")}
            >
              B+
            </button>
            <button
              className={`${btn.journalentrybtn} ${btn.white_btn}`}
              style={{ padding: "5px", minWidth: "50px", minHeight: "50px" }}
              type="button"
              onClick={() => setBloodType("AB+")}
            >
              AB+
            </button>
            <button
              className={`${btn.journalentrybtn} ${btn.white_btn}`}
              style={{ padding: "5px", minWidth: "50px", minHeight: "50px" }}
              type="button"
              onClick={() => setBloodType("O+")}
            >
              O+
            </button>
          </div>

          <div className={`${btn.center}`} style={{ marginBottom: "10px" }}>
            <button
              className={`${btn.journalentrybtn} ${btn.white_btn}`}
              style={{ padding: "5px", minWidth: "50px", minHeight: "50px" }}
              type="button"
              onClick={() => setBloodType("A-")}
            >
              A-
            </button>
            <button
              className={`${btn.journalentrybtn} ${btn.white_btn}`}
              style={{ padding: "5px", minWidth: "50px", minHeight: "50px" }}
              type="button"
              onClick={() => setBloodType("B-")}
            >
              B-
            </button>
            <button
              className={`${btn.journalentrybtn} ${btn.white_btn}`}
              style={{ padding: "5px", minWidth: "50px", minHeight: "50px" }}
              type="button"
              onClick={() => setBloodType("AB-")}
            >
              AB-
            </button>
            <button
              className={`${btn.journalentrybtn} ${btn.white_btn}`}
              style={{ padding: "5px", minWidth: "50px", minHeight: "50px" }}
              type="button"
              onClick={() => setBloodType("O-")}
            >
              O-
            </button>
          </div>

          <div className={`${btn.center}`}>
            <button
              className={`${btn.journalentrybtn} ${btn.white_btn}`}
              onClick={() => navigate("/health-info")}
              type="button"
            >
              Cancel
            </button>
            <button
              className={`${btn.journalentrybtn} ${btn.orange_btn}`}
              type="submit"
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </WrapperForm>
    </Screen>
  );
};

export default EditBloodTypeScreen;
