import BodyForm from "Components/Helpers/BodyForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Components/Pathway/ReviewAddPathway.module.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addPathway,
  selectPathways,
} from "Reducers/PathwayReducer";

const ReviewAddPathway = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addPathwayInfo } = useSelector(selectPathways);
  const [habits, setHabits] = useState({ daily: [], weekly: [], monthly: [] });

  const setHabitsList = () => {
    const daily = [],
      weekly = [],
      monthly = [];

    addPathwayInfo.habits.map((habit) => {
      if (habit.frequency === "daily") {
        daily.push(habit);
      } else if (habit.frequency === "weekly") {
        weekly.push(habit);
      } else {
        monthly.push(habit);
      }
    });

    setHabits({ daily, weekly, monthly });
  };

  const displayHabits = (freq) => {
    if (habits[freq].length > 0) {
      return (
        <div className={styles.reviewWellness_section}>
          <h3>{freq} Habits</h3>
          <div className={styles.reviewWellness_sectionList}>
            {habits[freq].map((habit) => (
              <div className={styles.reviewWellness_item} key={habit.name}>
                <h4>{habit.name}</h4>
                <p>Goal: 0/1 {habit.unit === "count" ? "" : habit.unit}</p>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  const displayCheckin = () => {
    if (addPathwayInfo.review) {
      return (
        <div className={styles.reviewWellness_section}>
          <h3>Check-In</h3>
          <div className={styles.reviewWellness_sectionList}>
            <div className={styles.reviewWellness_item}>
              <h4>{addPathwayInfo.checkin.todoTitle}</h4>
              <div className={styles.reviewWellness_checkin}>
                <span>Starting: </span>
                <p>
                  {moment(addPathwayInfo.checkin.remindTime).format("MMMM DD")}
                </p>
              </div>
              <div className={styles.reviewWellness_checkin}>
                <span>Review: </span>
                <p>every {addPathwayInfo.checkin.recurrenceType.slice(0, -2)}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleCreatePathway = () => {
    navigate("/new-pathway/add");
    const data = { ...addPathwayInfo };
    delete data.list;
    // don't include checkin if user changed their mind
    if (!addPathwayInfo.review) {
      delete data.checkin;
    }
    dispatch(addPathway({ ...data }));
  };

  useEffect(() => {
    setHabitsList();
  }, [addPathwayInfo.habits]);

  return (
    <div className={styles.reviewWellness}>
      <BodyForm desktop={styles.reviewWellness_body}>
        <div className={styles.reviewWellness_header}>
          <h3>Review Your Plan</h3>
          <div className={styles.reviewWellness_info}>
            <h2>{addPathwayInfo.title}</h2>
            <h3>{addPathwayInfo.motivationalSubtitle}</h3>
            <p>
              Desired Outcome: <span>{addPathwayInfo.desiredOutcome}</span>
            </p>
            <p>
              {addPathwayInfo.yourHow && (
                <>
                  Your Why: <span>{addPathwayInfo.yourHow}</span>
                </>
              )}
            </p>
          </div>
        </div>
        <div className={styles.reviewWellness_habits}>
          {displayCheckin()}
          {displayHabits("daily")}
          {displayHabits("weekly")}
          {displayHabits("monthly")}
        </div>
        <div className={styles.reviewWellness_btns}>
          <button
            onClick={() => {
              if (addPathwayInfo.review) {
                navigate("/new-pathway?page=4");
                return;
              }
              if (addPathwayInfo.habits.length === 0) {
                navigate("/new-pathway?page=2");
                return;
              }
              navigate(
                `/new-pathway?page=3&item=${addPathwayInfo.habits.length - 1
                }`
              );
            }}
            className={`${btn.btn} ${btn.secondary}`}
          >
            Back
          </button>
          <button
            className={`${btn.btn} ${btn.primary}`}
            onClick={handleCreatePathway}
          >
            Create
          </button>
        </div>
      </BodyForm>
    </div>
  );
};
export default ReviewAddPathway;
