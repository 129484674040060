import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/UpdateAdditionalNote.module.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteAdditionalNote,
  getAdditionalNote,
  resetAdditionalNote,
  selectAdditionalNotes,
  updateAdditionalNote,
} from "Reducers/AdditionalNoteReducer";

const UpdateAdditionalNoteScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const { loading, error, selectedAdditionalNote } = useSelector(
    selectAdditionalNotes
  );

  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [additionalNotes, setAdditionalNotes] = useState([]);

  // Autofill fields for selected entry with existing data
  const fillFields = () => {
    if (selectedAdditionalNote) {
      setDate(
        moment(selectedAdditionalNote.date).local().format("YYYY-MM-DDTHH:mm")
      );
      setAdditionalNotes(selectedAdditionalNote.additionalNotes);
    }
  };

  const updateAdditionalNoteSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateAdditionalNote({
        id,
        date: moment(date).utc(),
        additionalNotes,
      })
    );

    // Return to Additional Notes History screen after success
    if (!loading && !error) {
      navigate("/my-health-record/additional-notes");
    }
  };

  const deleteAdditionalNoteAction = (e) => {
    e.preventDefault();

    localStorage.setItem(
      "tempAdditionalNote",
      JSON.stringify(selectedAdditionalNote)
    );
    dispatch(deleteAdditionalNote(selectedAdditionalNote._id));

    // Return to Additional Notes History screen after success
    if (!loading && !error) {
      navigate("/my-health-record/additional-notes");
    }
  };

  useEffect(() => {
    dispatch(getAdditionalNote(id));

    if (error) {
      setErrorMessage(error);
    }
    fillFields();
  }, [error, dispatch, selectedAdditionalNote?.additionalNotes]);

  useEffect(() => {
    return () => {
      dispatch(resetAdditionalNote());
    };
  }, []);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={updateAdditionalNoteSubmit}>
        <div className={styles.header}>
          <button
            className={`${btn.links} ${btn.secondaryLink}`}
            onClick={() => navigate("/my-health-record/additional-notes")}
            type="button"
          >
            Cancel
          </button>
          <h3>
            Update <br /> Additional Note
          </h3>
          <button type="submit" className={`${btn.links} ${btn.primaryLink}`}>
            Update
          </button>
        </div>

        <BodyForm desktop={styles.body}>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <TextField
            className={styles.textField}
            label="Date of diagnosis"
            type="datetime-local"
            name="day"
            required
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            autoFocus={!isMobile}
          />
          <TextField
            className={styles.textField}
            label="Additional Notes"
            type="text"
            placeholder="Enter additional notes"
            name="Additional Notes"
            multiline={true}
            rows={3}
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            size="small"
            autoFocus={!isMobile}
          />
          <div className={styles.btnContainer}>
            <button
              type="button"
              className={`${btn.btn} ${btn.secondary}`}
              onClick={deleteAdditionalNoteAction}
            >
              Delete
            </button>
            <button
              type="submit"
              className={`${btn.btn} ${btn.primary}`}
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        </BodyForm>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default UpdateAdditionalNoteScreen;
