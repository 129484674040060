import { Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/BehavioralExperimentEntry.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import CompleteIncompleteFilter from "Components/Helpers/CompleteIncompleteFilterMenu";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { useRedirect } from "Hooks/useRedirect";
import {
  filterExperiments,
  getBehavioralExperiments, resetBehavioralSuccess, selectBehavioralExperiment,
  setSelectedExperiment
} from "Reducers/BehavioralExperimentReducer";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const BehavioralHomeScreen = () => {
  const navigate = useNavigate();
  const redirectUrl = useRedirect("/toolkit");
  const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();
  const {
    successAdd,
    loading, filteredExperiments
  } = useSelector(selectBehavioralExperiment);

  const handleStartNewBehavioralExperiments = () => {
    navigate("/toolkit/behavioral-entries");
  };

  const handleEntryClick = (experimentEntry) => {
    dispatch(setSelectedExperiment(experimentEntry));
    navigate("/toolkit/view-experiment-entry");
  };

  useEffect(() => {
    dispatch(getBehavioralExperiments());
  }, [dispatch, successAdd]);


  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("Added new behavioral experiment!");
    }
    return timer;
  };

  const handleFilterClick = (selectedfilter) => {
    dispatch(filterExperiments(selectedfilter));
  };

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, successAdd]);

  return (
    <Screen>
      <Wrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "1em", marginBottom: "1em" }}>
          <Grid xs={10} item>
            <Link
              to={redirectUrl}
              className={`${styles.backBtn}`}>
              Back to toolkit
            </Link>
          </Grid>

        </Grid>
        <h1>Behavioral Experiments</h1>
        <Grid container
          spacing={0}
          direction="row"
          className={`${styles.marginTop}`}>
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            className={`${styles.flexDisplay}`}>
            <AlertMessage
              message={successMessage}
              setMessage={() => {
                setSuccessMessage("🎉 Added new behavioral experiment entry!");
                dispatch(resetBehavioralSuccess());
              }}
              type="success"
              toggle={successAdd}
            />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={11} item>
            <button
              className={`
                  ${btn.faded_blue} 
                  ${btn.cognitiveDistortionsBtn}`}
              onClick={handleStartNewBehavioralExperiments}>
              Start New
            </button>
          </Grid>
          <Grid item xs={4} className={`${styles.right}`}>
            <CompleteIncompleteFilter handleFilterMenuChange={handleFilterClick} />
          </Grid>
        </Stack>
        {
          loading && <LoadingSpinner />
        }
        {
          filteredExperiments.length > 0 && (
            filteredExperiments.map((entry, index) => (
              <div className={`${styles.marginTop}`} key={index}>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  xs={12} item>
                  <Card className={`${styles.card}`} sx={{ minWidth: 350 }}>
                    <CardContent
                      className={`${styles.cardContent}`}
                      onClick={() => handleEntryClick(entry)}
                    >
                      <Grid
                        container
                        spacing={0}
                        style={{ marginBottom: "10px", fontSize: "12px" }}>
                        <Grid xs={12} item>
                          {moment(entry.date).format("MMMM Do YYYY, h:mm a")}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        style={{ marginBottom: "10px" }} >
                        {entry.thoughts}
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid xs={2} item>
                          <Avatar
                            alt="Duration Indication"
                            sx={{ width: 24, height: 24 }}>
                          </Avatar>
                        </Grid>
                        <Grid xs={10} item>
                          <Avatar
                            alt="Difficulty Indication"
                            sx={{ width: 24, height: 24 }}>
                          </Avatar>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </div>
            ))
          )
        }
      </Wrapper>
    </Screen>
  );
};

export default BehavioralHomeScreen;
