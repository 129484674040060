import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddConditionScreen.module.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addAdditionalNote,
  selectAdditionalNotes,
} from "Reducers/AdditionalNoteReducer";

const AddAdditionalNoteScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const { loading, error } = useSelector(selectAdditionalNotes);

  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [additionalNotes, setAdditionalNotes] = useState("");

  const handleAdditionalNoteSubmit = () => {
    dispatch(
      addAdditionalNote({
        additionalNotes,
        date: moment(date).utc(),
      })
    );

    navigate("/my-health-record/additional-notes");
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={handleAdditionalNoteSubmit}>
        <div className={styles.header}>
          <Link
            to="/my-health-record/additional-notes"
            className={`${btn.links} ${btn.secondaryLink}`}
          >
            Close
          </Link>
          <h3>New Additional Note</h3>
          <button type="submit" className={`${btn.links} ${btn.primaryLink}`}>
            Add
          </button>
        </div>

        <BodyForm desktop={styles.body}>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <TextField
            className={styles.textField}
            label="Date of diagnosis"
            type="datetime-local"
            name="day"
            required
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            autoFocus={!isMobile}
          />
          <TextField
            className={styles.textField}
            label="Additional Notes"
            type="text"
            placeholder="Enter additional notes"
            name="Additional Notes"
            multiline={true}
            rows={3}
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            size="small"
            autoFocus={!isMobile}
          />
          <button
            type="submit"
            className={`${btn.btn} ${btn.primary}`}
          >
            {loading ? "Adding..." : "Add"}
          </button>
        </BodyForm>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default AddAdditionalNoteScreen;
