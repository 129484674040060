import styles from "CSS/Components/Medication.module.css";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Medication = memo(function temp({ medication }) {
  const [frequencies, setFrequencies] = useState("");
  const [day, setDay] = useState(moment());
  const [time, setTime] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setDay(
      moment(medication.date).format("MMMM") +
      " " +
      moment(medication.date).date()
    );
    setTime(
      ", " +
      moment(medication.date)
        .local()
        .format("hh:mm A")
        .replace(/(^|-)0+/g, "")
    ); // remove leading 0 on date

    setFrequencies(
      medication.frequencies
        .map((frequency) => {
          if (frequency.selected) {
            return frequency.label + " ";
          }
        })
        .join("")
    );
  }, [medication.date, medication.frequencies]);

  return (
    <section
      className={
        frequencies.length > 3 ? styles.bigContainer : styles.container
      }
    >
      <div
        className={`${styles.info}
        }`}
        onClick={() =>
          navigate(
            `/my-health-record/update-medication/${medication._id}?redirect=${pathname}`
          )
        }
      >
        <h3 className={medication.isInactive ? styles.headerInactive : ""}>
          {medication.medicationName.length > 15
            ? `${medication.medicationName.substring(0, 15)}...`
            : medication.medicationName}
        </h3>
        <p>
          {medication.dosage.length > 15
            ? "💊 " + `${medication.dosage.substring(0, 15)}...`
            : medication.dosage.length === 0
              ? "🗓️ " + day + time
              : "💊 " + medication.dosage}
        </p>
        <p>{frequencies.length > 3 ? "🕰️ " + frequencies : ""}</p>
      </div>
    </section>
  );
});

export default Medication;
