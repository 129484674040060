import Condition from "Components/Condition/Condition";
import AddFab from "Components/Helpers/AddFab";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import UndoButton from "Components/Helpers/UndoButton";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import styles from "CSS/Screens/ConditionHistoryScreen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getConditions,
  resetAddConditionSuccess,
  resetDeleteConditionSuccess,
  selectConditions,
} from "Reducers/ConditionReducer";
import undoCondition from "Utilities/undo-condition";

const ConditionHistoryScreen = () => {
  const dispatch = useDispatch();
  const [showUndo, setShowUndo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { past, loading, error, successAdd, successDelete } =
    useSelector(selectConditions);

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new condition!");
      timer = setTimeout(() => {
        dispatch(resetAddConditionSuccess());
      }, 2000);
    }
    return timer;
  };

  const handleSuccessDelete = () => {
    if (successDelete) {
      setShowUndo(true);
      setTimeout(() => {
        setShowUndo(false);
        dispatch(resetDeleteConditionSuccess());
      }, 5000);
    }
  };

  useEffect(() => {
    dispatch(getConditions({ limit: 100000 }));

    if (error) {
      setErrorMessage(error);
    }
  }, [dispatch, error]);

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();
    handleSuccessDelete();

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, successAdd, successDelete]);

  const displayList = (list) =>
    list?.map((condition) => (
      <Condition key={condition._id} condition={condition} />
    ));

  return (
    <Screen>
      <Wrapper>
        <BackBtn title="Back" link="/my-health-record" />
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />

        <AlertMessage
          message={successMessage}
          setMessage={() => {
            setSuccessMessage("Added new condition!");
            dispatch(resetAddConditionSuccess());
          }}
          type="success"
          toggle={successAdd}
        />

        {loading && past?.length === 0 && <LoadingSpinner />}

        <div
          className={`${styles.conditionHistoryScreen_header} ${styles.conditionHistoryScreen_completedHeader}`}
        >
          <h3>📒 Conditions</h3>
        </div>
        {past?.length === 0 && (
          <p className={styles.conditionHistoryScreen_filler}>
            No past conditions!
          </p>
        )}
        {displayList(past)}
      </Wrapper>
      <UndoButton
        callback={() => {
          setShowUndo(false);
          undoCondition(dispatch);
        }}
        showUndo={showUndo}
      />

      <AddFab url="/my-health-record/add-condition" />
    </Screen>
  );
};

export default ConditionHistoryScreen;
