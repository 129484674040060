import { createSlice } from "@reduxjs/toolkit";
import { getUserFriends } from "./Actions/FriendActions";
import { acceptFriendRequest, deleteFriendRequest, getFriendRequests, sendFriendRequest } from "./Actions/FriendRequestActions";

const initialState = {
  loading: false,
  error: null,
  successAdd: false,
  successDelete: false,
  friendRequests: [],
  userFriends: [],
};

// functions for "useSelector"
const selectFriendRequest = (state) => state.friendRequest;

const friendRequestSlice = createSlice({
  name: "friendRequest",
  initialState,
  reducers: {
    resetFriendRequest: () => initialState
  },
  extraReducers: {
    // Add Community Async Reducers
    [sendFriendRequest.pending]: (state) => {
      state.loading = true;
    },
    [sendFriendRequest.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
    },
    [sendFriendRequest.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Friend Requests for a user Async Reducers
    [getFriendRequests.pending]: (state) => {
      state.loading = true;
    },
    [getFriendRequests.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.friendRequests = payload;
      state.error = null;
    },
    [getFriendRequests.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Accept Friend Request for a user Async Reducers
    [acceptFriendRequest.pending]: (state) => {
      state.loading = true;
    },
    [acceptFriendRequest.fulfilled]: (state, { payload }) => {
      state.friendRequests = state.friendRequests
        .filter(request => request._id !== payload.friendRequest._id);
      state.userFriends = payload.updatedFriendList;
      state.loading = false;
      state.error = null;
    },
    [acceptFriendRequest.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Friends for the user Async Reducers
    [getUserFriends.pending]: (state) => {
      state.loading = true;
    },
    [getUserFriends.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userFriends = payload;
      state.error = null;
    },
    [getUserFriends.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete Friend Request for a user Async Reducers
    [deleteFriendRequest.pending]: (state) => {
      state.loading = true;
    },
    [deleteFriendRequest.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
    },
    [deleteFriendRequest.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default friendRequestSlice.reducer;

export {
  acceptFriendRequest,
  deleteFriendRequest, getFriendRequests, selectFriendRequest, sendFriendRequest
};

export const {
  resetFriendRequest,
} = friendRequestSlice.actions;
