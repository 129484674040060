import { Grid, TextField } from "@mui/material";
import btn from "CSS/Button.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import Wrapper from "Components/Helpers/Wrapper";
import { URLS } from "Constants/Constants";
import BackBtn from "../../Components/Navigation/BackBtn";
import { verifyResetCode } from "Reducers/Actions/ResetPasswordActions";
import { selectResetPassword } from "Reducers/ResetPasswordReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CustomInputComponent = ({ ...rest }) => (
  <input
    {...rest}
    type="text"
    style={{ textAlign: "center", fontSize: "1em" }}
    pattern="[0-9]{4}"
    maxLength="4"
    min="0"
    max="9999" />
);

const ForgetPasswordCodeEntryScreen = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, isResetCodeValid, resetEmail, error } = useSelector(selectResetPassword);

  const handleResetCodeSubmission = () => {
    const requestData = { code, resetEmail };
    dispatch(verifyResetCode(requestData));
  };

  useEffect(() => {
    console.log(isResetCodeValid);
    if (isResetCodeValid) {
      navigate(URLS.RESET_PASSWORD_SCREEN);
    } else if (isResetCodeValid === false) {
      setErrorMessage("Invalid reset code provided");
    }
  }, [isResetCodeValid]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  return (
      <Wrapper>
        <Grid container justifyContent="left" item xs={12} sm={12} md={6} rowSpacing={6} marginTop={2}>
        <BackBtn title="Back" link="/login" />
        </Grid>
        <Grid container justifyContent="center" item xs={12} sm={12} md={12} rowSpacing={6} marginTop={2}>
          <Grid container justifyContent="center" item xs={12} sm={12} md={12}>
            <img
              src="/images/forgot_password/forgot_password_2.png"
              alt="3 fish swimming in a circle"
              height="200"
            />
          </Grid>
          <Grid item xs={10} sm={10} md={12} textAlign="center">
            Please enter the code your received in your email to reset your password.
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <AlertMessage
              message={errorMessage}
              setMessage={() => setErrorMessage("")}
              type="error"
              toggle={errorMessage}
              styles={{ marginBottom: "2em", width: "auto" }}
            />
            <Grid container justifyContent="center">
              <Grid item xs={8} sm={8} md={10}>
                <TextField
                  placeholder="Enter 4 digit code here"
                  id="code"
                  fullWidth
                  type="number"
                  name="code"
                  onChange={(e) => setCode(e.target.value)}
                  value={code}
                  InputProps={{
                    inputComponent: CustomInputComponent
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <button
              className={`${btn.btn} ${btn.orange_btn}`}
              style={{ minWidth: "100%" }}
              type="submit"
              onClick={() => handleResetCodeSubmission()}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </Grid>
        </Grid>
      </Wrapper>
  );
};

export default ForgetPasswordCodeEntryScreen;