import moment from "moment";
import { addCondition } from "Reducers/ConditionReducer";

const undoCondition = (dispatch) => {
  const condition = JSON.parse(localStorage.getItem("tempCondition")); // Get the data of the just deleted temp condition

  dispatch(
    addCondition({
      conditionName: condition.conditionName,
      date: moment(condition.date),
      healthCareProfessional: condition.healthCareProfessional,
      additionalNotes: condition.additionalNotes,
    })
  );
};

export default undoCondition;
