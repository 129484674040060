import { Grid, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import btn from "CSS/Button.module.css";

/**
 * Renders a dialog box that displays a title and two buttons for confirmation and cancellation.
 *
 * @param {function} handleConfirmation - The function to be called when the user clicks the confirmation button.
 * @param {string} dialogTitle - The title of the dialog box.
 * @param {string} dialogExitText - The text to display on the cancel button. Defaults to "Cancel".
 * @param {string} dialogActionText - The text to display on the confirmation button.
 * @param {boolean} isOpen - Indicates whether the dialog box is currently open.
 * @param {function} handleClose - The function to be called when the user clicks the cancel button or closes the dialog box.
 * @returns {JSX.Element} - The JSX markup for the AlertDialog component.
 */
const AlertDialog = ({
  handleConfirmation,
  dialogTitle,
  dialogExitText = "Cancel",
  dialogActionText,
  isOpen,
  handleClose }) => {

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        <Grid container direction="column" style={{ padding: "0.7em" }}>
          <DialogTitle id="alert-dialog-title">
            <Typography gutterBottom>
              {dialogTitle}
            </Typography>
          </DialogTitle>
          <DialogActions>
            <button
              className={`${btn.faded_orange} ${btn.btn}`}
              onClick={handleClose}
            >
              {dialogExitText}
            </button>
            <button
              className={`${btn.faded_blue} ${btn.btn}`}
              onClick={handleConfirmation}
            >
              {dialogActionText}
            </button>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
