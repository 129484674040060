const { createSlice } = require("@reduxjs/toolkit");
import {
  addActivities,
  addActivityEntry,
  addActivityToPathway,
  deleteActivity,
  getActivities, getActivityByName,
  getActivityHistory,
  updateActivity
} from "./Actions/ActivityActions";

const initialState = {
  activities: [], // saved activites for a user
  filteredActivities: [],
  loading: false,
  error: null,
  activitiesToCreate: [], // activites that a user wants to create.
  activityHistory: { history: [] }, // history of the selected activity.
};

// functions for "useSelector" ok
const selectActivity = (state) => state.activity;

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    addActivitiesToCreate: (state, { payload }) => {
      state.activitiesToCreate.push(payload);
    },
    removeActivitiesToCreate: (state, { payload }) => {
      state.activitiesToCreate = state.activitiesToCreate.filter(
        item => item !== payload);
    },
    resetActivitiesToCreate: (state) => {
      state.activitiesToCreate = [];
    },
    filterActivities: (state, { payload }) => {
      // Set filteredActivities to the full list of activities by default
      state.filteredActivities = state.activities;

      // If the filter type is "duration", filter activities by duration
      if (payload.type === "duration") {
        state.filteredActivities = state.filteredActivities.filter(
          activity => activity.duration === payload.value);
      }

      // If the filter type is "difficulty", filter activities by difficulty
      else if (payload.type === "difficulty") {
        state.filteredActivities = state.filteredActivities.filter(
          activity => activity.difficulty === payload.value);
      }

      // If the filter type is neither "duration" nor "difficulty", 
      // do not apply any filters
      else {
        state.filteredActivities = state.activities;
      }
    },
    searchActivity: (state, { payload }) => {
      state.filteredActivities = state.activities;

      state.filteredActivities = state.filteredActivities.filter(
        activity => activity.name.includes(payload));
    },
  },
  extraReducers: {
    // Create Activities Async Reducers
    [addActivities.pending]: (state) => {
      state.loading = true;
    },
    [addActivities.fulfilled]: (state) => {
      state.loading = false;
      state.activitiesToCreate = [];
      state.error = null;
    },
    [addActivities.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete Activity Async Reducers
    [deleteActivity.pending]: (state) => {
      state.loading = true;
    },
    [deleteActivity.fulfilled]: (state, { payload }) => {
      state.loading = false;

      // Remove the deleted activity from the activities array
      state.activities = state.activities
        .filter((activity) => activity._id !== payload.activity._id);

      // Remove the deleted activity from the filteredActivities array
      state.filteredActivities = state.filteredActivities
        .filter(activity => activity._id !== payload.activity._id);
      state.error = null;
    },
    [deleteActivity.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Activities Async Reducers
    [getActivities.pending]: (state) => {
      state.loading = true;
    },
    [getActivities.fulfilled]: (state, { payload }) => {
      state.loading = false;
      payload.activities.forEach(activity => {
        // Check if the activity already exists in state.activities
        const isSame = state.activities
          .some(existingActivity =>
            existingActivity._id === activity._id);

        // If the activity is new, add it to the activities array
        if (!isSame) {
          state.activities.push(activity);
        }
      });

      payload.activities.forEach(payloadObj => {
        // Find the index of the activity in state.activities 
        // with the same id as payloadObj
        const activityIndex = state.activities
          .findIndex(activityObj => activityObj._id === payloadObj._id);

        // If the activity already exists in state.activities and 
        // its lastDone value has changed, update it
        if (activityIndex >= 0
          && payloadObj.lastDone !== state.activities[activityIndex].lastDone) {
          state.activities.splice(activityIndex, 1, payloadObj);

          // If the activity doesn't exist in state.activities, add it
        } else if (activityIndex === -1) {
          state.activities.push(payloadObj);
        }
      });

      state.filteredActivities = state.activities;

      // Sort the activities list and update state
      sortList(state);
      state.error = null;
    },
    [getActivities.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Activities Async Reducers
    [getActivityByName.pending]: (state) => {
      state.loading = true;
    },
    [getActivityByName.fulfilled]: (state, { payload }) => {
      if (payload.activities.length > 0) {
        state.error = "Activity already exists";
      } else {
        state.error = null;
      }
      state.loading = false;
    },
    [getActivityByName.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Activity History Async Reducers
    [getActivityHistory.pending]: (state) => {
      state.loading = true;
    },
    [getActivityHistory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.activityHistory = payload.activity;
      state.error = null;
    },
    [getActivityHistory.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Activity History Async Reducers
    [addActivityToPathway.pending]: (state) => {
      state.loading = true;
    },
    [addActivityToPathway.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
    },
    [addActivityToPathway.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Create Activities Async Reducers
    [addActivityEntry.pending]: (state) => {
      state.loading = true;
    },
    [addActivityEntry.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
    },
    [addActivityEntry.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Update Activities Async Reducers
    [updateActivity.pending]: (state) => {
      state.loading = true;
    },
    [updateActivity.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;

      // Update the activities array by replacing the activity that was updated
      state.activities = state.activities
        .map((activity) => {

          // If the activity id matches the updated activity id, 
          // replace it with the updated activity
          if (activity._id === payload.activity._id) {
            return { ...activity, ...payload.activity };
          }
          return activity;
        });
      state.filteredActivities = state.activities;

      // Sort the activities list and update state
      sortList(state);
    },
    [updateActivity.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

// sorts all past entries based on created time
const sortList = (state) => {
  // Sort the filteredActivities array by isPinned and 
  // lastDone in descending order
  state.filteredActivities = state.filteredActivities
    .sort((a, b) => {
      if (b.isPinned && !a.isPinned) {
        // If b is pinned and a is not, move b before a
        return 1;
      } else if (!b.isPinned && a.isPinned) {
        // If a is pinned and b is not, move a before b
        return -1;
      } else {
        // If both objects have the same isPinned value, sort by lastDone
        return new Date(b.lastDone) - new Date(a.lastDone);
      }
    });
};

export default activitySlice.reducer;
export { addActivities, selectActivity };
export const {
  addActivitiesToCreate,
  removeActivitiesToCreate,
  filterActivities,
  resetActivitiesToCreate,
  searchActivity } = activitySlice.actions;