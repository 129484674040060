import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputBase, Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/ActivityScreen.module.css";
import communityStyle from "CSS/Screens/CommunityScreen.module.css";
import AddFab from "Components/Helpers/AddFab";
import ConfirmationModal from "Components/Helpers/ConfirmationModal";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import MuiMenu from "Components/Helpers/MuiMenu";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import {
  deleteActivity,
  getActivities,
  updateActivity,
} from "Reducers/Actions/ActivityActions";
import {
  filterActivities,
  searchActivity,
  selectActivity,
} from "Reducers/ActivityReducer";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ActivitiesScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activityIdToDelete, setActivityIdToDelete] = useState();
  const { loading, activities, filteredActivities } =
    useSelector(selectActivity);

  useEffect(() => {
    dispatch(getActivities(page));
  }, []);

  const handleActivityCompletedClick = (activityId, name) => {
    navigate(`/post-activities-entry/${activityId}?name=${name}`);
  };

  const handleActivityDelete = (activityId) => {
    setIsModalOpen(true);
    setActivityIdToDelete(activityId);
  };

  const handleActivityClick = (activityId) =>
    navigate(`/activity-history/${activityId}`);

  const loadMoreActivities = () => {
    dispatch(getActivities(page + 1));
    setPage(page + 1);
  };

  // selectedFilter = { type: "duration", value: "medium"}
  const handleFilterMenuChange = (selectedFilter) => {
    dispatch(filterActivities(selectedFilter));
  };

  const handlePushPinClick = (activityId, isPinned, e) => {
    // Stopping default event propagation to display event history.
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();

    // Update activity
    dispatch(updateActivity({ _id: activityId, isPinned: !isPinned }));
  };

  const getDurationIcon = (durationLevel) => {
    switch (durationLevel) {
      case "long":
        return styles.durationLong;
      case "medium":
        return styles.durationMedium;
      case "short":
        return styles.durationShort;
      default:
        return styles.durationMedium;
    }
  };

  const getDifficultyIcon = (difficultyLevel) => {
    switch (difficultyLevel) {
      case "difficult":
        return styles.levelDifficult;
      case "medium":
        return styles.levelMedium;
      case "easy":
        return styles.levelEasy;
      default:
        return styles.levelMedium;
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteActivity(activityIdToDelete));
    setIsModalOpen(false);
  };

  const onSearchFieldChange = (e) => dispatch(searchActivity(e.target.value));

  return (
    <>
      <Screen>
        <Wrapper>
          <h1>My Activities</h1>
          {loading && <LoadingSpinner />}
          <ConfirmationModal
            open={isModalOpen}
            handleClose={handleModalClose}
            text="Confirm Delete?"
            handleConfirmation={handleDeleteConfirmation}
            handleCancel={handleModalClose}
          />
          <div className={`${styles.container}`}>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              style={{ gap: "1.5rem" }}>
              <Paper className={communityStyle.searchBar}>
                <InputBase
                  sx={{ ml: 2 }}
                  placeholder="Search Activity"
                  inputProps={{ "aria-label": "search users" }}
                  onChange={onSearchFieldChange}
                />
                <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
              <MuiMenu handleFilterMenuChange={handleFilterMenuChange} />
            </Grid>
          </div>
          {activities.length === 0 && (
            <Grid item container xs={10} justifyContent="center">
              <div className={`${styles.container}`}>
                <Paper
                  elevation={2}
                  className={`${communityStyle.emptyCommunityCard}`}>
                  <Grid>
                    <Grid container justifyContent="center">
                      <img
                        className={communityStyle.emptyCommunityImage}
                        src="/images/community/not-found.png" />
                    </Grid>
                    <Grid container justifyContent="center">
                      <Grid sx={{ m: 1 }} item>
                        You don&apos;t have any activities. Try adding a new one.
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          )}
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              // columnSpacing={{ sm: 4, md: 4 }}
              justifyContent="center"
              alignItems="center"
              container
              xs={12}
              md={10}
              sm={12}
              lg={8}
            >
              {filteredActivities &&
                filteredActivities.map((activity, index) => (
                  <Grid
                    item
                    xs={10}
                    sm={5}
                    md={5}
                    lg={6}
                    xl={6}
                    style={{ marginTop: "1em" }}
                    key={index}
                  >
                    <Card className={`${styles.activityCard}`}>
                      <CardContent
                        className={`${styles.activityCardContent}`}
                        onClick={() => handleActivityClick(activity._id)}
                      >
                        <Typography variant="p" component="div">
                          <Grid container>
                            <Grid item xs={1}>
                              <Avatar
                                className={`${styles.indicationAvatar}`}
                                alt="Duration Indication"
                              >
                                <div
                                  className={`${styles.indicationIcon} ${getDurationIcon(
                                    activity.duration
                                  )}`}
                                />
                              </Avatar>
                            </Grid>
                            <Grid item xs={1}>
                              <Avatar
                                className={`${styles.indicationAvatar}`}
                                alt="Difficulty Indication"
                              >
                                <div
                                  className={`${styles.indicationIcon} ${getDifficultyIcon(
                                    activity.difficulty
                                  )}`}
                                />
                              </Avatar>
                            </Grid>
                            <Grid item xs={9}>
                              <span style={{ marginLeft: "6px" }}>{activity.name}</span>
                            </Grid>
                            <Grid item xs={1}>
                              <span>{activity.isPinned}</span>
                              <PushPinIcon
                                onClick={(e) =>
                                  handlePushPinClick(activity._id, activity.isPinned, e)
                                }
                                className={
                                  activity.isPinned
                                    ? `${styles.selectedPin}`
                                    : `${styles.pin}`
                                }
                              />
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography sx={{ mt: 1 }} className={`${styles.subtitle}`}>
                          {activity.lastDone != null
                            ? `Last done ${moment(activity.lastDone).fromNow()}`
                            : ""}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          className={`${btn.complete} ${styles.activityCompletionBtn}`}
                          onClick={() =>
                            handleActivityCompletedClick(activity._id, activity.name)
                          }
                          size="small"
                        >
                          I did this today
                        </Button>
                        <Button
                          className={`${styles.activityDeleteBtn}`}
                          onClick={() => handleActivityDelete(activity._id)}
                          size="small"
                        >
                          <DeleteOutlinedIcon style={{ fill: "#0a7393" }} />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          {activities.length > 3 && (
            <div className={`${styles.containerWidth}`}>
              <Grid justifyContent="center" alignItems="center" container spacing={0}>
                <Grid item xs={12} sm={4} md={4} className={`${styles.center}`}>
                  <button
                    className={`${btn.faded_blue} ${btn.smallJournalBtn} ${styles.loadMoreBtn}`}
                    onClick={loadMoreActivities}
                    disabled={loading}
                  >
                    {loading === true ? <LoadingSpinner /> : "Load more"}
                  </button>
                </Grid>
              </Grid>
            </div>
          )}
        </Wrapper>
        <AddFab url="/new-activity-entry" />
      </Screen >
    </>
  );
};

export default ActivitiesScreen;
