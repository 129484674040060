import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to fetch logged in users conditions
const getConditions = createAsyncThunk("conditions/", ({ limit = 2 }, thunkAPI) =>
  actionHandler(
    // get limit parameter number of past conditions
    axiosInstance.get(`/conditions?limit=${limit}`),
    thunkAPI
  )
);

// send backend request to get a singular medication
const getCondition = createAsyncThunk("get condition", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/conditions/${id}`),
    thunkAPI
  )
);

// send backend request to delete specific condition
const deleteCondition = createAsyncThunk("delete/", (_id, thunkAPI) =>
  actionHandler(
    //delete condition with specific ID
    axiosInstance.delete(`/conditions/${_id}`),
    thunkAPI
  )
);

// send backend request to add new condition
const addCondition = createAsyncThunk("add condition/", (
  {
    conditionName,
    date,
    healthCareProfessional,
    additionalNotes,
    healthCareProvider,
  }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/conditions",
      {
        conditionName,
        date,
        healthCareProfessional,
        additionalNotes,
        healthCareProvider,
      }
    ),
    thunkAPI
  )
);

// send backend request to update condition
const updateCondition = createAsyncThunk("update condition/", (
  {
    id,
    conditionName,
    date,
    healthCareProfessional,
    additionalNotes,
    healthCareProvider,
  }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(
      `/conditions/${id}`,
      {
        conditionName,
        date,
        healthCareProfessional,
        additionalNotes,
        healthCareProvider,
      },
    ),
    thunkAPI
  )
);


export {
  addCondition, deleteCondition, getCondition, getConditions, updateCondition
};

