// selects a specific emotions from list and returns updated list
// params:
//     @index:     specific emotion to select
//     @emotions:  list of emotions
// returns:
//    updates list of emotions
const handleEmotions = (index, emotions) => {
  // copy over emotions because you cannot mutate redux state directly
  const tempEmotions = [];
  emotions.forEach((emotion) =>
    tempEmotions.push({ label: emotion.label, selected: emotion.selected })
  );

  tempEmotions[index].selected = !tempEmotions[index].selected;
  const updatedEmotions = [...tempEmotions];

  return updatedEmotions;
};

// removes a specific emotion from emotion list
// params:
//     @em:        specific emotion to remove
//     @emotions:  list of emotions
// returns:
//    updates list of emotions
const handleDeleteEmotions = (em, emotions) => {
  const updatedEmotions = emotions.filter(
    (emotion) => emotion.label !== em.label
  );

  return updatedEmotions;
};

// updates list of emotions to include new emotion
// params:
//     @newEmotion:     new emotion to add
//     @emotions:  list of emotions
// returns:
//    updates list of emotions
const handleAddEmotionSubmit = (newEmotion, emotions) => {
  // Create emotion object and add it to storing arrays
  const newEmotionToAdd = { label: newEmotion, selected: true };

  const updatedEmotions = [...emotions, newEmotionToAdd];

  return updatedEmotions;
};

export { handleAddEmotionSubmit, handleDeleteEmotions, handleEmotions };
