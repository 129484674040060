import styles from "CSS/Components/Pathway/IndvidualPathwayNote.module.css";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const IndividualPathwayNote = memo(function temp({ pathwayNote }) {
  const [day, setDay] = useState(moment());
  const [time, setTime] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const generateTitle = () => {
    if (!pathwayNote.textReview) {
      return "no title";
    }

    return pathwayNote.textReview.length > 28
      ? `${pathwayNote.textReview.substring(0, 28)}...`
      : pathwayNote.textReview.length == 0
        ? day + " " + time
        : pathwayNote.textReview;
  };

  useEffect(() => {
    setDay(
      moment(pathwayNote.date).format("MMMM") +
      " " +
      moment(pathwayNote.date).date()
    );
    setTime(
      ", " +
      moment(pathwayNote.date)
        .local()
        .format("hh:mm A")
        .replace(/(^|-)0+/g, "")
    ); // remove leading 0 on date
  }, [pathwayNote.date]);

  return (
    <section className={styles.individualPathwayNote_container}>
      <div
        className={`${styles.individualPathwayNote_info}
        }`}
        onClick={() =>
          navigate(
            `/update-pathway-note/${pathwayNote._id}?redirect=${pathname}`
          )
        }
      >
        <div className={styles.individualPathwayNote_header}>
          <h3>{generateTitle()}</h3>
        </div>
        <p>
          {moment(pathwayNote.date).isSame(moment(), "day")
            ? "🗓️ " + "Today" + time
            : "🗓️ " + day + time}
        </p>
      </div>
    </section>
  );
});

export default IndividualPathwayNote;
