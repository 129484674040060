import { Grid, Paper, Skeleton } from "@mui/material";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddCommunityFriendScreen.module.css";
import communityStyle from "CSS/Screens/CommunityScreen.module.css";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import { URLS } from "Constants/Constants";
import { getUserFriends } from "Reducers/Actions/FriendActions";
import { selectFriendRequest } from "Reducers/FriendRequestReducer";
import { getHabits, selectHabits } from "Reducers/HabitsReducer";
import { createTeam, resetTeamToCreate, selectTeam, setTeamToCreateHabit } from "Reducers/TeamReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const AddCommunityHabitScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userFriends, loading: friendRequestLoading } = useSelector(selectFriendRequest);
  const [selectedHabits, setSelectedHabits] = useState([]);
  const { teamToCreate, loading } = useSelector(selectTeam);
  const { habits } = useSelector(selectHabits);

  useEffect(() => {
    dispatch(getUserFriends());
    dispatch(getHabits("current"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setTeamToCreateHabit(selectedHabits));
  }, [selectedHabits, dispatch]);

  // Add goals to team
  const handleHabitSelection = (id) => {
    if (selectedHabits.includes(id)) {
      setSelectedHabits(selectedHabits.filter((habitId) => habitId !== id));
    } else {
      setSelectedHabits(prev => ([...prev, id]));
    }
  };

  // Create team
  const handleCommunityCreation = () => {
    dispatch(createTeam(teamToCreate));
    dispatch(resetTeamToCreate());
    navigate(URLS.COMMUNITY);
  };

  const renderUserHabits = () => {
    return (
      <>
        <Grid container justifyContent="center" alignItems="center">
          {
            habits.daily.length === 0
            && habits.weekly.length === 0
            && habits.monthly.length === 0
            && (
              <Grid item xs={12} style={{ margin: "0.5em" }}>
                <Paper
                  elevation={1}
                  style={{
                    padding: "10px",
                    borderRadius: "15px",
                    minHeight: "80px",
                    textAlign: "center",
                    border: "2px solid white"
                  }}
                >
                  <h4>You do not have any created habits</h4>
                  <Grid container justifyContent="center" style={{ marginTop: "1em" }}>
                    <button
                      onClick={() => navigate("/add-habit")}
                      className={`${btn.smallJournalBtn} ${btn.faded_blue}`}
                      style={{ maxWidth: "100%" }}>
                      Create Habit
                    </button>
                  </Grid>
                </Paper>
              </Grid>
            )
          }
          {habits.daily.map((habit) => (
            <Grid item xs={5} key={habit._id} style={{ margin: "0.5em" }}>
              <Paper
                elevation={3}
                style={{ padding: "10px", borderRadius: "15px", minHeight: "80px", border: "2px solid white" }}
                onClick={() => handleHabitSelection(habit._id)}
                className={selectedHabits.includes(habit._id) ? styles.selectedBackground : ""}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center">
                  <Grid xs={1} item></Grid>
                  <Grid xs={8} item>
                    <h4>{habit.name}</h4>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
          {habits.weekly.map((habit) => (
            <Grid item xs={5} key={habit._id} style={{ margin: "0.5em" }}>
              <Paper
                elevation={3}
                style={{ padding: "10px", borderRadius: "15px", minHeight: "80px", border: "2px solid white" }}
                onClick={() => handleHabitSelection(habit._id)}
                className={selectedHabits.includes(habit._id) ? styles.selectedBackground : ""}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center">
                  <Grid xs={1} item></Grid>
                  <Grid xs={8} item>
                    <h4>{habit.name}</h4>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
          {habits.monthly.map((habit) => (
            <Grid item xs={5} key={habit._id} style={{ margin: "0.5em" }}>
              <Paper
                elevation={3}
                style={{ padding: "10px", borderRadius: "15px", minHeight: "80px", border: "2px solid white" }}
                onClick={() => handleHabitSelection(habit._id)}
                className={selectedHabits.includes(habit._id) ? styles.selectedBackground : ""}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center">
                  <Grid xs={1} item></Grid>
                  <Grid xs={8} item>
                    <h4>{habit.name}</h4>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <ScreenNoNav>
      <Grid
        style={{ marginTop: "2em", marginBottom: "10px" }}
        container
        xs={10}
        item={false}
        justifyContent="flex-start">
        <Grid item xs={4}>
          <Link
            to={URLS.COMMUNITY_ADD_COMMUNITY_FRIENDS}
            className={`${communityStyle.btn} ${communityStyle.btn_orange}`}
          >
            Back
          </Link>
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: "1em", marginBottom: "10px" }}
        container
        xs={10}
        item={false}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          item xs={12}>
          <h3>Select goals for your team</h3>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "10px", marginBottom: "10px" }} container xs={10}>
        <Grid item xs={12}>
          {
            friendRequestLoading && (<Skeleton variant="rounded" height={60} />)
          }
          {
            userFriends && userFriends.length > 0 && (renderUserHabits())
          }
        </Grid>
      </Grid>
      <Grid style={{
        marginTop: "1em",
        marginBottom: "10px",
        textAlign: "center"
      }} container xs={10}>
        <Grid item xs={12}>
          <button
            onClick={handleCommunityCreation}
            className={`${btn.btn} ${btn.faded_blue}`}
            style={{ maxWidth: "100%" }}>
            {
              loading && (<LoadingSpinner />)
            }
            {
              !loading && ("Create Community")
            }
          </button>
        </Grid>
      </Grid>
    </ScreenNoNav>
  );
};

export default AddCommunityHabitScreen;
