import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// BLOOD TYPE
// send backend request to get blood type
const getBloodType = createAsyncThunk("get blood type", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/healthInfo/blood-type"),
    thunkAPI
  )
);

// send backend request to update blood type
const updateBloodType = createAsyncThunk("update blood type/", (bloodType, thunkAPI) =>
  actionHandler(
    axiosInstance.put("/healthInfo/blood-type", { bloodType }),
    thunkAPI
  )
);

// HEALTH CARD
// send backend request to get health card
const getHealthCard = createAsyncThunk("get health card", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/healthInfo/health-card"),
    thunkAPI
  )
);

// send backend request to update health card
const updateHealthCard = createAsyncThunk("update health card/", (healthCard, thunkAPI) =>
  actionHandler(
    axiosInstance.put("/healthInfo/health-card", healthCard),
    thunkAPI
  )
);

// HEIGHT
// send backend request to get height
const getHeight = createAsyncThunk("get height", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/healthInfo/height"),
    thunkAPI
  )
);

// send backend request to update height
const updateHeight = createAsyncThunk("update height/", (height, thunkAPI) =>
  actionHandler(
    axiosInstance.put("/healthInfo/height", height),
    thunkAPI
  )
);

// WEIGHT
// send backend request to get weight
const getWeights = createAsyncThunk("get weight", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/healthInfo/weight"),
    thunkAPI
  )
);

// send backend request to add weight
const addWeight = createAsyncThunk("add weight/", (weight, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/healthInfo/weight", weight),
    thunkAPI
  )
);

// TEMPERATURE
// send backend request to get temperature
const getTemperatures = createAsyncThunk("get temperature", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/healthInfo/temperature"),
    thunkAPI
  )
);

// send backend request to add temperature
const addTemperature = createAsyncThunk("add temperature/", (temperature, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/healthInfo/temperature", temperature),
    thunkAPI
  )
);

// BLOOD PRESSURE
// send backend request to get blood pressure
const getBloodPressures = createAsyncThunk("get blood pressure", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/healthInfo/blood-pressure"),
    thunkAPI
  )
);

// send backend request to add blood pressure
const addBloodPressure = createAsyncThunk("add blood pressure/", (bloodPressure, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/healthInfo/blood-pressure", bloodPressure),
    thunkAPI
  )
);

// SUGAR LEVEL
// send backend request to get sugar level
const getSugarLevels = createAsyncThunk("get sugar level", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/healthInfo/sugar-level"),
    thunkAPI
  )
);

// send backend request to add sugar level
const addSugarLevel = createAsyncThunk("add sugar level/", (sugarLevel, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/healthInfo/sugar-level", sugarLevel),
    thunkAPI
  )
);

// PULSE
// send backend request to get pulse
const getPulses = createAsyncThunk("get pulse", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/healthInfo/pulse"),
    thunkAPI
  )
);

// send backend request to add pulse
const addPulse = createAsyncThunk("add pulse/", (pulse, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/healthInfo/pulse", pulse),
    thunkAPI
  )
);

// FAMILY HISTORY
// send backend request to get family history
const getFamilyHistorys = createAsyncThunk("get family history", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/healthInfo/family-history"),
    thunkAPI
  )
);

// send backend request to add family history
const addFamilyHistory = createAsyncThunk("add family history/", (familyHistory, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/healthInfo/family-history", familyHistory),
    thunkAPI
  )
);

export {
  addBloodPressure, addFamilyHistory, addPulse, addSugarLevel, addTemperature, addWeight, getBloodPressures, getBloodType, getFamilyHistorys, getHealthCard, getHeight, getPulses, getSugarLevels, getTemperatures, getWeights, updateBloodType, updateHealthCard, updateHeight
};

