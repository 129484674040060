import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  // : "http://localhost:5000/api";
  : "https://psyhealth.ca/api";


// export default axios.create({
//   baseURL,
//   headers: {
//     "Content-type": "application/json",
//   },
// });

let store;

export const injectStore = (_store) => {
  store = _store;
};


const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
  },
});


axiosInstance.interceptors.request.use(
  (config) => {
    if (store) {
      const state = store.getState();

      const token = state.user.user?.token || JSON.parse(localStorage.getItem("userData") || "{}")?.token;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;