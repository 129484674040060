import CircularSlider from "@fseehawer/react-circular-slider";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import btn from "CSS/Button.module.css";
import styles from "CSS/Components/Checkin.module.css";
import useEmotionSelector from "Hooks/useEmotionSelector";
import moment from "moment";
import { useState } from "react";
import { isMobile } from "react-device-detect";

// This component allows the user to complete a quick pre-activity check
const PreCheckin = ({ handlePreCheckIn, handlePreCheckInSkip }) => {
  const [dateTime, setDateTime] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const { renderSelector, renderModal, emotions } = useEmotionSelector();
  const [emotionRating, setEmotionRating] = useState(0);

  return (
    <>
      <h3>Pre Activity Quick Check</h3>
      <br></br>
      <div
        className={`
          ${styles.checkin_body} 
          ${isMobile ? styles.checkin_bodyMobile : ""}`}
      >
        {renderModal()}
        <TextField
          className={styles.datetextarea}
          type="datetime-local"
          name="day"
          required={true}
          value={dateTime}
          onChange={(e) => setDateTime(e.target.value)}
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <br />
        <CircularSlider
          label="How are you feeling?"
          type="number"
          labelColor="#107ca4"
          knobColor="#009c9a"
          progressColorFrom="rgba(11, 126, 161, 0.4)"
          progressColorTo="rgba(11, 126, 161, 1)"
          trackColor="#eeeeee"
          data={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
          min={0}
          max={10}
          dataIndex={0}
          labelBottom={true}
          labelFontSize="0.7rem"
          valueFontSize="1rem"
          onChange={(e) => setEmotionRating(e)}
          value={emotionRating}
          width={140}
          knobSize={32}
          trackSize={6}
          progressSize={12}
        />

        {renderSelector()}
        <Grid className={`${btn.group} ${btn.marginTop}`}>
          <button
            className={`${btn.btn} ${btn.secondary}`}
            type="submit"
            onClick={() => handlePreCheckInSkip()}
          >
            Skip
          </button>
          <button
            className={`${btn.btn} ${btn.primary}`}
            type="submit"
            onClick={() => handlePreCheckIn(emotions, emotionRating)}
          >
            Done
          </button>
        </Grid>
      </div>
    </>
  );
};

export default PreCheckin;
