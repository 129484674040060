import { SpeedDial, SpeedDialAction } from "@mui/material";
import { isMobile } from "react-device-detect";
import { CgNotes } from "react-icons/cg";
import { MdAdd, MdOutlineBook } from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setPage } from "Reducers/JournalReducer";

const HomeSpeedDial = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const actions = [
    {
      icon: <MdOutlineBook size={24} />,
      name: "Journal",
      callback: () => {
        dispatch(setPage("1"));
        navigate(`/new-journal-entry?redirect=${pathname}`);
      },
    },
    {
      icon: <CgNotes size={24} />,
      name: "Appointment",
      callback: () => {
        dispatch(setPage("2"));
        navigate(`/new-journal-entry?redirect=${pathname}`);
      },
    },
    {
      icon: <VscFeedback size={24} />,
      name: "Feedback",
      callback: () => navigate("/feedback"),
    },
  ];

  return (
    <SpeedDial
      ariaLabel="SpeedDial basic"
      sx={{
        position: "fixed",
        bottom: isMobile ? 90 : 70,
        right: 16,
        zIndex: 1300,
      }}
      icon={<MdAdd size={32} color="white" />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          tooltipOpen={isMobile}
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.callback}
        />
      ))}
    </SpeedDial>
  );
};

export default HomeSpeedDial;
