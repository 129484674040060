import { createSlice } from "@reduxjs/toolkit";
import {
  addHealthCareProvider,
  deleteHealthCareProvider,
  getHealthCareProvider,
  getHealthCareProviders,
  updateHealthCareProvider,
} from "./Actions/HealthCareProviderActions";

const initialState = {
  healthCareProviders: { current: [], archived: [] },
  loading: false,
  error: null,
  successAdd: false,
  successDelete: false,
  selectedHealthCareProvider: null,
  newlyAddedId: null, // id for provider linked to appointment note
  status: "current", // "current", "archived", "all"(filter drop down)
};

// functions for "useSelector"
const selectHealthCareProviders = (state) => state.healthCareProviders;

const healthCareProvidersSlice = createSlice({
  name: "healthCareProviders",
  initialState,
  reducers: {
    resetHealthCareProviders: () => initialState,
    // For updating when we only want to reset the error and loading
    resetHealthCareProvider: ({ healthCareProviders: healthCareProviders }) => {
      return {
        healthCareProviders: healthCareProviders,
        loading: false,
        error: null,
        successAdd: false,
        successDelete: false,
      };
    },
    resetAddHealthCareProviderSuccess: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    resetDeleteHealthCareProviderSuccess: (state) => {
      return {
        ...state,
        successDelete: false,
      };
    },
    setNewlyAddedId: (state, { payload }) => {
      state.newlyAddedId = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
  },
  extraReducers: {
    // Fetch health care provider Async Reducers
    [getHealthCareProvider.pending]: (state) => {
      state.loading = true;
    },
    [getHealthCareProvider.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedHealthCareProvider = payload;
    },
    [getHealthCareProvider.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Fetch health care providers Async Reducers
    [getHealthCareProviders.pending]: (state) => {
      state.loading = true;
    },
    [getHealthCareProviders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;

      state.healthCareProviders.current = payload.current;
      state.healthCareProviders.archived = payload.archived;
    },
    [getHealthCareProviders.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add health care provider Async Reducers
    [addHealthCareProvider.pending]: (state) => {
      state.loading = true;
    },
    [addHealthCareProvider.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.successAdd = true;
      state.newlyAddedId = payload._id;

      const isArchived = payload.isArchived ? "archived" : "current";
      state.healthCareProviders[isArchived].push(payload); // push payload to archived if isArchived is true
    },
    [addHealthCareProvider.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // Update health care provider Async Reducers
    [updateHealthCareProvider.pending]: (state) => {
      state.loading = true;
    },
    [updateHealthCareProvider.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedHealthCareProvider = payload;
      //remove the old version of the current health care provider and instead push the updated version to DB
      ["archived", "current"].forEach((activeStatus) => {
        const idx = state.healthCareProviders[activeStatus].findIndex(
          (healthCareProvider) => healthCareProvider._id === payload._id
        ); //go through both lists, find the index of health care provider with id of payload._id

        if (idx !== -1) {
          if (payload.isArchived) {
            //if the updated health care provider has isArchived set to true, and the old version of it was also in the archived list
            //means that an archived health care provider still remained archived despite other changes in text fields
            if (activeStatus === "archived") {
              state.healthCareProviders["archived"][idx] = payload; //replace the index found earlier of the archived list with the updated element from payload
            } else {
              // an archived medication has been changed to current, so we need to push it to the current list
              state.healthCareProviders["current"].push(payload);
            }

            // If the updated medication is CURRENT
          } else if (!payload.isArchived) {
            //if the updated health care provider has isArchived set to false, and the old version of it was also in the CURRENT list
            //means that an CURRENT medication still remained CURRENT despite other changes in text fields
            if (activeStatus === "current") {
              state.healthCareProviders["current"][idx] = payload; //replace the index found earlier of the CURRENT list with the updated element from payload
            } else {
              // an CURRENT medication has been changed to archived, so we need to push it to the archived list
              state.healthCareProviders["archived"].push(payload);
            }

            //state.healthCareProviders["current"][idx] = payload; ///replace the index found earlier of the active list with the updated element from payload
          }
        }
      });
    },
    [updateHealthCareProvider.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete health care provider Async Reducers
    [deleteHealthCareProvider.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successDelete = true;

      //remove from proper list
      state.healthCareProviders[payload.isArchived ? "archived" : "current"] =
        state.healthCareProviders[
          payload.isArchived ? "archived" : "current"
        ].filter(
          (healthCareProvider) => healthCareProvider._id !== payload._id
        ); // set the archived and ucrrent list to a new copy of same list without the healthCareProvider that was selected to be trashed
    },
    [deleteHealthCareProvider.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successDelete = false;
    },
  },
});

export default healthCareProvidersSlice.reducer;
export {
  addHealthCareProvider, deleteHealthCareProvider, getHealthCareProvider, getHealthCareProviders, selectHealthCareProviders, updateHealthCareProvider
};
export const {
  setStatus,
  setNewlyAddedId,
  resetHealthCareProviders,
  resetHealthCareProvider,
  resetAddHealthCareProviderSuccess,
  resetDeleteHealthCareProviderSuccess,
} = healthCareProvidersSlice.actions;
