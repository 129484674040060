import { SpeedDial, SpeedDialAction } from "@mui/material";
import { isMobile } from "react-device-detect";
import { AiFillMedicineBox } from "react-icons/ai";
import { FaDisease, FaHospital } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectHealthCareProviders } from "Reducers/HealthCareProvidersReducer";
import { setPage } from "Reducers/JournalReducer";

const UpdateHealthCareProviderSpeedDial = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedHealthCareProvider } = useSelector(selectHealthCareProviders);

  const actions = [
    {
      icon: <FaDisease size={24} />,
      name: "Condition",
      callback: () => {
        dispatch(setPage("1"));
        navigate(
          `/my-health-record/add-condition?healthCareProvider=${selectedHealthCareProvider.healthCareProviderName}`
        );
      },
    },
    {
      icon: <FaHospital size={24} />,
      name: "Treatment",
      callback: () => {
        dispatch(setPage("2"));
        navigate(
          `/my-health-record/add-treatment?healthCareProvider=${selectedHealthCareProvider.healthCareProviderName}`
        );
      },
    },
    {
      icon: <AiFillMedicineBox size={24} />,
      name: "Medication",
      callback: () => {
        dispatch(setPage("3"));
        navigate(
          `/my-health-record/add-medication?healthCareProvider=${selectedHealthCareProvider.healthCareProviderName}`
        );
      },
    },
  ];

  return (
    <SpeedDial
      ariaLabel="SpeedDial basic"
      sx={
        isMobile
          ? {
            position: "sticky",
            bottom: 90,
            marginLeft: 35,
          }
          : {
            position: "sticky",
            bottom: 70,
            marginLeft: 32,
          }
      }
      icon={<MdAdd size={32} color="white" />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          tooltipOpen={isMobile}
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.callback}
        />
      ))}
    </SpeedDial>
  );
};
export default UpdateHealthCareProviderSpeedDial;
