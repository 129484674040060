// used to create health care provider autocomplete

import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAppointmentNotes } from "Reducers/AppointmentNoteReducer";
import {
  addHealthCareProvider,
  getHealthCareProviders,
  selectHealthCareProviders,
  setNewlyAddedId,
} from "Reducers/HealthCareProvidersReducer";

const filter = createFilterOptions();

const useProvider = (isRequired, initialProvider = "") => {
  const [providerName, setProviderName] = useState(initialProvider);
  const [providers, setProviders] = useState([]);
  const dispatch = useDispatch();

  const { healthCareProviders, loading } = useSelector(
    selectHealthCareProviders
  );
  const { selectedAppointmentNote } = useSelector(selectAppointmentNotes);

  // returns the id of the selected provider, if it does not exist, creates a new provider
  const setProviderId = (value) => {
    const idx = healthCareProviders.current.findIndex(
      (provider) =>
        provider.healthCareProviderName === value.healthCareProviderName
    );

    // found index so return id
    if (idx !== -1) {
      dispatch(setNewlyAddedId(healthCareProviders.current[idx]._id));
    }
  };

  useEffect(() => {
    if (selectedAppointmentNote) {
      const provider = healthCareProviders.current.find(
        (prov) => prov._id === selectedAppointmentNote.healthCareProvider
      );
      if (provider) {
        setProviderName(provider);
      }
    }
    // fetch providers
    if (healthCareProviders.current.length === 0) {
      dispatch(getHealthCareProviders("current"));
    }

    // set providers
    if (healthCareProviders) {
      setProviders(healthCareProviders.current);
    }
  }, [
    healthCareProviders.current.length,
    selectedAppointmentNote?.healthCareProvider,
  ]);

  // https://mui.com/material-ui/react-autocomplete/#free-solo
  const renderAutocomplete = () => (
    <Autocomplete
      loading={loading}
      value={providerName}
      onChange={(_, newValue) => {
        if (typeof newValue === "string") {
          setProviderName({
            healthCareProviderName: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // create new provider
          dispatch(
            addHealthCareProvider({
              healthCareProviderName: newValue.inputValue,
            })
          );
          // Create a new value from the user input
          setProviderName({
            healthCareProviderName: newValue.inputValue,
          });
          setProviders([
            ...providers,
            { healthCareProviderName: newValue.inputValue },
          ]);
        } else {
          setProviderId(newValue);
          setProviderName(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.healthCareProviderName
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            healthCareProviderName: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      // size="small"
      options={providers}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.healthCareProviderName;
      }}
      renderOption={(props, option) => (
        <li {...props}>{option.healthCareProviderName}</li>
      )}
      sx={{
        minWidth: 300,
        maxWidth: "100%",
        alignSelf: "center",
      }}
      renderInput={(params) => (
        <TextField
          required={isRequired}
          {...params}
          label="Health Care Provider"
        />
      )}
    />
  );
  return { renderAutocomplete, setProviderName, providerName };
};

export { useProvider };

