// Importing required modules and components
import "App.css";
import AppRoutes from "AppRoutes";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Toast from "Components/Helpers/Toast";
import useAuthentication from "Hooks/useAuthentication";
import ScrollToTop from "Utilities/ScrollToTop";
import { Suspense, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";

// Initialize Google Analytics with a given tracking ID
ReactGA.initialize("G4A-385454828");

function App() {
  // Hooks to handle user navigation and authentication
  const navigate = useNavigate();
  const user = useAuthentication(navigate);

  // State to manage the app's notification visibility and message
  const [notificationState, setNotificationState] = useState({
    open: false,
    message: "",
  });
  const { open, message } = notificationState;

  // Handler to navigate the user to the journal and close the notification
  const handleNotificationClick = () => {
    navigate("/journal");
    setNotificationState({ ...notificationState, open: false });
  };

  // Handler to close the notification without navigating
  const handleNotificationClose = () => {
    setNotificationState({ ...notificationState, open: false });
  };

  // Get the base URL from the environment variable or use the default local URL
  // const baseURL = process.env.REACT_APP_API_BASE_URL ?
  //   process.env.REACT_APP_API_BASE_URL : "http://localhost:5000/";
  const baseURL = "https://psyhealth.ca/";

  const socket = io(baseURL, {
    path: "/socket.io",
    transports: ["websocket"],
    secure: true,
  });

  useEffect(() => {
    if (user && socket) {
      socket.emit("add-active-user", { data: user._id });
    }

    return () => {
      socket.disconnect();
    };
  }, [user, socket]);

  return (
    <div
      // Set the highlight color style and the appropriate class based on the device type
      style={{ WebkitTapHighlightColor: "transparent" }}
      className={isMobile ? "app_mobileWrapper" : ""}
    >
      <div>
        <ScrollToTop>
          {/* Wrap AppRoutes with Suspense for lazy loading and provide a fallback spinner */}
          <Suspense
            fallback={
              <div className="app_loading">
                <LoadingSpinner />
              </div>
            }
          >
            {/* Toast container to display notifications */}
            <div className="toast_reference_container">
              <div className="toast_container">
                <Toast
                  className={isMobile ? "" : "toast_body"}
                  isOpen={open}
                  onCloseHandler={handleNotificationClose}
                  message={message}
                  onClickHandler={handleNotificationClick}
                />
              </div>
            </div>
            {/* Render AppRoutes and pass the socket connection */}
            <AppRoutes socket={socket} />
          </Suspense>
        </ScrollToTop>
      </div>
    </div>
  );
}

export default App;
