import { Tab, Tabs } from "@mui/material";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectJournalEntries, setPage } from "Reducers/JournalReducer";
import AppointmentNoteHistoryScreen from "./Appointment-Note/AppointmentNoteHistoryScreen";
import JournalHistoryScreen from "./Journal/JournalHistoryScreen";
import PathwayReviewHistoryScreen from "./Pathway/PathwayNoteHistoryScreen";

const EntryHistoriesScreen = () => {
  const dispatch = useDispatch();
  const { page } = useSelector(selectJournalEntries);
  const [showEntry, setShowEntry] = useState(page);

  const handleSetShowEntry = (_, newValue) => {
    setShowEntry(newValue);
    dispatch(setPage(newValue));
  };

  return (
    <Screen>
      <Wrapper>
        <Tabs
          value={showEntry}
          onChange={handleSetShowEntry}
          style={{ marginBottom: "5px", marginTop: "-5px" }}
        >
          <Tab label="Journal" value="1" />
          <Tab label="Appointment" value="2" />
          <Tab label="Pathway" value="3" />
        </Tabs>
        {page === "1" && <JournalHistoryScreen />}
        {page === "2" && <AppointmentNoteHistoryScreen />}
        {page === "3" && <PathwayReviewHistoryScreen />}
      </Wrapper>
    </Screen>
  );
};
export default EntryHistoriesScreen;
