import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/ActivityScreen.module.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getActivityByName } from "Reducers/Actions/ActivityActions";
import {
  addActivitiesToCreate,
  removeActivitiesToCreate,
  resetActivitiesToCreate,
  selectActivity,
} from "Reducers/ActivityReducer";

const AddActivitiesEntryScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [activityNameInput, setActivityNameInput] = useState("");
  const { activitiesToCreate, error } = useSelector(selectActivity);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState({
    Cycling: false,
    Swimming: false,
    Yoga: false,
    Reading: false,
  });

  const predefinedActivities = [
    {
      name: "Cycling",
      fillIcon: <div className={`${styles.icon} ${styles.iconCycling}`} />,
    },
    {
      name: "Swimming",
      fillIcon: <div className={`${styles.icon} ${styles.iconSwimming}`} />,
    },
    {
      name: "Yoga",
      fillIcon: <div className={`${styles.icon} ${styles.yoga}`} />,
    },
    {
      name: "Reading",
      fillIcon: <div className={`${styles.icon} ${styles.reading}`} />,
    },
  ];

  const handleAddNewActivityClick = () => {
    if (!activitiesToCreate.includes(activityNameInput)) {
      dispatch(addActivitiesToCreate(activityNameInput));
      setActivityNameInput("");
    }
  };

  const handleChipDelete = (activity) => {
    dispatch(removeActivitiesToCreate(activity));
  };

  const handleActivityNameInputChange = (e) => {
    setActivityNameInput(e.target.value);
    dispatch(getActivityByName(e.target.value));
  };

  const handleNextClick = () => {
    if (activitiesToCreate.length === 0) {
      setIsToastOpen(true);
    } else {
      navigate("/detailed-activities-entry");
    }
  };

  const handleCancelBtnClick = () => {
    dispatch(resetActivitiesToCreate());
    if (searchParams.get("redirect")) {
      navigate(searchParams.get("redirect"));
    } else {
      navigate("/activities");
    }
  };

  const handleActivityCardClick = (activity) => {
    setSelectedActivities((prevState) => ({
      ...prevState,
      [activity]: !prevState[activity],
    }));
    if (!activitiesToCreate.includes(activity)) {
      dispatch(addActivitiesToCreate(activity));
    } else {
      dispatch(removeActivitiesToCreate(activity));
    }
  };

  return (
    <>
      <Screen>
        <Wrapper>
          <h1>Add New Activity</h1>
          <div className={`${styles.containerWidth}`}>
            <Grid container spacing={0}>
              {activitiesToCreate.map((activity, index) => (
                <Grid
                  item={true}
                  xs={12}
                  className={`${styles.historyContainer}`}
                  key={index}
                >
                  <Chip
                    label={activity}
                    onDelete={() => handleChipDelete(activity)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
          <Grid sx={{ mt: 2 }} container>
            <Grid item xs={12}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label="Enter new activity"
                variant="outlined"
                helperText={error === null ? "" : error}
                value={activityNameInput}
                onChange={handleActivityNameInputChange}
              />
            </Grid>
            <Grid item xs={12} className={styles.addActivityBtnBox}>
              <button
                className={`
                  ${btn.btn}
                  ${btn.primary}
                  ${styles.addActivityBtn}`}
                onClick={handleAddNewActivityClick}
                disabled={error === null ? false : true}
              >
                Add Activity
              </button>
            </Grid>
          </Grid>
          <Divider className={`${styles.activityDivider}`}>
            Select from pre-defied activities
          </Divider>
          <div className={`${styles.containerWidth}`}>
            <Grid container spacing={2}>
              {predefinedActivities.map((predefinedActivity, index) => (
                <Grid key={index} item={true} xs={4}>
                  <Card
                    style={{
                      padding: "1em",
                      textAlign: "-webkit-center",
                      borderRadius: "25px",
                      cursor: "pointer",
                    }}
                    className={`
                    ${selectedActivities[predefinedActivity.name] === true
                        ? styles.iconActive
                        : ""
                      }`}
                    onClick={() => handleActivityCardClick(predefinedActivity.name)}
                  >
                    {predefinedActivity.fillIcon}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>

          {
            isToastOpen && (
              <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  xs={10}
                  justifyContent="center"
                  alignItems="center"
                  item
                  className={`${styles.flexDisplay}`}>
                  <AlertMessage
                    message="Select atleast one activity to proceed."
                    setMessage={() => setIsToastOpen(false)}
                    type="warning"
                    toggle={isToastOpen}
                  />
                </Grid>
              </Grid>
            )
          }
          <div className={`${styles.containerWidth}`}>
            <Grid container spacing={1} style={{ marginTop: "1.5em" }}>
              <Grid item xs={6} className={`${styles.left}`}>
                <button
                  className={`${btn.btn} ${btn.secondary}`}
                  onClick={handleCancelBtnClick}
                >
                  Cancel
                </button>
              </Grid>
              <Grid item={true} xs={6} className={`${styles.right}`}>
                <button
                  className={`${btn.btn} ${btn.primary}`}
                  onClick={handleNextClick}
                >
                  Next
                </button>
              </Grid>
            </Grid>
          </div>
        </Wrapper>
      </Screen>
    </>
  );
};

export default AddActivitiesEntryScreen;
