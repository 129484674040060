import { SpeedDial, SpeedDialAction } from "@mui/material";
import { isMobile } from "react-device-detect";
import { CgNotes } from "react-icons/cg";
import { MdAdd, MdOutlineRateReview } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setPage } from "Reducers/JournalReducer";

const PathwaySpeedDial = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const actions = [
    {
      icon: <MdOutlineRateReview size={24} />,
      name: "New Pathway",
      callback: () => navigate("/new-pathway?page=1"),
    },
    {
      icon: <CgNotes size={24} />,
      name: "New Note",
      callback: () => {
        dispatch(setPage("3"));
        navigate(`/new-journal-entry?redirect=${pathname}`);
      },
    },
  ];

  return (
    <SpeedDial
      ariaLabel="SpeedDial basic"
      sx={
        isMobile
          ? {
            position: "sticky",
            bottom: 90,
            marginLeft: 35,
          }
          : {
            position: "sticky",
            bottom: 70,
            marginLeft: 32,
          }
      }
      icon={<MdAdd size={32} color="white" />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          tooltipOpen={isMobile}
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.callback}
        />
      ))}
    </SpeedDial>
  );
};
export default PathwaySpeedDial;
