export const actionHandler = async (promise, thunkAPI) => {
  try {
    const { data } = await promise;
    return data;
  } catch (error) {
    let errorMessage;

    if (error.response) {
      // Server responded with a status other than 2xx
      errorMessage = error.response.data?.message || `Server Error: ${error.response.status}`;
    } else if (error.request) {
      // Request was made but no response was received
      errorMessage = "Network Error: No response received from the server";
    } else {
      // Something else happened in setting up the request
      errorMessage = error.message || "An unexpected error occurred";
    }

    if (thunkAPI) {
      return thunkAPI.rejectWithValue(errorMessage);
    } else {
      throw new Error(errorMessage);
    }
  }
};
