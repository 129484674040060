import { Checkbox, FormControlLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import AlertMessage from "Components/Helpers/AlertMessage";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/UpdateToDo.module.css";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteToDo,
  getToDo,
  resetToDo,
  selectToDos,
  updateToDo,
} from "Reducers/ToDoReducer";

const UpdateToDosScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectUrl = useRedirect("/todos");

  const { selectedToDo, loading, error } = useSelector(selectToDos);

  const [todoTitle, setToDoTitle] = useState("");
  const [todoTime, setToDoTime] = useState(moment().format("HH:mm"));
  const [isFullDay, setIsFullDay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [todoDate, setToDoDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const updateToDoSubmit = (e) => {
    e.preventDefault();

    const remindTime = moment(todoDate + " " + todoTime).format();
    const completed = selectedToDo.completed;

    dispatch(
      updateToDo({
        id,
        todoTitle,
        remindTime,
        completed,
        isFullDay,
      })
    );

    // Success so go back to todos page
    if (!loading && !error) {
      navigate("/todos");
    }
  };

  const deleteToDoAction = (e) => {
    e.preventDefault();

    localStorage.setItem("tempToDo", JSON.stringify(selectedToDo));
    dispatch(deleteToDo(selectedToDo._id));

    // Success so go back to todos page
    if (!error) {
      navigate(redirectUrl);
    }
  };

  const fillFields = () => {
    if (selectedToDo) {
      setToDoTitle(selectedToDo.title);
      setIsFullDay(selectedToDo.isFullDay);
      setToDoDate(moment(selectedToDo.remindTime).format("YYYY-MM-DD"));
      setToDoTime(moment(selectedToDo.remindTime).format("HH:mm"));
    }
  };

  useEffect(() => {
    dispatch(getToDo(id));

    if (error) {
      setErrorMessage(error);
    }

    fillFields();

    return () => {
      dispatch(resetToDo);
    };
  }, [error, dispatch, selectedToDo?.title]);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={updateToDoSubmit}>
        <div className={styles.header}>
          <Link
            className={`${btn.links} ${btn.secondaryLink}`}
            to={redirectUrl}
          >
            Cancel
          </Link>
          <h3>Update To-Do</h3>
          <button
            type="submit"
            className={`${btn.links} ${btn.primaryLink}`}
          >
            Update
          </button>
        </div>

        <BodyForm desktop={styles.body}>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <TextField
            className={styles.field}
            label="To Do Title"
            type="text"
            placeholder="Enter to do"
            name="title"
            required={true}
            value={todoTitle}
            onChange={(e) => setToDoTitle(e.target.value)}
            variant="standard"
            inputProps={{
              maxLength: 256,
            }}
          />
          <TextField
            className={styles.field}
            label="Pick a day"
            type="date"
            placeholder="Enter to do"
            name="day"
            required={true}
            value={todoDate}
            onChange={(e) => setToDoDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />

          {!selectedToDo?.pathway && (
            <FormControlLabel
              className={styles.field}
              control={
                <Checkbox
                  checked={isFullDay}
                  onChange={(e) => setIsFullDay(e.target.checked)}
                />
              }
              label="All Day"
            />
          )}
          {!isFullDay && (
            <TextField
              className={styles.field}
              label="Pick a time"
              type="time"
              placeholder="Enter to do"
              name="time"
              required={true}
              value={todoTime}
              onChange={(e) => setToDoTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
              variant="standard"
            />
          )}
          <div className={styles.btns}>
            <button
              type="button"
              className={`${btn.btn} ${btn.secondary}`}
              onClick={deleteToDoAction}
            >
              Delete
            </button>
            <button
              type="submit"
              className={`${btn.btn} ${btn.primary}`}
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        </BodyForm>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default UpdateToDosScreen;
