import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import AlertDialog from "Components/Helpers/AlertDialog";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/CognitiveDistortionsScreen.module.css";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addCognitiveDistortionEntry,
  resetCurrentDistortionEntry,
  selectCognitiveDistortion
} from "Reducers/CognitiveDistortionReducer";

const CognitiveDistortionsReview = () => {
  const redirectUrl = useRedirect("/toolkit/distortion-questions");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const {
    thoughts,
    situation,
    selectedDistortions
  } = useSelector(selectCognitiveDistortion);

  const handleSaveClick = () => {
    dispatch(addCognitiveDistortionEntry({
      thoughts,
      situation,
      selectedDistortions,
      date: moment().utc(),
    }));
    dispatch(resetCurrentDistortionEntry());
    navigate("/toolkit/cognitive-distortions");
  };

  const handleCancelClick = () => {
    navigate("/toolkit/cognitive-distortions");
  };

  return (
    <Screen>
      <Wrapper>
        <AlertDialog
          handleConfirmation={handleCancelClick}
          dialogTitle="Are you sure you want to cancel?"
          dialogActionText="Yes"
          isOpen={isAlertDialogOpen}
          handleClose={() => setIsAlertDialogOpen(false)} />
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid xs={10} item>
            <Link
              to={redirectUrl}
              className={`${styles.backBtn}`}>
              Back
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid
            xs={10}
            item
            justifyContent="center"
            alignItems="center"
            className={`${styles.flexDisplay}`} >
            <h3>Review Congitive Entry</h3>
          </Grid>
        </Grid>
        {
          situation.length > 0 && (
            <Grid
              className={`${styles.marginTop}`}
              container
              direction="row"
              alignItems="center"
              justifyContent="center">
              <Grid xs={10} item>
                <Card
                  variant="outlined"
                  sx={{ minWidth: "100%" }}
                  className={styles.paper}>
                  <CardContent>
                    <Grid container direction="row">
                      <Grid
                        xs={3}
                        item
                        justifyContent="left"
                        alignItems="center"
                        className={`${styles.flexDisplay}`}>
                        <div className={`
                          ${styles.situationIcon} 
                          ${styles.icon}`}></div>
                      </Grid>
                      <Grid
                        xs={9}
                        item
                        style={{ display: "flex", fontSize: "0.9em" }}
                        alignItems="center">
                        <p>{situation}</p>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
        }
        {
          thoughts.length > 0 && (
            <Grid
              className={`${styles.marginTop}`}
              container
              direction="row"
              alignItems="center"
              justifyContent="center">
              <Grid xs={10} item>
                <Card
                  variant="outlined"
                  sx={{ minWidth: "100%" }}
                  className={styles.paper}>
                  <CardContent>
                    <Grid container direction="row">
                      <Grid
                        xs={3}
                        item
                        justifyContent="left"
                        alignItems="center"
                        className={`${styles.flexDisplay}`}>
                        <div className={`
                          ${styles.thoughtIcon} 
                          ${styles.icon}`}></div>
                      </Grid>
                      <Grid
                        xs={9}
                        item
                        style={{ display: "flex", fontSize: "0.9em" }}
                        alignItems="center">
                        <p>{thoughts}</p>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
        }

        {
          selectedDistortions.length > 0 &&
          selectedDistortions.map((distortion, key) => (
            // Questions For Each Distortions
            <Grid
              className={`${styles.marginTop}`}
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              key={key}>
              <Grid xs={10} item>
                <Card
                  variant="outlined"
                  sx={{ minWidth: "100%" }}>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      style={{ marginBottom: "1em" }}>
                      <Grid
                        xs={12}
                        item
                        justifyContent="center"
                        alignItems="center"
                        style={{ display: "flex", marginBottom: "0.5em" }}>
                        <div className={`
                          ${styles.thoughtIcon} 
                          ${styles.icon}`}></div>
                      </Grid>
                      <Grid
                        xs={12}
                        item
                        style={{ display: "flex", fontSize: "0.9em" }}
                        alignItems="center"
                        justifyContent="center">
                        <Typography
                          gutterBottom
                          variant="p"
                          component="div">
                          {distortion.title}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* Questions for the distortion */}
                    {distortion.distortionQuestions.map((question, index) => (
                      <Grid container
                        direction="row"
                        key={index}
                        style={{ marginBottom: "1em" }}>
                        <Grid
                          xs={12}
                          item
                          style={{
                            display: "flex",
                            fontSize: "0.9em",
                            fontWeight: "bold"
                          }}
                          alignItems="center">
                          <p>{question.question}</p>
                        </Grid>
                        <Grid
                          xs={12}
                          item
                          style={{ display: "flex", fontSize: "0.9em" }}
                          alignItems="center">
                          <p>{question.answer}</p>
                        </Grid>
                      </Grid>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ))
        }

        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginTop: "2em" }}>
          <Grid item xs={4}>
            <button
              className={`
                ${btn.faded_orange} 
                ${btn.smallActivityBtn} 
                ${styles.loadMoreBtn}`}
              onClick={() => setIsAlertDialogOpen(true)}>
              Cancel
            </button>
          </Grid>
          <Grid item xs={4} className={`${styles.rightAlign}`}>
            <button
              className={`
                ${btn.faded_blue} 
                ${btn.smallActivityBtn} 
                ${styles.loadMoreBtn}`}
              onClick={handleSaveClick}>
              Save
            </button>
          </Grid>
        </Grid>
      </Wrapper>
    </Screen>
  );
};

export default CognitiveDistortionsReview;
