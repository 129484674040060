import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to get badges
const getBadges = createAsyncThunk("get badges", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/badges"),
    thunkAPI
  )
);

// send backend request to add new badge
const addBadge = createAsyncThunk("add badge", ({ name, descriptions, goals }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/badges",
      {
        name,
        descriptions,
        goals,
      },
    ),
    thunkAPI
  )
);

// send backend request to add new badge instance
const addBadgeInstance = createAsyncThunk("add badge instance", (
  { name, descriptions, goals, progress }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/badges/instance",
      {
        name,
        descriptions,
        goals,
        progress,
      },
    ),
    thunkAPI
  )
);

// send backend request to update a badge
const updateBadge = createAsyncThunk("update badge", (
  { id, name, descriptions, goals }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(
      `/badges/${id}`,
      {
        name,
        descriptions,
        goals,
      },
    ),
    thunkAPI
  )
);

// send backend request to update a badge
const updateBadgeInstance = createAsyncThunk("update badge instance", (
  { id, name, descriptions, goals, progress }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(
      `/badges/instance/${id}`,
      {
        name,
        descriptions,
        goals,
        progress,
      },
    ),
    thunkAPI
  )
);

// send backend request to delete specific badge
const deleteBadge = createAsyncThunk("delete badge", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/badges/${id}`),
    thunkAPI,
  )
);

export {
  addBadge,
  addBadgeInstance, deleteBadge, getBadges,
  updateBadge,
  updateBadgeInstance
};
