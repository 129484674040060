import styles from "CSS/Components/Badge.module.css";

// levels are: none, unachieved, blue, bronze, silver, gold
const Badge = ({ badge, onClick }) => {
  const displayProgress = () => {
    const progress = badge.progress ? badge.progress : "0";
    let goal;

    if (badge.level === "unachieved") {
      goal = badge.goals.blueGoal;
    } else if (badge.level === "blue") {
      goal = badge.goals.bronzeGoal;
    } else if (badge.level === "bronze") {
      goal = badge.goals.silverGoal;
    } else {
      goal = badge.goals.goldGoal;
    }

    return progress + "/" + goal;
  };
  return (
    <div onClick={onClick} className={styles.badge}>
      <h3>{badge.name}</h3>
      <p>{displayProgress()}</p>
      <img
        src={`/images/badges/${badge.level}.png`}
        alt={`${badge.level} badge`}
      />
    </div>
  );
};
export default Badge;
