import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";

// send backend request to login to the app
const login = createAsyncThunk("users/login", async ({ email, password }) => {
  try {
    const { data } = await axiosInstance.post("/users/login", { email, password });

    // Setting onboarding completed to false after signing in.
    data.isOnboardingCompleted = false;

    // store user data in browser
    localStorage.setItem("userData", JSON.stringify(data));

    return data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
});

// send backend request to signup for the app
const signup = createAsyncThunk("users/signup", async (
  { firstName, email, password, profilePic }) => {
  try {

    // Create a form and append image with additional fields
    const form = new FormData();
    form.append("firstName", firstName);
    form.append("email", email);
    form.append("password", password);
    form.append("profilePic", profilePic);

    const { data } = await axiosInstance.post("/users", form,
      { headers: { "Content-Type": "multipart/form-data" } });

    // Setting onboarding completed to false after signing up.
    data.isOnboardingCompleted = false;

    // store user data in browser
    localStorage.setItem("userData", JSON.stringify(data));

    return data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
}
);

const getUser = createAsyncThunk("users/profile", async (_, thunkAPI) => {
  try {

    const user = thunkAPI.getState().user.user;
    if (user) return user;

    const { data } = await axiosInstance.get("/users/profile");

    return { ...data, token: user.token };
  } catch (e) {
    throw new Error(e.response?.data?.message);
  }
});

const getUserById = createAsyncThunk("users/id", async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().user.user.token;

    const { data } = await axiosInstance.get(`/users/${id}`);

    return { ...data, token };
  } catch (error) {
    thunkAPI.rejectWithValue(error.response?.data?.message || "An error occurred");
  }
});

const updateUser = createAsyncThunk("/users/update",
  async ({ firstName, lastName, age, email, password, profilePic }, thunkAPI) => {
    try {
      // Create a form and append image with additional fields
      const form = new FormData();
      form.append("profilePic", profilePic);
      form.append("firstName", firstName);
      form.append("lastName", lastName);
      form.append("password", password);
      form.append("email", email);
      form.append("age", age);

      const token = thunkAPI.getState().user.user.token;

      const { data } = await axiosInstance.put("/users", form,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        }
      );

      // store updated user data in browser
      localStorage.setItem("userData", JSON.stringify({ ...data, token }));

      return { ...data, token };
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

const deleteUser = createAsyncThunk("/users/delete", async () => {
  try {
    await axiosInstance.delete("/users");
  } catch (e) {
    throw new Error(e.response.data.message);
  }
}
);

const generateResetCode = createAsyncThunk("users/generate-reset-code", async (email) => {
  try {
    const { data } = await axiosInstance.post("/users/generate-reset-code", { email });

    return data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
});

const verifyResetCode = createAsyncThunk("users/verify-reset-code", async (resetCodeData) => {
  try {
    console.log(resetCodeData);
    const { data } = axiosInstance.post("/users/verify-reset-code", resetCodeData);

    return data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
});

export { deleteUser, generateResetCode, getUser, getUserById, login, signup, updateUser, verifyResetCode };

