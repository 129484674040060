import { Grid, Input, InputAdornment, InputLabel, NativeSelect } from "@mui/material";
import TextField from "@mui/material/TextField";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddHabitScreen.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import BodyForm from "Components/Helpers/BodyForm";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import ToggleCard from "Components/Helpers/ToggleCard.js";
import WrapperForm from "Components/Helpers/WrapperForm";
import DayOfWeekSelector from "Components/Selectors/DayOfWeekSelector";
import { useRedirect } from "Hooks/useRedirect";
import { useUnitSelector } from "Hooks/useUnitSelector";
import { getHabit } from "Reducers/Actions/HabitActions";
import { getSubscription, subscribeToHabits } from "Reducers/Actions/SubscriptionActions";
import { resetHabit, selectHabits, updateHabit } from "Reducers/HabitsReducer";
import { selectSubscription } from "Reducers/SubscriptionReducer";
import { convertLocalTimeToUTC } from "Utilities/date-time";
import { getNewFrequencyTitle } from "Utilities/habit-utility";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getTokenFromFirebase } from "../../firebase.js";

const UpdateHabitScreen = () => {
  const { selectedHabit, loading, error } = useSelector(selectHabits);
  const { id } = useParams();
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [frequency, setFrequency] = useState("daily");
  const [frequencyTitle, setFrequencyTitle] = useState("day");
  const [interval, setInterval] = useState(1);
  const [unitAmount, setUnitAmount] = useState(1);
  const [habitName, setHabitName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [notificationTime, setNotificationTime] = useState("");
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectUrl = useRedirect("/habits");
  const {
    unitsToAdd,
    currentUnit,
    fillUnits,
    renderUnitModal,
    renderUnitSelector,
  } = useUnitSelector();
  const { token } = useSelector(selectSubscription);

  useEffect(() => {
    dispatch(getSubscription());
  }, [dispatch]);

  // sets the days of the week selector
  const handleDaysOfWeek = (_, newDays) => {
    setDaysOfWeek(newDays);
  };

  // autofills all the fields for the given habit
  const fillFields = () => {
    if (selectedHabit) {
      if (selectedHabit.isNotificationEnabled != null) {
        // Converting UTC time to local timezone.
        const localNotifTime = moment(selectedHabit.notificationTime).format("HH:mm");
        setNotificationTime(localNotifTime);
        setIsNotificationEnabled(selectedHabit.isNotificationEnabled);
      }

      setHabitName(selectedHabit.name);
      setFrequency(selectedHabit.frequency);
      setInterval(selectedHabit.interval);
      setUnitAmount(selectedHabit.unitAmount);
      setDaysOfWeek(selectedHabit.daysOfWeek);
      fillUnits(selectedHabit);
      setFrequencyTitle(
        getNewFrequencyTitle(selectedHabit.frequency, selectedHabit.interval)
      );
    }
  };

  const checkToken = async () => {
    if (!token) {
      const newToken = await getTokenFromFirebase();
      return newToken;
    } else {
      return token;
    }
  };

  const updateHabitSubmit = async (e) => {
    e.preventDefault();

    // Checking if either notification time and notificaiton for habit was updated.
    if (notificationTime !== moment(selectedHabit.notificationTime).format("HH:mm")
      || selectedHabit.isNotificationEnabled != isNotificationEnabled) {
      // Updating notification time to include default date.
      const utcTimeString = convertLocalTimeToUTC(notificationTime);

      // Making sure that the device has an updated token for notification.
      const token = await checkToken();

      // Subscribing to habit notification.
      dispatch(
        subscribeToHabits({
          token,
          isEnabled: true,
        })
      );

      dispatch(
        updateHabit({
          id,
          name: habitName,
          unit: currentUnit,
          unitAmount,
          extraUnits: unitsToAdd,
          interval,
          frequency,
          daysOfWeek,
          isNotificationEnabled,
          notificationTime: utcTimeString,
        })
      );
    } else {
      dispatch(
        updateHabit({
          id,
          name: habitName,
          unit: currentUnit,
          unitAmount,
          extraUnits: unitsToAdd,
          interval,
          frequency,
          daysOfWeek,
        })
      );
    }

    // Success to reset state and go back to entries
    if (!loading && !error) {
      dispatch(resetHabit);
      navigate("/habits");
    }
  };

  useEffect(() => {
    dispatch(getHabit(id));
  }, []);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
    fillFields();
  }, [selectedHabit]);

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={updateHabitSubmit}>
        {renderUnitModal()}
        <div className={styles.header}>
          <Link
            to={redirectUrl}
            className={`${btn.links} ${btn.secondaryLink}`}
          >
            Cancel
          </Link>
          <h3>Update Habit</h3>
          <button type="submit" className={`${btn.links} ${btn.primaryLink}`}>
            Update
          </button>
        </div>
        <BodyForm desktop={styles.body}>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <TextField
            className={styles.field}
            label="Habit Name"
            type="text"
            placeholder="Enter habit"
            name="title"
            InputLabelProps={{ shrink: true }}
            value={habitName}
            required={true}
            onChange={(e) => setHabitName(e.target.value)}
            variant="standard"
            inputProps={{
              maxLength: 256,
            }}
          />
          <div className={styles.field}>
            <InputLabel
              variant="standard"
              htmlFor="uncontrolled-native"
              className={styles.repeat}
            >
              <p>How often?</p>
            </InputLabel>

            <NativeSelect
              onChange={(e) => {
                setFrequency(e.target.value);
                setFrequencyTitle(
                  getNewFrequencyTitle(e.target.value, interval)
                );
              }}
              value={frequency}
              inputProps={{
                name: "Frequency",
                id: "uncontrolled-native",
              }}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </NativeSelect>
          </div>

          <div className={styles.field}>
            <InputLabel variant="standard" className={styles.repeat}>
              <p>Repeat Every</p>
            </InputLabel>
            <Input
              className={styles.goalAmount}
              label="Interval"
              type="number"
              variant="standard"
              value={interval}
              onChange={(e) => {
                setInterval(e.target.value);
                setFrequencyTitle(
                  getNewFrequencyTitle(frequency, e.target.value)
                );
              }}
              endAdornment={
                <InputAdornment position="end">{frequencyTitle}</InputAdornment>
              }
            />
          </div>

          {frequency === "weekly" && (
            <div className={styles.field}>
              <InputLabel variant="standard" className={styles.frequency}>
                <p>Which Days?</p>
              </InputLabel>
              <DayOfWeekSelector
                className={`${isMobile ? styles.daysMobile : styles.days}`}
                daysOfWeek={daysOfWeek}
                handleDaysOfWeek={handleDaysOfWeek}
              />
            </div>
          )}
          <div className={styles.field}>
            <InputLabel variant="standard" className={styles.repeat}>
              <p>Goal Amount</p>
            </InputLabel>
            <Input
              className={styles.goalAmount}
              label="Goal Amount"
              type="number"
              variant="standard"
              value={unitAmount}
              onChange={(e) => setUnitAmount(e.target.value)}
              endAdornment={
                <InputAdornment position="end">{currentUnit}</InputAdornment>
              }
            />
          </div>

          <div className={styles.field}>
            <InputLabel variant="standard" className={styles.frequency}>
              <p>Units</p>
            </InputLabel>
            {renderUnitSelector()}
          </div>
          <Grid container>
            <ToggleCard
              cardLabel="Get notified for habit"
              isChecked={isNotificationEnabled}
              isJournal={true}
              time={notificationTime}
              required={false}
              style={{
                width: "100%",
                backgroundColor: "transparent",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                marginTop: "1em",
                marginBottom: "1em"
              }}
              onChange={() => setIsNotificationEnabled(prev => !prev)}
              onTimeChange={(e) => setNotificationTime(e)} />
          </Grid>
          <button
            className={`${btn.btn} ${btn.primary}`}
            onClick={updateHabitSubmit}
          >
            {loading ? "Loading..." : "Update"}
          </button>
        </BodyForm>
      </WrapperForm>
    </ScreenNoNav>
  );
};
export default UpdateHabitScreen;
