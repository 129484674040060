import { Grid, TextField } from "@mui/material";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/Journal.module.css";
import useEmotionSelector from "Hooks/useEmotionSelector";
import { useProvider } from "Hooks/useProvider";
import { useRedirect } from "Hooks/useRedirect";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addAppointmentNote } from "Reducers/AppointmentNoteReducer";
import { selectHealthCareProviders } from "Reducers/HealthCareProvidersReducer";

const AddAppointmentNoteScreen = () => {
  const [dateTime, setDateTime] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [thingsToDiscuss, setThingsToDiscuss] = useState("");
  const [appointmentNotes, setAppointmentNotes] = useState("");
  const [followUp, setFollowUp] = useState("");

  const { emotions, renderModal, renderSelector } = useEmotionSelector();
  const { newlyAddedId } = useSelector(selectHealthCareProviders);
  const { renderAutocomplete } = useProvider(true);
  // const { badges } = useSelector(selectBadges);
  const redirectUrl = useRedirect("/journal");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAppointmentNoteSubmit = (e) => {
    e.preventDefault();

    // find all selected emotions
    dispatch(
      addAppointmentNote({
        date: moment(dateTime).utc(),
        thingsToDiscuss,
        appointmentNotes,
        emotions,
        followUp,
        healthCareProvider: newlyAddedId,
      })
    );

    // get journaling badge
    // const badge = badges.find((badge) => badge.name === "Journaling");

    // increment progress when submitting new journal entry
    // addToBadge(badge, dispatch, badge.progress + 1);

    navigate("/journal");
  };

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={handleAppointmentNoteSubmit}>
        {renderModal()}

        <div className={styles.journal_header}>
          <Link
            to={redirectUrl}
            className={`${styles.journal_btn} ${styles.journal_btn_orange}`}
          >
            Close
          </Link>
          <h3>New Appointment Note</h3>
          <button
            type="submit"
            className={`${styles.journal_btn} ${styles.journal_btn_blue}`}
          >
            Add
          </button>
        </div>
        <Grid spacing={1} container justifyContent="center">
          <Grid xs={10} item>
            <div>
              <TextField
                className={styles.datetextarea}
                type="datetime-local"
                name="day"
                fullWidth
                required={false}
                value={dateTime}
                onChange={(e) => setDateTime(e.target.value)}
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
              />
              {renderAutocomplete()}
            </div>
          </Grid>

          <Grid xs={10} item>
            <TextField
              style={{ width: "100%" }}
              label="Things to Discuss"
              multiline
              rows={3}
              placeholder="Enter discussion points"
              name="discussionPoints"
              required={false}
              value={thingsToDiscuss}
              onChange={(e) => setThingsToDiscuss(e.target.value)}
              variant="outlined"
              inputProps={{
                maxLength: 500,
              }}
              size="small"
            />
          </Grid>

          <Grid xs={10} item>
            <TextField
              className={styles.textarea}
              label="Appointment Notes"
              multiline
              rows={3}
              style={{ width: "100%" }}
              placeholder="Enter appointment notes"
              name="appointmentNotes"
              required={false}
              value={appointmentNotes}
              onChange={(e) => setAppointmentNotes(e.target.value)}
              variant="outlined"
              inputProps={{
                maxLength: 500,
              }}
              size="small"
            />
          </Grid>

          <Grid xs={10} item>
            <TextField
              className={styles.textarea}
              label="Follow Up"
              style={{ width: "100%" }}
              multiline
              rows={2}
              placeholder="What are some follow ups?"
              name="followUp"
              required={false}
              value={followUp}
              onChange={(e) => setFollowUp(e.target.value)}
              variant="outlined"
              inputProps={{
                maxLength: 500,
              }}
              size="small"
            />
          </Grid>

          <Grid item xs={10}>
            {renderSelector()}
          </Grid>

          <button
            className={`${btn.btn} ${btn.faded_blue} ${styles.journal_submit}`}
            type="submit"
          >
            Add
          </button>
        </Grid>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default AddAppointmentNoteScreen;
