import { useSelector } from "react-redux";
import { selectJournalEntries } from "Reducers/JournalReducer";
import AddAppointmentNoteScreen from "./Appointment-Note/AddAppointmentNoteScreen";
import AddJournalEntryScreen from "./Journal/AddJournalEntryScreen";
import AddPathwayNoteScreen from "./Pathway/AddPathwayNoteScreen";

const AddEntriesScreen = () => {
  const { page } = useSelector(selectJournalEntries);

  if (page === "1") {
    return <AddJournalEntryScreen />;
  } else if (page === "2") {
    return <AddAppointmentNoteScreen />;
  } else if (page === "3") {
    return <AddPathwayNoteScreen />;
  }
};
export default AddEntriesScreen;
