
function SimsDimsLesson() {
    const pdfFile = "/pdfs/Toolkit_eBook_2023.pdf";

    return (
        <div style={{ width: "100%", height: "100vh" }}>
            <iframe
                src={pdfFile}
                title="PDF Viewer"
                width="100%"
                height="100%"
                style={{ border: "none" }}
            />
        </div>
    );
}

export default SimsDimsLesson;