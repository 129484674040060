import { Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AmbientSounds.module.css";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import { Howl } from "howler";
import { useEffect, useRef, useState } from "react";
import { AiOutlinePause } from "react-icons/ai";
import { BsPlay } from "react-icons/bs";
import { FiFastForward } from "react-icons/fi";
import { useLocation } from "react-router-dom";


const AmbientSoundsScreen = () => {
    const location = useLocation();
    const sound = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(0.5); // Initial volume
    const [suggestion, setSuggestion] = useState("");


    useEffect(() => {

        // Play the sound when the component mounts or when the location changes
        playSound();

        // Cleanup function
        return () => {
            stopSound();
        };
    }, [location]);

    const playSound = () => {
        // Get the sound file path from the URL state
        const soundFilePath = location.state ? location.state.audioSrc : "/audio/raain-sound.mp3";


        // If there is an existing sound, stop it before playing a new one
        if (sound.current) {
            sound.current.stop();
            setIsPlaying(false);
        }

        // Create a new Howl instance with the selected sound file
        sound.current = new Howl({
            src: [soundFilePath],
            volume: volume,
            loop: true,
            onend: () => setIsPlaying(false),
        });

        // Play the selected sound
        sound.current.play();
        setIsPlaying(true);
    };

    const stopSound = () => {
        // Stop the currently playing sound
        if (sound.current) {
            sound.current.stop();
            setIsPlaying(false);
        }
    };

    const handleVolumeChange = (event) => {
        const newVolume = parseFloat(event.target.value);
        setVolume(newVolume);
        if (sound.current) {
            sound.current.volume(newVolume);
        }
    };

    const handleSuggestionChange = (e) => {
        setSuggestion(e.target.value);
    };

    const handleSuggestionSubmit = () => {
        console.log("Suggestion sent:", suggestion);
        setSuggestion(""); // clean textfield
        // Change this with the logic to send the suggestion to server or database
    };

    return (
        <Screen>
            <Wrapper>
                <BackBtn title="Back to Ambient Sound" link="/toolkit/ambient-sound" />
                <div>
                    <h2>Ambient Sounds</h2>
                    {isPlaying ? (
                        <button onClick={stopSound} style={{ fontSize: "12em", marginRight: "10px" }}>
                            <AiOutlinePause />
                        </button>
                    ) : (
                        <button onClick={playSound} style={{ fontSize: "12em", marginRight: "10px" }}>
                            <BsPlay />
                        </button>
                    )}

                    <button onClick={() => console.log("Forward Clicked")} style={{ fontSize: "12em", marginRight: "10px" }}>
                        <FiFastForward />
                    </button>
                </div>
                <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}>
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={volume}
                        onChange={handleVolumeChange}
                        style={{ width: "100%", marginTop: "10px" }}
                    />
                </div>
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" className={styles.marginTop}>
                    <Grid xs={10} item>
                        <Typography variant="h6" gutterBottom>Your Suggestions</Typography>
                        <TextField
                            className={styles.textarea}
                            label="Suggestions"
                            type="text"
                            multiline
                            rows={3.5}
                            placeholder="Enter your suggestions here"
                            name="suggestions"
                            value={suggestion}
                            onChange={handleSuggestionChange}
                            variant="outlined"
                            inputProps={{ maxLength: 500 }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "2em" }}>
                    <Grid xs={10} item style={{ textAlign: "right" }}>
                        <button
                            className={`${btn.faded_blue} ${btn.smallActivityBtn} ${styles.navBtn}`}
                            onClick={handleSuggestionSubmit}>
                            Send Suggestion
                        </button>
                    </Grid>
                </Grid>

            </Wrapper>
        </Screen>
    );
};

export default AmbientSoundsScreen;