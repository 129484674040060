import styles from "CSS/Screens/MyHealthRecordScreen.module.css";
import Screen from "Components/Helpers/Screen";
import ToggleCard from "Components/Helpers/ToggleCard";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import { selectUserSetting, updateSideNavConfig } from "Reducers/UserSettingReducer";
import { useDispatch, useSelector } from "react-redux";

const SideNavSettingScreen = () => {
  const dispatch = useDispatch();
  const { sideNavConfig } = useSelector(selectUserSetting);

  const handleToggle = (content) => {
    dispatch(updateSideNavConfig({ content }));
  };

  return (
    <Screen>
      <Wrapper>
        <BackBtn title="Back to Account Settings" link="/setting/account" />
        <div className={styles.myHealthRecordScreen}>
          <h3>Select order for side navigation</h3>
          {
            sideNavConfig != null && sideNavConfig != undefined && (
              sideNavConfig.map((item, index) => {
                if (item.isModifiable) {
                  return (
                    <ToggleCard
                      key={index}
                      cardLabel={item.content}
                      isChecked={item.isActive}
                      onChange={() => handleToggle(item.content)}
                    />
                  );
                }
              })
            )
          }
        </div>
      </Wrapper>
    </Screen>
  );
};

export default SideNavSettingScreen;
