import { createSlice } from "@reduxjs/toolkit";
import { addBehavioralExperiments, addExperimentAttempt, editBehavioralExperiment, getBehavioralExperiments, updateExperiment } from "./Actions/BehavioralExperimentActions";


const initialState = {
  loading: false,
  date: "",
  thoughts: "",
  details: "",
  prediction: "",
  feeling: 0,
  outcome: "",
  feelingAfter: 0,
  newThought: "",
  successAdd: false,
  complete: false,
  experimentEntries: [],
  experimentAttempts: [],
  obstacle: "",
  change: "",
  filteredExperiments: [],
  selectedExperiment: null,
  successDelete: false,
  error: null
};

const selectBehavioralExperiment = (state) => state.behavioralExperiment;

const behavioralExperimentSlice = createSlice({
  name: "behavioralExperiment",
  initialState,
  reducers: {
    resetBehavioralExperiment: () => initialState,
    setDate: (state, { payload }) => {
      return {
        ...state,
        date: payload
      };
    },
    setThoughts: (state, { payload }) => {
      return {
        ...state,
        thoughts: payload
      };
    },
    setDetails: (state, { payload }) => {
      return {
        ...state,
        details: payload
      };
    },
    setPrediction: (state, { payload }) => {
      return {
        ...state,
        prediction: payload
      };
    },
    setFeeling: (state, { payload }) => {
      return {
        ...state,
        feeling: payload
      };
    },
    resetBehavioralSuccess: (state) => {
      return {
        ...state,
        successAdd: false
      };
    },
    setSelectedExperiment: (state, { payload }) => {
      return {
        ...state,
        selectedExperiment: payload,
      };
    },
    setOutcome: (state, { payload }) => {
      return {
        ...state,
        outcome: payload,
      };
    },
    setFeelingAfter: (state, { payload }) => {
      return {
        ...state,
        feelingAfter: payload,
      };
    },
    setNewThought: (state, { payload }) => {
      return {
        ...state,
        newThought: payload,
      };
    },
    filterExperiments: (state, { payload }) => {
      state.filteredExperiments = state.experimentEntries;

      if (payload.type === "Complete") {
        state.filteredExperiments = state.filteredExperiments.filter(
          experiment => experiment.complete === true
        );
      } else if (payload.type === "Incomplete") {
        state.filteredExperiments = state.filteredExperiments.filter(
          experiment => experiment.complete === false
        );
      } else {
        state.filteredExperiments = state.experimentEntries;
      }
    },
    setObstacle: (state, { payload }) => {
      return {
        ...state,
        obstacle: payload
      };
    },
    setChange: (state, { payload }) => {
      return {
        ...state,
        change: payload
      };
    }
  },
  extraReducers: {
    // ADDING
    [addBehavioralExperiments.pending]: (state) => {
      state.loading = true;
    },
    [addBehavioralExperiments.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
    },
    [addBehavioralExperiments.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // GETTING
    [getBehavioralExperiments.pending]: (state) => {
      state.loading = true;
    },
    [getBehavioralExperiments.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.experimentEntries = payload;
      state.error = null;

      state.filteredExperiments = state.experimentEntries;
    },
    [getBehavioralExperiments.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    }
  }
});

export default behavioralExperimentSlice.reducer;

export {
  addBehavioralExperiments, addExperimentAttempt, editBehavioralExperiment, getBehavioralExperiments, updateExperiment, selectBehavioralExperiment
};

export const {
  resetBehavioralExperiment,
  resetBehavioralSuccess,
  setNewThought,
  setFeelingAfter,
  setOutcome,
  setDate,
  setDetails,
  setFeeling,
  setObstacle,
  setChange,
  setSelectedExperiment,
  setPrediction,
  setThoughts,
  filterExperiments
} = behavioralExperimentSlice.actions;