import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Condition from "Components/Condition/Condition";
import ProviderNote from "Components/HealthCareProvider/ProviderNote";
import UpdateHealthCareProviderSpeedDial from "Components/HealthCareProvider/UpdateHealthCareProviderSpeedDial";
import AlertMessage from "Components/Helpers/AlertMessage";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import Medication from "Components/Medication";
import Treatment from "Components/Treatment";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/UpdateHealthCareProviderScreen.module.css";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteHealthCareProvider,
  getHealthCareProvider,
  resetHealthCareProviders,
  selectHealthCareProviders,
  updateHealthCareProvider,
} from "Reducers/HealthCareProvidersReducer";

const UpdateHealthCareProviderScreen = () => {
  const { selectedHealthCareProvider, loading, error } = useSelector(
    selectHealthCareProviders
  );
  const { id } = useParams();

  const [healthCareProviderName, setHealthCareProviderName] = useState("");
  const [appointmentNotes, setAppointmentNotes] = useState([]);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [medications, setMedications] = useState([]);
  const [isArchived, setIsArchived] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [address, setAddress] = useState("");
  const [field, setField] = useState("");
  const [treatments, setTreatments] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // fills all the fields for the given provider with the data from it
  const fillFields = () => {
    if (selectedHealthCareProvider) {
      setHealthCareProviderName(
        selectedHealthCareProvider.healthCareProviderName
      );
      setField(selectedHealthCareProvider.field);
      setPhoneNumber(selectedHealthCareProvider.phoneNumber);
      setAddress(selectedHealthCareProvider.address);
      setIsArchived(selectedHealthCareProvider.isArchived);
      setAdditionalNotes(selectedHealthCareProvider.additionalNotes);
      setAppointmentNotes(selectedHealthCareProvider.appointmentNotes);
      setMedications(selectedHealthCareProvider.medications);
      setConditions(selectedHealthCareProvider.conditions);
      setTreatments(selectedHealthCareProvider.treatments);
    }
  };

  const updateHealthCareProviderSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateHealthCareProvider({
        id,
        healthCareProviderName,
        field,
        phoneNumber,
        address,
        isArchived,
        additionalNotes,
      })
    );

    // Success so go back
    if (!loading && !error) {
      navigate("/my-health-record/health-care-providers");
    }
  };

  const deleteHealthCareProviderAction = (e) => {
    e.preventDefault();

    localStorage.setItem(
      "tempHealthCareProvider",
      JSON.stringify(selectedHealthCareProvider)
    );
    dispatch(deleteHealthCareProvider(selectedHealthCareProvider._id));
    // Success so go back to history page
    if (!loading && !error) {
      navigate("/my-health-record/health-care-providers");
    }
  };

  useEffect(() => {
    dispatch(getHealthCareProvider(id));

    fillFields();

    if (error) {
      setErrorMessage(error);
    }

    return () => {
      dispatch(resetHealthCareProviders);
    };
  }, [selectedHealthCareProvider?.healthCareProviderName]);

  // Load more function
  const initialEntries = 3;
  const [nextAppointmentNotes, setNextAppointmentNotes] =
    useState(initialEntries);
  const [nextMedications, setNextMedications] = useState(initialEntries);
  const [nextTreatments, setNextTreatments] = useState(initialEntries);
  const [nextConditions, setNextConditions] = useState(initialEntries);

  const handleMoreAppointmentNotes = () => {
    setNextAppointmentNotes(nextAppointmentNotes + initialEntries);
  };
  const handleMoreMedications = () => {
    setNextMedications(nextMedications + initialEntries);
  };
  const handleMoreTreatments = () => {
    setNextTreatments(nextTreatments + initialEntries);
  };
  const handleMoreConditions = () => {
    setNextConditions(nextConditions + initialEntries);
  };

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={updateHealthCareProviderSubmit}>
        <div className={styles.header}>
          <button
            className={`${btn.links} ${btn.secondaryLink}`}
            onClick={() => navigate("/my-health-record/health-care-providers")}
            type="button"
          >
            Cancel
          </button>
          <h3>Update Provider</h3>
          <button type="submit" className={`${btn.links} ${btn.primaryLink}`}>
            Update
          </button>
        </div>
        <div>
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />

          <div
            className={`${styles.body} ${isMobile ? styles.bodyMobile : ""}`}
          >
            <div className={styles.textField}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!isArchived}
                    onChange={(e) => setIsArchived(!e.target.checked)}
                    label="Active Status"
                  />
                }
                label="Active Status"
              />
            </div>

            <TextField
              className={styles.textField}
              label="Health Care Provider Name"
              type="text"
              placeholder="Enter health care provider name"
              name="name"
              required={true}
              value={healthCareProviderName}
              onChange={(e) => setHealthCareProviderName(e.target.value)}
              size="small"
            />
            <TextField
              className={styles.textField}
              label="Field"
              type="text"
              placeholder="Enter field"
              name="field"
              value={field}
              onChange={(e) => setField(e.target.value)}
              size="small"
            />
            <TextField
              className={styles.textField}
              label="Phone Number"
              type="text"
              placeholder="Enter phone number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              size="small"
              inputProps={{
                maxLength: 15,
              }}
            />
            <TextField
              className={styles.textField}
              label="Address"
              type="text"
              placeholder="Enter address"
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              size="small"
            />
            <TextField
              className={styles.textField}
              label="Additional Notes"
              type="text"
              placeholder="Enter additional notes"
              name="additionalNotes"
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
              size="small"
              multiline={true}
              rows={3}
            />
            {appointmentNotes?.length > 0 && (
              <>
                <h3>Recent Appointment Notes</h3>
                <div className={styles.notes}>
                  {appointmentNotes
                    ?.slice(0, nextAppointmentNotes)
                    ?.map((appointmentNote) => (
                      <ProviderNote
                        key={appointmentNote._id}
                        appointmentNote={appointmentNote}
                      />
                    ))}
                </div>
              </>
            )}
            {nextAppointmentNotes < appointmentNotes?.length && (
              <button
                type="button"
                className={`${btn.links} ${btn.primaryLink}`}
                onClick={handleMoreAppointmentNotes}
              >
                Load more
              </button>
            )}
            {medications?.length > 0 && (
              <>
                <h3>Medications</h3>
                <div className={styles.notes}>
                  {medications?.slice(0, nextMedications)?.map((medication) => (
                    <Medication key={medication._id} medication={medication} />
                  ))}
                </div>
              </>
            )}
            {nextMedications < medications?.length && (
              <button
                type="button"
                className={`${btn.links} ${btn.primaryLink}`}
                onClick={handleMoreMedications}
              >
                Load more
              </button>
            )}
            {treatments?.length > 0 && (
              <>
                <h3>Treatments</h3>
                <div className={styles.notes}>
                  {treatments?.slice(0, nextTreatments)?.map((treatment) => (
                    <Treatment key={treatment._id} treatment={treatment} />
                  ))}
                </div>
              </>
            )}
            {nextTreatments < treatments?.length && (
              <button
                type="button"
                className={`${btn.links} ${btn.primaryLink}`}
                onClick={handleMoreTreatments}
              >
                Load more
              </button>
            )}
            {conditions?.length > 0 && (
              <>
                <h3>Conditions</h3>
                <div className={styles.notes}>
                  {conditions?.slice(0, nextConditions)?.map((condition) => (
                    <Condition key={condition._id} condition={condition} />
                  ))}
                </div>
              </>
            )}
            {nextConditions < conditions?.length && (
              <button
                type="button"
                className={`${btn.links} ${btn.primaryLink}`}
                onClick={handleMoreConditions}
              >
                Load more
              </button>
            )}
          </div>
          <div className={styles.btns}>
            <button
              type="button"
              className={`${btn.btn} ${btn.secondary}`}
              onClick={deleteHealthCareProviderAction}
            >
              Delete
            </button>
            <button
              type="submit"
              className={`${btn.btn} ${btn.primary}`}
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
        <UpdateHealthCareProviderSpeedDial />
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default UpdateHealthCareProviderScreen;
