import styles from "CSS/Components/Condition.module.css";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Condition = memo(function temp({ condition }) {
  const [day, setDay] = useState(moment());
  const [time, setTime] = useState("");

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setDay(
      moment(condition.date).format("MMMM") +
      " " +
      moment(condition.date).date()
    );
    setTime(
      ", " +
      moment(condition.date)
        .format("hh:mm A")
        .replace(/(^|-)0+/g, "")
    ); // remove leading 0 on date
  }, [condition.date]);

  return (
    <section className={styles.container}>
      <div
        className={`${styles.info}
        }`}
        onClick={() =>
          navigate(
            `/my-health-record/conditions/${condition._id}?redirect=${pathname}`
          )
        }
      >
        <h3>
          {condition.conditionName.length > 15
            ? `${condition.conditionName.substring(0, 15)}...`
            : condition.conditionName.length == 0
              ? "Untitled"
              : condition.conditionName}
        </h3>
        <p>
          {moment(condition.date).isSame(moment(), "day")
            ? "🗓️ " + "Today" + time
            : "🗓️ " + day + time}
        </p>
      </div>
    </section>
  );
});

export default Condition;
