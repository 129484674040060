import globals from "CSS/Globals.module.css";
import { isMobile } from "react-device-detect";

const ScreenNoNav = ({ children }) => {
  return (
    <>
      {isMobile && <div className={globals.generic_fillerMobile} />}
      {isMobile && <div className={globals.generic_fillerMobile2} />}
      <main
        className={`${isMobile ? globals.generic_containerMobile : globals.generic_container
          }`}
      >
        {children}
      </main>
    </>
  );
};
export default ScreenNoNav;
