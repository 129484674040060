import { createSlice } from "@reduxjs/toolkit";
import {
  addPostCheckin,
  addPreCheckin,
  getUserCheckin,
  updateUserCheckin,
} from "./Actions/CheckinActions";

const initialState = {
  loading: false,
  error: null,
  isCheckinEnabled: false,
};

// functions for "useSelector"
const selectCheckin = (state) => state.checkins;

const checkinSlice = createSlice({
  name: "checkin",
  initialState,
  reducers: {
    resetCheckin: () => initialState,
    toggleCheckinStatus: (state) => {
      return {
        ...state,
        isCheckinEnabled: !state.isCheckinEnabled,
      };
    },
  },
  extraReducers: {
    // Get User Checkin Async Reducers
    [getUserCheckin.pending]: (state) => {
      state.loading = true;
    },
    [getUserCheckin.fulfilled]: (state, { payload }) => {
      state.isCheckinEnabled = payload.isCheckinEnabled;
    },
    [getUserCheckin.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Update User Checkin Async Reducers
    [updateUserCheckin.pending]: (state) => {
      state.loading = true;
    },
    [updateUserCheckin.fulfilled]: () => { },
    [updateUserCheckin.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add Pre Checkin Async Reducers
    [addPreCheckin.pending]: (state) => {
      state.loading = true;
    },
    [addPreCheckin.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
    },
    [addPreCheckin.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add Post Checkin Async Reducers.
    [addPostCheckin.pending]: (state) => {
      state.loading = true;
    },
    [addPostCheckin.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
    },
    [addPostCheckin.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default checkinSlice.reducer;

export { addPostCheckin, addPreCheckin, getUserCheckin, selectCheckin };

export const { resetCheckin, toggleCheckinStatus } = checkinSlice.actions;
