import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";


// send backend request to get cognitive distortions
const getCongitiveDistortions = createAsyncThunk("get cognitive distoritons/", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/cognitive-distortion/"),
    thunkAPI
  )
);

// send backend request to add new cognitive distortion entry
const addCognitiveDistortionEntry = createAsyncThunk("add cognitive distortion entry/", async (
  questionEntry, thunkAPI) => {
  // Format the selected distortions before sending to the server.
  const formattedSelectedDistortions = await _formatCognitiveDistortionEntry(questionEntry.selectedDistortions);
  actionHandler(
    axiosInstance.post("/distortion-entry",
      {
        thoughts: questionEntry.thoughts,
        situation: questionEntry.situation,
        date: questionEntry.date,
        selectedDistortions: formattedSelectedDistortions,
      },
    ),
    thunkAPI
  );
});

// send backend request to get cognitive distortion entries
const getCognitiveDistortionEntries = createAsyncThunk("get cognitive distortion entries/", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/distortion-entry"),
    thunkAPI
  )
);

const _formatCognitiveDistortionEntry = (entries) => {
  const formattedEntry = entries.map(item => {
    // Extract the "d_id" property from the object and store the 
    // rest of the properties in the "rest" variable
    const { _id, ...rest } = item;

    // Map over the "distortionQuestions" array and extract 
    // the "q_id" property from each object in the array
    const distortionQuestions = rest.distortionQuestions.map(question => {
      const { _id, ...restOfQuestion } = question;

      // Create a new object with the renamed property names 
      // using the spread operator ("...") and return it
      return {
        ...restOfQuestion,
        id: _id
      };
    });

    // Create a new object with the renamed property names 
    // using the spread operator ("...") and return it
    return {
      ...rest,
      distortionId: _id,
      distortionQuestions
    };
  });

  return formattedEntry;
};

export {
  addCognitiveDistortionEntry,
  getCognitiveDistortionEntries, getCongitiveDistortions
};

