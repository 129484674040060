import PostCheckin from "Components/Checkin/PostCheckin";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addPostCheckin } from "Reducers/CheckinReducer";

/**
 * /post-checkin/{activity-type}/{check-in-id}
 *
 * @returns
 */
const PostCheckinScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePostCheckIn = (emotions, emotionRating) => {
    // Send post checkin details to database.
    dispatch(
      addPostCheckin({
        checkinId: id,
        time: new Date(),
        emotions,
        emotionRating,
      })
    );

    navigate("/toolkit");
  };

  const handlePostCheckInSkip = () => {
    navigate("/toolkit");
  };

  return (
    <Screen>
      <Wrapper>
        <PostCheckin
          handlePostCheckIn={handlePostCheckIn}
          handlePostCheckInSkip={handlePostCheckInSkip}
        />
      </Wrapper>
    </Screen>
  );
};

export default PostCheckinScreen;
