import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import HomeSpeedDial from "Components/HomeSpeedDial";
import styles from "CSS/Screens/HomeScreen.module.css";
import moment from "moment";
import HabitsWidget from "Widgets/HabitsWidget";
import ToDosWidget from "Widgets/ToDosWidget";

const HomeScreen = () => {
  return (
    <Screen>
      <Wrapper>
        <h1 className={styles.home_date}>
          {moment().format("dddd, MMMM D YYYY")}
        </h1>
        {/* <p>env: {process.env.NODE_ENV}</p> */}
        <ToDosWidget />
        <HabitsWidget />
        <HomeSpeedDial />
      </Wrapper>
    </Screen>
  );
};

export default HomeScreen;
