import { createSlice } from "@reduxjs/toolkit";
import {
  getCode,
  getFeedback,
  getUsers,
  updateFeedback,
} from "./Actions/AdminActions";

const initialState = {
  feedback: [],
  users: [],
  code: {},
  loading: false,
  error: null,
};

// functions for "useSelector"
const selectAdmin = (state) => state.admin;

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetAdmin: () => initialState,
  },
  extraReducers: {
    // Get access code Async Reducers
    [getCode.pending]: (state) => {
      state.loading = true;
    },
    [getCode.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.code = payload;
    },
    [getCode.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get users Async Reducers
    [getUsers.pending]: (state) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.users = payload;
    },
    [getUsers.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get feedback Async Reducers
    [getFeedback.pending]: (state) => {
      state.loading = true;
    },
    [getFeedback.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.feedback = payload;
    },
    [getFeedback.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Update feedback Async Reducers
    [updateFeedback.pending]: (state) => {
      state.loading = true;
    },
    [updateFeedback.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      const idx = state.feedback.findIndex(
        (feedback) => feedback._id === payload._id
      );
      state.feedback[idx] = payload;
    },
    [updateFeedback.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default adminSlice.reducer;
export { getCode, getFeedback, getUsers, selectAdmin };
export const { resetAdmin } = adminSlice.actions;
