import AlertMessage from "Components/Helpers/AlertMessage";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/HealthInfoScreen.module.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getBloodPressures,
  getBloodType,
  getFamilyHistorys,
  getHealthCard,
  getHeight,
  getPulses,
  getSugarLevels,
  getTemperatures,
  getWeights,
  resetHealthInfos,
  selectHealthInfo,
} from "Reducers/HealthInfoReducer";

const HealthInfoScreen = () => {
  const {
    error,
    healthCard,
    bloodPressure,
    bloodType,
    pulse,
    sugarLevel,
    height,
    weight,
    temperature,
    familyHistory,
  } = useSelector(selectHealthInfo);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Get component data
    dispatch(getHealthCard());
    dispatch(getBloodPressures());
    dispatch(getBloodType());
    dispatch(getPulses());
    dispatch(getSugarLevels());
    dispatch(getHeight());
    dispatch(getWeights());
    dispatch(getTemperatures());
    dispatch(getFamilyHistorys());

    if (error) {
      setErrorMessage(error);
    }

    return () => {
      dispatch(resetHealthInfos());
    };
  }, [dispatch]);

  return (
    <Screen>
      <Wrapper>
        <BackBtn title="Back" link="/my-health-record" />
        {errorMessage && (
          <AlertMessage
            sx={{ width: "80%", marginBottom: "15px" }}
            severity="error"
            onClose={() => setErrorMessage("")}
          >
            {errorMessage}
          </AlertMessage>
        )}
        <h1>Health Info</h1>
        <div>
          <div className={styles.record_container}>
            <div className={styles.wrapper}>
              <div className={`${btn.spacearound}`}>
                <h3>💳 Health Card</h3>
                <Link to="/edit-health-card" style={{ textDecoration: "none" }}>
                  <button
                    className={`${btn.rightjournalentrybtn} ${btn.white_btn}`}
                    style={{ marginLeft: "63px", marginBottom: "5px" }}
                    type="button"
                  >
                    Edit
                  </button>
                </Link>
              </div>
              <div key={healthCard._id} className={styles.healthinfocomponent}>
                <p>{healthCard.cardNumber}</p>
                <p>{moment(healthCard.cardExpiration).format("MM / YY")}</p>
              </div>
            </div>

            <div className={styles.wrapper}>
              <div className={`${btn.spacearound}`}>
                <h3>🩸 Blood Pressure</h3>
                <Link
                  to="/add-blood-pressure"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    className={`${btn.rightjournalentrybtn} ${btn.white_btn}`}
                    style={{ marginLeft: "35px", marginBottom: "10px" }}
                    type="button"
                  >
                    Add
                  </button>
                </Link>
              </div>
              <div
                key={bloodPressure._id}
                className={styles.healthinfocomponent}
              >
                <p>
                  {bloodPressure.bloodPressureSystolic} /
                  {bloodPressure.bloodPressureDiastolic}
                </p>
                <p>{moment(bloodPressure.date).format("LT ll")}</p>
              </div>
            </div>

            <div className={styles.wrapper}>
              <div className={`${btn.spacearound}`}>
                <h3>⌨️ Blood Type</h3>
                <Link to="/edit-blood-type" style={{ textDecoration: "none" }}>
                  <button
                    className={`${btn.rightjournalentrybtn} ${btn.white_btn}`}
                    style={{ marginLeft: "70px", marginBottom: "-5px" }}
                    type="button"
                  >
                    Edit
                  </button>
                </Link>
              </div>
              <div key={bloodType._id} className={styles.healthinfocomponent}>
                <p>{bloodType.bloodType}</p>
              </div>
            </div>

            <div className={styles.wrapper}>
              <div className={`${btn.spacearound}`}>
                <h3>💗 Pulse</h3>
                <Link to="/add-pulse" style={{ textDecoration: "none" }}>
                  <button
                    className={`${btn.rightjournalentrybtn} ${btn.white_btn}`}
                    style={{ marginLeft: "120px", marginBottom: "5px" }}
                    type="button"
                  >
                    Add
                  </button>
                </Link>
              </div>
              <div key={pulse._id} className={styles.healthinfocomponent}>
                <p>{pulse.pulse} bpm</p>
                <p>{moment(pulse.date).format("LT ll")}</p>
              </div>
            </div>

            <div className={styles.wrapper}>
              <div className={`${btn.spacearound}`}>
                <h3>🍭 Sugar Level</h3>
                <Link to="/add-blood-sugar" style={{ textDecoration: "none" }}>
                  <button
                    className={`${btn.rightjournalentrybtn} ${btn.white_btn}`}
                    style={{ marginLeft: "65px", marginBottom: "5px" }}
                    type="button"
                  >
                    Add
                  </button>
                </Link>
              </div>
              <div key={sugarLevel._id} className={styles.healthinfocomponent}>
                <p>{sugarLevel.sugarLevel} mg/dL</p>
                <p>{moment(sugarLevel.date).format("LT ll")}</p>
              </div>
            </div>

            <div className={styles.wrapper}>
              <div className={`${btn.spacearound}`}>
                <h3>🧍 Height</h3>
                <Link to="/edit-height" style={{ textDecoration: "none" }}>
                  <button
                    className={`${btn.rightjournalentrybtn} ${btn.white_btn}`}
                    style={{ marginLeft: "110px", marginBottom: "5px" }}
                    type="button"
                  >
                    Edit
                  </button>
                </Link>
              </div>
              <div key={height._id} className={styles.healthinfocomponent}>
                <p>
                  {height.height} {height.unit}
                </p>
                <p>{moment(height.date).format("LT ll")}</p>
              </div>
            </div>

            <div className={styles.wrapper}>
              <div className={`${btn.spacearound}`}>
                <h3>🏋️‍♂️ Weight</h3>
                <Link to="/add-weight" style={{ textDecoration: "none" }}>
                  <button
                    className={`${btn.rightjournalentrybtn} ${btn.white_btn}`}
                    style={{ marginLeft: "105px", marginBottom: "5px" }}
                    type="button"
                  >
                    Add
                  </button>
                </Link>
              </div>
              <div key={weight._id} className={styles.healthinfocomponent}>
                <p>
                  {weight.weight} {weight.unit}
                </p>
                <p>{moment(weight.date).format("LT ll")}</p>
              </div>
            </div>

            <div className={styles.wrapper}>
              <div className={`${btn.spacearound}`}>
                <h3>🌡️ Temperature</h3>
                <Link to="/add-temperature" style={{ textDecoration: "none" }}>
                  <button
                    className={`${btn.rightjournalentrybtn} ${btn.white_btn}`}
                    style={{ marginLeft: "57px", marginBottom: "5px" }}
                    type="button"
                  >
                    Add
                  </button>
                </Link>
              </div>
              <div key={temperature._id} className={styles.healthinfocomponent}>
                <p>
                  {temperature.temperature} {temperature.unit}
                </p>
                <p>{moment(temperature.date).format("LT ll")}</p>
              </div>
            </div>

            <div className={styles.wrapper}>
              <div className={`${btn.spacearound}`}>
                <h3>🧬 Family History</h3>
                <Link
                  to="/add-family-history"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    className={`${btn.rightjournalentrybtn} ${btn.white_btn}`}
                    style={{ marginLeft: "47px", marginBottom: "5px" }}
                    type="button"
                  >
                    Add
                  </button>
                </Link>
              </div>
              <div
                key={familyHistory._id}
                className={styles.familyHistoryComponent}
              >
                <p className={styles.familyHistoryCondition}>
                  {familyHistory.condition}
                </p>
                <p>{familyHistory.familyMember}</p>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Screen>
  );
};

export default HealthInfoScreen;
