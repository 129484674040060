import { Modal, TextField } from "@mui/material";
import UnitsSelector from "Components/Selectors/UnitsSelector";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddHabitScreen.module.css";
import { useEffect, useState } from "react";
import unitsData from "Utilities/habit-units";

const useUnitSelector = () => {
  const [openUnitModal, setOpenUnitModal] = useState(false);
  const [newUnit, setNewUnit] = useState("");
  const [unitsToAdd, setUnitsToAdd] = useState([]);
  const [units, setUnits] = useState([
    ...unitsData,
    { label: "+", selected: false },
  ]);
  const [currentUnit, setCurrentUnit] = useState(units[0].label);

  // updates "units" state when creating a custom unit
  const handleAddUnitSubmit = () => {
    // Create unit object and add it to storing array
    const newUnitToAdd = { label: newUnit, selected: true };
    setNewUnit("");
    setUnitsToAdd([...unitsToAdd, newUnitToAdd]);

    setCurrentUnit(newUnitToAdd.label);
    // make all other untis not selected
    units.forEach((unit) => (unit.selected = false));
    const updatedUnits = [
      ...units.slice(0, -1), // remove "+" so we can add it to the end
      newUnitToAdd,
      { label: "+", selected: false }, // add "+" to the end
    ];
    setUnits(updatedUnits);
  };

  // handles selecting different units
  const handleUnit = (idx) => {
    if (units[idx].label === "+") {
      setOpenUnitModal(true);
      return;
    }
    setCurrentUnit(units[idx].label);
    // remove any selections
    units.forEach((unit) => (unit.selected = false));
    const temp = [];
    unitsToAdd.forEach((unit) =>
      temp.push({ label: unit.label, selected: false })
    );
    // add selection back if its one of the custom units
    if (idx > 9) {
      temp[idx - 10].selected = true;
    }
    setUnitsToAdd(temp);

    units[idx].selected = true;
    const updatedUnits = [...units];
    setUnits(updatedUnits);
  };

  // fills the "units" state for a given habit
  // used for going back and forth between the habits
  // when adding a habit to a pathway
  const fillUnits = (selectedHabit) => {
    const newUnits = [];
    // add all normal units(skip "+")
    for (let unit of unitsData) {
      if (unit.label === "+") {
        continue;
      }
      if (selectedHabit?.unit === unit.label) {
        newUnits.push({ label: unit.label, selected: true });
        setCurrentUnit(unit.label);
      } else {
        newUnits.push({ label: unit.label, selected: false });
      }
    }
    // add all extra units
    selectedHabit?.extraUnits.forEach((unit) => {
      if (unit.selected) {
        setCurrentUnit(unit.label);
      }
      newUnits.push({ label: unit.label, selected: unit.selected });
    });

    // re-add "+" at the end
    newUnits.push({ label: "+", selected: false });

    setUnits(newUnits);
    setUnitsToAdd(selectedHabit?.extraUnits || []);
    setCurrentUnit(selectedHabit?.unit);
  };

  const resetData = () => {
    setUnitsToAdd([]);
    setNewUnit("");
    setUnits([...unitsData, { label: "+", selected: false }]);
    setCurrentUnit(unitsData[0].label);
    // set first count to selected
    handleUnit(0);
  };

  useEffect(() => {
    handleUnit(0);

    return () => {
      units.forEach((unit) => (unit.selected = false));
      units[0].selected = true; // reset first unit to being selected
    };
  }, []);

  const renderUnitModal = () => (
    <Modal className={styles.modal} open={openUnitModal}>
      <div className={styles.modalForm}>
        <h3>Add Unit</h3>
        <TextField
          label="Unit"
          type="text"
          placeholder="Unit"
          name="title"
          value={newUnit}
          onChange={(e) => setNewUnit(e.target.value)}
          variant="standard"
          required
          inputProps={{
            maxLength: 256,
          }}
        />
        <button
          type="submit"
          className={`${btn.faded_orange} ${btn.btn}`}
          onClick={() => {
            setOpenUnitModal(false);
            handleAddUnitSubmit();
          }}
        >
          Add
        </button>
        <button
          className={`${btn.faded_black} ${btn.btn}`}
          onClick={() => setOpenUnitModal(false)}
        >
          Close
        </button>
      </div>
    </Modal>
  );

  const renderUnitSelector = () => (
    <UnitsSelector units={units} handleUnit={handleUnit} />
  );

  return {
    unitsToAdd,
    currentUnit,
    fillUnits,
    resetData,
    renderUnitModal,
    renderUnitSelector,
  };
};

export { useUnitSelector };

