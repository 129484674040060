import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send (backend) request to fetch treatment
const getTreatment = createAsyncThunk("get treatment", (id, thunkAPI) =>
  actionHandler(
    // retrieve one entry from provided ID
    axiosInstance.get(`/treatments/${id}`),
    thunkAPI
  )
);

// send (backend) request to fetch treatments for logged-in user
// date and time of the treatment would be fetched from the API request
const getTreatments = createAsyncThunk("treatments/", (_, thunkAPI) =>
  actionHandler(
    // get all treatments
    axiosInstance.get("/treatments"),
    thunkAPI
  )
);

// send (backend) request to delete specific treatment
const deleteTreatment = createAsyncThunk("delete/", (_id, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/treatments/${_id}`),
    thunkAPI
  )
);

// send (backend) request to add new treatment
const addTreatment = createAsyncThunk("add treatment/", (
  {
    treatmentName,
    additionalNotes,
    prescriber,
    healthCareProvider
  }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/treatments",
      {
        treatmentName,
        additionalNotes,
        prescriber,
        healthCareProvider,
      }
    ),
    thunkAPI
  )
);

// send (backend) request to update specific treatment entry
const updateTreatment = createAsyncThunk("update treatment/", (
  {
    id,
    treatmentName,
    additionalNotes,
    date,
    prescriber,
    healthCareProvider,
  }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/treatments/${id}`,
      {
        treatmentName,
        additionalNotes,
        date,
        prescriber,
        healthCareProvider,
      }
    ),
    thunkAPI
  )
);

export {
  addTreatment, deleteTreatment, getTreatment,
  getTreatments, updateTreatment
};

