import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AlertMessage from "Components/Helpers/AlertMessage";
import Screen from "Components/Helpers/Screen";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddBloodSugarScreen.module.css";
import moment from "moment";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addSugarLevel, selectHealthInfo } from "Reducers/HealthInfoReducer";

const AddBloodSugarScreen = () => {
  const { loading, error } = useSelector(selectHealthInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [sugarLevel, setSugarLevel] = useState("");

  const addSugarLevelSubmit = (e) => {
    e.preventDefault();

    dispatch(
      addSugarLevel({
        sugarLevel,
        date,
      })
    );

    // If successful, return to previous screen
    if (!loading && !error) {
      navigate("/health-info");
    }
  };

  return (
    <Screen>
      <WrapperForm onSubmit={addSugarLevelSubmit}>
        <TextField
          className={styles.datetextarea}
          type="datetime-local"
          name="day"
          required={true}
          value={date}
          onChange={(e) => setDate(e.target.value)}
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        {errorMessage && (
          <AlertMessage
            sx={{ width: "80%", marginBottom: "15px" }}
            severity="error"
            onClose={() => setErrorMessage("")}
          >
            {errorMessage}
          </AlertMessage>
        )}
        <h1 style={{ margin: "10px" }}>Blood Sugar</h1>
        <TextField
          className={styles.textarea}
          style={{ width: "275px" }}
          label="Blood Sugar"
          type="text"
          placeholder="Enter blood sugar"
          InputProps={{
            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
          }}
          name="name"
          required={true}
          variant="outlined"
          inputProps={{
            maxLength: 256,
          }}
          autoFocus={!isMobile}
          onChange={(e) => setSugarLevel(e.target.value)}
          value={sugarLevel}
          size="small"
        />
        <div className={`${btn.center}`}>
          <button
            className={`${btn.journalentrybtn} ${btn.white_btn}`}
            onClick={() => navigate("/health-info")}
            type="button"
          >
            Cancel
          </button>

          <button
            className={`${btn.journalentrybtn} ${btn.orange_btn}`}
            type="submit"
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </WrapperForm>
    </Screen>
  );
};

export default AddBloodSugarScreen;
