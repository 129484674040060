import { Card, CardContent, Typography } from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

const FearLadder = memo(function FearLadder({ ladder }) {
  const navigate = useNavigate();

  const formattedDate = new Date(ladder?.createdAt).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 5,
        boxShadow: 3,
      }}
      onClick={() => navigate(`/toolkit/fear-ladder/${ladder?._id}`)}
    >
      <CardContent sx={{ px: 2, py: 1, "&:last-child": { pb: 1 } }}>
        <Typography variant="h6" >
          {ladder?.objective}
        </Typography>
        <Typography variant="body2" sx={{ mb: 0 }} >
          {ladder?.isCompleted ? "Completed on" : "Started on"}: {formattedDate}
        </Typography>
      </CardContent>
    </Card>
  );
});

export default FearLadder;
