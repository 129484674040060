import { URLS } from "Constants/Constants";
import NotFoundScreen from "Screens/404Screen";
import ActivitiesScreen from "Screens/Activities/ActivitiesScreen";
import ActivityCompletionScreen from "Screens/Activities/ActivityCompletionScreen";
import ActivityHistoryScreen from "Screens/Activities/ActivityHistoryScreen";
import AddActivitiesEntryScreen from "Screens/Activities/AddActivitiesEntryScreen";
import DetailedEntryActivitiesScreen from "Screens/Activities/DetailedEntryActivitiesScreen";
import AddEntriesScreen from "Screens/AddEntriesScreen";
import AddAdditionalNoteScreen from "Screens/Additional-Notes/AddAdditionalNoteScreen";
import AdditionalNotesHistoryScreen from "Screens/Additional-Notes/AdditionalNotesHistoryScreen";
import UpdateAdditionalNoteScreen from "Screens/Additional-Notes/UpdateAdditionalNoteScreen";
import UpdateAppointmentNoteScreen from "Screens/Appointment-Note/UpdateAppointmentNoteScreen";
import ForgetPasswordCodeEntryScreen from "Screens/Auth/ForgetPasswordCodeEntryScreen";
import ForgetPasswordScreen from "Screens/Auth/ForgetPasswordScreen";
import NewPasswordCreationScreen from "Screens/Auth/NewPasswordCreationScreen";
import BadgesScreen from "Screens/BadgesScreen";
import BookingScreen from "Screens/BookingScreen";
import PostCheckinScreen from "Screens/Checkin/PostCheckinScreen";
import AddCommunityFriendScreen from "Screens/Community/AddCommunityFriendScreen";
import AddCommunityGoalScreen from "Screens/Community/AddCommunityGoalScreen";
import AddCommunityScreen from "Screens/Community/AddCommunityScreen";
import AddFriendScreen from "Screens/Community/AddFriendScreen";
import AddPostScreen from "Screens/Community/AddPostScreen";
import AddTeamPostScreen from "Screens/Community/AddTeamPostScreen";
import CommunityChatScreen from "Screens/Community/CommunityChatScreen";
import CommunityScreen from "Screens/Community/CommunityScreen";
import TeamMemberScreen from "Screens/Community/TeamMemberScreen";
import TeamScreen from "Screens/Community/TeamScreen";
import AddConditionScreen from "Screens/Condition/AddConditionScreen";
import ConditionHistoryScreen from "Screens/Condition/ConditionHistoryScreen";
import UpdateConditionScreen from "Screens/Condition/UpdateCondition";
import EntryHistoriesScreen from "Screens/EntryHistoriesScreen";
import FeedbackScreen from "Screens/FeedbackScreen";
import AddHabitsScreen from "Screens/Habit/AddHabitScreen";
import AdjustHabitInstanceScreen from "Screens/Habit/AdjustHabitInstanceScreen";
import AdjustHabitScreen from "Screens/Habit/AdjustHabitScreen";
import HabitsScreen from "Screens/Habit/HabitsScreen";
import UpdateHabitScreen from "Screens/Habit/UpdateHabitScreen";
import AddHealthCareProviderScreen from "Screens/Health-Care-Provider/AddHealthCareProviderScreen";
import HealthCareProviderHistoryScreen from "Screens/Health-Care-Provider/HealthCareProviderHistoryScreen";
import UpdateHealthCareProviderScreen from "Screens/Health-Care-Provider/UpdateHealthCareProviderScreen";
import AddBloodPressureScreen from "Screens/Health-Info/AddBloodPressureScreen";
import AddBloodSugarScreen from "Screens/Health-Info/AddBloodSugarScreen";
import AddFamilyHistoryScreen from "Screens/Health-Info/AddFamilyHistoryScreen";
// import AddHeightScreen from "Screens/Health-Info/AddHeightScreen";
import AddPulseScreen from "Screens/Health-Info/AddPulseScreen";
import AddTemperatureScreen from "Screens/Health-Info/AddTemperatureScreen";
import AddWeightScreen from "Screens/Health-Info/AddWeightScreen";
import EditBloodTypeScreen from "Screens/Health-Info/EditBloodTypeScreen";
import EditHealthCardScreen from "Screens/Health-Info/EditHealthCardScreen";
import HealthInfoScreen from "Screens/Health-Info/HealthInfoScreen";
import HomeScreen from "Screens/HomeScreen";
import UpdateJournalEntryScreen from "Screens/Journal/UpdateJournalEntryScreen";
import AddMedicationScreen from "Screens/Medication/AddMedicationScreen";
import MedicationHistoryScreen from "Screens/Medication/MedicationHistoryScreen";
import UpdateMedicationScreen from "Screens/Medication/UpdateMedicationScreen";
import MonthlyInsightScreen from "Screens/MonthlyInsightScreen";
import MyHealthRecordScreen from "Screens/MyHealthRecordScreen";
import HealthRecordSetupAdditionalNoteScreen from "Screens/Onboarding/HealthRecordSetupAdditionalNoteScreen";
import AddPathwayCheckinScreen from "Screens/Pathway/AddPathwayCheckinScreen";
import AddPathwayScreen from "Screens/Pathway/AddPathwayScreen";
import FinalAddPathwayScreen from "Screens/Pathway/FinalAddPathwayScreen";
import IndividualPathwayScreen from "Screens/Pathway/IndividualPathwayScreen";
import PathwayScreen from "Screens/Pathway/PathwayScreen";
import UpdatePathwayNoteScreen from "Screens/Pathway/UpdatePathwayNoteScreen";
import UpdatePathwayScreen from "Screens/Pathway/UpdatePathwayScreen";
import AccountSettingScreen from "Screens/Settings/AccountSettingScreen";
import BottomNavSettingScreen from "Screens/Settings/BottomNavSettingScreen";
import NotificationScreen from "Screens/Settings/NotificationScreen";
import SideNavSettingScreen from "Screens/Settings/SideNavSettingScreen";
import AddToDoScreen from "Screens/ToDo/AddToDoScreen";
import CompletedToDosScreen from "Screens/ToDo/CompletedToDosScreen";
import ToDosScreen from "Screens/ToDo/ToDosScreen";
import UpdateToDosScreen from "Screens/ToDo/UpdateToDosScreen";
import ToolKitScreen from "Screens/ToolKitScreen";
import AmbientSoundList from "Screens/Toolkit-Functions/AmbientSoundList";
import AmbientSoundsScreen from "Screens/Toolkit-Functions/AmbientSoundScreen";
import BehavioralCompleteRecord from "Screens/Toolkit-Functions/Behavioral-Experiments/BehavioralCompleteRecord";
import BehavioralCompleteScreen from "Screens/Toolkit-Functions/Behavioral-Experiments/BehavioralCompleteScreen";
import BehavioralCompleteViewScreen from "Screens/Toolkit-Functions/Behavioral-Experiments/BehavioralCompleteViewScreen";
import BehavioralEditEntryScreen from "Screens/Toolkit-Functions/Behavioral-Experiments/BehavioralEditEntryScreen";
import BehavioralEntryScreen from "Screens/Toolkit-Functions/Behavioral-Experiments/BehavioralEntryScreen";
import BehavioralHomeScreen from "Screens/Toolkit-Functions/Behavioral-Experiments/BehavioralHomeScreen";
import BehavioralIncompleteScreen from "Screens/Toolkit-Functions/Behavioral-Experiments/BehavioralIncompleteScreen";
import BehavioralSaveEditScreen from "Screens/Toolkit-Functions/Behavioral-Experiments/BehavioralSaveEditScreen";
import BehavioralSaveScreen from "Screens/Toolkit-Functions/Behavioral-Experiments/BehavioralSaveScreen";
import BehavioralViewScreen from "Screens/Toolkit-Functions/Behavioral-Experiments/BehavioralViewScreen";
import BodyScanScreen from "Screens/Toolkit-Functions/BodyScanScreen";
import BreathworkScreen from "Screens/Toolkit-Functions/BreathworkScreen";
import ButterflyHugScreen from "Screens/Toolkit-Functions/ButterflyHugScreen";
import CircleBreathScreen from "Screens/Toolkit-Functions/CircleBreathScreen";
import CognitiveDistortionsReview from "Screens/Toolkit-Functions/Cognitive-Distortions/CoginitiveDistortionsReview";
import CognitiveDistortionsDetailScreen from "Screens/Toolkit-Functions/Cognitive-Distortions/CognitiveDistortionDetailScreen";
import CognitiveDistortionEntryDetailScreen from "Screens/Toolkit-Functions/Cognitive-Distortions/CognitiveDistortionEntryDetailScreen";
import CognitiveDistortionsHomeScreen from "Screens/Toolkit-Functions/Cognitive-Distortions/CognitiveDistortionsHomeScreen";
import CognitiveDistortionsScreen from "Screens/Toolkit-Functions/Cognitive-Distortions/CognitiveDistortionsScreen";
import CognitiveDistortionsThoughtScreen from "Screens/Toolkit-Functions/Cognitive-Distortions/CognitiveDistortionsThoughtScreen";
import CognitivieDistortionsQuestionScreen from "Screens/Toolkit-Functions/Cognitive-Distortions/CognitivieDistortionsQuestionScreen";
import AddFearLadderScreen from "Screens/Toolkit-Functions/Fear-Ladder/AddFearLadderScreen";
import ExposureStepDashboardScreen from "Screens/Toolkit-Functions/Fear-Ladder/ExposureStepDashboardScreen";
import FearLadderDashboardScreen from "Screens/Toolkit-Functions/Fear-Ladder/FearLadderDashboardScreen";
import FearLaddersHomeScreen from "Screens/Toolkit-Functions/Fear-Ladder/FearLaddersHomeScreen";
import NostrilBreathingScreen from "Screens/Toolkit-Functions/NostrilBreathingScreen";
import SimsDimsEntryScreen from "Screens/Toolkit-Functions/SIMs-DIMs/SimsDimsEntryScreen";
import SimsDimsHistoryScreen from "Screens/Toolkit-Functions/SIMs-DIMs/SimsDimsHistory";
import SimsDimsHomeScreen from "Screens/Toolkit-Functions/SIMs-DIMs/SimsDimsHomeScreen";
import SimsDimsLesson from "Screens/Toolkit-Functions/SIMs-DIMs/SimsDimsLesson";
import SimsDimsReviewScreen from "Screens/Toolkit-Functions/SIMs-DIMs/SimsDimsReviewScreen";
import SimsDimsWhatDoingRightNow from "Screens/Toolkit-Functions/SIMs-DIMs/SimsDimsWhatDoingRightNow";
import SimsDimsWhatHappeningInMyBody from "Screens/Toolkit-Functions/SIMs-DIMs/SimsDimsWhatHappeningInMyBody";
import SimsDimsWhatSayingRightNow from "Screens/Toolkit-Functions/SIMs-DIMs/SimsDimsWhatSayingRightNow";
import SimsDimsWhatThinkingRightNow from "Screens/Toolkit-Functions/SIMs-DIMs/SimsDimsWhatThinkingRightNow";
import SimsDimsWhereRightNow from "Screens/Toolkit-Functions/SIMs-DIMs/SimsDimsWhereRightNow";
import SimsDimsWhoInteractingRightNow from "Screens/Toolkit-Functions/SIMs-DIMs/SimsDimsWhoInteractingRightNow";
import SquareBreathScreen from "Screens/Toolkit-Functions/SquareBreathScreen";
import WaveBreathScreen from "Screens/Toolkit-Functions/WaveBreathScreen";
import AddTreatmentScreen from "Screens/Treatment/AddTreatmentScreen";
import TreatmentHistoryScreen from "Screens/Treatment/TreatmentHistoryScreen";
import UpdateTreatmentScreen from "Screens/Treatment/UpdateTreatmentScreen";
import QuickCheckTutorialScreen from "Screens/Tutorials/QuickCheckTutorialScreen";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import HealthRecordSetupConditionScreen from "./Screens/Onboarding/HealthRecordSetupConditionScreen";
import HealthRecordSetupMedicationScreen from "./Screens/Onboarding/HealthRecordSetupMedicationScreen";
import HealthRecordSetupProviderScreen from "./Screens/Onboarding/HealthRecordSetupProviderScreen";
import HealthRecordSetupTreatmentScreen from "./Screens/Onboarding/HealthRecordSetupTreatmentScreen";
import HealthRecordTutorialScreen from "./Screens/Onboarding/HealthRecordTutorialScreen";
import JournalTutorialScreen from "./Screens/Onboarding/JournalTutorialScreen";
import OnboardingScreen from "./Screens/Onboarding/OnboardingScreen";
import PathwayTutorialScreen from "./Screens/Onboarding/PathwayTutorialScreen";


// screens to be lazily loaded
const SignupScreen = lazy(() => import("Screens/Auth/SignupScreen"));
const LoginScreen = lazy(() => import("Screens/Auth/LoginScreen"));
const AdminFeedbackScreen = lazy(() =>
  import("./Screens/Admin/AdminFeedbackScreen")
);
const AdminScreen = lazy(() => import("Screens/Admin/AdminScreen"));
const AdminUsersScreen = lazy(() => import("Screens/Admin/AdminUsersScreen"));

function AppRoutes({ socket }) {
  return (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/signup" element={<SignupScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path={URLS.FORGET_PASSWORD} element={<ForgetPasswordScreen />} />
      <Route path={URLS.FORGET_PASSWORD_CODE_ENTRY} element={<ForgetPasswordCodeEntryScreen />} />
      <Route path={URLS.RESET_PASSWORD_SCREEN} element={<NewPasswordCreationScreen />} />
      <Route path="/todos" element={<ToDosScreen />} />
      <Route
        path="/todos/:id"
        element={<UpdateToDosScreen />}
      />
      <Route path="/add-todo" element={<AddToDoScreen />} />
      <Route
        path="/completed-todos"
        element={<CompletedToDosScreen />}
      />
      <Route path="/journal" element={<EntryHistoriesScreen />} />
      <Route
        path="/update-journal/:id"
        element={<UpdateJournalEntryScreen />}
      />
      <Route
        path="/update-appointment-note/:id"
        element={<UpdateAppointmentNoteScreen />}
      />
      <Route
        path="/update-pathway-note/:id"
        element={<UpdatePathwayNoteScreen />}
      />

      <Route path="new-journal-entry" element={<AddEntriesScreen />} />
      <Route path="/habits" element={<HabitsScreen />} />
      <Route path="/activities" element={<ActivitiesScreen />} />
      <Route
        path="/activity-history/:id"
        element={<ActivityHistoryScreen />}
      />
      <Route
        path="/new-activity-entry"
        element={<AddActivitiesEntryScreen />}
      />
      <Route
        path="/detailed-activities-entry"
        element={<DetailedEntryActivitiesScreen />}
      />
      <Route
        path="/post-activities-entry/:id"
        element={<ActivityCompletionScreen />}
      />
      <Route path="/add-habit" element={<AddHabitsScreen />} />
      <Route path="/habits/:id" element={<AdjustHabitScreen />} />
      <Route path="/update-habit/:id" element={<UpdateHabitScreen />} />
      <Route
        path="/habits/instance/:id"
        element={<AdjustHabitInstanceScreen />}
      />
      <Route path="/toolkit" element={<ToolKitScreen />} />
      <Route path="/toolkit/body-scan" element={<BodyScanScreen />} />
      <Route
        path="/toolkit/butterfly-hug"
        element={<ButterflyHugScreen />}
      />
      <Route path="/post-checkin/:id" element={<PostCheckinScreen />} />
      <Route
        path="/toolkit/breathwork"
        element={<BreathworkScreen />}
      />
      <Route
        path="/toolkit/breathwork/circle-breath"
        element={<CircleBreathScreen />}
      />
      <Route
        path="/toolkit/breathwork/square-breath"
        element={<SquareBreathScreen />}
      />
      <Route
        path="/toolkit/ambient-sound"
        element={<AmbientSoundList />}
      />
      <Route
        path="/toolkit/ambient-sounds-screen"
        element={<AmbientSoundsScreen />}
      />


      <Route
        path="/toolkit/breathwork/wave-breath"
        element={<WaveBreathScreen />}
      />
      <Route
        path="/toolkit/breathwork/nostril-breath"
        element={<NostrilBreathingScreen />}
      />

      <Route
        path="/toolkit/sims-dims"
        element={<SimsDimsHomeScreen />}
      />

      <Route
        path="/toolkit/sims-dims-history"
        element={<SimsDimsHistoryScreen />}
      />

      <Route
        path="/toolkit/sims-dims-lesson"
        element={<SimsDimsLesson />}
      />

      <Route
        path="/toolkit/sims-dims-entry"
        element={<SimsDimsEntryScreen />}
      />

      <Route
        path="/toolkit/sims-dims-doing"
        element={<SimsDimsWhatDoingRightNow />}
      />

      <Route
        path="/toolkit/sims-dims-happening"
        element={<SimsDimsWhatHappeningInMyBody />}
      />

      <Route
        path="/toolkit/sims-dims-saying"
        element={<SimsDimsWhatSayingRightNow />}
      />

      <Route
        path="/toolkit/sims-dims-thinking"
        element={<SimsDimsWhatThinkingRightNow />}
      />

      <Route
        path="/toolkit/sims-dims-where"
        element={<SimsDimsWhereRightNow />}
      />

      <Route
        path="/toolkit/sims-dims-interacting"
        element={<SimsDimsWhoInteractingRightNow />}
      />

      <Route
        path="/toolkit/sims-dims-review"
        element={<SimsDimsReviewScreen />}
      />

      <Route
        path="/toolkit/fear-ladder"
        element={<FearLaddersHomeScreen />}
      />
      <Route
        path="/toolkit/fear-ladder/add"
        element={<AddFearLadderScreen />}
      />
      {/* <Route
        path="/toolkit/fear-ladder/add/steps"
        element={<AddExposureStepsScreen/>}
      /> */}
      <Route
        path="/toolkit/fear-ladder/:id"
        element={<FearLadderDashboardScreen />}
      />
      <Route
        path="/toolkit/fear-ladder/:ladderId/step/:stepId"
        element={<ExposureStepDashboardScreen />}
      />

      <Route
        path="/my-health-record"
        element={<MyHealthRecordScreen />}
      />
      <Route path="/health-info" element={<HealthInfoScreen />} />
      <Route
        path="/my-health-record/condition-history"
        element={<ConditionHistoryScreen />}
      />
      <Route
        path="/my-health-record/conditions/:id"
        element={<UpdateConditionScreen />}
      />
      <Route
        path="/my-health-record/medications"
        element={<MedicationHistoryScreen />}
      />
      <Route
        path="/my-health-record/add-medication"
        element={<AddMedicationScreen />}
      />
      <Route
        path="/my-health-record/update-medication/:id"
        element={<UpdateMedicationScreen />}
      />
      <Route
        path="/my-health-record/health-care-providers"
        element={<HealthCareProviderHistoryScreen />}
      />
      <Route
        path="/my-health-record/add-health-care-provider"
        element={<AddHealthCareProviderScreen />}
      />
      <Route
        path="/my-health-record/update-health-care-provider/:id"
        element={<UpdateHealthCareProviderScreen />}
      />
      <Route
        path="/my-health-record/add-condition"
        element={<AddConditionScreen />}
      />
      <Route
        path="/add-blood-pressure"
        element={<AddBloodPressureScreen />}
      />
      <Route
        path="/my-health-record/treatments"
        element={<TreatmentHistoryScreen />}
      />
      <Route
        path="/my-health-record/add-treatment"
        element={<AddTreatmentScreen />}
      />
      <Route
        path="/my-health-record/update-treatment/:id"
        element={<UpdateTreatmentScreen />}
      />
      <Route
        path="/my-health-record/additional-notes"
        element={<AdditionalNotesHistoryScreen />}
      />
      <Route
        path="/my-health-record/add-additional-note"
        element={<AddAdditionalNoteScreen />}
      />
      <Route
        path="/my-health-record/update-additional-note/:id"
        element={<UpdateAdditionalNoteScreen />}
      />

      <Route
        path="/edit-blood-type"
        element={<EditBloodTypeScreen />}
      />
      <Route path="/add-pulse" element={<AddPulseScreen />} />
      <Route
        path="/add-blood-sugar"
        element={<AddBloodSugarScreen />}
      />
      {/* <Route path="/add-height" element={<AddHeightScreen />} /> */}
      <Route path="/add-weight" element={<AddWeightScreen />} />
      <Route
        path="/add-temperature"
        element={<AddTemperatureScreen />}
      />
      <Route
        path="/add-family-history"
        element={<AddFamilyHistoryScreen />}
      />
      <Route
        path="/edit-health-card"
        element={<EditHealthCardScreen />}
      />
      <Route path="/feedback" element={<FeedbackScreen />} />
      <Route path="/setting/notifications" element={<NotificationScreen />} />
      <Route path={URLS.SETTING_BOTTOM_NAV} element={<BottomNavSettingScreen />} />
      <Route path={URLS.SETTING_SIDE_NAV} element={<SideNavSettingScreen />} />
      <Route path="/admin" element={<AdminScreen />} />
      <Route path="/admin-feedback" element={<AdminFeedbackScreen />} />
      <Route path="/admin-users" element={<AdminUsersScreen />} />

      <Route path="/badges" element={<BadgesScreen />} />
      <Route path="/pathway" element={<PathwayScreen />} />
      <Route
        path="/new-pathway"
        element={<AddPathwayScreen />}
      />
      <Route
        path="/new-pathway/add"
        element={<FinalAddPathwayScreen />}
      />
      <Route
        path="/pathway/:id"
        element={<IndividualPathwayScreen />}
      />
      <Route
        path="/update-pathway/:id"
        element={<UpdatePathwayScreen />}
      />
      <Route
        path="/add-pathway-checkin"
        element={<AddPathwayCheckinScreen />}
      />
      <Route path="/insights" element={<MonthlyInsightScreen />} />

      <Route path="/welcome" element={<OnboardingScreen />} />
      <Route
        path="/journal-tutorial"
        element={<JournalTutorialScreen />}
      />
      <Route
        path="/health-record-tutorial"
        element={<HealthRecordTutorialScreen />}
      />
      <Route
        path="/health-record-provider"
        element={<HealthRecordSetupProviderScreen />}
      />
      <Route
        path="/health-record-condition"
        element={<HealthRecordSetupConditionScreen />}
      />
      <Route
        path="/health-record-treatment"
        element={<HealthRecordSetupTreatmentScreen />}
      />
      <Route
        path="/health-record-medication"
        element={<HealthRecordSetupMedicationScreen />}
      />
      <Route
        path="/health-record-additional-note"
        element={<HealthRecordSetupAdditionalNoteScreen />}
      />
      <Route
        path="/pathway-tutorial"
        element={<PathwayTutorialScreen />}
      />
      <Route
        path="/toolkit/cognitive-distortions"
        element={<CognitiveDistortionsHomeScreen />}
      />
      <Route
        path="/toolkit/cognitive-thoughts"
        element={<CognitiveDistortionsThoughtScreen />}
      />
      <Route
        path="/toolkit/distortions"
        element={<CognitiveDistortionsScreen />}
      />
      <Route
        path="/toolkit/distortion-questions"
        element={<CognitivieDistortionsQuestionScreen />}
      />
      <Route
        path="/toolkit/cognitive-questions-review"
        element={<CognitiveDistortionsReview />}
      />
      <Route
        path="/toolkit/distortion-info"
        element={<CognitiveDistortionsDetailScreen />}
      />
      <Route
        path="/toolkit/distortion-entry-info"
        element={<CognitiveDistortionEntryDetailScreen />}
      />

      <Route
        path="/toolkit/behavioral-experiments"
        element={<BehavioralHomeScreen />}
      />
      <Route
        path="/toolkit/behavioral-entries"
        element={<BehavioralEntryScreen />}
      />
      <Route
        path="/toolkit/behavioral-save"
        element={<BehavioralSaveScreen />}
      />
      <Route
        path="/toolkit/view-experiment-entry"
        element={<BehavioralViewScreen />}
      />
      <Route
        path="/toolkit/complete-experiment-entry"
        element={<BehavioralCompleteScreen />}
      />
      <Route
        path="/toolkit/incomplete-experiment-entry"
        element={<BehavioralIncompleteScreen />}
      />
      <Route
        path="/toolkit/complete-experiment-view"
        element={<BehavioralCompleteViewScreen />}
      />
      <Route
        path="/toolkit/complete-experiment-record"
        element={<BehavioralCompleteRecord />}
      />
      <Route
        path="/toolkit/edit-experiment-entry"
        element={<BehavioralEditEntryScreen />}
      />
      <Route
        path="/toolkit/behavioral-save-edit"
        element={<BehavioralSaveEditScreen />}
      />




      <Route
        path="/tutorial/quick-check"
        element={<QuickCheckTutorialScreen />}
      />

      <Route
        path={URLS.COMMUNITY}
        element={<CommunityScreen />}
      />
      <Route
        path={URLS.COMMUNITY_ADD_POST}
        element={<AddPostScreen />}
      />
      <Route
        path={URLS.COMMUNITY_ADD_COMMUNITY}
        element={<AddCommunityScreen />}
      />
      <Route
        path={URLS.COMMUNITY_ADD_COMMUNITY_FRIENDS}
        element={<AddCommunityFriendScreen />}
      />
      <Route
        path={URLS.COMMUNITY_ADD_FRIEND}
        element={<AddFriendScreen />}
      />
      <Route
        path={URLS.COMMUNITY_ADD_COMMUNITY_GOAL}
        element={<AddCommunityGoalScreen />}
      />
      <Route
        path={`${URLS.TEAM}/:id`}
        element={<TeamScreen />}
      />
      <Route
        path={`${URLS.TEAM}/:id/add-post`}
        element={<AddTeamPostScreen />}
      />
      <Route
        path={`${URLS.TEAM}/members`}
        element={<TeamMemberScreen />}
      />
      <Route
        path={`${URLS.COMMUNITY_CHAT}/:id`}
        element={<CommunityChatScreen socket={socket} />}
      />
      <Route
        path={URLS.SETTING_ACCOUNT}
        element={<AccountSettingScreen />}
      />
      <Route
        path="/booking"
        element={<BookingScreen />}
      />
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
}

export default AppRoutes;
