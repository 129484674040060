import { Alert, Grid, TextField } from "@mui/material";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AuthenticationScreen.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import Wrapper from "Components/Helpers/Wrapper";
import { resetPassword } from "Reducers/Actions/ResetPasswordActions";
import { resetPasswordResetData, selectResetPassword } from "Reducers/ResetPasswordReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NewPasswordCreationScreen = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordStrong, setIsPasswordStrong] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, resetCode, resetEmail, isPasswordReset, error } = useSelector(selectResetPassword);

  const handleResetPasswordSubmission = () => {
    if (password != confirmPassword) {
      setErrorMessage("Passwords do not match");
    } else if (!_isPasswordStrong(password)) {
      setIsPasswordStrong(false);
    } else {
      dispatch(resetPassword({ resetCode, resetEmail, password }));
    }
  };

  useEffect(() => {
    if (isPasswordReset) {
      navigate("/login");
      dispatch(resetPasswordResetData());
    }
  }, [isPasswordReset]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  const _isPasswordStrong = (password) => {
    let isStrong = true;

    // Check password length
    if (password.length < 8)
      isStrong = false;

    // Check for mixed case
    if (!(password.match(/[a-z]/) && password.match(/[A-Z]/)))
      isStrong = false;

    // Check for numbers
    if (!password.match(/\d/))
      isStrong = false;

    // Check for special characters
    if (!password.match(/[^a-zA-Z\d]/))
      isStrong = false;

    return isStrong;
  };

  return (
    <ScreenNoNav>
      <Wrapper>
        <Grid container justifyContent="center" item xs={12} sm={12} md={12} rowSpacing={6} marginTop={2}>
          <Grid container justifyContent="center" item xs={12} sm={12} md={12}>
            <img
              src="/images/icons/Logo.svg"
              alt="3 fish swimming in a circle"
              width="100"
              height="100"
            />
          </Grid>
          <Grid item xs={10} sm={10} md={12} textAlign="center">
            Please enter the code your received in your email to reset your password.
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <AlertMessage
              message={errorMessage}
              setMessage={() => setErrorMessage("")}
              type="error"
              toggle={errorMessage}
              styles={{ marginBottom: "2em", width: "auto" }}
            />
            {
              !isPasswordStrong ? (
                <Alert
                  icon={false}
                  sx={{
                    width: "auto",
                    marginBottom: "10px",
                  }}
                  severity="error"
                >
                  <ul style={{ paddingLeft: "14px" }}>
                    <li>Should have more than 8 characters</li>
                    <li>Should have atleast one lowercase and uppercase characters</li>
                    <li>Should have atleast one digit</li>
                    <li>Should have atleast one special character</li>
                  </ul>
                </Alert>
              ) : (<></>)
            }
            <TextField
              className={styles.auth_field}
              label="Enter new password"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="standard"
              inputProps={{
                maxLength: 128,
              }}
            />
            <TextField
              className={styles.auth_field}
              label="Confirm password"
              type="password"
              name="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              variant="standard"
              inputProps={{
                maxLength: 128,
              }}
            />
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <button
              className={`${btn.btn} ${btn.orange_btn}`}
              style={{ minWidth: "100%" }}
              type="submit"
              onClick={() => handleResetPasswordSubmission()}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </Grid>
        </Grid>
      </Wrapper>
    </ScreenNoNav>
  );
};

export default NewPasswordCreationScreen;
