import moment from "moment";
import { addJournalEntry } from "Reducers/JournalReducer";

const undoJournalEntry = (dispatch) => {
  const journalEntry = JSON.parse(localStorage.getItem("tempJournalEntry"));

  dispatch(
    addJournalEntry({
      date: moment(journalEntry.date).utc(),
      emotionRating: journalEntry.emotionRating,
      emotions: journalEntry.emotions,
      textReview: journalEntry.textReview,
    })
  );
};

export default undoJournalEntry;
