import { createSlice } from "@reduxjs/toolkit";
import {
  addPathwayNote,
  deletePathwayNote,
  getPathwayNote,
  getPathwayNotes,
  updatePathwayNote,
} from "./Actions/PathwayNoteActions";

const initialState = {
  past: [], // past pathway reviews only
  loading: false,
  successAdd: false,
  successDelete: false,
  error: null,
  selectedPathwayNote: null,
  activeStatus: "active", // remember if user is looking at active or archived notes
};

// functions for "useSelector"
const selectPathwayNotes = (state) => state.pathwayNotes;

const pathwayNoteSlice = createSlice({
  name: "pathway note",
  initialState,
  reducers: {
    resetPathwayNotes: () => initialState,
    // For updating when we only want to reset the error and loading
    resetPathwayNote: ({ past }) => {
      return {
        past,
        loading: false,
        error: null,
        successAdd: false,
      };
    },
    resetPathwayNoteAdd: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    resetPathwayNoteDelete: (state) => {
      return {
        ...state,
        successDelete: false,
      };
    },
    setActiveStatus: (state, { payload }) => {
      state.activeStatus = payload;
    },
  },
  extraReducers: {
    // Fetch pathway note Async Reducers
    [getPathwayNotes.pending]: (state) => {
      state.loading = true;
    },
    [getPathwayNotes.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.past = payload;
    },

    [getPathwayNotes.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Fetch single pathway note Async Reducers
    [getPathwayNote.pending]: (state) => {
      state.loading = true;
    },
    [getPathwayNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedPathwayNote = payload;
    },
    [getPathwayNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add pathway note Async Reducers
    [addPathwayNote.pending]: (state) => {
      state.loading = true;
    },
    [addPathwayNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
      state.past.push(payload);
      state.past = state.past.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    },
    [addPathwayNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // Update pathway note Async Reducers
    [updatePathwayNote.pending]: (state) => {
      state.loading = true;
    },
    [updatePathwayNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedPathwayNote = payload;
      updateNoteFulfilled(state, payload);
    },
    [updatePathwayNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete pathway note Async Reducers
    [deletePathwayNote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.past = state.past.filter((note) => note._id !== payload._id);
      state.successDelete = true;
    },
    [deletePathwayNote.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

// helper functions
// logic for placing the updated note into the proper order
const updateNoteFulfilled = (state, payload) => {
  // find note index and update it
  const idx = state.past.findIndex((note) => note._id === payload._id);
  state.past[idx] = payload;
};

export default pathwayNoteSlice.reducer;
export {
  addPathwayNote, deletePathwayNote, getPathwayNote, getPathwayNotes, selectPathwayNotes, updatePathwayNote
};
export const {
  resetPathwayNotes,
  resetPathwayNote,
  resetPathwayNoteAdd,
  resetPathwayNoteDelete,
  setActiveStatus,
} = pathwayNoteSlice.actions;
