import Diversity1Icon from "@mui/icons-material/Diversity1";
import GroupIcon from "@mui/icons-material/Group";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import {
  AppBar,
  Grid,
  Tab,
  Tabs
} from "@mui/material";
import CommunityFeed from "Components/Community/CommunityFeed";
import CommunityFriends from "Components/Community/CommunityFriends";
import CommunityTeams from "Components/Community/CommunityTeams";
import AddFab from "Components/Helpers/AddFab";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import ImageCarousal from "Components/ImageCarousal";
import { URLS } from "Constants/Constants";
import { getFeedPosts } from "Reducers/Actions/CommunityActions";
import { getUserFriends } from "Reducers/Actions/FriendActions";
import { getFriendRequests } from "Reducers/Actions/FriendRequestActions";
import { getTeams } from "Reducers/Actions/TeamActions";
import { selectCommunity, setPage, setSelectedPost } from "Reducers/CommunityReducer";
import { selectFriendRequest } from "Reducers/FriendRequestReducer";
import { selectTeam } from "Reducers/TeamReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const CommunityScreen = () => {
  const dispatch = useDispatch();

  // Selecting data from the Redux store
  const { page, communityPosts, loading, selectedPost } = useSelector(selectCommunity);

  const { friendRequests, userFriends } = useSelector(selectFriendRequest);
  const { teams } = useSelector(selectTeam);

  // State variables
  const [showHabits, setShowHabits] = useState(page);
  const [addFabUrl, setAddFabUrl] = useState(URLS.COMMUNITY_ADD_POST);

  useEffect(() => {
    dispatch(getFriendRequests());
    dispatch(getUserFriends());
    dispatch(getTeams());
    dispatch(getFeedPosts(1));

    // Setting the URL for AddFab based on active tab.
    if (showHabits === "1") {
      setAddFabUrl(URLS.COMMUNITY_ADD_POST);
    } else if (showHabits === "2") {
      setAddFabUrl(URLS.COMMUNITY_ADD_COMMUNITY);
    } else if (showHabits === "3") {
      setAddFabUrl(URLS.COMMUNITY_ADD_FRIEND);
    }

  }, [dispatch]);

  const handleSetShowHabits = (_, newValue) => {
    setShowHabits(newValue);
    dispatch(setPage(newValue));

    // Setting the URL for AddFab based on active tab.
    if (newValue === "1") {
      setAddFabUrl(URLS.COMMUNITY_ADD_POST);
    } else if (newValue === "2") {
      setAddFabUrl(URLS.COMMUNITY_ADD_COMMUNITY);
    } else if (newValue === "3") {
      setAddFabUrl(URLS.COMMUNITY_ADD_FRIEND);
    }
  };

  const handleToggleDisplay = () => {
    if (showHabits === "1") {
      return loading ? (
        <LoadingSpinner />
      ) : (
        <CommunityFeed communityPosts={communityPosts} />
      );
    }
    if (showHabits === "2") {
      return <CommunityTeams teams={teams} />;
    }
    if (showHabits === "3") {
      return (
        <CommunityFriends
          friendRequests={friendRequests}
          userFriends={userFriends}
        />
      );
    }
  };

  const displayCarousal = () => (
    <ImageCarousal
      images={selectedPost.images}
      type="posts"
      onClose={() => dispatch(setSelectedPost(null))} />);

  return (
    <Screen>
      <Wrapper>
        <AppBar component="nav" style={{ backgroundColor: "#f8f7f2", marginTop: "67px", boxShadow: "none" }}>
          <Tabs
            value={showHabits}
            onChange={handleSetShowHabits}
            variant="fullWidth"
            style={{ width: "100%" }}
          >
            <Tab icon={<NewspaperIcon />} value="1" />
            <Tab icon={<Diversity1Icon />} value="2" />
            <Tab icon={<GroupIcon />} value="3" />
          </Tabs>
        </AppBar>
        <Grid xs={12} item container justifyContent="center" sx={{ mt: 3 }}>
          {selectedPost != null && displayCarousal()}
          {handleToggleDisplay()}
        </Grid>
      </Wrapper>
      <AddFab url={addFabUrl} />
    </Screen>
  );
};

export default CommunityScreen;
