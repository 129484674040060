export const EMOTION_EMOJI_MAP = {
  0: "😱",
  1: "😖",
  2: "😞",
  3: "🙁",
  4: "😕",
  5: "😐",
  6: "🙂",
  7: "😊",
  8: "😁",
  9: "🤩",
  10: "🥳"
};

export const SLIDER = {
  COLOR_FROM_CODE: "#FF9800",
  COLOR_TO_CODE: "#8BC34A",
  TRACK_COLOR_CODE: "#EEEEEE",
  LABEL_COLOR_CODE: "#107CA4",
  KNOB_COLOR_CODE: "#009C9A",
};
