import { Slider, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/BehavioralExperimentEntry.module.css";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { useRedirect } from "Hooks/useRedirect";
import { editBehavioralExperiment, selectBehavioralExperiment, setFeelingAfter, setNewThought, setOutcome } from "Reducers/BehavioralExperimentReducer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";


const BehavioralCompleteScreen = () => {
    const redirectUrl = useRedirect("/toolkit/behavioral-experiments");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedExperiment, feelingAfter, newThought, outcome } = useSelector(selectBehavioralExperiment);
    const id = selectedExperiment._id;

    //add success add hook toastify
    // const onSubmit = async (event) => {
    //     event.preventDefault();
    //     navigate("/toolkit/behavioral-save");
    // };

    const handleSaveClick = (e) => {
        e.preventDefault();

        dispatch(editBehavioralExperiment({
            id,
            outcome,
            feelingAfter,
            newThought
        }));

        navigate("/toolkit/complete-experiment-view");
    };

    // Slider
    const marks = [
        {
            value: 1,
            label: "1 (bad)"
        },
        {
            value: 2,
            label: "2"
        },
        {
            value: 3,
            label: "3"
        },
        {
            value: 4,
            label: "4"
        },
        {
            value: 5,
            label: "5"
        },
        {
            value: 6,
            label: "6"
        },
        {
            value: 7,
            label: "7"
        },
        {
            value: 8,
            label: "8"
        },
        {
            value: 9,
            label: "9"
        },
        {
            value: 10,
            label: "10 (good)"
        },
    ];

    function valuetext(value) {
        return `${value}`;
    }

    return (
        <Screen>
            <Wrapper>
                <Wrapper>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={`${styles.marginTop}`}>
                        <Grid xs={10} item>
                            <Link
                                to={redirectUrl}
                                className={`${styles.backBtn}`}>
                                Back to all experiments
                            </Link>
                        </Grid>
                    </Grid>
                    <Stack
                        sx={{
                            background: "#fff",
                            padding: "1.25rem",
                            borderRadius: "0.5rem",
                            border: "solid 0.15rem #0b7ea1",
                            margin: "1rem 0 0",
                            minWidth: "336px"
                        }}
                    >
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            item={true}>
                            <Grid xs={10} item>
                                <div className={styles.selectedExperimentAnswers}>
                                    <h3>Thought being tested</h3>
                                    <p>{selectedExperiment.thoughts}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            item={true}>
                            <Grid xs={10} item>
                                <div className={styles.selectedExperimentAnswers}>
                                    <h3>Experiment Details</h3>
                                    <p>{selectedExperiment.details}</p>
                                </div>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "1em" }}
                        item={true}>
                        <Grid xs={10} item>
                            <div>
                                <p>Describe the outcome</p>
                                <TextField
                                    className={styles.textarea}
                                    label=""
                                    type="text"
                                    multiline
                                    rows={3.5}
                                    placeholder=""
                                    name="outcome"
                                    required={true}
                                    onChange={(e) => dispatch(setOutcome(e.target.value))}
                                    value={outcome}
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "1em" }}
                        item={true}>
                        <Grid xs={10} item>
                            <div>
                                <p>How do you feel after the experiment?</p>
                                <Slider
                                    aria-label="Feeling Scale"
                                    defaultValue={1}
                                    getAriaValueText={valuetext}
                                    valueLabelDisplay="auto"
                                    step={1}
                                    marks={marks}
                                    min={1}
                                    max={10}
                                    required={true}
                                    onChange={(e) => dispatch(setFeelingAfter(e.target.value))}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "1em" }}
                        item={true}>
                        <Grid xs={10} item>
                            <div>
                                <p>New thought based on your experience</p>
                                <TextField
                                    className={styles.textarea}
                                    label=""
                                    type="text"
                                    multiline
                                    rows={3.5}
                                    placeholder=""
                                    name="newThought"
                                    required={true}
                                    onChange={(e) => dispatch(setNewThought(e.target.value))}
                                    value={newThought}
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={4} className={`${styles.rightAlign}`}>
                            <button
                                className={`
                                    ${btn.faded_blue} 
                                    ${btn.smallActivityBtn} 
                                    ${styles.loadMoreBtn}`}
                                onClick={handleSaveClick} >
                                Save
                            </button>
                        </Grid>
                    </Stack>
                </Wrapper>
            </Wrapper>
        </Screen>
    );
};

export default BehavioralCompleteScreen;