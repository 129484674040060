import { AiFillMedicineBox } from "react-icons/ai";
import {
  FaDisease,
  FaHospital,
  FaStethoscope,
  FaStickyNote,
} from "react-icons/fa";

import BoxItem from "Components/Helpers/BoxItem";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import styles from "CSS/Screens/MyHealthRecordScreen.module.css";

const MyHealthRecordScreen = () => {
  return (
    <Screen>
      <Wrapper>
        <h1>My Health Record</h1>
        <div className={styles.myHealthRecordScreen}>
          {/* <BoxItem
            text="Health Info"
            icon={
              <IoIosBody className={styles.myHealthRecordItem_icon} size={24} />
            }
            link="/health-info"
          /> */}
          <BoxItem
            text="Health Care Providers"
            icon={
              <FaStethoscope
                className={styles.myHealthRecordItem_icon}
                size={24}
              />
            }
            link="health-care-providers"
          />
          <BoxItem
            text="Conditions"
            icon={
              <FaDisease className={styles.myHealthRecordItem_icon} size={24} />
            }
            link="condition-history"
          />
          <BoxItem
            text="Medications"
            icon={
              <AiFillMedicineBox
                className={styles.myHealthRecordItem_icon}
                size={24}
              />
            }
            link="medications"
          />
          <BoxItem
            text="Treatments"
            icon={
              <FaHospital
                className={styles.myHealthRecordItem_icon}
                size={24}
              />
            }
            link="treatments"
          />
          <BoxItem
            text="Additional Notes"
            icon={
              <FaStickyNote
                className={styles.myHealthRecordItem_icon}
                size={24}
              />
            }
            link="additional-notes"
          />
        </div>
      </Wrapper>
    </Screen>
  );
};

export default MyHealthRecordScreen;
