import { Grid, Paper } from "@mui/material";
import styles from "CSS/Components/ChatBubble.module.css";
import moment from "moment";
import { memo } from "react";

const ChatBubble = memo(function temp({ message, position }) {

  return (
    <div>
      {
        position === "left" && (
          <Grid
            container
            justifyContent="flex-start"
            className={`${styles.leftBubbleContainer}`}>
            <Paper className={`${styles.bubble} ${styles.leftBubble}`}>
              <Grid justifyContent="flex-start">
                <Grid item xs={12}>
                  {message.message}
                </Grid>
              </Grid>
            </Paper>
            <Grid
              item
              xs={12}
              className={`${styles.bubbleDate} ${styles.bubbleDateLeft}`}>
              {moment(message.sentOn).format("DD/MM")}
            </Grid>
          </Grid>
        )
      }
      {
        position === "right" && (
          <Grid
            container
            justifyContent="flex-end"
            className={`${styles.rightBubbleContainer}`}>
            <Paper className={`${styles.bubble} ${styles.rightBubble}`}>
              <Grid justifyContent="flex-end">
                <Grid item xs={12}>
                  {message.message}
                </Grid>
              </Grid>
            </Paper>
            <Grid
              item
              xs={12}
              className={`${styles.bubbleDate} ${styles.bubbleDateRight}`}>
              {moment(message.sentOn).format("DD/MM")}
            </Grid>
          </Grid>
        )
      }
    </div>
  );

});

export default ChatBubble;
