import btn from "CSS/Button.module.css";
import styles from "CSS/Components/UndoButton.module.css";
import { BiUndo } from "react-icons/bi";

const UndoButton = ({ callback, showUndo }) => {
  return (
    <div
      className={`${btn.orange_btn} ${styles.undo} ${!showUndo ? styles.undo_hidden : ""
        }`}
      onClick={callback}
    >
      <BiUndo size={22} />
      <span>Undo</span>
    </div>
  );
};

export default UndoButton;
