import { Chip } from "@mui/material";
import styles from "CSS/Components/UnitsSelector.module.css";

const UnitsSelector = ({ units, handleUnit }) => {
  return (
    <div>
      {units.map((option, index) => (
        <Chip
          variant={option.selected ? "filled" : "outlined"}
          key={index}
          color="primary"
          label={option.label}
          onClick={() => handleUnit(index)}
          className={`${styles.chip} ${option.selected ? styles.active : ""} ${option.label === "+" ? styles.btn : ""
            }`}
        />
      ))}
    </div>
  );
};

export default UnitsSelector;
