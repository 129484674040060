import { Tab, Tabs } from "@mui/material";
import styles from "CSS/Screens/HabitsScreen.module.css";
import Habit from "Components/Habit/Habit";
import AddFab from "Components/Helpers/AddFab";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import UndoButton from "Components/Helpers/UndoButton";
import Wrapper from "Components/Helpers/Wrapper";
import {
  addHabit,
  getHabits,
  resetAddHabitSuccess,
  resetDeleteHabitSuccess,
  selectHabits,
  setPage,
} from "Reducers/HabitsReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const HabitsScreen = () => {
  const dispatch = useDispatch();
  const {
    loading,
    habits,
    error,
    successAdd,
    successDelete,
    selectedHabit,
    page,
  } = useSelector(selectHabits);
  const [showHabits, setShowHabits] = useState(page);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showUndo, setShowUndo] = useState(false);

  const handleSetShowHabits = (_, newValue) => {
    setShowHabits(newValue);
    dispatch(setPage(newValue));
  };

  // takes all the habit data of the deleted habit and re-creates the habit on undo
  const undoHabit = () => {
    dispatch(addHabit({ ...selectedHabit, habitName: selectedHabit.name }));
    dispatch(resetDeleteHabitSuccess);
  };

  // displays success alert when new habit is added
  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new habit!");
      timer = setTimeout(() => {
        dispatch(resetAddHabitSuccess());
      }, 2000);
    }

    return timer;
  };

  // displays undo bottom when habit is deleted
  const handleSuccessDelete = () => {
    if (successDelete) {
      // show undo popup after user deletes entry
      setShowUndo(true);
      setTimeout(() => {
        setShowUndo(false);
        dispatch(resetDeleteHabitSuccess());
      }, 5000);
    }
  };

  useEffect(() => {
    let type = "";
    if (showHabits === "1") {
      type = "current";
    } else if (showHabits === "3") {
      type = "archived";
    }

    dispatch(getHabits(type)); // get archived when showHabits is "3"

    if (error) {
      setErrorMessage(error);
    }

    // return timer so we can clear it on component dismount
    const timer = handleSuccessAdd();
    handleSuccessDelete();

    return () => {
      clearTimeout(timer);
    };
  }, [showHabits, successAdd, successDelete, dispatch]);

  const displayHabits = (type) => {
    if (habits[type] && habits[type].length > 0) {
      return habits[type].map((habit) => (
        <Habit key={habit._id} habit={habit} />
      ));
    }
    if (habits[type] && habits[type].length === 0) {
      return (
        <Link to="/add-habit" className={styles.habits_none}>
          <span className={styles.habits_nonePlus}>+</span> Add new habit
        </Link>
      );
    }
  };

  const displayArchived = () => (
    <div className={styles.habits_section}>
      <h2>📨 Archived Habits</h2>
      {displayHabits("archived")}
    </div>
  );

  const displayAllHabits = () => (
    <>
      <div className={styles.habits_section}>
        <h2>☀️ Daily Habits</h2>
        {displayHabits("daily")}
      </div>
      <div className={styles.habits_section}>
        <h2>📊 Weekly Habits</h2>
        {displayHabits("weekly")}
      </div>

      <div className={styles.habits_section}>
        <h2>🗓 Monthly Habits</h2>
        {displayHabits("monthly")}
      </div>
    </>
  );

  const displayTodaysHabits = () => (
    <>
      <div className={styles.habits_section}>
        <h2>☀️ Today&rsquo;s Habits</h2>
        {displayHabits("daily")}
      </div>
      <div className={styles.habits_section}>
        <h2>📊 This Week&rsquo;s Habits</h2>
        {displayHabits("weekly")}
      </div>

      <div className={styles.habits_section}>
        <h2>🗓 This Month&rsquo;s Habits</h2>
        {displayHabits("monthly")}
      </div>
    </>
  );

  const handleToggleDisplay = () => {
    if (showHabits === "1") {
      return displayTodaysHabits();
    }
    if (showHabits === "2") {
      return displayAllHabits();
    }
    if (showHabits === "3") {
      return displayArchived();
    }
  };

  return (
    <Screen>
      <Wrapper>
        <Tabs
          value={showHabits}
          onChange={handleSetShowHabits}
          style={{ marginBottom: "10px" }}
        >
          <Tab label="Current" value="1" />
          <Tab label="All" value="2" />
          <Tab label="Archived" value="3" />
        </Tabs>

        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />

        {/* display success message when user adds habit */}
        <AlertMessage
          message={successMessage}
          setMessage={() => {
            setSuccessMessage("");
            dispatch(resetAddHabitSuccess());
          }}
          type="success"
          toggle={successAdd}
        />
        {loading &&
          habits?.daily?.length === 0 &&
          habits?.weekly?.length === 0 &&
          habits?.monthly?.length === 0 && <LoadingSpinner />}
        {handleToggleDisplay()}
      </Wrapper>
      <UndoButton
        callback={() => {
          setShowUndo(false);
          dispatch(resetDeleteHabitSuccess());
          undoHabit();
        }}
        showUndo={showUndo}
      />
      <AddFab url="/add-habit" />
    </Screen>
  );
};

export default HabitsScreen;
