import { createSlice } from "@reduxjs/toolkit";
import {
  addPathway,
  deletePathway,
  getPathway,
  getPathways,
  updatePathway,
} from "./Actions/PathwayActions";

const initialState = {
  page: "1", // "1": all, "2": archived
  error: null,
  loading: false,
  pathways: { active: [], archived: [] },
  addPathwayInfo: {
    title: "",
    motivationalSubtitle: "",
    yourHow: "",
    desiredOutcome: "",
    review: false,
    list: [], // temp list of habit names to create
    habits: [], // all habits to be created
    checkin: null,
  }, // used to persist state between the different add-pathway pages
  successAdd: false,
  successDelete: false,
  selectedPathway: null,
};

// functions for "useSelector"
const selectPathways = (state) => state.pathways;

const pathwaysSlice = createSlice({
  name: "Pathways",
  initialState,
  reducers: {
    resetPathways: () => initialState,
    // For updating when we only want to reset the error and loading
    resetPathway: ({ pathways, page }) => {
      return {
        pathways,
        selectPathway: null,
        loading: false,
        review: false,
        error: null,
        successAdd: false,
        successDelete: false,
        addPathwayInfo: {
          title: "",
          motivationalSubtitle: "",
          yourHow: "",
          desiredOutcome: "",
          list: [],
          habits: [],
          checkin: null,
        },
        page,
      };
    },
    resetAddPathwaySuccess: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    resetDeletePathwaySuccess: (state) => {
      return {
        ...state,
        successDelete: false,
      };
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setAddPathwayInfo: (state, { payload }) => {
      state.addPathwayInfo = payload;
    },
  },
  extraReducers: {
    // Add Pathway Async Reducers
    [addPathway.pending]: (state) => {
      state.loading = true;
      state.successAdd = false;
    },
    [addPathway.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.pathways.active = [payload, ...state.pathways.active];
      state.successAdd = true;
    },
    [addPathway.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },
    // Get pathways Async Reducers
    [getPathways.pending]: (state) => {
      state.loading = true;
    },
    [getPathways.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.pathways = payload;
    },
    [getPathways.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Get pathway Async Reducers
    [getPathway.pending]: (state) => {
      state.loading = true;
    },
    [getPathway.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedPathway = payload;
    },
    [getPathway.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Update pathway Async Reducers
    [updatePathway.pending]: (state) => {
      state.loading = true;
    },
    [updatePathway.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // update current selected pathway
      state.selectedPathway = {
        ...payload,
        habits: [],
        notes: [],
        todos: [],
      };

      // update proper list
      ["active", "archived"].forEach((type) => {
        const idx = state.pathways[type].findIndex(
          (plan) => plan._id === payload._id
        );

        if (idx !== -1) {
          if (payload.isArchived) {
            // remove from active list, add to archived list
            state.pathways[type].splice(idx, 1);
            state.pathways.archived.push(payload);
          } else if (type === "archived") {
            // remove from archived list, add to active list
            state.pathways[type].splice(idx, 1);
            state.pathways.active.push(payload);
          } else {
            // update pathway in list
            state.pathways[type][idx] = payload;
          }
        }
      });
    },
    [updatePathway.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // Delete Async Reducers
    [deletePathway.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successDelete = true;

      // remove from proper list
      state.pathways.active = state.pathways.active.filter(
        (pathway) => pathway._id !== payload._id
      );
    },
    [deletePathway.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successDelete = false;
    },
  },
});

export default pathwaysSlice.reducer;
export {
  addPathway, deletePathway, getPathway,
  getPathways, selectPathways, updatePathway
};

export const {
  setPage,
  setAddPathwayInfo,
  resetPathway,
  resetPathways,
  resetAddPathwaySuccess,
  resetDeletePathwaySuccess,
} = pathwaysSlice.actions;
