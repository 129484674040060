import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";


// send backend request to fetch logged in users todos completed
const getCompletedToDos = createAsyncThunk("completed todos", ({ type = "", limit = 50, skip = 0 }, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/todos?type=${type}&completed=true&limit=${limit}&skip=${skip}`),
    thunkAPI
  )
);

// send backend request to fetch logged in users todos
const getToDos = createAsyncThunk("get todos", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/todos?type=all&limit=50"),
    thunkAPI
  )
);

// send backend request to get a specific todo
const getToDo = createAsyncThunk("get todo", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/todos/${id}`),
    thunkAPI
  )
);

// send backend request to delete specific todo
const deleteToDo = createAsyncThunk("delete todo", (_id, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/todos/${_id}`),
    thunkAPI
  )
);

// send backend request to add new todo
const addToDo = createAsyncThunk("add todo", (
  {
    todoTitle,
    remindTime,
    completed = false,
    completedDate = null,
    isFullDay = false,
    recurrenceType,
    pathway,
  }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/todos",
      {
        title: todoTitle,
        remindTime,
        isFullDay,
        completed,
        completedDate,
        recurrenceType,
        pathway,
      },
    ),
    thunkAPI
  )
);


// send backend request to add new todo
const updateToDo = createAsyncThunk("update todo", (
  { id, todoTitle, remindTime, isFullDay, completed, recurrenceType },
  thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/todos/${id}`,
      {
        title: todoTitle,
        remindTime,
        isFullDay,
        completed,
        recurrenceType,
      }
    ),
    thunkAPI
  )
);

// send backend request to update todo so that it is completed
const toggleCompleteToDo = createAsyncThunk("toggle todo", (
  { id, completedDate, completed, isFullDay }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/todos/${id}`,
      {
        completed,
        completedDate,
        isFullDay
      }
    ),
    thunkAPI
  )
);

export {
  addToDo,
  deleteToDo, getCompletedToDos, getToDo, getToDos, toggleCompleteToDo,
  updateToDo
};

