import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import btn from "CSS/Button.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

// This is a functional component named ConfirmationModal
// It accepts props such as open, handleClose, text, 
// handleConfirmation, and handleCancel from the parent component
const ConfirmationModal = ({
  open,
  handleClose,
  text,
  handleConfirmation,
  handleCancel }) => {

  // The component renders a Modal window using the Material UI library
  // It uses the open and onClose props to control the 
  // visibility of the modal window
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title"
          variant="h6" component="h2"
          style={{ textAlign: "center" }}>
          {text}
        </Typography>
        <Grid container spacing={2} style={{ marginTop: "1em" }}>
          <Grid item={true} xs={12} style={{ textAlign: "center" }}>
            <button
              style={{ width: "80%" }}
              className={`${btn.faded_orange} ${btn.smallActivityBtn}`}
              onClick={handleConfirmation}>
              Confirm
            </button>
          </Grid>
          <Grid item={true} xs={12} style={{ textAlign: "center" }}>
            <button
              style={{ width: "80%" }}
              className={`${btn.faded_orange} ${btn.smallActivityBtn}`}
              onClick={handleCancel}>
              Cancel
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
