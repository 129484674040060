import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to get a habit
const getHabit = createAsyncThunk("get habit", async (id, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/habits/${id}`),
    thunkAPI
  )
);

// send backend request to get habits
const getHabits = createAsyncThunk("get habits", async (type, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/habits?type=${type}`),
    thunkAPI
  )
);

// send backend request to get habit instance
const getHabitInstances = createAsyncThunk("get habit instances", async ({ id, limit = 7 }, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/habits/instances/${id}?limit=${limit}`),
    thunkAPI
  )
);

// send backend request to get habits
const getHabitInstance = createAsyncThunk("get habit instance", async (id, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/habits/instance/${id}`),
    thunkAPI
  )
);

// send backend request to update a habit
const updateHabitInstance = createAsyncThunk("update habit instance", async (
  { id, amountCompleted, isCompleted }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/habits/instance/${id}`,
      {
        amountCompleted,
        isCompleted,
      },
    ),
    thunkAPI
  )
);

// send backend request to add new habit
const addHabit = createAsyncThunk("add habit", async (
  {
    habitName,
    remindTime,
    unit,
    unitAmount,
    extraUnits,
    interval,
    frequency,
    daysOfWeek,
    pathway,
    isNotificationEnabled,
    notificationTime,
  }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/habits",
      {
        name: habitName,
        remindTime,
        unit,
        unitAmount,
        extraUnits,
        interval,
        frequency,
        daysOfWeek,
        pathway,
        isNotificationEnabled,
        notificationTime,
      },
    ),
    thunkAPI
  )
);

// send backend request to update a habit
const updateHabit = createAsyncThunk("update habit", async (updatedHabit, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/habits/${updatedHabit.id}`, updatedHabit),
    thunkAPI
  )
);

// send backend request to delete specific habiy
const deleteHabit = createAsyncThunk("delete habit", async (id, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/habits/${id}`),
    thunkAPI
  )
);


export {
  addHabit,
  deleteHabit,
  getHabit,
  getHabitInstance,
  getHabitInstances,
  getHabits,
  updateHabit,
  updateHabitInstance
};

