import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to send new message.
const sendMessage = createAsyncThunk("send message/", (messageInfo, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/messages", messageInfo),
    thunkAPI
  )
);

// send backend request to get messages for a user.
const getMessagesForUser = createAsyncThunk("get messages for user/", (toUserId, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/messages/${toUserId}`),
    thunkAPI
  )
);

export { getMessagesForUser, sendMessage };

