import styles from "CSS/Components/BoxItem.module.css";
import { Link } from "react-router-dom";

const BoxItem = ({ text, icon, link, onClick }) => {

  return onClick ? (
    <div className={styles.boxItem} onClick={onClick}>
      <p>{text}</p>
      {icon}
    </div>
  ) : (
    <Link to={`${link}`} className={styles.boxItem}>
      <p>{text}</p>
      {icon}
    </Link>
  );
};

export default BoxItem;

