import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to get a singular medication
const getMedication = createAsyncThunk("get medication", (id, thunkAPI) =>
  actionHandler(
    //retrieve singular entry with given ID
    axiosInstance.get(`/medications/${id}`),
    thunkAPI
  )
);

// send backend request to fetch logged in users medications
// type of medication to fetch will be in API request {active, inactive, all}
const getMedications = createAsyncThunk("medications/", ({ type, time }, thunkAPI) =>
  actionHandler(
    // get either active, inactive, or all medications
    axiosInstance.get(`/medications?type=${type}&time=${time}`),
    thunkAPI
  )
);

// send backend request to delete specific medication
const deleteMedication = createAsyncThunk("delete/", (_id, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/medications/${_id}`),
    thunkAPI
  )
);

// send backend request to add new medication
const addMedication = createAsyncThunk("add medication/", (
  {
    medicationName,
    date,
    isInactive,
    prescriber,
    dosage,
    frequencies,
    sideEffects,
    additionalNotes,
    healthCareProvider,
  }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/medications",
      {
        medicationName,
        date,
        isInactive,
        prescriber,
        dosage,
        frequencies,
        sideEffects,
        additionalNotes,
        healthCareProvider,
      },
    ),
    thunkAPI
  )
);

// send backend request to update medication
const updateMedication = createAsyncThunk("update medication/", (
  {
    id,
    medicationName,
    date,
    isInactive,
    prescriber,
    dosage,
    frequencies,
    sideEffects,
    additionalNotes,
    healthCareProvider,
  }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/medications/${id}`,
      {
        medicationName,
        date,
        isInactive,
        prescriber,
        dosage,
        frequencies,
        sideEffects,
        additionalNotes,
        healthCareProvider,
      }
    ),
    thunkAPI
  )
);


export {
  addMedication,
  deleteMedication, getMedication,
  getMedications, updateMedication
};

