import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import Screen from "Components/Helpers/Screen";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddTemperatureScreen.module.css";
import moment from "moment";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addTemperature, selectHealthInfo } from "Reducers/HealthInfoReducer";

const AddTemperatureScreen = () => {
  const { loading, error } = useSelector(selectHealthInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [temperature, setTemperature] = useState("");
  const [unit, setUnit] = useState("");

  const handleUnitChange = (e) => {
    setUnit((prevUnit) => {
      convertTemperatureBasedOnUnit(prevUnit, e.target.value);
      return e.target.value;
    });
  };

  const convertTemperatureBasedOnUnit = (prevUnit, currUnit) => {
    if (prevUnit === currUnit) {
      return;
    } else if (prevUnit === "°F" && currUnit === "°C") {
      // convert °F to °C -> °C = (°F - 32) / (9 / 5)
      setTemperature(((temperature - 32) / (9 / 5)).toFixed(2));
    } else if (prevUnit === "°C" && currUnit === "°F") {
      // convert °C to °F -> °F = (°C * (9 / 5)) + 32
      setTemperature((temperature * (9 / 5) + 32).toFixed(2));
    }
  };

  const addTemperatureSubmit = (e) => {
    e.preventDefault();

    dispatch(
      addTemperature({
        temperature,
        unit,
        date,
      })
    );

    // If successful, return to previous screen
    if (!loading && !error) {
      navigate("/health-info");
    }
  };

  return (
    <Screen>
      <WrapperForm onSubmit={addTemperatureSubmit}>
        <TextField
          className={styles.datetextarea}
          type="datetime-local"
          name="day"
          required={true}
          value={date}
          onChange={(e) => setDate(e.target.value)}
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        {errorMessage && (
          <AlertMessage
            sx={{ width: "80%", marginBottom: "15px" }}
            severity="error"
            onClose={() => setErrorMessage("")}
          >
            {errorMessage}
          </AlertMessage>
        )}
        <h1 style={{ margin: "10px" }}>Temperature</h1>
        <div className={styles.inputArea}>
          <Input
            className={styles.textarea}
            label="Temperature"
            type="text"
            placeholder="Enter temperature"
            name="name"
            required={true}
            variant="none"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
            onChange={(e) => setTemperature(e.target.value)}
            value={temperature}
            size="small"
            slotProps={{
              input: {
                step: 0.1,
              },
            }}
          />
          <FormControl size="small">
            <InputLabel>Unit</InputLabel>
            <Select
              sx={{ borderRadius: "8px" }}
              className={styles.filterSelect}
              label="Unit"
              value={unit}
              onChange={handleUnitChange}
            >
              <MenuItem value={"°C"}>°C</MenuItem>
              <MenuItem value={"°F"}>°F</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={`${btn.center}`}>
          <button
            className={`${btn.journalentrybtn} ${btn.white_btn}`}
            onClick={() => navigate("/health-info")}
            type="button"
          >
            Cancel
          </button>

          <button
            className={`${btn.journalentrybtn} ${btn.orange_btn}`}
            type="submit"
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </WrapperForm>
    </Screen>
  );
};

export default AddTemperatureScreen;
