import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/BehavioralExperimentEntry.module.css";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { useRedirect } from "Hooks/useRedirect";
import { addExperimentAttempt, resetBehavioralExperiment, selectBehavioralExperiment, setChange, setObstacle } from "Reducers/BehavioralExperimentReducer";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const BehavioralIncompleteScreen = () => {
    const redirectUrl = useRedirect("/toolkit/behavioral-experiments");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedExperiment, obstacle, change } = useSelector(selectBehavioralExperiment);
    const id = selectedExperiment._id;

    //add success add hook toastify
    // const onSubmit = async (event) => {
    //     event.preventDefault();
    //     navigate("/toolkit/behavioral-save");
    // };

    const handleBack = () => {
        dispatch(resetBehavioralExperiment());
        navigate("/toolkit/behavioral-experiments");
    };

    const handleSaveClick = (e) => {
        e.preventDefault();

        dispatch(addExperimentAttempt({
            id,
            obstacle,
            change
        }));

        navigate("/toolkit/complete-experiment-view");
    };

    const [day, setDay] = useState(moment());
    const [time, setTime] = useState("");
    useEffect(() => {
        setDay(
            moment(selectedExperiment.date).format("MMMM") +
            " " +
            moment(selectedExperiment.date).date()
        );
        setTime(
            ", " +
            moment(selectedExperiment.date)
                .local()
                .format("hh:mm A")
                .replace(/(^|-)0+/g, "")
        ); // remove leading 0 on date
    }, [selectedExperiment.date]);


    return (
        <Screen>
            <Wrapper>
                <Wrapper>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={`${styles.marginTop}`}>
                        <Grid xs={10} item>
                            <Link
                                to={redirectUrl}
                                // onClick={handleBack}
                                className={`${styles.backBtn}`}>
                                Back
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid
                        className={`${styles.marginTop}`}
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center">
                        <Grid xs={10} item>
                            <Grid item
                                sx={{
                                    background: "#fff",
                                    padding: "1.25rem",
                                    borderRadius: "0.5rem",
                                    border: "solid 0.15rem #0b7ea1",
                                    margin: "1rem 0 0",
                                }}
                            >
                                <h2>🧪 Experiment</h2>
                                <div className={styles.selectedExperimentAnswers}>
                                    <h3>Thought being tested</h3>
                                    <p>{selectedExperiment.thoughts}</p>
                                </div>
                                <div className={styles.selectedExperimentAnswers}>
                                    <h3>Experiment details</h3>
                                    <p>{selectedExperiment.details}</p>
                                </div>
                                <div className={styles.selectedExperimentAnswers}>
                                    <h3>Scheduled for:</h3>
                                    <p>
                                        {moment(selectedExperiment.date).isSame(moment(), "day")
                                            ? "🗓️ " + "Today" + time
                                            : "🗓️ " + day + time}
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "2em" }}
                        item={true}>
                        <Grid xs={10} item>
                            <div>
                                <p>What stopped you from completing the experiment?</p>
                                <TextField
                                    className={styles.textarea}
                                    label=""
                                    type="text"
                                    multiline
                                    rows={3.5}
                                    placeholder=""
                                    name="outcome"
                                    required={true}
                                    onChange={(e) => dispatch(setObstacle(e.target.value))}
                                    value={obstacle}
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "2em" }}
                        item={true}>
                        <Grid xs={10} item>
                            <div>
                                <p>What could you do differently next time to overcome past obstacles?</p>
                                <TextField
                                    className={styles.textarea}
                                    label=""
                                    type="text"
                                    multiline
                                    rows={3.5}
                                    placeholder=""
                                    name="newThought"
                                    required={true}
                                    onChange={(e) => dispatch(setChange(e.target.value))}
                                    value={change}
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={4}>
                            <button
                                className={`
                                ${btn.faded_orange} 
                                ${btn.smallActivityBtn} 
                                ${styles.loadMoreBtn}`}
                                onClick={handleBack}>
                                Back
                            </button>
                        </Grid>
                        <Grid item xs={4} className={`${styles.rightAlign}`}>
                            <button
                                className={`
                                ${btn.faded_blue} 
                                ${btn.smallActivityBtn} 
                                ${styles.loadMoreBtn}`}
                                onClick={handleSaveClick} >
                                Save
                            </button>
                        </Grid>
                    </Stack>
                </Wrapper>
            </Wrapper>
        </Screen>
    );
};

export default BehavioralIncompleteScreen;