import SearchIcon from "@mui/icons-material/Search";
import { Avatar, Button, Grid, IconButton, InputBase, Paper } from "@mui/material";
import { default as communityStyle, default as styles } from "CSS/Screens/CommunityScreen.module.css";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import { URLS, USER_PROFILE_PICTURE_S3_URL } from "Constants/Constants";
import { searchUsers } from "Reducers/Actions/CommunityActions";
import { deleteFriendRequest, sendFriendRequest } from "Reducers/Actions/FriendRequestActions";
import { resetUserSearchResult, selectCommunity } from "Reducers/CommunityReducer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const AddFriendScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userSearchResult, loading } = useSelector(selectCommunity);
  const [searchText, setSearchText] = useState("");

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      dispatch(resetUserSearchResult());
      return;
    }

    dispatch(searchUsers(e.target.value));
  };

  const handleSendFriendRequest = (userId) => {
    dispatch(sendFriendRequest(userId))
      .then(() => dispatch(searchUsers(searchText)));
  };

  const handleDeleteFriendRequest = (id) => {
    dispatch(deleteFriendRequest(id))
      .then(() => dispatch(searchUsers(searchText)));
  };

  const handleClose = (e) => {
    e.preventDefault();
    dispatch(resetUserSearchResult());
    setSearchText("");
    navigate(URLS.COMMUNITY);
  };

  const renderSearchResult = () => {
    return (
      <>
        {userSearchResult.map(({ user, friendRequest }) => (
          <div key={user._id} style={{ marginBottom: "1em" }}>
            <Paper
              style={{ padding: "10px", borderRadius: "15px" }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center">
                <Grid xs={3} item>
                  <Avatar
                    sx={{ width: 45, height: 45 }}
                    imgProps={{
                      crossOrigin: "anonymous"
                    }}
                    src={`${user.profilePicture != null ?
                      (USER_PROFILE_PICTURE_S3_URL + user.profilePicture) : ""}`}
                    aria-label={`profile picture of ${user.firstName}`} />
                </Grid>
                <Grid xs={5} item>
                  <p>{user.firstName} {user.lastName ? user.lastName : ""}</p>
                </Grid>
                <Grid xs={4} item style={{ textAlign: "center" }}>
                  {
                    friendRequest === null && (
                      <Button
                        variant="outlined"
                        size="small"
                        style={{ fontSize: "10px" }}
                        onClick={() => handleSendFriendRequest(user._id)}>
                        Add Friend
                      </Button>
                    )
                  }
                  {
                    friendRequest != null && friendRequest.status === "pending" && (
                      <Button
                        variant="outlined"
                        size="small"
                        style={{ fontSize: "10px" }}
                        onClick={() => handleDeleteFriendRequest(friendRequest._id)}>
                        Undo Friend Request
                      </Button>
                    )
                  }
                </Grid>
              </Grid>
            </Paper>
          </div>
        ))}
      </>
    );
  };

  return (
    <ScreenNoNav>
      <Grid
        style={{ marginTop: "2em", marginBottom: "10px" }}
        container
        xs={10}
        item={false}
        justifyContent="flex-start">
        <Grid item xs={4}>
          <Link
            to=""
            onClick={handleClose}
            className={`${communityStyle.btn} ${communityStyle.btn_orange}`}
          >
            Close
          </Link>
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: "1em", marginBottom: "10px" }}
        container
        xs={10}
        item={false}
        justifyContent="flex-start">
        <h3>Add Friends</h3>
      </Grid>
      <Grid style={{ marginTop: "10px", marginBottom: "10px" }} justifyContent="center" container xs={10}>
        <Grid item xs={12} sm={8}>
          <Paper className={styles.searchBar}>
            <InputBase
              sx={{ ml: 2 }}
              style={{ width: "75%" }}
              placeholder="Search Users"
              inputProps={{ "aria-label": "search users" }}
              onChange={handleSearchInputChange}
              value={searchText}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "10px", marginBottom: "10px" }} justifyContent="center" container xs={10}>
        <Grid item xs={12} sm={8}>
          {
            loading && (
              <div style={{ textAlign: "-webkit-center", marginTop: "1em" }}>
                <LoadingSpinner />
              </div>
            )
          }
          {
            userSearchResult.length > 0 && (renderSearchResult())
          }
          {
            searchText.length > 0 && userSearchResult.length === 0 && (
              <Grid item={true} xs={12}>
                <Paper
                  elevation={2}
                  className={`${styles.emptyCommunityCard}`}>
                  <Grid>
                    <Grid container justifyContent="center">
                      <img
                        className={styles.emptyCommunityImage}
                        src="/images/community/not-found.png" />
                    </Grid>
                    <Grid container justifyContent="center">
                      No users found for
                      <span className={styles.emptyCommunitySearchText}> {searchText}</span>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </ScreenNoNav>
  );
};

export default AddFriendScreen;
