import styles from "CSS/Screens/BookingScreen.module.css";
import BoxItemExt from "Components/Helpers/BoxItemExternal";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { MdEditCalendar } from "react-icons/md";

const BookingScreen = () => {
  return (
    <Screen>
      <Wrapper>
        <h1>📆 Book a Coaching Session</h1>
        <div className={styles.cta_booking}>
          <p>Transform your well-being today.</p>
          <p>Book a personalized coaching session!</p>
        </div>
        <div className={styles.screenContainer}>
          <BoxItemExt
            text="Andrew Hartman"
            icon={<MdEditCalendar className={styles.item_icon} size={24} />}
            link="https://calendly.com/psystem/ds"
          />
          <BoxItemExt
            text="Bonnie Hartman"
            icon={<MdEditCalendar className={styles.item_icon} size={24} />}
            link="https://calendly.com/coach-bonnie-psystem"
          />
        </div>
      </Wrapper>
    </Screen>
  );
};

export default BookingScreen;
