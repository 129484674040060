import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import {
  Avatar,
  Badge,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Radio,
  TextField
} from "@mui/material";
import { styled } from "@mui/material/styles";
import btn from "CSS/Button.module.css";
import communityStyle from "CSS/Screens/CommunityScreen.module.css";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import {
  COMMUNITY_TEAM_ICONS,
  COMMUNITY_TEAM_ICON_TO_VALUE_MAP,
  COMMUNITY_TEAM_ICON_VALUE_TO_ICON_MAP,
  URLS
} from "Constants/Constants";
import { useRedirect } from "Hooks/useRedirect";
import { resetTeamToCreate, selectTeam, setTeamToCreateInfo } from "Reducers/TeamReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const AddCommunityScreen = () => {
  // Retrieve the `teamToCreate` object from the Redux store
  const { teamToCreate } = useSelector(selectTeam);

  // Custom hook to get the redirect URL
  const redirectUrl = useRedirect(URLS.COMMUNITY);

  // Custom hook to navigate between routes
  const navigate = useNavigate();

  // Access the dispatch function from the Redux store
  const dispatch = useDispatch();

  // State variable to manage the visibility of picture selection
  const [isPictureSelectionOpen, setIsPictureSelectionOpen] = useState(false);

  // State variable to track whether the title is invalid
  const [isTitleInvalid, setIsTitleInvalid] = useState(false);

  const [selectedIcon, setSelectedIcon] = useState("T");

  // Object representing the team icon with specific properties
  const teamIcon = { bgcolor: "#f6ddd3", width: 50, height: 50 };

  useEffect(() => setSelectedIcon(teamToCreate.icon), [teamToCreate.icon]);

  // Styled component for an editable community picture, extending the Avatar component
  const EditCommunityPicture = styled(Avatar)(({ theme }) => ({
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  // Styled component for a Bootstrap-style Dialog component
  const BootstrapDialog = styled(Dialog)(() => ({
    "& .MuiPaper-root": {
      maxWidth: "250px",
      borderRadius: "20px",
    },
    "& .MuiDialogTitle-root": {
      fontSize: "1rem",
    },
  }));

  // Function to handle closing the dialog
  const handleDialogClose = () => {
    setIsPictureSelectionOpen(false);
  };

  // Function to handle changes in the new community data and dispatch an action
  const handleNewCommunityChange = (newCommunity) => {
    dispatch(setTeamToCreateInfo(newCommunity));
  };

  const handleBack = (e) => {
    e.preventDefault();
    dispatch(resetTeamToCreate());
    navigate(URLS.COMMUNITY);
  };

  // Function to handle changing the community image/icon
  const handleImageChange = (icon) => {
    // Validating if the icon is mapped, If not setting to default icon.
    const iconValue = COMMUNITY_TEAM_ICON_TO_VALUE_MAP[icon] ?
      COMMUNITY_TEAM_ICON_TO_VALUE_MAP[icon] : "T";
    handleNewCommunityChange({ icon: iconValue });
    setIsPictureSelectionOpen(false);
  };

  // Function to handle the click on the "Add Friends" button
  const handleAddFriendsClick = () => {
    if (teamToCreate.name.length === 0) {
      setIsTitleInvalid(true);
      return;
    }

    navigate(URLS.COMMUNITY_ADD_COMMUNITY_FRIENDS);
  };

  return (
    <ScreenNoNav>
      <BootstrapDialog
        maxWidth="xs"
        onClose={handleDialogClose}
        open={isPictureSelectionOpen}>
        <DialogTitle style={{ textAlign: "center" }}>Select Team Picture</DialogTitle>
        <DialogContent>
          <Grid
            justifyContent="center"
            alignItems="center"
            container
            direction="row"
            spacing={1}>
            {
              COMMUNITY_TEAM_ICONS.map((icon, index) => (
                <Grid item justifyContent="center" container xs={4} key={index}>
                  <Avatar onClick={() => handleImageChange(icon)} sx={teamIcon}>
                    <span style={{ fontSize: "28px" }}>
                      {icon}
                    </span>
                  </Avatar>
                </Grid>
              ))
            }
          </Grid>
        </DialogContent>
      </BootstrapDialog>

      <Grid
        style={{ marginTop: "2em", marginBottom: "10px" }}
        container
        xs={10}
        item={false}
        justifyContent="flex-start">
        <Grid item xs={4}>
          <Link
            to={redirectUrl}
            onClick={handleBack}
            className={`${communityStyle.btn} ${communityStyle.btn_orange}`}
          >
            Close
          </Link>
        </Grid>
      </Grid>
      <Grid
        container
        xs={10}
        spacing={2}
        style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
        <Grid item xs={12}>
          <Badge
            overlap="circular"
            badgeContent={
              <EditCommunityPicture alt="Remy Sharp">
                <EditIcon
                  onClick={() => setIsPictureSelectionOpen(true)}
                  style={{
                    height: "15px",
                    width: "15px",
                    color: "black",
                    cursor: "pointer"
                  }} />
              </EditCommunityPicture>
            }
          >
            <Avatar
              sx={{ width: 56, height: 56, bgcolor: "#f6ddd3" }}>
              <span style={{ fontSize: "35px" }}>
                {COMMUNITY_TEAM_ICON_VALUE_TO_ICON_MAP[selectedIcon]}
              </span>
            </Avatar>
          </Badge>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={isTitleInvalid}
            helperText={isTitleInvalid ? "Name cannot not be empty" : ""}
            fullWidth
            id="community-name-input"
            label="Community Name"
            variant="filled"
            value={teamToCreate.name}
            onChange={(e) => handleNewCommunityChange({ name: e.target.value })} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="community-description-input"
            label="Community description"
            multiline
            rows={4}
            variant="filled"
            value={teamToCreate.description}
            onChange={(e) => handleNewCommunityChange({ description: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <List
            disablePadding
            sx={{
              width: "100%",
              bgcolor: "background.paper"
            }}>
            <ListItem
              secondaryAction={
                <Radio
                  checked={teamToCreate.isPublic === true}
                  onChange={() => handleNewCommunityChange({ isPublic: true })}
                  value="public"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar variant="rounded">
                    <PublicIcon style={{ color: "black" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Public" secondary="Anyone can join this group" />
              </ListItemButton>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem
              secondaryAction={
                <Radio
                  checked={teamToCreate.isPublic === false}
                  onChange={() => handleNewCommunityChange({ isPublic: false })}
                  value="private"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                  ><LockIcon style={{ color: "black" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  style={{ paddingRight: "5px" }}
                  primary={"Private"}
                  secondary="Only visible to those you share with" />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Grid style={{
        marginTop: "1em",
        marginBottom: "10px",
        textAlign: "center"
      }} container xs={10}>
        <Grid item xs={12}>
          <button
            onClick={handleAddFriendsClick}
            className={`${btn.btn} ${btn.faded_blue}`}
            style={{ maxWidth: "100%" }}>
            Add Friends
          </button>
        </Grid>
      </Grid>
    </ScreenNoNav>
  );
};

export default AddCommunityScreen;
