import { Typography } from "@mui/material";
import { memo, useState } from "react";
import { GoInfo } from "react-icons/go";
import ModalBox from "./ModalBox";


const InfoButton = memo(function InfoButton({ message, title, size = 24 }) {
  const [openMessage, setOpenMessage] = useState(false);
  return (
    <>
      <ModalBox open={openMessage} onClose={() => setOpenMessage(false)} title={title}>
        <Typography variant="body1">{message}</Typography>
      </ModalBox>

      {/* <InfoIcon onClick={() => setOpenMessage(true)} /> */}
      <GoInfo size={size} onClick={() => setOpenMessage(true)} />
    </>
  );
});

export default InfoButton;