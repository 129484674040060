import { Grid, ListItemButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import styles from "CSS/Components/SideNav.module.css";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { selectUser } from "Reducers/UserReducer";
import { selectUserSetting } from "Reducers/UserSettingReducer";

const SideNav = ({ onClose }) => {
  const { user } = useSelector(selectUser);
  const { sideNavConfig } = useSelector(selectUserSetting);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [sideNavMap, setSideNavMap] = useState(null);
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    setSideNavMap(new Map(sideNavConfig
      .map(navItem => [navItem.content,
      { content: navItem.content, isActive: navItem.isActive }])));

  }, [sideNavConfig]);

  // Add new link in listValues AND Constants.js file
  const listValues = [
    {
      name: "Home",
      icon: (
        <div className={`${styles.sideNav_iconHome} ${styles.sideNav_icon}`} />
      ),
      activeIcon: (
        <div
          className={`${styles.sideNav_iconHome} ${styles.sideNav_icon} ${styles.sideNav_iconActive}`}
        />
      ),
      link: "/",
      security: "public",
    },
    {
      name: "Journal",
      icon: (
        <div
          className={`${styles.sideNav_iconJournal} ${styles.sideNav_icon}`}
        />
      ),
      activeIcon: (
        <div
          className={`${styles.sideNav_iconJournal} ${styles.sideNav_icon} ${styles.sideNav_iconActive}`}
        />
      ),
      link: "/journal",
      security: "public",
    },
    {
      name: "To-Do's",
      icon: (
        <div
          className={`${styles.sideNav_iconTodos} ${styles.sideNav_icon}`}
        />
      ),
      activeIcon: (
        <div
          className={`${styles.sideNav_iconTodos} ${styles.sideNav_icon} ${styles.sideNav_iconActive}`}
        />
      ),
      link: "/todos",
      security: "public",
    },
    {
      name: "Habits",
      icon: (
        <div
          className={`${styles.sideNav_iconHabits} ${styles.sideNav_icon}`}
        />
      ),
      activeIcon: (
        <div
          className={`${styles.sideNav_iconHabits} ${styles.sideNav_icon} ${styles.sideNav_iconActive}`}
        />
      ),
      link: "/habits",
      security: "public",
    },
    {
      name: "Pathways",
      icon: (
        <div
          className={`${styles.sideNav_iconPathway} ${styles.sideNav_icon}`}
        />
      ),
      activeIcon: (
        <div
          className={`${styles.sideNav_iconPathway} ${styles.sideNav_icon} ${styles.sideNav_iconActive}`}
        />
      ),
      link: "/pathway",
      security: "public",
    },
    {
      name: "My Health Record",
      icon: (
        <div
          className={`${styles.sideNav_iconMyHealthRecord} ${styles.sideNav_icon}`}
        />
      ),
      activeIcon: (
        <div
          className={`${styles.sideNav_iconMyHealthRecord} ${styles.sideNav_icon} ${styles.sideNav_iconActive}`}
        />
      ),
      link: "/my-health-record",
      security: "public",
    },
    {
      name: "Toolkit",
      icon: (
        <div
          className={`${styles.sideNav_iconToolkit} ${styles.sideNav_icon}`}
        />
      ),
      activeIcon: (
        <div
          className={`${styles.sideNav_iconToolkit} ${styles.sideNav_icon} ${styles.sideNav_iconActive}`}
        />
      ),
      link: "/toolkit",
      security: "public",
    },
    {
      name: "Insights",
      icon: (
        <div
          className={`${styles.sideNav_iconInsight} ${styles.sideNav_icon}`}
        />
      ),
      activeIcon: (
        <div
          className={`${styles.sideNav_iconInsight} ${styles.sideNav_icon} ${styles.sideNav_iconActive}`}
        />
      ),
      link: "/insights",
      security: "public",
    },
    {
      name: "Book a Session",
      icon: (
        <div
          className={`${styles.sideNav_iconBooking} ${styles.sideNav_icon}`}
        />
      ),
      activeIcon: (
        <div
          className={`${styles.sideNav_iconBooking} ${styles.sideNav_icon} ${styles.sideNav_iconActive}`}
        />
      ),
      link: "/booking",
      security: "public",
    },
    {
      name: "Community",
      icon: (
        <div
          className={`${styles.sideNav_iconCommunity} ${styles.sideNav_icon}`}
        />
      ),
      activeIcon: (
        <div
          className={`${styles.sideNav_iconCommunity} ${styles.sideNav_icon} ${styles.sideNav_iconActive}`}
        />
      ),
      link: "/community",
      security: "public",
    },
    {
      name: "Admin",
      icon: (
        <div className={`${styles.sideNav_iconAdmin} ${styles.sideNav_icon}`} />
      ),
      activeIcon: (
        <div
          className={`${styles.sideNav_iconAdmin} ${styles.sideNav_icon} ${styles.sideNav_iconActive}`}
        />
      ),
      link: "/admin",
      security: "admin",
    },
  ];

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText("https://play.google.com/store/apps/details?id=ca.psyhealth.twa");
    setTooltipOpen(true);
  };

  const handleSideNavClick = (toUrl) => {
    onClose();
    navigate(toUrl);
  };

  return (
    <div>
      <Grid style={{ marginTop: "1em", marginBottom: "1em", textAlign: "left", marginLeft: "2em" }}>
        <h3>
          Welcome,{" "}<br></br>
          <Link
            to="/setting/account"
            className={`${isMobile ? styles.sideNav_profileMobile : styles.sideNav_profile
              }`}
          >
            {user ? user.firstName : "user"}
          </Link>
        </h3>
      </Grid>

      <Stack
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={0}
        style={{ height: "86vh" }}
      >
        <Grid
          item>
          {sideNavMap != null && listValues.map((navItem, index) => {
            if (sideNavMap.has(navItem.name)) {
              const userConfigNavItem = sideNavMap.get(navItem.name);
              if (
                userConfigNavItem.isActive &&
                (navItem.security === "public" ||
                  (navItem.security === "admin" && user.isAdmin))
              ) {
                return (
                  <Grid key={index} item>
                    <ListItemButton className={styles.sideNav_item_btn} onClick={() => handleSideNavClick(navItem.link)} key={navItem.name}>
                      <SideNavItem
                        icon={navItem.icon}
                        name={navItem.name}
                        active={pathname === navItem.link}
                        activeIcon={navItem.activeIcon}
                      />
                    </ListItemButton>
                  </Grid>
                );
              }
            }
          })}
        </Grid>
        <Grid item>
          <Grid item>
            <ListItemButton className={styles.sideNav_item_btn} onClick={() => navigate("/feedback")}>
              <div className={`${styles.sideNav_iconFeedback} ${styles.sideNav_icon} ${isMobile ? styles.sideNav_itemMobile : styles.sideNav_item
                }`} />
              <span className={styles.sideNav_itemName}>{"Feedback"}</span>
            </ListItemButton>
          </Grid>
          <Grid item>
            <Tooltip
              open={isTooltipOpen}
              title={"Link copied to clipboard!"}
              leaveDelay={1500}
              onClose={handleTooltipClose}
              onClick={handleTooltipOpen}
            >
              <ListItemButton className={styles.sideNav_item_btn}>
                <div
                  className={`${styles.sideNav_iconShare} ${styles.sideNav_icon} ${isMobile ? styles.sideNav_itemMobile : styles.sideNav_item
                    }`}
                />
                <span className={styles.sideNav_itemName}>{"Share"}</span>
              </ListItemButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default SideNav;

const SideNavItem = ({ icon, activeIcon, name, onClick, active }) => {
  return (
    <div
      className={`${isMobile ? styles.sideNav_itemMobile : styles.sideNav_item
        }`}
      onClick={onClick}
    >
      {active ? activeIcon : icon}
      <span
        className={`${styles.sideNav_itemName} ${active && styles.sideNav_itemActive
          }`}
      >
        {name}
      </span>
    </div>
  );
};
