import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { FormControl, Grid, IconButton, ImageListItem, ImageListItemBar, Paper, TextField } from "@mui/material";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddPostScreen.module.css";
import communityStyle from "CSS/Screens/CommunityScreen.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import { URLS } from "Constants/Constants";
import { useRedirect } from "Hooks/useRedirect";
import { createTeamPost } from "Reducers/Actions/TeamActions";
import { selectTeam } from "Reducers/TeamReducer";
import Compressor from "compressorjs";
import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

const AddTeamPostScreen = () => {
  const { id } = useParams();
  const redirectUrl = useRedirect(URLS.TEAM);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const { selectedTeam } = useSelector(selectTeam);

  const inputFile = useRef(null);

  // States required for image upload.
  const [selectedImages, setSelectedImages] = useState([]);
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");


  const addPostSubmit = (e) => {
    e.preventDefault();
    dispatch(createTeamPost({
      content: content,
      teamId: selectedTeam._id,
      postType: "community",
      images: selectedImages,
    })).then(navigate(`${URLS.TEAM}/${id}`));
  };

  const handleImageUploadClick = () => {
    inputFile.current.click();
  };

  const handleFileUpload = (event) => {
    if (event.target.files.length > 3 || selectedImages.length === 3) {
      setAlertMessage("Cannot upload more than 3 images per entry");
      setIsAlertActive(true);
      return;
    }

    if (event.target.files.length > 0) {
      const filesArray = [...event.target.files];
      filesArray.map((image) => compressImage(image));
    }
  };

  const compressImage = (image) => {
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        setSelectedImages((prevState) => [...prevState, compressedResult]);
      },
    });
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prevState) => prevState.filter((_, i) => i !== index));
  };

  return (
    <ScreenNoNav>
      <WrapperForm onSubmit={addPostSubmit}>
        <div className={communityStyle.header}>
          <Link
            to={`${redirectUrl}/${id}`}
            className={`${communityStyle.btn} ${communityStyle.btn_orange}`}>
            Close
          </Link>
          <h3>New Team Post</h3>
          <button
            type="submit"
            className={`${communityStyle.btn} ${communityStyle.btn_blue}`}>
            Add
          </button>
        </div>
        <div
          className={`
            ${styles.add_post_body} 
            ${isMobile ? styles.add_post_bodyMobile : ""
            }`}>
          <Grid container justifyContent="center">
            <Grid item xs={10}>
              <TextField
                className={styles.textarea}
                label="Enter team post"
                type="text"
                fullWidth
                multiline
                minRows={3.5}
                // rows={3.5}
                placeholder="How are you feeling?"
                name="review"
                required={false}
                onChange={(e) => setContent(e.target.value)}
                value={content}
                variant="outlined"
                inputProps={{
                  maxLength: 500,
                }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid xs={10}>
              <AlertMessage
                toggle={isAlertActive}
                type="warning"
                setMessage={() => setIsAlertActive(false)}
                message={alertMessage} />
              <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
                <input
                  onChange={handleFileUpload}
                  type="file"
                  id="images"
                  name="images"
                  accept="image/*"
                  ref={inputFile}
                  style={{ display: "none" }}
                  multiple />
                <Paper
                  variant="outlined"
                  className={
                    selectedImages.length > 0 ?
                      styles.imageUploadPaperActive :
                      styles.imageUploadPaper}
                  onClick={selectedImages.length === 0 ? handleImageUploadClick : null}>
                  {selectedImages.length > 0 ? (
                    <Grid container>
                      {
                        selectedImages.map((image, index) => (
                          <Grid xs={4} key={index} className={styles.journalImageContainer}>
                            <ImageListItem key={index} className={styles.journalImage}>
                              <img
                                className={styles.journalImage}
                                src={URL.createObjectURL(image)}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                className={styles.imageList}
                                position="top"
                                actionIcon={
                                  <IconButton
                                    className={styles.imageIcon}
                                    onClick={() => handleRemoveImage(index)}
                                  >
                                    <HighlightOffRoundedIcon />
                                  </IconButton>
                                }
                                actionPosition="left"
                              />
                            </ImageListItem>
                          </Grid>
                        ))
                      }
                      <Grid xs={4}>
                        <Paper
                          onClick={handleImageUploadClick}
                          className={styles.moreImageUpload}>
                          <AddCircleOutlineIcon fontSize="large" />
                        </Paper>
                      </Grid>
                    </Grid>
                  ) : (<AddPhotoAlternateIcon fontSize="large" />)}
                </Paper>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid xs={10}>
              <button
                className={`${btn.btn} ${btn.faded_blue} ${styles.post_submit}`}
                style={{ minWidth: "100%" }}
                type="submit">
                Share Team Post
              </button>
            </Grid>
          </Grid>

        </div>
      </WrapperForm>
    </ScreenNoNav>
  );
};

export default AddTeamPostScreen;
