import { useNavigate } from "react-router-dom";
import btn from "../../CSS/Button.module.css";
import styles from "../../CSS/Screens/JournalTutorialScreen.module.css";
import Screen from "../../Components/Helpers/Screen";
import Wrapper from "../../Components/Helpers/Wrapper";

const JournalTutorialScreen = () => {
  const navigate = useNavigate();

  return (
    <Screen>
      <Wrapper>
        <h1 className={styles.heading}>Journal Tutorial</h1>
        <div className={styles.centerAnimation}>
          <img
            src="/images/onboarding/journal_tutorial.gif"
            className={styles.tutorialVideo}
            loading="lazy"
          />
        </div>
        <div className={styles.btnContainer}>
          <button
            className={`${btn.btn} ${btn.blue_btn} ${styles.nextBtn}`}
            onClick={() => navigate("/health-record-tutorial")}
            type="button"
          >
            Next
          </button>
        </div>
      </Wrapper>
    </Screen>
  );
};

export default JournalTutorialScreen;
