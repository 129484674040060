import { createSlice } from "@reduxjs/toolkit";
import { generateResetCode, resetPassword, verifyResetCode } from "./Actions/ResetPasswordActions";

const initialState = {
  loading: false,
  error: null,
  isResetPasswordFullfilled: null,
  isResetCodeValid: null,
  resetEmail: null,
  resetCode: null,
  isPasswordReset: null,
};

const selectResetPassword = (state) => state.resetPassword;

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    resetPasswordResetData: (state) => {
      state.loading = false;
      state.error = null;
      state.isResetPasswordFullfilled = null;
      state.isResetCodeValid = null;
      state.resetEmail = null;
      state.isPasswordReset = null;
    },
  },
  extraReducers: {
    // Reset Password Async Reducers
    [generateResetCode.pending]: (state) => {
      state.loading = true;
      state.isResetPasswordFullfilled = false;
    },
    [generateResetCode.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.isResetPasswordFullfilled = true;
      state.resetEmail = payload.email;
    },
    [generateResetCode.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.isResetPasswordFullfilled = false;
    },

    // Reset Password Async Reducers
    [verifyResetCode.pending]: (state) => {
      state.loading = true;
      state.isResetCodeValid = false;
    },
    [verifyResetCode.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.isResetCodeValid = true;
      state.resetCode = payload.code;
    },
    [verifyResetCode.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.isResetCodeValid = false;
    },

    // Reset Password Async Reducers
    [resetPassword.pending]: (state) => {
      state.loading = true;
      state.isPasswordReset = null;
    },
    [resetPassword.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.isPasswordReset = true;
    },
    [resetPassword.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.isPasswordReset = false;
    },
  },
});

export default resetPasswordSlice.reducer;
export { selectResetPassword };
export const {
  resetPasswordResetData
} = resetPasswordSlice.actions;
