import { TextField } from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import Screen from "Components/Helpers/Screen";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/EditHealthCardScreen.module.css";
import moment from "moment";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectHealthInfo, updateHealthCard } from "Reducers/HealthInfoReducer";

const EditHealthCardScreen = () => {
  const { loading, error } = useSelector(selectHealthInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const [dateTime, setDateTime] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiration, setCardExpiration] = useState("");

  const updateHealthCardSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateHealthCard({
        cardNumber,
        cardExpiration,
      })
    );

    // If successful, return to previous screen
    if (!loading && !error) {
      navigate("/health-info");
    }
  };

  return (
    <Screen>
      <WrapperForm onSubmit={updateHealthCardSubmit}>
        <TextField
          className={styles.datetextarea}
          type="datetime-local"
          name="day"
          required={true}
          value={dateTime}
          onChange={(e) => setDateTime(e.target.value)}
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        {errorMessage && (
          <AlertMessage
            sx={{ width: "80%", marginBottom: "15px" }}
            severity="error"
            onClose={() => setErrorMessage("")}
          >
            {errorMessage}
          </AlertMessage>
        )}
        <h1 style={{ margin: "10px" }}>Health Card</h1>
        <TextField
          className={styles.textarea}
          style={{ width: "275px" }}
          label="Card Number"
          type="text"
          placeholder="Enter card number"
          name="name"
          required={true}
          variant="outlined"
          inputProps={{
            maxLength: 256,
          }}
          autoFocus={!isMobile}
          onChange={(e) => setCardNumber(e.target.value)}
          value={cardNumber}
          size="small"
        />
        <TextField
          className={styles.textarea}
          style={{ width: "275px" }}
          label="Expiry Date"
          type="text"
          placeholder="Enter expiry date"
          name="name"
          required={true}
          variant="outlined"
          inputProps={{
            maxLength: 256,
          }}
          onChange={(e) => setCardExpiration(e.target.value)}
          value={cardExpiration}
          size="small"
        />
        <div className={`${btn.center}`}>
          <button
            className={`${btn.journalentrybtn} ${btn.white_btn}`}
            onClick={() => navigate("/health-info")}
            type="button"
          >
            Cancel
          </button>

          <button
            className={`${btn.journalentrybtn} ${btn.orange_btn}`}
            type="submit"
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </WrapperForm>
    </Screen>
  );
};

export default EditHealthCardScreen;
