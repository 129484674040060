import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import Screen from "Components/Helpers/Screen";
import WrapperForm from "Components/Helpers/WrapperForm";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AddWeightScreen.module.css";
import moment from "moment";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addWeight, selectHealthInfo } from "Reducers/HealthInfoReducer";

const AddWeightScreen = () => {
  const { loading, error } = useSelector(selectHealthInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [weight, setWeight] = useState(0);
  const [unit, setUnit] = useState("");

  const handleUnitChange = (e) => {
    setUnit((prevUnit) => {
      convertWeightBasedOnUnit(prevUnit, e.target.value);
      return e.target.value;
    });
  };

  const convertWeightBasedOnUnit = (prevUnit, currUnit) => {
    if (prevUnit === currUnit) {
      return;
    } else if (prevUnit === "kg" && currUnit === "lbs") {
      // convert kg to lbs -> lbs = kg*2.20462
      setWeight((weight * 2.20462).toFixed(2));
    } else if (prevUnit === "lbs" && currUnit === "kg") {
      // convert lbs to kg -> kg = lbs * 0.453592
      setWeight((weight * 0.453592).toFixed(2));
    }
  };

  const addWeightSubmit = (e) => {
    e.preventDefault();

    dispatch(
      addWeight({
        weight,
        unit,
        date,
      })
    );

    // If successful, return to previous screen
    if (!loading && !error) {
      navigate("/health-info");
    }
  };

  return (
    <Screen>
      <WrapperForm onSubmit={addWeightSubmit}>
        <TextField
          className={styles.datetextarea}
          type="datetime-local"
          name="day"
          required={true}
          value={date}
          onChange={(e) => setDate(e.target.value)}
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        {errorMessage && (
          <AlertMessage
            sx={{ width: "80%", marginBottom: "15px" }}
            severity="error"
            onClose={() => setErrorMessage("")}
          >
            {errorMessage}
          </AlertMessage>
        )}
        <h1 style={{ margin: "10px" }}>Weight</h1>
        <div className={styles.inputArea}>
          <Input
            className={styles.textarea}
            label="Weight"
            type="number"
            placeholder="Enter weight"
            name="name"
            required={true}
            variant="none"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
            onChange={(e) => setWeight(e.target.value)}
            value={weight}
            size="small"
            slotProps={{
              input: {
                step: 0.1,
              },
            }}
          />
          <FormControl size="small">
            <InputLabel>Unit</InputLabel>
            <Select
              sx={{ borderRadius: "8px" }}
              className={styles.filterSelect}
              label="Unit"
              value={unit}
              onChange={handleUnitChange}
            >
              <MenuItem value={"lbs"}>lbs</MenuItem>
              <MenuItem value={"kg"}>kg</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={`${btn.center}`}>
          <button
            className={`${btn.journalentrybtn} ${btn.white_btn}`}
            onClick={() => navigate("/health-info")}
            type="button"
          >
            Cancel
          </button>

          <button
            className={`${btn.journalentrybtn} ${btn.orange_btn}`}
            type="submit"
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </WrapperForm>
    </Screen>
  );
};

export default AddWeightScreen;
