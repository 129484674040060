import InfoIcon from "@mui/icons-material/Info";
import { Grid, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import BoxItem from "Components/Helpers/BoxItem";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import styles from "CSS/Screens/ToolKitScreen.module.css";
import { useEffect } from "react";
import { GiSoundOn } from "react-icons/gi";
import { IoIosBody } from "react-icons/io";
import { MdCloudQueue } from "react-icons/md";
import { PiLadder } from "react-icons/pi";
import { RiWindyLine } from "react-icons/ri";
import { TbButterfly } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUserCheckin } from "Reducers/Actions/CheckinActions";
import {
  getUserCheckin,
  selectCheckin,
  toggleCheckinStatus
} from "Reducers/CheckinReducer";


const ToolKitScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isCheckinEnabled } = useSelector(selectCheckin);

  useEffect(() => {
    dispatch(getUserCheckin());
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateUserCheckin(isCheckinEnabled));
  }, [isCheckinEnabled]);

  // Updates the user"s check-in status on the server.
  const handleQuickCheckToggle = () => dispatch(toggleCheckinStatus());

  const handleInfoClick = () => {
    navigate("/tutorial/quick-check");
  };

  return (
    <Screen>
      <Wrapper>
        <h1> Toolkit</h1>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "1em" }}>
          <Grid xs={10} item>
            <Paper elevation={0} className={`${styles.quickCheckContainer}`}>
              <Grid
                container
                spacing={0}
                direction="row"
              >
                <Grid spacing={0}
                  direction="row"
                  container>
                  <Grid xs={11} item>
                    <div className={`${styles.quickCheckHeadingContainer}`}>
                      <h3>Quick Check</h3>
                    </div>
                  </Grid>
                  <Grid xs={1} item>
                    <InfoIcon onClick={handleInfoClick} />
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  item
                  justifyContent="center"
                  alignItems="center"
                  style={{ display: "flex" }}>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isCheckinEnabled}
                          onChange={handleQuickCheckToggle}
                        />
                      }
                      className={`${styles.muiSwitch}`}
                      label={
                        <Typography
                          variant="body2"
                          color="textSecondary">
                          Enable automatic quick check
                        </Typography>}
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <div className={styles.toolKitScreen}>
          <BoxItem
            text="Body Scan"
            icon={<IoIosBody className={styles.toolKitItem_icon} size={24} />}
            link="body-scan"
          />
          <BoxItem
            text="Breathwork"
            icon={<RiWindyLine className={styles.toolKitItem_icon} size={24} />}
            link="breathwork"
          />

          <BoxItem
            text="Butterfly Hug"
            icon={<TbButterfly className={styles.toolKitItem_icon} size={24} />}
            link="butterfly-hug"
          />

          {/* <BoxItem
            text="Cognitive Distortions"
            icon={<BiBrain className={styles.toolKitItem_icon} size={24} />}
            link="cognitive-distortions"
          /> */}
          <BoxItem
            text="Fear Ladder"
            icon={<PiLadder className={styles.toolKitItem_icon} size={24} />}
            link="fear-ladder"
          />
          <BoxItem
            text="Ambient Sound"
            icon={<GiSoundOn className={styles.toolKitItem_icon} size={24} />}
            link="ambient-sound"
          />
          {/* <BoxItem
            text="Behavioral Experiments"
            icon={
              <MdCloudQueue className={styles.toolKitItem_icon} size={24} />
            }
            link="behavioral-experiments"
          /> */}

          <BoxItem
            text="More coming soon..."
            icon={
              <MdCloudQueue className={styles.toolKitItem_icon} size={24} />
            }
            link=" "
          />

        </div>
      </Wrapper>
    </Screen>
  );
};

export default ToolKitScreen;
