import { Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/CognitiveDistortionsScreen.module.css";
import { useRedirect } from "Hooks/useRedirect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addAnswer,
  selectCognitiveDistortion
} from "Reducers/CognitiveDistortionReducer";

const CognitiveDistortionsQuestionScreen = () => {

  const redirectUrl = useRedirect("/toolkit/distortions");
  const {
    thoughts,
    situation,
    selectedDistortions,
  } = useSelector(selectCognitiveDistortion);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNextClick = () => {
    navigate("/toolkit/cognitive-questions-review");
  };

  const handleCancelClick = () => {
    navigate("/toolkit/cognitive-distortions");
  };

  const handleAnswerInputChange =
    (distortionId, questionId, answer) => {
      dispatch(addAnswer({ distortionId, questionId, answer }));
    };

  return (
    <Screen>
      <Wrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}>
          <Grid xs={10} item>
            <Link
              to={redirectUrl}
              className={`${styles.backBtn}`}>
              Back
            </Link>
          </Grid>
        </Grid>
        {
          situation.length > 0 && (
            <Grid
              className={`${styles.marginTop}`}
              container
              direction="row"
              alignItems="center"
              justifyContent="center">
              <Grid xs={10} item>
                <Card
                  variant="outlined"
                  sx={{ minWidth: "100%" }} className={styles.paper}>
                  <CardContent>
                    <Grid container direction="row">
                      <Grid
                        xs={3}
                        item
                        justifyContent="left"
                        alignItems="center"
                        className={`${styles.flexDisplay}`}>
                        <div className={`
                          ${styles.situationIcon} 
                          ${styles.icon}`}></div>
                      </Grid>
                      <Grid
                        xs={9}
                        item
                        style={{ display: "flex", fontSize: "0.9em" }}
                        alignItems="center">
                        <p>{situation}</p>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
        }
        {
          thoughts.length > 0 && (
            <Grid
              className={`${styles.marginTop}`}
              container
              direction="row"
              alignItems="center"
              justifyContent="center">
              <Grid xs={10} item>
                <Card
                  variant="outlined"
                  sx={{ minWidth: "100%" }}
                  className={styles.paper}>
                  <CardContent>
                    <Grid container direction="row">
                      <Grid
                        xs={3}
                        item
                        justifyContent="left"
                        alignItems="center"
                        className={`${styles.flexDisplay}`}>
                        <div className={`
                          ${styles.thoughtIcon} 
                          ${styles.icon}`}></div>
                      </Grid>
                      <Grid
                        xs={9}
                        item
                        style={{ display: "flex", fontSize: "0.9em" }}
                        alignItems="center">
                        <p>{thoughts}</p>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
        }

        {
          (thoughts.length > 0 || situation.length > 0) && (
            <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={`${styles.marginTop}`}
              item={true}>
              <Grid xs={10} item>
                <hr></hr>
              </Grid>
            </Grid>
          )
        }

        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={`${styles.marginTop}`}
          item={true}>
          {/* Questions Starts Here */}
          {
            selectedDistortions.length > 0 &&
            selectedDistortions.map((distortion) => (
              // Questions For Each Distortions
              distortion.distortionQuestions.map((question, index) => (
                <Grid
                  xs={10}
                  item
                  key={index}
                  style={{ marginBottom: "1em" }}>
                  <div>
                    <Grid container style={{ marginBottom: "1em" }}>
                      <Grid xs={2} item>
                        <Avatar
                          alt="Remy Sharp"
                          src=""
                          sx={{ width: 40, height: 40 }} />
                      </Grid>
                      <Grid
                        xs={10}
                        item
                        className={`${styles.flexDisplay}`}
                        alignItems="center">
                        <p>{question.question}</p>
                      </Grid>
                    </Grid>
                    <TextField
                      className={styles.textarea}
                      label=""
                      type="text"
                      multiline
                      rows={2}
                      placeholder=""
                      name="review"
                      required={false}
                      onChange={(e) =>
                        handleAnswerInputChange(
                          distortion._id,
                          question._id,
                          e.target.value)}
                      value={question.answer}
                      variant="outlined"
                      inputProps={{
                        maxLength: 500,
                      }}
                    />
                  </div>
                </Grid>
              ))
            ))
          }

        </Grid>
        {/* Bottom Navigation Buttons */}
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginTop: "2em" }}>
          <Grid item xs={4}>
            <button
              className={`
                ${btn.faded_orange} 
                ${btn.smallActivityBtn} 
                ${styles.loadMoreBtn}`}
              onClick={handleCancelClick}>
              Cancel
            </button>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <button
              className={`
                ${btn.faded_blue} 
                ${btn.smallActivityBtn} 
                ${styles.loadMoreBtn}`}
              onClick={handleNextClick}>
              Next
            </button>
          </Grid>
        </Grid>
      </Wrapper>
    </Screen>
  );
};

export default CognitiveDistortionsQuestionScreen;
