import { createSlice } from "@reduxjs/toolkit";
import { addExposureStep, addFearLadder, deleteExposureStep, getAllFearLadders, getFearLadder, updateExposureStep, updateFearLadder } from "./Actions/FearLadderActions";


const initialState = {
  fearLadders: [],
  selectedFearLadder: null,
  exposureSteps: [],
  selectedStep: null,
  loading: false,
  success: "",
  error: null,
  isPreview: false, // if preview of exposure info, or edit (in ExposureStepDashboard & children)
};


const selectFearLadders = (state) => state.fearLadder;


const fearLadderSlice = createSlice({
  name: "fearLadder",
  initialState,
  reducers: {
    resetFearLadders: () => initialState,

    setSelectedFearLadder: (state, { payload }) => {
      state.selectedFearLadder = payload;
    },
    setSelectedStepById: (state, { payload }) => {
      const { id } = payload;
      const step = state.exposureSteps.find(step => step._id === id);
      state.selectedStep = step || null;
      state.isPreview = state.selectedStep?.status !== "created";
    },

    resetSelectedFearLadder: (state) => {
      state.selectedFearLadder = null;
      state.selectedStep = null;
    },
    resetSelectedStep: (state) => {
      state.selectedStep = null;
      state.isPreview = false;
    },

    setIsPreview: (state, { payload }) => {
      state.isPreview = payload;
    },

    resetSuccess: (state) => {
      state.success = "";
    },
  },
  extraReducers: {

    // Get fear ladders Async Reducers
    [getAllFearLadders.pending]: (state) => {
      state.loading = true;
    },
    [getAllFearLadders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.fearLadders = payload;
    },
    [getAllFearLadders.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add fear ladder Async Reducers
    [addFearLadder.pending]: (state) => {
      state.loading = true;
    },
    [addFearLadder.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.message;
      state.fearLadders.push(payload.fearLadder);
      state.selectedFearLadder = payload.fearLadder;
      state.exposureSteps.push(payload.step);
    },
    [addFearLadder.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add exposure step Async Reducers
    [addExposureStep.pending]: (state) => {
      state.loading = true;
    },
    [addExposureStep.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.message;
      // state.exposureSteps.push(payload.step);
      // state.exposureSteps.sort((a, b) => a.fearLevel - b.fearLevel);
      console.log(`Added exposure step with id: ${payload.step._id}`);
    },
    [addExposureStep.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete exposure step Async Reducers
    [deleteExposureStep.pending]: (state) => {
      state.loading = true;
    },
    [deleteExposureStep.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.message;
      console.log(`Removed exposure step with id: ${payload.removedId}`);
    },
    [deleteExposureStep.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get fear ladder Async Reducers
    [getFearLadder.pending]: (state) => {
      state.loading = true;
    },
    [getFearLadder.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.loading = false;
      state.selectedFearLadder = payload.fearLadder;
      state.exposureSteps = payload.steps;
      state.isPreview = false;
      state.selectedStep = null;
    },
    [getFearLadder.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Update exposure step Async Reducers
    [updateExposureStep.pending]: (state) => {
      state.loading = true;
    },
    [updateExposureStep.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.selectedStep = payload.step;
      state.success = payload.message;
      // reflect change in the steps array
      updateChildInParent(state.exposureSteps, state.selectedStep);
    },
    [updateExposureStep.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Update fear ladder Async Reducers
    [updateFearLadder.pending]: (state) => {
      state.loading = true;
    },
    [updateFearLadder.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.success = payload.message;
      state.selectedFearLadder = payload.fearLadder;
      // update in the fearLadders array
      updateChildInParent(state.fearLadders, state.selectedFearLadder);
    },
    [updateFearLadder.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  }
});

// 2
const updateChildInParent = (parent, updatedChild) => {
  const childIdx = parent.findIndex(item => item._id === updatedChild._id);
  if (childIdx !== -1) {
    return [
      ...parent.slice(0, childIdx),
      updatedChild,
      ...parent.slice(childIdx + 1)
    ];
  }
  return parent;
};

export default fearLadderSlice.reducer;

export {
  addExposureStep,
  addFearLadder,
  deleteExposureStep,
  getAllFearLadders,
  getFearLadder,
  selectFearLadders,
  updateExposureStep,
  updateFearLadder
};

export const {
  resetFearLadders,
  setSelectedFearLadder,
  setSelectedStepById,
  resetSelectedFearLadder,
  resetSelectedStep,
  resetSuccess,
  setIsPreview,
} = fearLadderSlice.actions;