import btn from "CSS/Button.module.css";
import styles from "CSS/Components/Pathway/Pathway.module.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updatePathway } from "Reducers/PathwayReducer";

const Pathway = ({ pathway }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className={styles.pathway}>
      <div className={styles.pathway_header}>
        <h2>{pathway.title}</h2>
        <h4>{pathway?.motivationalSubtitle}</h4>
      </div>
      <div className={styles.pathway_body}>
        <p>
          Desired Outcome: <span>{pathway.desiredOutcome}</span>
        </p>
        <p>
          {pathway?.yourHow && (
            <>
              Your Why: <span>{pathway.yourHow}</span>
            </>
          )}
        </p>
      </div>
      {pathway.isArchived ? (
        <button
          className={`${btn.btn} ${btn.faded_orange}`}
          onClick={() =>
            dispatch(updatePathway({ ...pathway, isArchived: false }))
          }
        >
          Unarchive
        </button>
      ) : (
        <button
          className={`${btn.btn} ${btn.primary}`}
          onClick={() => navigate(`/pathway/${pathway._id}`)}
        >
          View Plan
        </button>
      )}
    </div>
  );
};
export default Pathway;
