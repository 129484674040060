import { Box, Grid, Slider, Typography } from "@mui/material";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectFearLadders } from "Reducers/FearLadderReducer";

const FearLadderSlider = memo(function FearLadderSlider({ question, onChange, marks, value }) {
  const { isPreview } = useSelector(selectFearLadders);

  return (
    <Box>
      <Grid container sx={{ alignItems: "flex-start" }}>
        <Grid item xs={10}>
          {question}

        </Grid>
        <Grid item container xs={2} justifyContent="end"
          sx={{
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              border: "3px solid var(--tangerine-300)",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography variant="body2"
            // sx={{ fontSize: "14px" }}
            >
              {value ? value : ""}
            </Typography>
          </div>
        </Grid>

      </Grid>
      {!isPreview &&
        <>
          <Slider
            getAriaValueText={(val) => `${val}`}
            valueLabelDisplay="off"
            step={1}
            value={value ? value : 5}
            marks
            min={0}
            max={10}
            required={true}
            onChange={onChange}
          // color={touched ? "primary" : "disabled"}
          />
          <Grid container sx={{ alignItems: "center", marginTop: -2 }}>
            <Grid item xs={4} sx={{ textAlign: "start" }}>{marks?.left || "Low"}</Grid>
            <Grid item xs={4} sx={{ textAlign: "center" }}>{marks?.middle}</Grid>
            <Grid item xs={4} sx={{ textAlign: "end" }}>{marks?.right || "High"}</Grid>
          </Grid>
        </>
      }
    </Box>
  );
});

export default FearLadderSlider;