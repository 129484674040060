import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to get a singular health care provider
const getHealthCareProvider = createAsyncThunk("get health care provider", (id, thunkAPI) =>
  actionHandler(
    //retrieve singular health care provider with given ID
    axiosInstance.get(`/healthCareProviders/${id}`),
    thunkAPI
  )
);

// send backend request to fetch logged in users health care providers
// type of health care provider to fetch will be in API request {current, archived, all}
const getHealthCareProviders = createAsyncThunk("get health care providers/", (type, thunkAPI) =>
  actionHandler(
    // get either current, archived, or all health care providers
    axiosInstance.get(`/healthCareProviders?type=${type}`),
    thunkAPI
  )
);

// send backend request to delete specific health care provider
const deleteHealthCareProvider = createAsyncThunk("delete health care provider", (_id, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/healthCareProviders/${_id}`),
    thunkAPI
  )
);

// send backend request to add new health care provider
const addHealthCareProvider = createAsyncThunk("add health care provider", (
  {
    healthCareProviderName,
    field,
    phoneNumber,
    address,
    isArchived,
    additionalNotes,
  }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/healthCareProviders",
      {
        healthCareProviderName,
        field,
        phoneNumber,
        address,
        isArchived,
        additionalNotes,
      }
    ),
    thunkAPI
  )
);

// send backend request to update health care provider
const updateHealthCareProvider = createAsyncThunk("update health care provider", (
  {
    id,
    healthCareProviderName,
    field,
    phoneNumber,
    address,
    isArchived,
    additionalNotes,
  }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/healthCareProviders/${id}`,
      {
        healthCareProviderName,
        field,
        phoneNumber,
        address,
        isArchived,
        additionalNotes,
      },
    ),
    thunkAPI
  )
);

export {
  addHealthCareProvider, deleteHealthCareProvider,
  getHealthCareProvider,
  getHealthCareProviders, updateHealthCareProvider
};

