import styles from "CSS/Components/Treatment.module.css";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Treatment = memo(function temp({ treatment }) {
  const { pathname } = useLocation();
  const [day, setDay] = useState(moment());
  const [time, setTime] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setDay(
      moment(treatment.date).format("MMMM") +
      " " +
      moment(treatment.date).date()
    );
    setTime(
      ", " +
      moment(treatment.date)
        .local()
        .format("hh:mm A")
        .replace(/(^|-)0+/g, "")
    ); // remove leading 0 on date
  }, [treatment.date]);

  return (
    <section className={styles.container}>
      <div
        className={`${styles.info}
            }`}
        onClick={() =>
          navigate(
            `/my-health-record/update-treatment/${treatment._id}?redirect=${pathname}`
          )
        }
      >
        <h3 className={treatment.isInactive ? styles.headerInactive : ""}>
          {treatment.treatmentName.length > 15
            ? `${treatment.treatmentName.substring(0, 15)}...`
            : treatment.treatmentName}
        </h3>
        <p>
          {moment(treatment.date).isSame(moment(), "day")
            ? "🗓️ " + "Today" + time
            : "🗓️ " + day + time}
        </p>
        <p>
          {treatment.additionalNotes.length > 15
            ? `${treatment.additionalNotes.substring(0, 15)}...`
            : treatment.additionalNotes}
        </p>
      </div>
    </section>
  );
});

export default Treatment;
