import PreCheckin from "Components/Checkin/PreCheckin";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/CircleBreathScreen.module.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addPreCheckin, selectCheckin } from "Reducers/CheckinReducer";
import { v1 as uuid } from "uuid";

const BodyScanScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPreCheckActive, setPreCheckActive] = useState(true);
  const [checkinId] = useState(uuid());
  const { isCheckinEnabled } = useSelector(selectCheckin);

  const handlePreCheckIn = async (emotions, emotionRating) => {
    // Updating the view
    setPreCheckActive(false);

    dispatch(
      addPreCheckin({
        checkinId,
        activityId: "BodyScan",
        time: new Date(),
        emotions,
        emotionRating,
      })
    );
  };
  const handlePreCheckInSkip = () => setPreCheckActive(false);
  const handleTaskCompletion = () => navigate(`/post-checkin/${checkinId}`);

  return (
    <Screen>
      <Wrapper>
        {isPreCheckActive && isCheckinEnabled ? (
          <PreCheckin
            handlePreCheckIn={handlePreCheckIn}
            handlePreCheckInSkip={handlePreCheckInSkip}
          />
        ) : (
          <>
            <BackBtn title="Back to toolkit" link="/toolkit" />
            <h1>Body Scan</h1>
            <div
              className={styles.centerAnimation}
              style={{ marginTop: "150px" }}
            >
              <audio controls src={"/audio/Bodyscan.mp3"}></audio>
            </div>
            {isCheckinEnabled && (
              <div>
                <button
                  className={`${btn.btn} ${btn.primary} ${btn.marginTop}`}
                  type="submit"
                  onClick={() => handleTaskCompletion()}
                >
                  Completed
                </button>
              </div>
            )}
          </>
        )}
      </Wrapper>
    </Screen>
  );
};
export default BodyScanScreen;
