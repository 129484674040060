import {
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/AdjustHabitScreen.module.css";
import Chart from "Components/Chart";
import HabitHistoryItem from "Components/Habit/HabitHistoryItem";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import WrapperForm from "Components/Helpers/WrapperForm";
import { useHabitProgress } from "Hooks/useHabitProgress";
import { useRedirect } from "Hooks/useRedirect";
import { getHabitInstances } from "Reducers/Actions/HabitActions";
import {
  getHabit,
  resetInstances,
  selectHabits,
  setInstanceFilter,
  updateHabit
} from "Reducers/HabitsReducer";
import { convertScale } from "Utilities/adjust-scale";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import Confetti from "react-confetti";
import { isMobile } from "react-device-detect";
import {
  HiCheck,
  HiMinus,
  HiOutlineCheckCircle,
  HiOutlineFire,
  HiOutlineScale,
  HiOutlineStar,
  HiPlus
} from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";

const AdjustHabitScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const redirectUrl = useRedirect("/habits");
  const { selectedHabit, error, loading, selectedInstances, instanceFilter } =
    useSelector(selectHabits);
  const {
    progress,
    setProgress,
    increaseProgress,
    decreaseProgress,
    completeProgress,
  } = useHabitProgress(selectedHabit);

  const [manualAdd, setManualAdd] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [filterLimit, setFilterLimit] = useState(instanceFilter);
  const [historyView, setHistoryView] = useState("row");
  const [isConfettiActive, setIsConfettiActive] = useState(false);

  // This function returns an array of the last n days
  // n: integer, number of days to return
  const getLastNDays = (n) => {
    const today = new Date();

    // Create an array of length n and populate it 
    // with date objects for the last n days
    const lastNDays = Array.from({ length: n }, (_, i) => {

      // Create a new date object based on today's date
      const day = new Date(today);

      // Subtract i days from the date object to get 
      // the date of the i-th previous day
      day.setDate(today.getDate() - i);
      return day;
    });
    return lastNDays;
  };

  const getHabitCompletedForDate = (date) => {
    const foundHabit = selectedInstances?.find(habit => moment(habit.date).local().format("MMM Do") === date);
    return foundHabit ? foundHabit.amountCompleted : 0;
  };

  const _buildChartData = (lineChart = false) => {
    // Map instances to labels and data values
    const daysList = getLastNDays(filterLimit).reverse();
    const labels = daysList.map((date) => moment(date).local().format("MMM Do"));
    const dataValues = labels.map((date) => getHabitCompletedForDate(date));

    // Define chart data
    const data = {
      labels,
      datasets: [
        {
          label: selectedHabit?.name,
          data: dataValues,
          // Set different colors depending on lineChart value
          ...(lineChart
            ? {
              borderColor: "#229fc5",
              backgroundColor: "#229fc5",
            }
            : {
              backgroundColor: "#f27254",
            }),
        },
      ],
    };

    return data;
  };

  const data = _buildChartData();
  const lineChartData = _buildChartData(true);

  const setCompletionRate = () => {
    let totalCompleted = selectedHabit?.totalCompleted;
    let totalResets = selectedHabit?.totalResets;
    if (progress / selectedHabit?.unitAmount >= 1) {
      totalCompleted += 1;
      totalResets += 1;
    }

    if (totalCompleted === 0 || totalResets === 0) {
      return "0%";
    }

    return Math.round((totalCompleted / totalResets) * 100) + "%";
  };

  useEffect(() => {
    if (progress === selectedHabit?.unitAmount) {
      setIsConfettiActive(true);
    } else {
      setIsConfettiActive(false);
    }
  }, [progress]);

  const handleManualAdd = () => {
    if (manualAdd === "") return;

    const amount = progress + parseInt(manualAdd);
    setProgress(amount);
    setManualAdd("");
    dispatch(
      updateHabit({
        id: selectedHabit._id,
        ...selectedHabit,
        amountCompleted: amount,
      })
    );
  };

  useEffect(() => {
    dispatch(getHabit(id));

    if (selectedHabit) {
      setProgress(selectedHabit.amountCompleted);
    }

    if (error) {
      setErrorMessage(error);
    }
  }, [selectedHabit?.unitAmount]);

  useEffect(() => {
    dispatch(getHabitInstances({ id, limit: filterLimit }));

    if (error) {
      setErrorMessage(error);
    }

    return () => {
      dispatch(resetInstances());
    };
  }, [filterLimit]);

  const getPathColor = (currentProgress, unitAmount) => {
    return (currentProgress >= unitAmount) ?
      "#0b7ea1" :
      `rgba(242, 114, 84, ${convertScale(currentProgress + 10, unitAmount) / 100})`;
  };

  return (
    <ScreenNoNav>
      {isConfettiActive && <Confetti recycle={false} />}
      <WrapperForm onSubmit={(e) => e.preventDefault()}>
        <div className={styles.header}>
          <Link
            to={redirectUrl}
            className={`${btn.links} ${btn.secondaryLink}`}
          >
            Back
          </Link>
          <h3>{selectedHabit?.name}</h3>
          <Link
            to={`/update-habit/${id}${searchParams.get("redirect")
              ? "?redirect=" + searchParams.get("redirect")
              : `?redirect=/habits/${id}`
              }`}
            className={`${btn.links} ${btn.primaryLink}`}
          >
            Edit
          </Link>
        </div>
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        <div className={`${styles.body} ${isMobile ? styles.bodyMobile : ""}`}>
          <div className={styles.header_info}>
            <div>
              <p className={styles.header_infoText}>
                <HiOutlineCheckCircle className={styles.header_check} />
                Total Completions:{" "}
                {progress / selectedHabit?.unitAmount >= 1
                  ? selectedHabit?.totalCompleted + 1
                  : selectedHabit?.totalCompleted}
                {/* fake UI until the habit actually resets and 'totalCompleted' gets incremented */}
              </p>

              <p className={styles.header_infoText}>
                <HiOutlineScale className={styles.header_balance} />
                Completion Rate: {setCompletionRate()}
              </p>
            </div>
            <div className={styles.streaks}>
              <p className={styles.header_infoText}>
                <HiOutlineStar className={styles.header_star} />
                Best Streak: {selectedHabit?.bestStreak}
              </p>
              <p className={styles.header_infoText}>
                <HiOutlineFire className={styles.header_fire} />
                Current Streak: {selectedHabit?.currentStreak}
              </p>
            </div>
          </div>
          <div className={styles.infoBody}>
            <div className={`${isMobile ? styles.infoMobile : styles.info}`}>
              <CircularProgressbarWithChildren
                className={styles.progressBar}
                value={convertScale(progress, selectedHabit?.unitAmount)}
                styles={buildStyles({
                  rotation: 0.25,
                  strokeLinecap: "round",
                  textSize: "18px",
                  pathTransitionDuration: 0.5,
                  pathColor: `${getPathColor(progress, selectedHabit?.unitAmount)}`,
                  trailColor: "rgba(242, 114, 84, 0.2)",
                  backgroundColor: "#0b7ea1",
                })}
              >
                <p
                  className={`${styles.progressText} ${selectedHabit?.unitAmount.toString().length >= 5
                    ? styles.progressTextSmaller
                    : styles.progressTextLarger
                    }`}
                >
                  {progress} / {selectedHabit?.unitAmount}{" "}
                  {selectedHabit?.unit !== "count" && selectedHabit?.unit}
                </p>
              </CircularProgressbarWithChildren>
            </div>

            <div className={styles.adjust}>
              <button
                className={btn.faded_blue}
                onClick={increaseProgress}
                type="button"
              >
                <HiPlus size={20} />
              </button>
              <button
                className={btn.faded_green}
                onClick={completeProgress}
                type="button"
              >
                <HiCheck size={20} />
              </button>
              <button
                className={btn.faded_orange}
                onClick={decreaseProgress}
                type="button"
              >
                <HiMinus size={20} />
              </button>
            </div>
          </div>
          <div className={styles.field}>
            <div>
              <InputLabel variant="standard" sx={{ maxWidth: "100px" }}>
                <p className={styles.field_add}>Manual add</p>
              </InputLabel>
              <Input
                label="Goal Amount"
                type="number"
                variant="standard"
                placeholder="0"
                value={manualAdd}
                onChange={(e) =>
                  setManualAdd(e.target.value < 0 ? 0 : e.target.value)
                }
                sx={{ width: "60%", mr: 2 }}
                endAdornment={
                  <InputAdornment position="end">
                    {selectedHabit?.unit}
                  </InputAdornment>
                }
              />
              <button
                className={`${btn.links} ${btn.primaryLink}`}
                type="submit"
                onClick={handleManualAdd}
              >
                Add
              </button>
            </div>
          </div>
          <div className={styles.history}>
            <div className={styles.history_header}>
              <Select
                sx={{ borderRadius: "15px", height: "30px" }}
                className={`${styles.medication_filterSelect}`}
                value={historyView}
                onChange={(e) => {
                  setHistoryView(e.target.value);
                }}
              >
                <MenuItem value={"row"}>History</MenuItem>
                <MenuItem value={"graph"}>Chart</MenuItem>
              </Select>
              {/* <h3>History</h3> */}
              <Select
                sx={{ borderRadius: "15px", height: "30px" }}
                className={`${styles.medication_filterSelect}`}
                value={filterLimit}
                onChange={(e) => {
                  setFilterLimit(e.target.value);
                  dispatch(setInstanceFilter(e.target.value));
                }}
              >
                <MenuItem value={"7"}>Past 7 Days</MenuItem>
                <MenuItem value={"30"}>Past 30 Days</MenuItem>
                <MenuItem value={"90"}>Past 90 Days</MenuItem>
                <MenuItem value={"360"}>Past Year</MenuItem>
              </Select>
            </div>
            {selectedInstances?.length === 0 && (
              <p className={styles.history_empty}>
                You have no habit history yet!
              </p>
            )}
            {
              historyView === "row" && (
                <div className={styles.history_body}>
                  {selectedInstances?.map((instance) => (
                    <HabitHistoryItem
                      id={instance._id}
                      key={instance._id}
                      date={instance.date}
                      progress={instance.amountCompleted}
                      unit={selectedHabit?.unit}
                    />
                  ))}
                </div>
              )
            }
            {
              historyView === "graph" && (
                <div className={styles.history_body}>
                  {
                    selectedInstances.length < 10 && (
                      <Chart data={data} type="bar" />
                    )
                  }
                  {
                    selectedInstances.length >= 10 && (
                      <Chart data={lineChartData} type="line" />
                    )
                  }
                </div>
              )
            }
            {loading && <LoadingSpinner />}
          </div>
        </div>
      </WrapperForm>
    </ScreenNoNav>
  );
};
export default AdjustHabitScreen;
