import { Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/SimsDimsEntryScreen.module.css";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectSimsDims, setDimsEntry, setSimsEntry } from "Reducers/SimsDimsReducer";

const SimsDimsEntryScreen = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { simsEntries = [], dimsEntries = [] } = useSelector(selectSimsDims) || {};

    const handleSimsChange = (e) => {
        dispatch(setSimsEntry({ index: 4, value: e.target.value }));
    };

    const handleDimsChange = (e) => {
        dispatch(setDimsEntry({ index: 4, value: e.target.value }));
    };
    const handleBackClick = () => {
        navigate("/toolkit/sims-dims-where");
    };
    const handleNextClick = () => {
        navigate("/toolkit/sims-dims-thinking");
    };

    return (
        <Screen>
            <Wrapper>

                {/* Subtitle */}
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.marginTop}
                >
                    <Grid xs={10} item>
                        <Typography variant="h6" gutterBottom>
                            Who am I interacting with right now?
                        </Typography>
                    </Grid>
                </Grid>

                {/* First Question */}
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.marginTop}
                >
                    <Grid xs={10} item>
                        <div>
                            <p>Which people are a Danger in Me?</p>
                            <TextField
                                className={styles.textarea}
                                label=""
                                type="text"
                                multiline
                                rows={3.5}
                                placeholder=""
                                name="sensations"
                                required={false}
                                onChange={handleDimsChange} // Update to the appropriate handler
                                value={dimsEntries[4]} // Bind value when integrating with backend
                                variant="outlined"
                                inputProps={{
                                    maxLength: 500,
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>

                {/* Second Question */}
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: "2em" }}
                >
                    <Grid xs={10} item>
                        <div>
                            <p>Which people are a Safety in Me?</p>
                            <TextField
                                className={styles.textarea}
                                label=""
                                type="text"
                                multiline
                                rows={3.5}
                                placeholder=""
                                name="thoughts"
                                required={false}
                                onChange={handleSimsChange} // Update to the appropriate handler
                                value={simsEntries[4]} // Bind value when integrating with backend
                                variant="outlined"
                                inputProps={{
                                    maxLength: 500,
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>

                {/* Next Button */}
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: "2em", width: "80%" }}
                >
                    <Grid item={true} xs={4} style={{ textAlign: "left" }}>
                        <button
                            className={`${btn.faded_orange} 
                            ${btn.smallActivityBtn} 
                            ${styles.navBtn}`}
                            onClick={handleBackClick}>
                            Back
                        </button>
                    </Grid>
                    <Grid item={true} xs={4} style={{ textAlign: "right" }}>
                        <button
                            className={`${btn.faded_blue} 
                            ${btn.smallActivityBtn} 
                            ${styles.navBtn}`}
                            onClick={handleNextClick}>

                            Next
                        </button>
                    </Grid>
                </Grid>


            </Wrapper>
        </Screen>
    );
};
export default SimsDimsEntryScreen;
