import AddFab from "Components/Helpers/AddFab";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import UndoButton from "Components/Helpers/UndoButton";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import Treatment from "Components/Treatment";
import styles from "CSS/Screens/TreatmentHistoryScreen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTreatments,
  resetAddTreatmentSuccess,
  resetDeleteTreatmentSuccess,
  selectTreatments,
} from "Reducers/TreatmentsReducer";
import undoTreatment from "Utilities/undo-treatment";

const TreatmentHistoryScreen = () => {
  const { treatment, loading, error, successAdd, successDelete } =
    useSelector(selectTreatments);
  const dispatch = useDispatch();
  const [showUndo, setShowUndo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new treatment!");
      timer = setTimeout(() => {
        dispatch(resetAddTreatmentSuccess());
      }, 2000);
    }
    return timer;
  };

  const handleSuccessDelete = () => {
    if (successDelete) {
      // show undo popup after user deletes entry
      setShowUndo(true);
      setTimeout(() => {
        setShowUndo(false);
        dispatch(resetDeleteTreatmentSuccess());
      }, 5000);
    }
  };

  useEffect(() => {
    // Fetch Treatments
    if (treatment?.length === 0) {
      dispatch(getTreatments({ limit: 100000 }));
    }
    if (error) {
      setErrorMessage(error);
    }
  }, [dispatch, error]);

  useEffect(() => {
    const timer = handleSuccessAdd();
    handleSuccessDelete();

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, successAdd, successDelete]);

  const displayList = (
    list // function to create Treatment Object for each entry in the list
  ) =>
    list?.map((treatment) => (
      <Treatment key={treatment._id} treatment={treatment} />
    ));

  return (
    <Screen>
      <Wrapper>
        <BackBtn title="Back" link="/my-health-record" />
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        <AlertMessage
          message={successMessage}
          setMessage={() => {
            setSuccessMessage("Added new treatment!");
            dispatch(resetAddTreatmentSuccess());
          }}
          type="success"
          toggle={successAdd}
        />

        {loading && <LoadingSpinner />}
        <div className={`${styles.header} ${styles.completedHeader}`}>
          <h3>🏥 Treatment History</h3>
        </div>

        {treatment.inactive?.length === 0 && treatment.active?.length === 0 && (
          <p className={styles.filler}>No treatments!</p>
        )}
        {displayList(treatment)}
      </Wrapper>
      <UndoButton
        callback={() => {
          setShowUndo(false);
          dispatch(resetDeleteTreatmentSuccess());
          undoTreatment(dispatch);
        }}
        showUndo={showUndo}
      />
      <AddFab url="/my-health-record/add-treatment" />
    </Screen>
  );
};

export default TreatmentHistoryScreen;
