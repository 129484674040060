import { createSlice } from "@reduxjs/toolkit";
import {
  addTreatment,
  deleteTreatment,
  getTreatment,
  getTreatments,
  updateTreatment,
} from "./Actions/TreatmentsActions";

// Define the initial set up of one treatment entry
const initialState = {
  treatment: [],
  loading: false,
  error: null,
  successAdd: false,
  successDelete: false,
  selectedTreatment: null,
};

// Functions for "useSelector" on TreatmentHistoryScreen.js
const selectTreatments = (state) => state.treatments;

const treatmentsSlice = createSlice({
  name: "treatments",
  initialState,
  reducers: {
    resetTreatments: () => initialState,
    // For updating, when we only want to reset the error and landing
    resetTreatment: ({ treatments }) => {
      return {
        treatments,
        loading: false,
        error: null,
        successAdd: false,
        successDelete: false,
      };
    },
    resetAddTreatmentSuccess: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    resetDeleteTreatmentSuccess: (state) => {
      return {
        ...state,
        successDelete: false,
      };
    },
  },
  extraReducers: {
    // Fetch Treatment Async Reducers
    [getTreatment.pending]: (state) => {
      state.loading = true;
    },
    [getTreatment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // Check if we need this below:
      state.selectedTreatment = payload; // one treatment object
    },
    [getTreatment.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Fetch Treatments Async Reducers
    [getTreatments.pending]: (state) => {
      state.loading = true;
    },
    [getTreatments.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.treatment = payload;
      sortList(state);
    },
    [getTreatments.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add Treatments Async Reducers
    [addTreatment.pending]: (state) => {
      state.loading = true;
    },
    [addTreatment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
      state.treatment.push(payload); // Push entry to treatment [array]
      sortList(state);
    },
    [addTreatment.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // Update Treatment Async Reducers
    [updateTreatment.pending]: (state) => {
      state.loading = true;
    },
    [updateTreatment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      updateTreatmentFulfilled(state, payload);
      state.selectedTreatment = payload;
      sortList(state);
    },
    [updateTreatment.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete Treatment Async Reducers
    [deleteTreatment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successDelete = true;
      state.treatment = state.treatment.filter(
        (treatment) => treatment._id !== payload._id
      ); //remove entry from history
      sortList(state);
    },
    [deleteTreatment.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successDelete = false;
    },
  },
});

// Helper Functions
// logic for placing the updated treatment into the proper order
const updateTreatmentFulfilled = (state, payload) => {
  // find treatment index and update it
  const idx = state.treatment.findIndex(
    (treatment) => treatment._id === payload._id
  );
  state.treatment[idx] = payload;
};

// sorts all entries based on created time
const sortList = (state) => {
  state.treatment = state.treatment.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
};

export default treatmentsSlice.reducer;
export {
  addTreatment, deleteTreatment, getTreatment, getTreatments, selectTreatments, updateTreatment
};
export const {
  resetTreatments,
  resetTreatment,
  resetAddTreatmentSuccess,
  resetDeleteTreatmentSuccess,
} = treatmentsSlice.actions;
