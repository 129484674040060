const units = [
  {
    label: "count",
    selected: false,
  },
  {
    label: "sec",
    selected: false,
  },
  {
    label: "min",
    selected: false,
  },
  {
    label: "hr",
    selected: false,
  },
  {
    label: "steps",
    selected: false,
  },
  {
    label: "oz",
    selected: false,
  },
  {
    label: "ml",
    selected: false,
  },

  {
    label: "m",
    selected: false,
  },
  {
    label: "km",
    selected: false,
  },
  {
    label: "mile",
    selected: false,
  },
];

export default units;
