import { CardActionArea } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import {
  EMOTION_WEIGHT_COLOR_MAP, EMOTION_WEIGHT_TITLE_MAP
} from "Constants/MonthlyInsightScreen.constant";
import styles from "CSS/Components/JournalInsightEntry.module.css";

/**
 * JournalInsightEntry component displays a single journal entry with date, emotion and time
 * It receives date and value as props
 * @param {*} {date, value: { weight, time }} 
 * @returns 
 */
const JournalInsightEntry = ({ date, value }) => {

  const EMOTION_WEIGHT_STYLE_MAP = {
    6: `${styles.happy} ${styles.emotion_icon}`,
    5: `${styles.energized} ${styles.emotion_icon}`,
    4: `${styles.medium} ${styles.emotion_icon}`,
    3: `${styles.stressed} ${styles.emotion_icon}`,
    2: `${styles.sad} ${styles.emotion_icon}`,
    1: `${styles.pain} ${styles.emotion_icon}`,
    undefined: `${styles.emotion_icon}`,
  };

  return (
    <div>
      <Card sx={{ marginTop: "1em", borderRadius: "20px" }}>
        <CardActionArea>
          <CardContent>
            <Box sx={{ flexGrow: 0 }}>
              <Grid container spacing={2.5}>
                <Grid item xs={2} style={{ alignSelf: "center", fontSize: "2rem" }}>
                  <div className={EMOTION_WEIGHT_STYLE_MAP[value.weight]}></div>
                </Grid>
                <Grid item xs={4} style={{
                  alignSelf: "center",
                  fontSize: "1.2rem",
                  color: EMOTION_WEIGHT_COLOR_MAP[value.weight]
                }}>
                  <div>{EMOTION_WEIGHT_TITLE_MAP[value.weight]}</div>
                </Grid>
                <Grid item xs={6} style={{
                  textAlign: "right",
                  fontSize: "0.8rem",
                  color: "#757575"
                }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <div>{date}</div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>{value.time}</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default JournalInsightEntry;
