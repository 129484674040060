export const convertUTCToLocalTime = (timeString) => {
  // Step 1: Create a Date object for the provided UTC date and time
  const utcDate = new Date(timeString);

  // Step 2: Get the UTC hours and minutes
  const utcHours = utcDate.getUTCHours();
  const utcMinutes = utcDate.getUTCMinutes();

  // Step 3: Create a new Date object for the local time
  const localDate = new Date();

  // Step 4: Set the UTC hours and minutes to the local time
  localDate.setUTCHours(utcHours);
  localDate.setUTCMinutes(utcMinutes);

  // Step 5: Format the local time to "HH:mm" format
  const localTime = `${String(localDate.getHours()).padStart(2, "0")}:${String(
    localDate.getMinutes()
  ).padStart(2, "0")}`;

  return localTime;
};

export const convertLocalTimeToUTC = (timeString) => {
  // Step 1: Split the local time into hours and minutes
  const [hours, minutes] = timeString.split(":").map(Number);

  // Step 2: Get the current date in local time
  const currentDate = new Date();

  // Step 3: Set the hours and minutes to the provided local time
  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);

  // Step 4: Get the UTC hours and minutes
  const utcHours = currentDate.getUTCHours();
  const utcMinutes = currentDate.getUTCMinutes();

  // Step 5: Get the UTC date
  const utcDate = new Date(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate()
  );

  // Step 6: Set the UTC hours and minutes to the UTC date
  utcDate.setUTCHours(utcHours);
  utcDate.setUTCMinutes(utcMinutes);

  return utcDate;
};
