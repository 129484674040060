import styles from "CSS/Screens/JournalScreen.module.css";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import UndoButton from "Components/Helpers/UndoButton";
import Wrapper from "Components/Helpers/Wrapper";
import ImageCarousal from "Components/ImageCarousal";
import AddFabJournal from "Components/Journal/AddFabJournal";
import JournalEntry from "Components/Journal/JournalEntry";
import {
  getJournalEntries,
  resetAddJournalEntrySuccess,
  resetDeleteJournalEntrySuccess,
  selectJournalEntries,
  setSelectedJournal
} from "Reducers/JournalReducer";
import undoJournalEntry from "Utilities/undo-journalEntry";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const JournalScreen = () => {
  const dispatch = useDispatch();
  const [showUndo, setShowUndo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  //const dailyLimit = 50;

  const { past, loading, error, successAdd, successDelete, selectedJournal } =
    useSelector(selectJournalEntries);

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("Added new journal entry!");
      timer = setTimeout(() => {
        dispatch(resetAddJournalEntrySuccess());
      }, 2000);
    }
    return timer;
  };

  // displays undo button when entry is deleted
  const handleSuccessDelete = () => {
    if (successDelete) {
      // show undo popup after user deletes entry
      setShowUndo(true);
      setTimeout(() => {
        setShowUndo(false);
        dispatch(resetDeleteJournalEntrySuccess());
      }, 5000);
    }
  };

  useEffect(() => {
    // fetch all journal entries
    if (past?.length === 0) {
      dispatch(getJournalEntries({ limit: 100000 })); //possible limit
    }

    setErrorMessage(error);
  }, [dispatch, error]);

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();
    handleSuccessDelete();

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, successAdd, successDelete]);

  const displayList = (
    list //function to create JournalEntry object out of each entry in the past list
  ) =>
    list?.map((journalEntry) => (
      <JournalEntry key={journalEntry._id} journalEntry={journalEntry} />
    ));


  const displayCarousal = () => (
    <ImageCarousal
      images={selectedJournal.images}
      type="journal"
      onClose={() => dispatch(setSelectedJournal(null))} />);

  return (
    <>
      <Wrapper>
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />
        <AlertMessage
          message={successMessage}
          setMessage={() => {
            setSuccessMessage("🎉 Added new journal entry!");
            dispatch(resetAddJournalEntrySuccess());
          }}
          type="success"
          toggle={successAdd}
        />

        {loading && <LoadingSpinner />}
        <div
          className={`${styles.journalEntry_header} ${styles.journalEntry_completedHeader}`}
        >
          <h3>📕 Recent Journal Entries</h3>
        </div>
        {past?.length === 0 && (
          <p className={styles.journalEntry_filler}>No past entries!</p>
        )}
        {selectedJournal != null && displayCarousal()}
        {displayList(past)}
      </Wrapper>
      <UndoButton
        callback={() => {
          setShowUndo(false);
          dispatch(resetDeleteJournalEntrySuccess());
          undoJournalEntry(dispatch);
        }}
        showUndo={showUndo}
      />
      <AddFabJournal page="1" />
    </>
  );
};

export default JournalScreen;
