import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to get current access code
const getCode = createAsyncThunk("get access code", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/access-code"),
    thunkAPI
  )
);

// send backend request to get all users
const getUsers = createAsyncThunk("get users", async (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/users"),
    thunkAPI
  )
);

// send backend request to get all feedback
const getFeedback = createAsyncThunk("get feedback", async (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/feedback"),
    thunkAPI
  )
);

// send backend request to update feedback
const updateFeedback = createAsyncThunk("update feedback", ({ id, checked }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/feedback/${id}`, { acknowledged: checked }),
    thunkAPI
  )
);

export { getCode, getFeedback, getUsers, updateFeedback };

