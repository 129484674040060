import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { Avatar, Grid, IconButton, Paper } from "@mui/material";
import { USER_PROFILE_PICTURE_S3_URL } from "Constants/Constants";
import { memo } from "react";

const FriendCard = memo(function temp({ user }) {
  return (
    <Grid item={true} xs={12}>
      <Paper
        elevation={2}
        style={{ padding: "10px", borderRadius: "15px" }}>
        <Grid container
          direction="row"
          alignItems="center">
          <Grid item={true} xs={3}>
            <Avatar
              imgProps={{
                crossOrigin: "anonymous"
              }}
              src={`${user.user.profilePicture != null ? (USER_PROFILE_PICTURE_S3_URL + user.user.profilePicture) : ""}`}
              alt={user.user.firstName}
              sx={{ width: 45, height: 45 }}
            />
          </Grid>
          <Grid item={true} xs={5}>
            <p>{`${user.user.firstName} ${user.user.lastName ? user.user.lastName : ""}`}</p>
          </Grid>
          <Grid item={true} xs={4} container justifyContent="flex-end">
            <IconButton>
              <ChatBubbleIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );

});

export default FriendCard;