import { addBadgeInstance, updateBadgeInstance } from "Reducers/BadgesReducer";

// call this function whenever you want to update the progress on a badge
// params:
//     @badge:     specific badge to update
//     @dispatch:  dispatch from redux toolkit
//     @amount:    amount to add(or subtract) to badge
const addToBadge = (badge, dispatch, amount) => {
  // instance does not yet exist, so create instance of badge
  if (!badge.progress) {
    dispatch(addBadgeInstance({ ...badge, progress: 1 }));
  } else {
    dispatch(
      updateBadgeInstance({
        id: badge._id,
        progress: amount,
      })
    );
  }
};

export { addToBadge };
