import { createSlice } from "@reduxjs/toolkit";
import {
  addJournalEntry,
  deleteJournalEntry,
  getJournalEntries,
  getJournalEntriesForDate,
  getJournalEntry,
  updateJournalEntry
} from "./Actions/JournalActions";

const initialState = {
  past: [], // past journal entries only
  monthlyEntries: [], // journal entries for the selected month (for insights screen)
  loading: false,
  successAdd: false,
  successDelete: false,
  error: null,
  selectedJournalEntry: null,
  page: "1", // 1 for "journal", 2 for "appointment", 3 for "pathway",
  selectedJournal: null,
};

// functions for "useSelector" ok
const selectJournalEntries = (state) => state.journal; // idk if journalEntries or different name[maybe Journal]

const journalSlice = createSlice({
  name: "journal",
  initialState,
  reducers: {
    resetJournals: () => initialState,
    // For updating when we only want to reset the error and loading
    resetJournal: ({ journal }) => {
      return {
        journal,
        loading: false,
        error: null,
        successAdd: false,
      };
    },
    resetAddJournalEntrySuccess: (state) => {
      return {
        ...state,
        successAdd: false,
      };
    },
    resetDeleteJournalEntrySuccess: (state) => {
      return {
        ...state,
        successDelete: false,
      };
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setSelectedJournal: (state, { payload }) => {
      state.selectedJournal = payload;
    },
  },
  extraReducers: {
    // Get journal entry Async Reducers
    [getJournalEntry.pending]: (state) => {
      state.loading = true;
    },
    [getJournalEntry.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedJournalEntry = payload;
    },
    [getJournalEntry.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Fetch journal entries Async Reducers
    [getJournalEntries.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getJournalEntries.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.past = payload;
      sortList(state);
    },
    [getJournalEntries.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Fetch journal entries Async Reducers
    [getJournalEntriesForDate.pending]: (state) => {
      state.loading = true;
    },
    [getJournalEntriesForDate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.monthlyEntries = payload;
    },
    [getJournalEntriesForDate.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add journal entry Async Reducers
    [addJournalEntry.pending]: (state) => {
      state.loading = true;
    },
    [addJournalEntry.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
      state.past.push(payload); // Push entry to past [array]
      sortList(state);
    },
    [addJournalEntry.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // Update journal entry Async Reducers
    [updateJournalEntry.pending]: (state) => {
      state.loading = true;
    },
    [updateJournalEntry.fulfilled]: (state, { payload }) => {
      state.loading = false;
      updateJournalEntryFulfilled(state, payload);
      state.selectedJournalEntry = payload;
      sortList(state);
    },
    [updateJournalEntry.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete journal entry Async Reducers
    [deleteJournalEntry.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successDelete = true;
      state.past = state.past.filter((journal) => journal._id !== payload._id); //remove entry from history
      sortList(state);
    },
    [deleteJournalEntry.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successDelete = false;
    },
  },
});

// helper functions
// logic for placing the updated journal entry into the proper order
const updateJournalEntryFulfilled = (state, payload) => {
  // find journal entry index and update it
  const idx = state.past.findIndex(
    (journalEntry) => journalEntry._id === payload._id
  );
  state.past[idx] = payload;
};

// sorts all past entries based on created time
const sortList = (state) => {
  state.past = state.past.sort((a, b) => new Date(b.date) - new Date(a.date));
};

export default journalSlice.reducer;
export {
  addJournalEntry, deleteJournalEntry, getJournalEntries, getJournalEntriesForDate, getJournalEntry, selectJournalEntries, updateJournalEntry
};
export const {
  resetJournals,
  resetJournal,
  resetAddJournalEntrySuccess,
  resetDeleteJournalEntrySuccess,
  setPage,
  setSelectedJournal,
} = journalSlice.actions;
