import { Modal } from "@mui/material";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/Pathway/AddPathwayScreen.module.css";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import Wrapper from "Components/Helpers/Wrapper";
import NewPathwayIntro from "Components/Pathway/NewPathwayIntro";
import OutcomeList from "Components/Pathway/OutcomeList";
import ReviewAddPathway from "Components/Pathway/ReviewAddPathway";
import { useRedirect } from "Hooks/useRedirect";
import { resetPathway } from "Reducers/PathwayReducer";
import AddHabitScreen from "Screens/Habit/AddHabitScreen";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddPathwayCheckinScreen from "./AddPathwayCheckinScreen";

const AddPathwayScreen = () => {
  const redirectUrl = useRedirect("/pathway");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [pageNum, setPageNum] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  // only prompt 'are you sure' modal if user is at least on the second page
  const cancel = () => {
    if (pageNum > 1) {
      setIsOpen(true);
    } else {
      dispatch(resetPathway());
      navigate(redirectUrl);
    }
  };

  useEffect(() => {
    setPageNum(parseInt(searchParams.get("page")));
  }, [searchParams.get("page")]);

  const renderCancelModal = () => (
    <Modal className={styles.modal} open={isOpen}>
      <div className={styles.modalForm}>
        <h3>Are you sure?</h3>
        <p>
          You will lose <span>all</span> progress if you cancel
        </p>
        <button
          className={`${btn.btn} ${btn.secondary}`}
          onClick={() => {
            dispatch(resetPathway());
            setIsOpen(false);
            navigate(redirectUrl);
          }}
        >
          Leave
        </button>
        <button
          className={`${btn.btn} ${btn.primary}`}
          onClick={() => setIsOpen(false)}
        >
          Close
        </button>
      </div>
    </Modal>
  );

  return (
    <ScreenNoNav>
      <Wrapper>
        <div className={styles.header}>
          <button className={`${btn.links} ${btn.secondaryLink}`} onClick={cancel}>
            Cancel
          </button>
          <h3>New Pathway</h3>
          <div className={styles.progress}>{pageNum} / 5</div>
        </div>
        {renderCancelModal()}
        {pageNum === 1 && <NewPathwayIntro />}
        {pageNum === 2 && <OutcomeList />}
        {pageNum === 3 && <AddHabitScreen />}
        {pageNum === 4 && <AddPathwayCheckinScreen />}
        {pageNum === 5 && <ReviewAddPathway />}
      </Wrapper>
    </ScreenNoNav>
  );
};
export default AddPathwayScreen;
