import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to get a appointment note
const getAppointmentNote = createAsyncThunk("get appointment note", (id, thunkAPI) =>
  actionHandler(
    //retrieve singular note with given ID
    axiosInstance.get(`/appointmentNotes/${id}`),
    thunkAPI
  )
);

// send backend request to fetch logged in users past appointment notes
const getAppointmentNotes = createAsyncThunk("get appointment notes", ({ limit = 2 }, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/appointmentNotes?limit=${limit}`),
    thunkAPI
  )
);

// send backend request to delete specific appointment note
const deleteAppointmentNote = createAsyncThunk("delete appointment note", (_id, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/appointmentNotes/${_id}`),
    thunkAPI
  )
);

// send backend request to add new entry
const addAppointmentNote = createAsyncThunk("add appointment note", (
  {
    date,
    providerName,
    thingsToDiscuss,
    appointmentNotes,
    emotions,
    followUp,
    healthCareProvider,
  }, thunkAPI) =>
  actionHandler(
    axiosInstance.post(
      "/appointmentNotes",
      {
        date, // no need for [date: date] since shorthand shortcut
        providerName,
        thingsToDiscuss,
        appointmentNotes,
        emotions,
        followUp,
        healthCareProvider,
      },
    ),
    thunkAPI
  )
);

// send backend request to update entry
const updateAppointmentNote = createAsyncThunk("update appointment notes", (
  {
    id,
    date,
    providerName,
    thingsToDiscuss,
    appointmentNotes,
    followUp,
    emotions,
    healthCareProvider,
  },
  thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/appointmentNotes/${id}`,
      {
        date,
        providerName,
        thingsToDiscuss,
        appointmentNotes,
        followUp,
        emotions,
        healthCareProvider,
      }
    ),
    thunkAPI
  )
);

export {
  addAppointmentNote, deleteAppointmentNote, getAppointmentNote,
  getAppointmentNotes,
  updateAppointmentNote
};

