import AdditionalNote from "Components/AdditionalNote/AdditionalNote";
import AddFab from "Components/Helpers/AddFab";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import UndoButton from "Components/Helpers/UndoButton";
import Wrapper from "Components/Helpers/Wrapper";
import BackBtn from "Components/Navigation/BackBtn";
import styles from "CSS/Screens/AdditionalNotesHistoryScreen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdditionalNotes,
  resetAddAdditionalNoteSuccess,
  resetDeleteAdditionalNoteSuccess,
  selectAdditionalNotes,
} from "Reducers/AdditionalNoteReducer";
import undoCondition from "Utilities/undo-condition";

const AdditionalNotesHistoryScreen = () => {
  const dispatch = useDispatch();
  const [showUndo, setShowUndo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { additionalNote, loading, error, successAdd, successDelete } =
    useSelector(selectAdditionalNotes);

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new additional note!");
      timer = setTimeout(() => {
        dispatch(resetAddAdditionalNoteSuccess());
      }, 2000);
    }
    return timer;
  };

  const handleSuccessDelete = () => {
    if (successDelete) {
      setShowUndo(true);
      setTimeout(() => {
        setShowUndo(false);
        dispatch(resetDeleteAdditionalNoteSuccess());
      }, 5000);
    }
  };

  useEffect(() => {
    dispatch(getAdditionalNotes({ limit: 100000 }));

    if (error) {
      setErrorMessage(error);
    }
  }, [dispatch, error]);

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();
    handleSuccessDelete();

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, successAdd, successDelete]);

  const displayList = (list) =>
    list?.map((additionalNote) => (
      <AdditionalNote
        key={additionalNote._id}
        additionalNote={additionalNote}
      />
    ));

  return (
    <Screen>
      <Wrapper>
        <BackBtn title="Back" link="/my-health-record" />
        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
        />

        <AlertMessage
          message={successMessage}
          setMessage={() => {
            setSuccessMessage("Added new additional note!");
            dispatch(resetAddAdditionalNoteSuccess());
          }}
          type="success"
          toggle={successAdd}
        />

        {loading && additionalNote?.length === 0 && <LoadingSpinner />}

        <div
          className={`${styles.additionalNotesHistoryScreen_header} ${styles.additionalNotesHistoryScreen_completedHeader}`}
        >
          <h3>📝 Additional Notes</h3>
        </div>
        {additionalNote?.length === 0 && (
          <p className={styles.additionalNotesHistoryScreen_filler}>
            No past additional notes!
          </p>
        )}
        {displayList(additionalNote)}
      </Wrapper>
      <UndoButton
        callback={() => {
          setShowUndo(false);
          undoCondition(dispatch);
        }}
        showUndo={showUndo}
      />

      <AddFab url="/my-health-record/add-additional-note" />
    </Screen>
  );
};

export default AdditionalNotesHistoryScreen;
