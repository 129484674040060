import { createSlice } from "@reduxjs/toolkit";
import {
  addBloodPressure,
  addFamilyHistory,
  addPulse,
  addSugarLevel,
  addTemperature,
  addWeight,
  getBloodPressures,
  getBloodType,
  getFamilyHistorys,
  getHealthCard,
  getHeight,
  getPulses,
  getSugarLevels,
  getTemperatures,
  getWeights,
  updateBloodType,
  updateHealthCard,
  updateHeight,
} from "./Actions/HealthInfoActions";

const initialState = {
  healthInfo: [],
  loading: false,
  successAdd: false,
  successDelete: false,
  error: null,
  bloodType: "",
  healthCard: {},
  height: [],
  weight: [],
  temperature: [],
  bloodPressure: [],
  sugarLevel: [],
  pulse: [],
  familyHistory: [],
};

// Functions for "useSelector"
const selectHealthInfo = (state) => state.healthInfo;

const healthInfoSlice = createSlice({
  name: "healthInfo",
  initialState,
  reducers: {
    // Only need one to reset applicable fields
    resetHealthInfos: () => initialState,
  },
  extraReducers: {
    // Fetch Health Info component entry Async Reducers
    // BLOOD TYPE
    [getBloodType.pending]: (state) => {
      state.loading = true;
    },
    [getBloodType.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.bloodType = payload; // 1 blood type object
    },
    [getBloodType.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // HEALTH CARD
    [getHealthCard.pending]: (state) => {
      state.loading = true;
    },
    [getHealthCard.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.healthCard = payload; // 1 health card object
    },
    [getHealthCard.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // HEIGHT
    [getHeight.pending]: (state) => {
      state.loading = true;
    },
    [getHeight.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      payload.height = payload.height.$numberDecimal;
      state.height = payload; // 1 height object
    },
    [getHeight.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Fetch Health Info component entries Async Reducers
    // WEIGHT
    [getWeights.pending]: (state) => {
      state.loading = true;
    },
    [getWeights.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      payload.weight = payload.weight.$numberDecimal;
      state.weight = payload; // 1 weight object
    },
    [getWeights.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // TEMPERATURE
    [getTemperatures.pending]: (state) => {
      state.loading = true;
    },
    [getTemperatures.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      payload.temperature = payload.temperature.$numberDecimal;
      state.temperature = payload; // 1 temperature object
    },
    [getTemperatures.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // BLOOD PRESSURE
    [getBloodPressures.pending]: (state) => {
      state.loading = true;
    },
    [getBloodPressures.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      payload.bloodPressureSystolic =
        payload.bloodPressureSystolic.$numberDecimal;
      payload.bloodPressureDiastolic =
        payload.bloodPressureDiastolic.$numberDecimal;
      state.bloodPressure = payload;
    },
    [getBloodPressures.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // SUGAR LEVEL
    [getSugarLevels.pending]: (state) => {
      state.loading = true;
    },
    [getSugarLevels.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      payload.sugarLevel = payload.sugarLevel.$numberDecimal;
      state.sugarLevel = payload; // 1 sugar level object
    },
    [getSugarLevels.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // PULSE
    [getPulses.pending]: (state) => {
      state.loading = true;
    },
    [getPulses.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      payload.pulse = payload.pulse.$numberDecimal;
      state.pulse = payload; // 1 pulse object
    },
    [getPulses.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // FAMILY HISTORY
    [getFamilyHistorys.pending]: (state) => {
      state.loading = true;
    },
    [getFamilyHistorys.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.familyHistory = payload;
    },
    [getFamilyHistorys.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add Health Info component entry Async Reducers
    // WEIGHT
    [addWeight.pending]: (state) => {
      state.loading = true;
    },
    [addWeight.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
    },
    [addWeight.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // TEMPERATURE
    [addTemperature.pending]: (state) => {
      state.loading = true;
    },
    [addTemperature.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
    },
    [addTemperature.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // BLOOD PRESSURE
    [addBloodPressure.pending]: (state) => {
      state.loading = true;
    },
    [addBloodPressure.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
    },
    [addBloodPressure.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // SUGAR LEVEL
    [addSugarLevel.pending]: (state) => {
      state.loading = true;
    },
    [addSugarLevel.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
    },
    [addSugarLevel.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // PULSE
    [addPulse.pending]: (state) => {
      state.loading = true;
    },
    [addPulse.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
    },
    [addPulse.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // FAMILY HISTORY
    [addFamilyHistory.pending]: (state) => {
      state.loading = true;
    },
    [addFamilyHistory.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
    },
    [addFamilyHistory.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // Update Health Info component entry Async Reducers
    // BLOOD TYPE
    [updateBloodType.pending]: (state) => {
      state.loading = true;
    },
    [updateBloodType.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.height = payload; // 1 blood type object
    },
    [updateBloodType.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // HEALTH CARD
    [updateHealthCard.pending]: (state) => {
      state.loading = true;
    },
    [updateHealthCard.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.healthCard = payload; // 1 health card object
    },
    [updateHealthCard.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // HEIGHT
    [updateHeight.pending]: (state) => {
      state.loading = true;
    },
    [updateHeight.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.height = payload; // 1 height object
    },
    [updateHeight.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete Health Info component entry Async Reducers
  },
});

export default healthInfoSlice.reducer;
export {
  getBloodType,
  updateBloodType,
  getHealthCard,
  updateHealthCard,
  getHeight,
  updateHeight,
  addWeight,
  getWeights,
  addTemperature,
  getTemperatures,
  addBloodPressure,
  getBloodPressures,
  addSugarLevel,
  getSugarLevels,
  addPulse,
  getPulses,
  addFamilyHistory,
  getFamilyHistorys,
  selectHealthInfo,
};
export const { resetHealthInfos } = healthInfoSlice.actions;
