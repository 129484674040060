import IndividualWidgetHabit from "Components/Habit/IndividualWidgetHabit";
import styles from "CSS/Widgets/HabitsWidget.module.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getHabits, selectHabits } from "Reducers/HabitsReducer";

const HabitsWidget = () => {
  const { habits } = useSelector(selectHabits);
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHabits("current")); // get current habits
  }, [dispatch]);

  const renderHabits = (frequency) =>
    habits[frequency].map((habit) => (
      <IndividualWidgetHabit habit={habit} key={habit._id} />
    ));

  return (
    <section className={styles.habitsWidget_container}>
      <h3 data-testid="todosWidget-title">☀️ Todays Habits</h3>
      <div className={styles.habitsWidget_wrapper}>
        {renderHabits("daily")}
        {renderHabits("weekly")}
        {renderHabits("monthly")}

        <Link
          to={`/add-habit?redirect=${pathname}`}
          className={styles.habitsWidget_fillerButton}
        >
          <span className={styles.habitsWidget_fillerPlus}>+</span>Add new habit
        </Link>
      </div>
    </section>
  );
};

export default HabitsWidget;
