import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import btn from "CSS/Button.module.css";
import styles from "CSS/Screens/CognitiveDistortionsScreen.module.css";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import { useRedirect } from "Hooks/useRedirect";
import { selectBehavioralExperiment, setDetails, setThoughts } from "Reducers/BehavioralExperimentReducer";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const BehavioralViewScreen = () => {

    const redirectUrl = useRedirect("/toolkit/behavioral-experiments");
    const { selectedExperiment } = useSelector(selectBehavioralExperiment);
    const navigate = useNavigate();

    const handleCompleteClick = () => {
        setDetails(selectedExperiment.details);
        setThoughts(selectedExperiment.thoughts);
        navigate("/toolkit/complete-experiment-entry");
    };

    const handleIncompleteClick = () => {
        navigate("/toolkit/incomplete-experiment-entry");
    };

    const handleEditClick = () => {
        navigate("/toolkit/edit-experiment-entry");
    };

    const [day, setDay] = useState(moment());
    const [time, setTime] = useState("");
    useEffect(() => {
        setDay(
            moment(selectedExperiment.date).format("MMMM") +
            " " +
            moment(selectedExperiment.date).date()
        );
        setTime(
            ", " +
            moment(selectedExperiment.date)
                .local()
                .format("hh:mm A")
                .replace(/(^|-)0+/g, "")
        ); // remove leading 0 on date
    }, [selectedExperiment.date]);

    return (
        <Screen>
            <Wrapper>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={`${styles.marginTop}`}>
                    <Grid xs={10} item>
                        <Link
                            to={redirectUrl}
                            className={`${styles.backBtn}`}>
                            Back to experiments
                        </Link>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    className={`${styles.marginTop}`}>
                    <Grid xs={10}
                        item
                        justifyContent="center"
                        alignItems="center"
                        className={`${styles.flexDisplay}`} >

                    </Grid>
                </Grid>
                {
                    selectedExperiment != null && (
                        <Grid xs={10} item>
                            <Grid item
                                sx={{
                                    background: "#fff",
                                    padding: "1.25rem",
                                    borderRadius: "0.5rem",
                                    border: "solid 0.15rem #0b7ea1",
                                    margin: "1rem 0",
                                }}
                            >

                                <Grid item xs={4} className={styles.experimentBox}>
                                    <h2>🧪 Experiment</h2>
                                    <button
                                        className={`
                                            ${btn.faded_blue} 
                                            ${btn.smallActivityBtn} 
                                            ${styles.loadMoreBtn}
                                            ${styles.experimentBoxBtn}`
                                        }
                                        onClick={handleEditClick}>
                                        Edit
                                    </button>
                                </Grid>
                                <div className={styles.selectedExperimentAnswers}>
                                    <h3>Thought being tested</h3>
                                    <p>{selectedExperiment.thoughts}</p>
                                </div>
                                <div className={styles.selectedExperimentAnswers}>
                                    <h3>Experiment details</h3>
                                    <p>{selectedExperiment.details}</p>
                                </div>
                                <div className={styles.selectedExperimentAnswers}>
                                    <h3>Scheduled for:</h3>
                                    <p>
                                        {moment(selectedExperiment.date).isSame(moment(), "day")
                                            ? "🗓️ " + "Today" + time
                                            : "🗓️ " + day + time}
                                    </p>
                                </div>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={4}>
                                        <button
                                            className={`
                                    ${btn.faded_blue} 
                                    ${btn.smallActivityBtn} 
                                    ${styles.loadMoreBtn}`}
                                            onClick={handleCompleteClick}>
                                            ✔ Complete
                                        </button>
                                    </Grid>
                                    <Grid item xs={4} className={`${styles.rightAlign}`}>
                                        <button
                                            className={`
                                    ${btn.faded_orange} 
                                    ${btn.smallActivityBtn} 
                                    ${styles.loadMoreBtn}`}
                                            onClick={handleIncompleteClick}>
                                            ✖ Incomplete
                                        </button>
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Grid item
                                sx={{
                                    background: "#fff",
                                    padding: "1.25rem",
                                    borderRadius: "0.5rem",
                                    border: "solid 0.15rem #0b7ea1",
                                    margin: "1rem 0",
                                }}
                            >
                                <h2>🤔 Prediction</h2>
                                <div className={styles.selectedExperimentAnswers}>
                                    <h3>Expected outcome of the experiment</h3>
                                    <p>{selectedExperiment.prediction}</p>
                                </div>
                                <div className={styles.selectedExperimentAnswers}>
                                    <h3>Expected feeling after experiment:</h3>
                                    <p>{selectedExperiment.feeling} / 10</p>
                                </div>
                            </Grid>
                        </Grid>
                    )
                }
            </Wrapper>
        </Screen>
    );

};

export default BehavioralViewScreen;