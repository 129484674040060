import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

const getUserFriends = createAsyncThunk("users/friends", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/friends"),
    thunkAPI
  )
);


export { getUserFriends };

