import { createSlice } from "@reduxjs/toolkit";
import { getMessagesForUser, sendMessage } from "./Actions/ChatActions";
import { getUserById } from "./Actions/UserActions";

const initialState = {
  loading: false,
  error: null,
  toUser: null,
  messages: [],
};

// functions for "useSelector"
const selectChat = (state) => state.chat;

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetToUser: (state) => {
      state.toUser = null;
    },
    addToMessage: (state, { payload }) => {
      // Check if a message with the same _id already exists
      const messageExists = state.messages.some(message => message._id === payload._id);

      // If the message doesn't exist, add it to the state
      if (!messageExists) {
        state.messages = [...state.messages, payload];
      }
    },
  },
  extraReducers: {
    // Send Messages Async Reducers
    [sendMessage.pending]: (state) => {
      state.loading = true;
    },
    [sendMessage.fulfilled]: (state, { payload }) => {
      state.messages = [...state.messages, payload.message];
      state.loading = false;
    },
    [sendMessage.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Messages for a user Async Reducers
    [getMessagesForUser.pending]: (state) => {
      state.loading = true;
    },
    [getMessagesForUser.fulfilled]: (state, { payload }) => {
      state.messages = payload.messages;
      state.loading = false;
    },
    [getMessagesForUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Messages for a user Async Reducers
    [getUserById.pending]: (state) => {
      state.loading = true;
    },
    [getUserById.fulfilled]: (state, { payload }) => {
      state.toUser = payload;
      state.loading = false;
    },
    [getUserById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default chatSlice.reducer;

export { selectChat };

export const {
  resetToUser,
  setToUser,
  addToMessage,
} = chatSlice.actions;
