import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Button, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import AlertMessage from "Components/Helpers/AlertMessage";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import Screen from "Components/Helpers/Screen";
import Wrapper from "Components/Helpers/Wrapper";
import FearLadderQuestion from "Components/Toolkit/FearLadderQuestion";
import FearLadderSlider from "Components/Toolkit/FearLadderSlider";
import FearLadderSummary from "Components/Toolkit/FearLadderSummary";
import InfoButton from "Components/Toolkit/InfoButton";
import moment from "moment";
import { useEffect, useState } from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getFearLadder, resetSelectedStep, resetSuccess, selectFearLadders, setIsPreview, setSelectedStepById, updateExposureStep } from "Reducers/FearLadderReducer";
import { toggleCompleteToDo } from "Reducers/ToDoReducer";

const ExposureStepDashboardScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ladderId, stepId } = useParams();


  const { selectedStep, selectedFearLadder, error, isPreview, loading, success } = useSelector(selectFearLadders);

  const [completedClick, setCompletedClick] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [errorMessage, setSucc] = useState("");
  const [exposureDetails, setExposureDetails] = useState("");
  const [fears, setFears] = useState("");
  const [preparation, setPreparation] = useState("");
  const [fearProbability, setFearProbability] = useState(null);
  const [seriousness, setSeriousness] = useState(null);
  const [feasibility, setFeasibility] = useState(null);
  const [remindTime, setRemindTime] = useState("");

  useEffect(() => {

    dispatch(getFearLadder(ladderId)).then(() => {
      dispatch(setSelectedStepById({ id: stepId }));
    });

    if (error) {
      setErrorMessage(error);
    }

  }, [ladderId, dispatch, stepId, completedClick, success]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch(resetSuccess());
      }, 2000);
    }
  }, [success]);

  useEffect(() => {
    if (isPreview && selectedStep?.status !== "created") {
      setExposureDetails(selectedStep.exposureDetails);
      setFears(selectedStep.fears);
      setPreparation(selectedStep.preparation);
      setFearProbability(selectedStep.scores?.fearProbability);
      setSeriousness(selectedStep.scores?.seriousness);
      setFeasibility(selectedStep.scores?.feasibility);
      setRemindTime(moment(selectedStep.todos[0]?.remindTime).format("YYYY-MM-DDTHH:mm"));
    }
  }, [selectedStep, isPreview]);

  const handleSaveClick = (e) => {
    e.preventDefault();

    if (!exposureDetails || exposureDetails.trim() === "" ||
      !fears || fears.trim() === "" ||
      !preparation || preparation.trim() === "" ||
      !fearProbability || !seriousness || !feasibility || !remindTime) {
      setErrorMessage("Please fill out all the values before submitting!");
    }
    else {
      if (!moment(remindTime).isAfter(moment())) {
        setErrorMessage("Please enter a valid future date and time.");
      } else { // if everything is valid

        let formattedRemindTime = moment(remindTime).utc();

        dispatch(updateExposureStep({
          ladderId: selectedStep.fearLadder,
          stepId: selectedStep._id,
          status: "planned",
          exposureDetails: exposureDetails,
          fears: fears,
          preparation: preparation,
          remindTime: formattedRemindTime,
          scores: {
            seriousness: seriousness,
            feasibility: feasibility,
            fearProbability: fearProbability,
          }
        }));

        setErrorMessage("");
        dispatch(setIsPreview(true));
      }
    }
  };

  const handleBackClick = () => {
    dispatch(resetSelectedStep());
    navigate(`/toolkit/fear-ladder/${ladderId}`);
  };

  const handleCancelClick = () => {
    if (selectedStep.status === "created") {
      handleBackClick();
    }
    else {
      dispatch(setIsPreview(true));
    }
  };

  const handleCompleted = () => {
    dispatch(toggleCompleteToDo({
      id: selectedStep.todos[0]._id,
      completedDate: new Date(),
      completed: true,
      isFullDay: false,
    })).then(() => {
      setCompletedClick(true);
    });
  };

  return (
    <Screen>
      <Wrapper>
        <Stack onClick={handleBackClick} style={{ marginTop: "-0.25rem", marginBottom: "0.3rem", width: "90%", justifyContent: "flex-start" }} direction="row" spacing={1}>
          <HiArrowNarrowLeft />
          <Typography variant="body1">
            Back
          </Typography>
        </Stack>

        <AlertMessage
          message={errorMessage}
          setMessage={() => setErrorMessage("")}
          type="error"
          toggle={errorMessage}
          styles={{
            position: "sticky",
            top: 80,
            zIndex: 1100,
          }}
        />

        <AlertMessage
          message={success}
          setMessage={() => {
            dispatch(resetSuccess());
          }}
          type="success"
          toggle={success}
        />



        {loading ? <LoadingSpinner /> :
          <Container sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <FearLadderSummary
              fearLadder={selectedFearLadder}
            />
            <br />
            <Grid container spacing={2} display="flex" justifyContent="center" sx={{ justify: "center", alignItems: "center" }}>
              <Grid item xs={10} justifySelf="flex-start">
                {selectedStep?.stepNumber &&
                  <Typography variant="" sx={{ lineHeight: 1.2, fontWeight: 500 }}>
                    Step {selectedStep?.stepNumber}
                  </Typography>
                }
                <Typography variant="h5">
                  {selectedStep?.title}
                </Typography>
                <Typography variant="body2">
                  status: {selectedStep?.status === "created" ? "created" :
                    selectedStep?.status === "planned" ? "scheduled" :
                      "completed"}
                </Typography>
              </Grid>
              <Grid item xs={2} justifyContent="center">
                <CheckCircleOutlineIcon
                  fontSize={"large"}
                  sx={{
                    color: selectedStep?.status === "completed" ? "var(--green-200)" : "var(--grey-100)",
                  }} />
              </Grid>

              <Grid item xs={10} justifySelf="flex-start"><Typography mt="0" variant="h6">Exposure Details</Typography></Grid>
              <Grid item display="flex" justifyContent={"center"} xs={2}>
                {selectedStep?.status === "created" ?
                  <InfoButton title={"Plan your step!"} message={"Now that you created a new step, provide more details and schedule your exposure to advance forward on the ladder."} />
                  :
                  selectedStep?.status === "planned" ?
                    <InfoButton title={"Complete your step!"} message={"Complete your step by toggling your ToDo!"} />
                    :
                    ""
                }

              </Grid>
              <Grid item xs={12}>
                <FearLadderQuestion
                  question="What will you expose yourself to?"
                  onChange={(e) => setExposureDetails(e.target.value)}
                  value={isPreview ? selectedStep?.exposureDetails : exposureDetails}
                  rows={1}
                />
              </Grid>

              <Grid item xs={12}>
                <FearLadderQuestion
                  question="What fears would stop you from completing this step?"
                  onChange={(e) => setFears(e.target.value)}
                  value={fears}
                  rows={1}
                />
              </Grid>

              <Grid item xs={12}>
                <FearLadderSlider
                  question="How likely do you think this will happen?"
                  onChange={(e, val) => setFearProbability(val)}
                  value={fearProbability}
                  isPreview={true}
                />
              </Grid>

              <Grid item xs={12}>
                <FearLadderSlider
                  question="How serious would it be if this happened?"
                  onChange={(e, val) => setSeriousness(val)}
                  value={seriousness}
                />
              </Grid>

              <Grid item xs={12} >

                <Typography variant="body1">
                  When do you plan to complete the activity?
                </Typography>
                {isPreview && selectedStep?.todos?.length > 0 ?
                  <Grid item display="flex" justifyContent="center">
                    <Box justifyContent="center" sx={{ backgroundColor: "#fff", borderRadius: 3, p: 1, width: "100%", border: "1px solid var(--tangerine-300)" }}>
                      <Typography variant="body1">Scheduled on: {moment(remindTime).local().format("MMMM D, YYYY")}</Typography>
                      {selectedStep?.status !== "completed" && <Button onClick={handleCompleted}>Mark Completed!</Button>}
                    </Box>
                  </Grid>
                  :
                  <TextField
                    type="datetime-local"
                    required
                    fullWidth
                    value={remindTime}
                    onChange={(e) => setRemindTime(e.target.value)}
                    variant="outlined"
                    size={"small"}
                    InputLabelProps={{ shrink: true }}
                  />
                }
              </Grid>

              <Grid item xs={12}>
                <FearLadderSlider
                  question="How likely are you to complete the exposure?"
                  onChange={(e, val) => setFeasibility(val)}
                  value={feasibility}
                />
              </Grid>

              <Grid item xs={12}>
                <FearLadderQuestion
                  question="How can you prepare?"
                  onChange={(e) => setPreparation(e.target.value)}
                  value={preparation}
                  rows={1}
                />
              </Grid>

              {!isPreview &&

                <Grid item xs={6}>
                  <Button variant="contained" fullWidth onClick={(e) => handleSaveClick(e)} sx={{ color: "#fff", borderRadius: 4, }} >
                    Save
                  </Button>
                </Grid>
              }
              {!isPreview &&
                <Grid item xs={6}>
                  <Button variant="outlined" fullWidth onClick={(e) => handleCancelClick(e)} sx={{ borderRadius: 4, }}>
                    Cancel
                  </Button>
                </Grid>
              }
              {(selectedStep?.status === "planned" && isPreview) &&
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth onClick={() => dispatch(setIsPreview(false))}
                    sx={{ borderRadius: 4, p: 0.3, backgroundColor: "var(--blue-100)" }}>
                    Edit
                  </Button>
                </Grid>
              }
            </Grid>
          </Container>
        }
      </Wrapper>
    </Screen >
  );
};

export default ExposureStepDashboardScreen;