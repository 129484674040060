import { createSlice } from "@reduxjs/toolkit";
import {
  addCommentToPost,
  createPost,
  getFeedPosts,
  reactToPost,
  searchUsers
} from "./Actions/CommunityActions";

const initialState = {
  loading: false,
  error: null,
  successAdd: false,
  successDelete: false,
  page: "1", // 1 for "feed", 2 for "community", 3 for "friends",
  userSearchResult: [],
  communityPosts: [],
  selectedPost: null,
};

// functions for "useSelector"
const selectCommunity = (state) => state.community;

const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    resetHabits: () => initialState,
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    resetUserSearchResult: (state) => {
      state.userSearchResult = [];
    },
    setSelectedPost: (state, { payload }) => {
      state.selectedPost = payload;
    },
  },
  extraReducers: {
    // Add Community Async Reducers
    [searchUsers.pending]: (state) => {
      state.loading = true;
    },
    [searchUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userSearchResult = payload;
      state.error = null;
    },
    [searchUsers.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Feed For User Async Reducer
    [getFeedPosts.pending]: (state) => {
      state.loading = true;
    },
    [getFeedPosts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const newCommunityPosts = payload.filter(newPost => {
        // Check if the ID of the newPost is not already in state.communityPosts
        return !state.communityPosts.some(existingPost => existingPost.id === newPost.id);
      });

      // Add the filtered new posts to state.communityPosts
      state.communityPosts = [...state.communityPosts, ...newCommunityPosts];
      state.error = null;
    },
    [getFeedPosts.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Feed For User Async Reducer
    [createPost.pending]: (state) => {
      state.loading = true;
    },
    [createPost.fulfilled]: (state, { payload }) => {
      state.loading = false;
      payload.user = {};
      let user = localStorage.getItem("userData");
      if (user) {
        let parsedUser = JSON.parse(user);
        payload.user.profilePicture = parsedUser.profilePicture;
        payload.user.firstName = parsedUser.firstName;
        payload.user.lastName = parsedUser.lastName;
      } else {
        payload.user.profilePicture = "";
      }

      state.communityPosts = [payload, ...state.communityPosts];
      state.error = null;
    },
    [createPost.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Feed For User Async Reducer
    [reactToPost.pending]: (state) => {
      state.loading = true;
    },
    [reactToPost.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const index = state.communityPosts
        .findIndex((post) => post._id === payload._id);
      if (index !== -1) {
        state.communityPosts[index] = payload;
      }
      state.error = null;
    },
    [reactToPost.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Add comment to post async reducers.
    [addCommentToPost.pending]: (state) => {
      state.loading = true;
    },
    [addCommentToPost.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const index = state.communityPosts
        .findIndex((post) => post._id === payload._id);
      if (index !== -1) {
        state.communityPosts[index] = payload;
      }
      state.error = null;
    },
    [addCommentToPost.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default communitySlice.reducer;

export { selectCommunity };

export const {
  setPage,
  resetUserSearchResult,
  setSelectedPost,
} = communitySlice.actions;
