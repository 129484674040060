import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, ImageListItem } from "@mui/material";
import { USER_PROFILE_PICTURE_S3_URL } from "Constants/Constants";
import { memo } from "react";
import Carousel from "react-material-ui-carousel";

const ImageCarousal = memo(function temp({ images, onClose, type }) {

  return (
    <Dialog
      fullScreen={true}
      open={true}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle sx={{ m: 0, p: 4 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Carousel navButtonsAlwaysVisible={true} animation="slide" autoPlay={false}>
          {
            images.map((image) => <ImageListItem key={image.fileName} >
              <img
                crossOrigin="anonymous"
                src={`${USER_PROFILE_PICTURE_S3_URL}${type}/${image.fileName}`}
                alt="selected journal image"
                loading="lazy"
              />
            </ImageListItem>)
          }
        </Carousel>
      </DialogContent>
    </Dialog>
  );
});

export default ImageCarousal;
