import AddIcon from "@mui/icons-material/Add";
import { Avatar, Button, Dialog, DialogActions, DialogTitle, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import LoadingSpinner from "Components/Helpers/LoadingSpinner";
import { addHabitToTeam } from "Reducers/Actions/TeamActions";
import { getHabits, selectHabits } from "Reducers/HabitsReducer";
import { selectTeam } from "Reducers/TeamReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const HabitSelectionDialog = ({ handleClose, open, teamId }) => {

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedHabit, setSelectedHabit] = useState(null);
  const { habits, loading } = useSelector(selectHabits);
  const { selectedTeam } = useSelector(selectTeam);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getHabits("current")); // Fetch habits when component mounts
  }, [dispatch]);

  // Handle click on a habit in the list
  const handleListItemClick = (index, habitId) => {
    setSelectedIndex(index);
    setSelectedHabit(habitId);
  };

  // Handle click on "Create a new habit" button
  const handleCreateHabit = () => {
    navigate(`/add-habit?backUrl=team/${teamId}`);
  };

  // Handle click on "Add Goal" button
  const handleAddGoal = () => {
    dispatch(addHabitToTeam({ teamId, habitId: selectedHabit }));
  };

  // Handle close of the dialog, checking for loading state to prevent closing during loading
  const handleInterDialogClose = () => {
    if (!loading) {
      handleClose();
    }
  };

  return (
    <Dialog onClose={handleInterDialogClose} open={open} sx={{ ".MuiPaper-root": { minWidth: "80%" } }}>
      <DialogTitle>Set a habit for goal</DialogTitle>
      <List sx={{ pt: 0 }}>
        {/* Render habits that are not already selected in the team */}
        {habits && selectedTeam && selectedTeam.habits ?
          [...habits.daily, ...habits.weekly, ...habits.monthly]
            .filter((habit) => !selectedTeam.habits.some((selectedTeamHabit) => selectedTeamHabit._id === habit._id))
            .map((habit, index) => (
              <ListItem disableGutters key={habit._id}>
                <ListItemButton selected={selectedIndex === index} onClick={() => handleListItemClick(index, habit._id)}>
                  <ListItemText
                    primary={habit.name}
                    secondary={`Frequency: ${habit.frequency.charAt(0).toUpperCase()}${habit.frequency.slice(1)}`} />
                </ListItemButton>
              </ListItem>
            ))
          : null}
        <Divider />
        <ListItem disableGutters>
          <ListItemButton autoFocus onClick={handleCreateHabit}>
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Create a new habit" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <DialogActions>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAddGoal} autoFocus>
              Add Goal
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default HabitSelectionDialog;
