import { useSearchParams } from "react-router-dom";

// returns the redirect parameter in the url if it exists,
// returns base otherwise
const useRedirect = (base) => {
  const [searchParams] = useSearchParams();

  if (searchParams.get("redirect")) {
    return searchParams.get("redirect");
  }

  return base;
};

export { useRedirect };
