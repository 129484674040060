import { FormControlLabel, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import AlertMessage from "Components/Helpers/AlertMessage";
import { useProvider } from "Hooks/useProvider";
import { selectHealthCareProviders } from "Reducers/HealthCareProvidersReducer";
import {
  addMedication,
  resetAddMedicationSuccess,
  selectMedications,
} from "Reducers/MedicationsReducer";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import btn from "../../CSS/Button.module.css";
import styles from "../../CSS/Screens/HealthRecordSetupMedicationScreen.module.css";
import Screen from "../../Components/Helpers/Screen";
import WrapperForm from "../../Components/Helpers/WrapperForm";

const HealthRecordSetupProviderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { loading, error } = useSelector(selectHealthCareProviders);
  const { successAdd } = useSelector(selectMedications);

  const [medicationName, setMedicationName] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const { renderAutocomplete, setProviderName, providerName } = useProvider();
  const [isInactive, setIsInactive] = useState(false);
  const [dosage, setDosage] = useState("");
  const [sideEffects, setSideEffects] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState([]);
  const { newlyAddedId } = useSelector(selectHealthCareProviders);

  const handleSuccessAdd = () => {
    let timer;
    if (successAdd) {
      setSuccessMessage("🎉 Added new medication!");
      timer = setTimeout(() => {
        dispatch(resetAddMedicationSuccess());
      }, 2000);
    }
    return timer;
  };

  const handleMedicationSubmit = (event) => {
    event.preventDefault();

    dispatch(
      addMedication({
        medicationName,
        date: moment(date).utc(),
        isInactive,
        prescriber: providerName.healthCareProviderName,
        dosage,
        sideEffects,
        additionalNotes,
        healthCareProvider: newlyAddedId,
      })
    );

    // Reset input fields after submitting new entry
    setMedicationName("");
    setDate("");
    setProviderName("");
    setIsInactive(false);
    setDosage("");
    setSideEffects("");
    setAdditionalNotes([]);
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  // have separate useEffect so we don't make the 3 extra requests when add alert is removed
  useEffect(() => {
    const timer = handleSuccessAdd();

    return () => {
      clearTimeout(timer);
    };
  }, [successAdd]);

  return (
    <Screen>
      <WrapperForm onSubmit={handleMedicationSubmit}>
        <div className={styles.stepDescription}>
          <p className={styles.paragraph}>Add your medications.</p>
          <p className={styles.paragraph}>
            If you want to record a medication you <br /> aren&apos;t taking
            anymore, toggle the <br />
            Active indicator.
          </p>
        </div>
        <div
          className={`${styles.medicationContainer_body} ${
            isMobile ? styles.medicationContainer_bodyMobile : ""
          }`}
        >
          <AlertMessage
            message={errorMessage}
            setMessage={() => setErrorMessage("")}
            type="error"
            toggle={errorMessage}
          />
          <AlertMessage
            message={successMessage}
            setMessage={() => {
              setSuccessMessage("Added new medication!");
              dispatch(resetAddMedicationSuccess());
            }}
            type="success"
            toggle={successAdd}
          />
          <div className={styles.switchField}>
            <FormControlLabel
              control={
                <Switch
                  checked={!isInactive}
                  onChange={(e) => setIsInactive(!e.target.checked)}
                  label="Active Status"
                />
              }
              label={`${isInactive ? "Inactive" : "Active"}`}
            />
          </div>
          <TextField
            className={styles.textField}
            label="Medication"
            type="text"
            placeholder="Enter medication"
            name="name"
            required={true}
            value={medicationName}
            onChange={(e) => setMedicationName(e.target.value)}
            variant="outlined"
            inputProps={{
              maxLength: 256,
            }}
            autoFocus={!isMobile}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Prescription start date"
            type="datetime-local"
            name="day"
            required
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            size="small"
          />
          {renderAutocomplete()}
          <TextField
            className={styles.textField}
            label="Dosage"
            type="text"
            placeholder="Enter dosage"
            name="dosage"
            value={dosage}
            onChange={(e) => setDosage(e.target.value)}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Side effects"
            type="text"
            placeholder="Enter side effects"
            name="sideEffects"
            value={sideEffects}
            onChange={(e) => setSideEffects(e.target.value)}
            variant="outlined"
            multiline
            autoFocus={!isMobile}
            size="small"
          />
          <TextField
            className={styles.textField}
            label="Additional notes"
            type="text"
            placeholder="Any other notes to keep in mind?"
            name="name"
            multiline
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            variant="outlined"
            autoFocus={!isMobile}
            size="small"
          />
          <div className={styles.btnGroup}>
            <button
              className={`${btn.btn} ${btn.faded_blue} ${styles.submitBtn}`}
              type="submit"
            >
              {loading ? "Saving..." : "Save and Add New"}
            </button>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={`${btn.btn} ${btn.blue_btn} ${styles.nextBtn}`}
            onClick={() => navigate("/health-record-additional-note")}
            type="button"
          >
            Continue
          </button>
          <button
            className={styles.skipBtn}
            onClick={() => navigate("/health-record-additional-note")}
            type="button"
          >
            Skip
          </button>
        </div>
      </WrapperForm>
    </Screen>
  );
};

export default HealthRecordSetupProviderScreen;
