import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to get additional notes
const getAdditionalNotes = createAsyncThunk("additional note/", ({ limit = 10 }, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/additional-note?limit=${limit}`),
    thunkAPI
  )
);

// send backend request to retrieve a singular additional note entry
const getAdditionalNote = createAsyncThunk("get additional note", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/additional-note/${id}`),
    thunkAPI
  )
);

// send backend request to add additional note
const addAdditionalNote = createAsyncThunk("add additional note/", ({ additionalNotes, date }, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/additional-note", { additionalNotes, date }),
    thunkAPI
  )
);

// send backend request to update additional note
const updateAdditionalNote = createAsyncThunk("update additional note/", (
  { id, date, additionalNotes }, thunkAPI) =>
  actionHandler(
    axiosInstance.put(`/additional-note/${id}`, { date, additionalNotes }),
    thunkAPI
  )
);

// send backend request to delete specific additional note entry
const deleteAdditionalNote = createAsyncThunk("delete/", (_id, thunkAPI) =>
  actionHandler(
    axiosInstance.delete(`/additional-note/${_id}`),
    thunkAPI
  )
);

export {
  addAdditionalNote, deleteAdditionalNote, getAdditionalNote, getAdditionalNotes, updateAdditionalNote
};

