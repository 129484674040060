/* eslint-disable quotes */
import { Box, LinearProgress } from "@mui/material";
import ScreenNoNav from "Components/Helpers/ScreenNoNav";
import Wrapper from "Components/Helpers/Wrapper";
import styles from "CSS/Screens/Pathway/FinalAddPathwayScreen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetPathway,
  selectPathways,
  setPage,
} from "Reducers/PathwayReducer";

const FinalAddPathwayScreen = () => {
  const { addPathwayInfo } = useSelector(selectPathways);
  const [progress, setProgress] = useState(0);
  const [progressTwo, setProgressTwo] = useState(0);
  const [progressText, setProgressText] = useState(
    'Adding "' + addPathwayInfo.title + '"...'
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // use effect to update text on the screen
  useEffect(() => {
    if (progress < 95 && progressTwo < addPathwayInfo.habits.length) {
      const timer = setInterval(() => {
        setProgressTwo((prevProgress) => (prevProgress += 1));
        setProgressText(
          'Adding "' + addPathwayInfo.habits[progressTwo].name + '"...'
        );
      }, 400);
      return () => {
        clearInterval(timer);
      };
    }
  }, [progressTwo]);

  // use effect to show progress bar
  useEffect(() => {
    if (progress == 88) {
      setProgressText("Almost done!");
    }
    if (progress <= 112) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress += 2));
      }, 50);
      return () => {
        clearInterval(timer);
      };
    } else {
      // finished loading so go to pathway page
      dispatch(setPage("1"));
      dispatch(resetPathway());
      navigate("/pathway");
    }
  }, [progress]);

  return (
    <ScreenNoNav>
      <Wrapper>
        <div className={styles.body}>
          <h1>Creating your personalized pathway</h1>
          <p>{progressText}</p>
          <Box sx={{ width: "90%", marginTop: "15px" }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </div>
      </Wrapper>
    </ScreenNoNav>
  );
};
export default FinalAddPathwayScreen;
