import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SendIcon from "@mui/icons-material/Send";
import { AppBar, Avatar, Divider, Grid, IconButton, InputBase, MenuItem, Paper, Skeleton, Stack, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import styles from "CSS/Screens/CommunityChatScreen.module.css";
import ChatBubble from "Components/Community/ChatBubble";
import { URLS, USER_PROFILE_PICTURE_S3_URL } from "Constants/Constants";
import { getMessagesForUser, sendMessage } from "Reducers/Actions/ChatActions";
import { getUserById } from "Reducers/Actions/UserActions";
import { addToMessage, selectChat } from "Reducers/ChatReducer";
import { selectUser } from "Reducers/UserReducer";
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const CommunityChatScreen = ({ socket }) => {

  const { id } = useParams();

  // State variable for the message
  const [message, setMessage] = useState("");

  // Redux dispatch function
  const dispatch = useDispatch();

  // Selecting the user from the Redux store
  const { user } = useSelector(selectUser);

  // Selecting the toUser and messages from the Redux store
  const { toUser, messages: legacyMessages, loading } = useSelector(selectChat);

  // Reference for scrolling
  const scrollRef = createRef(null);

  // Navigation function from React Router
  const navigate = useNavigate();

  // Effect for getting messages.
  useEffect(() => {
    dispatch(getUserById(id));
    dispatch(getMessagesForUser(id));
  }, [dispatch, id]);

  // Scroll to the bottom of the chat
  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView();
  };

  useEffect(() => {
    if (socket) {
      socket.on("msg-to-client", (message) => {
        dispatch(addToMessage(message));
      });
    }
  }, [socket]);

  const handleSendMessage = () => {
    const messageData = {
      fromUser: user._id,
      toUser: id,
      message: message,
    };

    if (socket) {
      // Send message to socket.
      socket.emit("msg-to-server", messageData);
    }

    // Send message to database.
    dispatch(sendMessage(messageData));

    // Clear the message input
    setMessage("");
  };

  // Effect for scrolling to the bottom when new messages arrive
  useEffect(() => {
    scrollToBottom();
  }, [legacyMessages]);

  const handleChatSubmit = (e) => {
    if (e != null) {
      e.preventDefault();
    }
    handleSendMessage();
    setMessage("");
  };

  return (
    <div className={styles.chatScreenBody}>
      <AppBar
        position="fixed"
        className={styles.chatBar}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate(URLS.COMMUNITY)}
          >
            <KeyboardBackspaceIcon
              sx={{ color: "black" }}
              fontSize="large" />
          </IconButton>
          <MenuItem sx={{ paddingLeft: "0px" }}>
            {
              loading || toUser === null ? (
                <Skeleton variant="circular" width={40} height={40} />
              ) : (
                <Avatar imgProps={{
                  crossOrigin: "anonymous"
                }}
                  src={`${toUser.profilePicture != null ? (USER_PROFILE_PICTURE_S3_URL + toUser.profilePicture) : ""}`}
                  alt={toUser.firstName}
                  sx={{ width: 45, height: 45 }} />
              )
            }
          </MenuItem>
          <Stack style={{ width: "100%" }}>
            <MenuItem className={styles.chatBarItem}>
              {
                loading || toUser === null ? (
                  <Skeleton width="100%" variant="text" sx={{ fontSize: "1rem" }} />
                ) : (
                  <Typography
                    className={styles.chatUserName}
                    textAlign="center">
                    {toUser.firstName}
                  </Typography>
                )
              }
            </MenuItem>
            <MenuItem className={styles.chatBarItem}>
              {
                loading || toUser === null ? (
                  <Skeleton width="40%" variant="text" sx={{ fontSize: "1rem" }} />
                ) : (
                  toUser.isOnline ? (
                    <Typography
                      className={`${styles.chatUserStatus} ${styles.chatUserStatusOnline}`}
                      textAlign="center">
                      Online
                    </Typography>
                  ) : (
                    <Typography
                      className={styles.chatUserStatus}
                      textAlign="center">
                      Offline
                    </Typography>
                  )
                )
              }
            </MenuItem>
          </Stack>
        </Toolbar>
      </AppBar>
      <footer style={{ marginTop: "auto" }}>
        {legacyMessages.map((message) => {
          const isFromToUser = message.fromUser === id;
          const isFromCurrentUser = message.fromUser === user._id;

          return (
            <div key={message._id} style={{ marginBottom: "1em" }}>
              {isFromToUser && (
                <ChatBubble message={message} position="left" />
              )}
              {isFromCurrentUser && (
                <ChatBubble message={message} position="right" />
              )}
            </div>
          );
        })}
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid className={styles.chatInputContainer} xs={12} item>
            <Paper
              onSubmit={handleChatSubmit}
              component="form"
              className={styles.chatInputPaper}
            >
              <InputBase
                sx={{ ml: 2, flex: 1 }}
                placeholder="Write message"
                inputProps={{ "aria-label": "write message" }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton onClick={handleChatSubmit} color="primary" sx={{ p: "10px" }} aria-label="directions">
                <SendIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
        <div ref={scrollRef} />
      </footer>
    </div>
  );
};

export default CommunityChatScreen;