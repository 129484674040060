import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { actionHandler } from "../../Utilities/actionErrorHandler";

// send backend request to create team.
const createTeam = createAsyncThunk("create teams/", (team, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/teams", team),
    thunkAPI
  )
);

// send backend request to get teams for a user.
const getTeams = createAsyncThunk("get teams/", (_, thunkAPI) =>
  actionHandler(
    axiosInstance.get("/teams"),
    thunkAPI
  )
);

// send backend request to get teams for a user.
const searchTeams = createAsyncThunk("search teams/", (searchText, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/teams?name=${searchText}`),
    thunkAPI
  )
);

// send backend request to get teams for a user.
const getTeamById = createAsyncThunk("get team by id/", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/teams/${id}`),
    thunkAPI
  )
);

// send backend request to add members to team.
const addMembersToTeam = createAsyncThunk("add members to team/", (teamIdAndMembers, thunkAPI) =>
  actionHandler(
    axiosInstance.post(`/teams/${teamIdAndMembers.id}/members`,
      { members: teamIdAndMembers.members }),
    thunkAPI
  )
);

// send backend request to add habit to team.
const addHabitToTeam = createAsyncThunk("add habit to team/", (teamIdAndHabitId, thunkAPI) =>
  actionHandler(
    axiosInstance.post("/teams/habit",
      { habitId: teamIdAndHabitId.habitId, teamId: teamIdAndHabitId.teamId }),
    thunkAPI
  )
);

// send backend request to get posts for a team.
const getTeamPosts = createAsyncThunk("get team posts/", (id, thunkAPI) =>
  actionHandler(
    axiosInstance.get(`/teams/${id}/posts`),
    thunkAPI
  )
);

// send backend request to create post for team.
const createTeamPost = createAsyncThunk("create team posts/", async (post, thunkAPI) => {

  // Create a form and append image with additional fields
  const form = new FormData();
  post.images.forEach(image => {
    form.append("images", image);
  });

  form.append("content", post.content);
  form.append("postType", post.postType);
  form.append("teamId", post.teamId);

  actionHandler(
    axiosInstance.post("/posts", form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }),
    thunkAPI
  );
});


export {
  addHabitToTeam, addMembersToTeam,
  createTeam,
  createTeamPost,
  getTeamById,
  getTeamPosts,
  getTeams,
  searchTeams
};

